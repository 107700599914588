// ** React Imports
import { Navigate } from "react-router-dom"
import { Suspense } from "react"

// ** Context Imports
import { AbilityContext } from "@src/utility/context/Can"

// ** Encryption service
import EncryptionDecryptionService from "@service/EncryptionDecryptionService"
import { getModuleRestrictedPages } from "../../../views/role-management/store/permissionList"
import { useSelector } from "react-redux"

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  // const ability = useContext(AbilityContext)
  const [roleAccess, tenantAccess] = useSelector(({ auth: { userData } }) => [
    userData?.currentRoleAccess?.accessRestriction,
    userData?.tenantAccess?.tenantAccessObjects
  ])
  // ** User Data from localStorage
  const user = localStorage.getItem("accessToken")
  function accessCheck(currentRoute) {
    const { meta, menuGroup, accessId, accessMap } = currentRoute

    try {
      const isModuleRestricted =
        getModuleRestrictedPages(tenantAccess).includes(accessMap)
      if (isModuleRestricted) return false
      if (!meta.accessRestricted) return true
      if (roleAccess[accessMap] == undefined) return true
      const check =
        typeof accessMap == "string"
          ? roleAccess[accessMap].access !== "HIDE"
          : accessMap?.map((s) => roleAccess[s].access).some((s) => s != "HIDE")
      if (check) {
        return true
      }
      if (roleAccess && !_.isEmpty(roleAccess)) {
        if (roleAccess[menuGroup]) {
          return roleAccess[menuGroup][accessId] !== "HIDE"
        }
      }
      return false
    } catch (error) {
      return false
    }
  }

  if (route) {
    let restrictedRoute = false

    if (route.meta) {
      restrictedRoute = route.meta.restricted
    }
    if (!user) {
      return <Navigate to="/v2/login" />
    }
    if (user && restrictedRoute) {
      // return <Navigate to={route} />
      // console.log(children)
      // return children
    }
    // if (user && restrictedRoute && user.role === 'client') {
    //   return <Navigate to='/access-control' />
    // }

    // if (user || !accessCheck(route)) {
    //   return <Navigate to='/misc/not-authorized' replace />
    // }
    if (route.meta.accessRestricted) {
      if (accessCheck(route)) {
        return <Suspense fallback={null}>{children}</Suspense>
      } else {
        return <Navigate to="/misc/not-authorized" replace />
      }
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
