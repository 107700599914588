import { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import Select from "react-select"
import { FormFeedback } from "reactstrap"
import classNames from "classnames"
import { cloneDeep } from "lodash"
import { selectThemeColors } from "@utils"

const YupMultiSelectAll = (props) => {
  const {
    name = "customLocation",
    options = [],
    disabled = false,
    onChange = () => {},
    onChangeFieldName = "",
    ...restProps
  } = props

  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext()

  const [selectOptions, setSelectOptions] = useState([])
  const watchValue = watch(name)

  useEffect(() => {
    const optionWithId = options.map((v) => ({
      ...v,
      _id: crypto.randomUUID()
    }))
    const clone = cloneDeep(optionWithId)
    const getLabel = watchValue.map((val) => val._id)
    const isAll = getLabel.includes("All")
    const all = { label: "All", value: options, isDisabled: false, _id: "All" }
    const formatedClone = clone.map((val) => ({ ...val, isDisabled: isAll }))

    if (!options.length) setSelectOptions([])
    else setSelectOptions([all, ...formatedClone])
  }, [options])

  const handleChange = (e) => {
    const getLabel = e.map((val) => val._id)
    const getValues =
      getLabel.includes("All") || options.length === e.length
        ? [selectOptions[0]]
        : selectOptions.filter((v) => getLabel.includes(v._id))

    const getOptions = selectOptions.map((val) => ({
      ...val,
      isDisabled: getValues[0]?.label === "All"
    }))

    setSelectOptions(getOptions)
    setValue(name, getValues)

    onChange(getValues, onChangeFieldName)
  }

  return (
    <>
      <Controller
        name={name}
        id={name}
        control={control}
        render={({ field }) => (
          <Select
            theme={selectThemeColors}
            className={classNames("react-select", {
              "is-invalid": errors[name] && true
            })}
            classNamePrefix="select"
            options={selectOptions}
            isDisabled={disabled}
            isMulti
            isClearable
            {...field}
            onChange={(e) => {
              field.onChange(e)
              handleChange(e)
            }}
            id="select-business-unit"
            {...restProps}
          />
        )}
      />
      {errors[name] && <FormFeedback>{errors[name]["message"]}</FormFeedback>}
    </>
  )
}

export default YupMultiSelectAll
