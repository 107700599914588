import checkTenantId from "../../utility/helper/checkTenantId"
import moment from "moment"

const ALL_BILLS = {
  startUrn: null,
  endUrn: null,
  amountFrom: null,
  amountTo: null,
  entityId: [],
  vendorType: [],
  vendorId: [],
  documentType: [],
  assignTo: [],
  docDateFrom: null,
  docDateTo: null,
  poFrom: null,
  poTo: null,
  isPriority: null,
  documentNumberFrom: null,
  documentNumberTo: null,
  submittedDateFrom: null,
  submittedDateTo: null,
  page: "1",
  count: "25",
  searchTerm: null,
  ispageable: true,
  currentStages: [],
  activeStatues: ["ACTIVE"]
}

const TRACK_PAYMENT = {
  BATCH: {
    page: 1,
    count: 100,
    isPageable: true,
    vendorPagination: false,
    status: [],
    paymentMode: [],
    vendorId: []
  },
  PAYEE_LIST: {
    page: 1,
    count: 100,
    isPageable: true,
    vendorPagination: true,
    status: [],
    paymentMode: [],
    vendorId: []
  },
  defaultView: "BATCH"
}
const MY_BOX = {
  startUrn: "",
  endUrn: "",
  amountFrom: "",
  amountTo: "",
  currentStages: ["AWAITING_ASSESSMENT", "ASSIGNED"],
  entityId: [],
  vendorType: [],
  vendorName: [],
  documentType: [],
  assignTo: [],
  docDateFrom: "",
  docDateTo: "",
  poFrom: "",
  poTo: "",
  isPriority: null,
  documentNumberFrom: "",
  documentNumberTo: "",
  submittedDateFrom: "",
  submittedDateTo: "",
  page: "1",
  count: "25",
  searchTerm: "",
  ispageable: true,
  activeStatues: ["ACTIVE"]
}

const BANKING_RULES = {
  status: [],
  accountId: [],
  primarycategory: [],
  detailcategory: [],
  glCodeId: [],
  fromAmount: "",
  toAmount: "",
  searchTerm: "",
  transactionType: checkTenantId() ? "CASHOUT" : "",
  page: 1,
  count: 100,
  isPageable: true
}
const IN_PROCESS = {
  startUrn: null,
  endUrn: null,
  amountFrom: null,
  amountTo: null,
  currentStages: [
    "Create",
    "Resolve",
    "Awaiting for API",
    "Awaiting approval",
    "Awaiting resolution",
    "API Failed",
    "Followup",
    "Process",
    "AWAITING_ASSESSMENT",
    "ASSIGNED",
    "REJECTED",
    "REVERSED",
    "Awaiting to Post"
  ],
  entityId: [],
  vendorType: [],
  vendorId: [],
  documentType: [],
  assignTo: [],
  docDateFrom: null,
  docDateTo: null,
  poFrom: null,
  poTo: null,
  isPriority: null,
  isSpecialVendor: null,
  documentNumberFrom: null,
  documentNumberTo: null,
  submittedDateFrom: null,
  submittedDateTo: null,
  page: "1",
  count: "25",
  searchTerm: null,
  ispageable: true,
  activeStatues: ["ACTIVE"]
}
const REPORTING_UNPAID_BILL_REPORT = {
  startUrn: null,
  endUrn: null,
  amountFrom: null,
  amountTo: null,
  currentStages: [
    "Create",
    "Resolve",
    "Awaiting for API",
    "Awaiting approval",
    "Awaiting resolution",
    "POSTING_FAILED",
    "Followup",
    "Process",
    "AWAITING_ASSESSMENT",
    "ASSIGNED",
    "REVERSED",
    "REJECTED",
    "Awaiting to Post",
    "Posted",
    "Voided",
    "Credit Applied",
    "Partially Paid",
    "selectedForPayment"
  ],
  entityId: [],
  vendorType: [],
  vendorId: [],
  documentType: [],
  assignTo: [],
  docDateFrom: null,
  docDateTo: null,
  poFrom: null,
  poTo: null,
  isPriority: null,
  isSpecialVendor: null,
  documentNumberFrom: null,
  documentNumberTo: null,
  submittedDateFrom: null,
  submittedDateTo: null,
  page: "1",
  count: "25",
  searchTerm: null,
  ispageable: true,
  activeStatues: ["ACTIVE"]
}
export const CASH_APP = {
  accountId: [],
  type: [],
  customerName: [],
  transactionCategory: ["UNIDENTIFIED", "UNAPPLIED", "APPLIED"],
  page: "1",
  count: "100"
}

const INVOICING = {
  clientEntityId: [],
  documentType: [],
  invoiceStatus: [],
  urnFrom: "",
  urnTo: "",
  invoiceDateFrom: "",
  invoiceDateTo: "",
  customerId: [],
  invoicenoFrom: "",
  invoicenoTo: "",
  searchTerm: "",
  amountFrom: "",
  amountTo: "",
  sortValue: [],
  sortOrder: [],
  page: "1",
  count: "100",
  isPageable: true
}
const CUSTOMER = {
  customerType: "",
  page: 1,
  count: 100,
  customerType: [],
  terms: [],
  paymentMode: [],
  createdFrom: "",
  createdTo: "",
  customerStatus: [],
  clientEntityId: [],
  isActive: "",
  ispageable: true
}
const VENDOR = {
  clientEntityId: "",
  page: "1",
  count: "100",
  selectedVendorType: [],
  vendorStatus: [],
  terms: [],
  paymentMode: [],
  createdFrom: "",
  createdTo: "",
  clientEntityId: [],
  isActive: true,
  sortValue: [],
  sortOrder: [],
  isSpecialVendor: null,
  searchTerm: null,
  ispageable: true
}

const CUSTOMER_INVOICE = {
  clientEntityId: [],
  customerId: [],
  page: "1",
  count: "25"
}

const MANAGE_USERS = {
  clientId: [],
  clientEntityId: [],
  userRole: [],
  status: [],
  page: "1",
  count: "50",
  ispageable: true,
  initial: false
}

const BOOKED_TRANSACTION = {
  accountId: [],
  type: [],
  customerId: [],
  transactionCategory: [
    "BOOKED",
    "MARKASPOSTED",
    "AWAITINGTOPOST",
    "AWAITINGBULKBOOKING",
    "POSTING_FAILED"
  ],
  transFrom: "",
  transTo: "",
  searchTerm: "",
  txnNature: "",
  glCode: "",
  page: 1,
  count: 200,
  ispageable: true,
  clientId: ""
}

const CASH_APP_APPLY_DEPOSITS = {
  accountId: [],
  type: [],
  customerId: [],
  transactionCategory: [],
  page: "1",
  count: "100",
  transFrom: "",
  transTo: "",
  isPageable: true,
  sortDirection: "DESC",
  sortField: "date"
}

const CRATOBOT_RULES = {
  searchTerm: "",
  page: "1",
  count: "25"
}

const PO_LIST = {
  searchTerm: "",
  page: "1",
  count: "25",
  isPageable: true,
  dateTo: "",
  dateFrom: "",
  status: [],
  vendorId: []
}

const CRATOPAY_MAKE_PAYMENTS = {
  payee: [],
  accountId: [],
  paymentType: [],
  status: [
    "UNAPPLIED",
    "UNIDENTIFIED",
    "APPLIED",
    "AWAITINGTOPOST",
    "POSTING_FAILED"
  ],
  paymentFrom: "",
  paymentTo: "",
  searchTerm: "",
  page: 1,
  count: 100,
  sortValue: [],
  sortOrder: []
}
const REPORTING_BANK_TRANSACTION = {
  accountId: [],
  type: [],
  customerId: [],
  transactionCategory: [],
  txnNature: "",
  transFrom: moment().subtract(1, "day").format("YYYY-MM-DD"),
  transTo: moment().format("YYYY-MM-DD"),
  glCode: "",
  searchTerm: "",
  page: 1,
  count: 25
}

const REPORTING_CASH_RECIPT_REPORT = {
  clientId: "",
  accountId: [],
  type: [],
  customerId: [],
  transactionCategory: ["UNCATEGORIZED", "UNIDENTIFIED", "UNAPPLIED"],
  transFrom: "",
  transTo: "",
  searchTerm: "",
  page: 1,
  count: 100,
  sortValue: [],
  sortOrder: [],
  isPageable: true
}

const CASH_BALANCING_REPORT = {
  transFrom: "",
  transTo: "",
  searchTerm: "",
  page: 1,
  count: "100",
  showPending: true,
  sortValue: "",
  sortOrder: ""
}
const REMITTANCE = {
  customerId: [],
  modeOfDelivery: [],
  senderEmail: [],
  amountFrom: "",
  amountTo: "",
  status: [
    // "UNIDENTIFIED",
    "UNAPPLIED",
    "UPLOADED",
    // "EXCEPTION",
    "PROCESSING",
    "APPLIED",
    // "FAILED",
    "NEEDS_REVIEW",
    "AWAITING_TO_POST"
  ],
  remitanceFrom: "",
  remitanceTo: "",
  searchTerm: "",
  sortOrder: "",
  sortValue: "",
  page: 1,
  count: 100
}
export const BUSINESS_USER = {
  BILLS: true,
  VENDOR: false,
  CUSTOMER: false,
  PAYMENT: false,
  TRANSACTION: false,
  PO: false,
  BILLS_ASSESSMENT: false
}

const BU_SUBMITTED_BY_ME = {
  vendorId: [],
  currentStages: []
}

const DEDUCTION_RULES = {
  customerIds: [],
  sortValue: [],
  sortOrder: [],
  page: 1,
  count: 25,
  isPageable: true,
  test: ""
}

const BANK_RECON = {
  PROCESSING: {
    accountId: [],
    accountType: [],
    status: ["PROCESSING", "RECONCILE_INPROCESS"],
    reconcilFrom: "",
    reconcilTo: "",
    page: 1,
    count: 50
  },
  RECONCILED: {
    accountId: [],
    accountType: [],
    status: ["RECONCILED", "REVRSE_INPROCESS"],
    reconcilFrom: "",
    reconcilTo: "",
    page: 1,
    count: 50
  },
  defaultView: "PROCESSING"
}

const CASH_FLOW_TREND = {
  calculatePeriod: false,
  groupMonth: false,
  groupYear: true,
  pageNumber: 1,
  pageSize: "25",
  accountId: [],
  dateFrom: "",
  dateTo: ""
}

const SALES_ENTRIES = {
  clientEntityId: [],
  startDate: "2019-01-01",
  endDate: "2023-11-21",
  clientId: ""
}

const THROUGHPUT_REPORT = {
  startDate: "2019-01-01",
  endDate: "2023-11-21"
}

const CORP_STRUTURE = {
  entityIds: [],
  segmentCode: "Location",
  page: 1,
  count: 50,
  isPageable: true
}

const DEDUCTION = {
  page: 1,
  count: 25,
  status: [],
  isPageable: true
}

export const MY_BOX_ACTIVE_TAB = {
  myBox: true,
  myActivities: false,
  uploadDocuments: false
}
const CUSTOMER_SUB_FILTER = {
  page: 1,
  count: 50,
  isPageable: true,
  isActive: true,
  searchTerm: "",
  filterType: "INCLUDE"
}
const SUB_FILTER_CUSTOMER_APPLY_DEPOSIT = { ...CUSTOMER_SUB_FILTER }
const SUB_FILTER_CUSTOMER_REMITTANCE = { ...CUSTOMER_SUB_FILTER }
const SUB_FILTER_CUSTOMER_INVOICE = { ...CUSTOMER_SUB_FILTER }
const SUB_FILTER_CUSTOMER_DEDUCTION = { ...CUSTOMER_SUB_FILTER }
const SUB_FILTER_CUSTOMER_DEDUCTION_CREATE_MODAL = { ...CUSTOMER_SUB_FILTER }
const SUB_FILTER_CUSTOMER_APPLY_DEPOSIT_MODAL = { ...CUSTOMER_SUB_FILTER }
const SUB_FILTER_CUSTOMER_APPLY_DEPOSIT_CREATE_CUSTOMER = {
  ...CUSTOMER_SUB_FILTER
}
const SUB_FILTER_CUSTOMER_APPLY_DEPOSIT_CREATE_NON_INVOICE = {
  ...CUSTOMER_SUB_FILTER
}
const SUB_FILTER_CUSTOMER_DEDUCTION_LIST = { ...CUSTOMER_SUB_FILTER }

const SUB_FILTERS = {
  SUB_FILTER_CUSTOMER_APPLY_DEPOSIT,
  SUB_FILTER_CUSTOMER_REMITTANCE,
  SUB_FILTER_CUSTOMER_INVOICE,
  SUB_FILTER_CUSTOMER_DEDUCTION,
  SUB_FILTER_CUSTOMER_DEDUCTION_CREATE_MODAL,
  SUB_FILTER_CUSTOMER_APPLY_DEPOSIT_MODAL,
  SUB_FILTER_CUSTOMER_APPLY_DEPOSIT_CREATE_CUSTOMER,
  SUB_FILTER_CUSTOMER_APPLY_DEPOSIT_CREATE_NON_INVOICE,
  SUB_FILTER_CUSTOMER_DEDUCTION_LIST
}

export const INITIAL_VALUES = {
  ACTIVE_FILTERS: [],
  ...SUB_FILTERS,
  ALL_BILLS,
  MY_BOX_ACTIVE_TAB,
  BANKING_RULES,
  BU_SUBMITTED_BY_ME,
  IN_PROCESS,
  CASH_APP,
  INVOICING,
  PO_LIST,
  CUSTOMER,
  VENDOR,
  CASH_APP_APPLY_DEPOSITS,
  MANAGE_USERS,
  BOOKED_TRANSACTION,
  MY_BOX,
  CRATOBOT_RULES,
  CUSTOMER_INVOICE,
  CRATOPAY_MAKE_PAYMENTS,
  REPORTING_BANK_TRANSACTION,
  REPORTING_CASH_RECIPT_REPORT,
  REPORTING_UNPAID_BILL_REPORT,
  CASH_BALANCING_REPORT,
  REMITTANCE,
  BUSINESS_USER,
  DEDUCTION_RULES,
  BANK_RECON,
  CASH_FLOW_TREND,
  TRACK_PAYMENT,
  SALES_ENTRIES,
  THROUGHPUT_REPORT,
  CORP_STRUTURE,
  DEDUCTION
}
