import Avatar from "@components/avatar"
import { useState, useEffect } from "react"
import * as Icon from "react-feather"
import { useSelector } from "react-redux"
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap"
import dataConfig from "../../../../configs/dataConfig"
import { ShortListSkeleton } from "@custom-components/skeleton/Loader"
import { getPerformanceMatrix } from "../../Service/Service"

const getDayFilterfn = (val) => {
  switch (val) {
    case "Last Day":
      return "lastDay"
    case "Last 7 Days":
      return "lastWeek"
    case "Last Month":
      return "lastMonth"
    case "Last Quarter":
      return "lastQuarter"
    case "Last Year":
      return "lastYear"
    default:
      return ""
  }
}

const PerformanceMatrix = () => {
  const { userData } = useSelector((state) => state.auth)
  const [dateSelect] = useState([
    "Last 7 Days",
    "Last Month",
    "Last Quarter",
    "Last Year"
  ])
  const colors = [
    "light-warning",
    "light-warning",
    "light-danger",
    "light-warning",
    "light-info"
  ]
  const icons = [
    Icon["TrendingUp"],
    Icon["RotateCw"],
    Icon["HelpCircle"],
    Icon["CheckCircle"],
    Icon["Clock"]
  ]
  const [selectedRange, setSelectedRange] = useState("Last Month")
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setLoader(true)
    getPerformanceMatrix(userData.clientId)
      .then((res) => {
        //console.log(res.data)
        setData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoader(false)
      })
    return () => setData(null)
  }, [])
  //console.log(data)
  const renderTransactions = () => {
    console.log(data)
    return (
      data &&
      data.map((item, index) => {
        const MatrixIcon = icons[index]
        const selectedRangeData = getDayFilterfn(selectedRange)
        const { brief = "", description = "" } =
          dataConfig.performanceMetrics[item.category]
        //console.log(item[selectedRangeData])
        return (
          <div key={index} className="transaction-item" id={`perf${index}`}>
            <div className="d-flex align-items-center">
              <UncontrolledTooltip placement="top" target={`perf${index}`}>
                {description}
              </UncontrolledTooltip>
              <Avatar
                className="rounded"
                color={colors[index]}
                icon={<MatrixIcon size={18} />}
              />
              <div>
                <h6 className="transaction-title">{item.category}</h6>
                <div
                  className="font-small-2"
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "12vw"
                  }}
                >
                  {brief}
                </div>
              </div>
            </div>
            <div className="fw-bold">{`${item[selectedRangeData].days} d ${item[selectedRangeData].hours} h ${item[selectedRangeData].minutes} m`}</div>
          </div>
        )
      })
    )
  }

  return (
    <Card className="card-transaction">
      <CardHeader>
        <CardTitle className="flex-center" tag="h4">
          Performance Metrics
        </CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
            id="performance-metrics-date-btn"
          >
            {selectedRange}
          </DropdownToggle>
          <DropdownMenu end id="select-performance-metrics-date">
            {dateSelect.map((item) => (
              <DropdownItem
                className="w-100"
                key={item}
                onClick={() => setSelectedRange(item)}
              >
                {item}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      <CardBody>
        {loader ? <ShortListSkeleton /> : renderTransactions()}
      </CardBody>
    </Card>
  )
}

export default PerformanceMatrix
