// ** Reactstrap Imports
import {
  Card,
  CardBody,
  CardText,
  Button,
  Row,
  Col,
  CardTitle,
  DropdownToggle,
  DropdownItem,
  UncontrolledButtonDropdown,
  DropdownMenu
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { Grid, Server, ShoppingBag } from "react-feather"
import { ReactComponent as UploadLogo } from "../../../assets/images/svg/upload-img.svg"

const FileUplod = ({ tenantAccess }) => {
  const navigate = useNavigate()
  const uploadOptions = [
    {
      name: "AP Bills",
      Icon: ShoppingBag,
      to: "/v2/uploadDocuments",
      module: "Bill_Processing"
    },
    {
      name: "AR Remitances",
      Icon: Server,
      to: "/v2/ar/revenue/remittance-list?upload=active",
      module: "Cash_Application"
    },
    {
      name: "Bank Statements",
      Icon: Grid,
      to: "/v2/cratomatch/uploadBank-tnxs",
      module: "Banking"
    }
  ].filter(({ module }) => tenantAccess[module])

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">File Upload</CardTitle>
        <CardText className="font-small-3">
          You can upload your documents here.
        </CardText>
        {false && <UploadLogo style={{ width: "100%", margin: "1rem auto" }} />}
        <CardText
          className="font-small-3 mb-0"
          // style={{ whiteSpace: "nowrap" }}
        >
          Please choose the module and file type as per the
        </CardText>
        <CardText
          className="font-small-3 mb-1"
          // style={{ whiteSpace: "nowrap" }}
        >
          document
        </CardText>
        <Row className="match-height">
          <Col>
            {uploadOptions.length == 1 ? (
              (() => {
                const [{ to, name }] = uploadOptions
                return (
                  <Button
                    className="cratoButton"
                    color="primary"
                    size="md"
                    onClick={() => navigate(to)}
                    id="file-upload"
                  >
                    {/* <Icon style={{ marginRight: "1rem" }} /> */}
                    Upload {name}
                  </Button>
                )
              })()
            ) : (
              <UncontrolledButtonDropdown>
                <DropdownToggle color="primary" caret>
                  Upload
                </DropdownToggle>
                <DropdownMenu style={{ width: "-webkit-fill-available" }}>
                  {uploadOptions.map(({ to, Icon, name }) => (
                    <DropdownItem to={to} tag={Link} s>
                      <Icon size={15} style={{ marginRight: "1rem" }} />
                      {name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default FileUplod
