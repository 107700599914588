// ** Navigation imports
import dashboards from "./dashboards"
import expenses from "./expenses"
import revenue from "./revenue"
import cratoLedge from "./crato-ledge"
import cratoMatch from "./crato-match"
import analytics from "./analytics"
// import settings from "./settings"
import reportings from "./reportings"
import Ledger from "./Ledger"
// import myBox from "./my-box"

// ** Nav filter
import { getNavs } from "../router/routes/routesControler"

// ** Merge & Export
export default getNavs([
  ...dashboards,
  // ...myBox,
  ...expenses,
  ...revenue,
  ...cratoLedge,
  ...cratoMatch,
  ...analytics,
  ...Ledger,
  ...reportings
  // ...settings
])
