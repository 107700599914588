import "./styles.scss"
export default function TickMark({ active }) {
  return (
    <div id="tickMark">
      <div class="wrapper">
        <svg
          class={`checkmark ${active ? "active" : ""}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class={`checkmark__circle  ${active ? "active" : ""}`}
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            class={`checkmark__check  ${active ? "active" : ""}`}
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
    </div>
  )
}
