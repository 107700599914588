// ** React Import
import React from 'react'
import { Input, Row, Col } from 'reactstrap'

const SalesDashboardHeader = () => {
    return (
        <div className='invoice-list-table-header w-100 my-1'>
            <Row>
                <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
                    <div className='d-flex align-items-center me-2'>
                        <label htmlFor='rows-per-page'>Show</label>
                        <Input
                            type='select'
                            id='rows-per-page'
                            className='form-control mx-50 pe-3'
                        >
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='200'>200</option>
                        </Input>
                    </div>
                </Col>
                <Col
                    lg='6'
                    className='actions-right d-flex align-items-center justify-content-lg-end flex-lg-nowrap flex-wrap mt-lg-0 mt-1 pe-lg-1 p-0'
                >
                    <div className='d-flex align-items-center'>
                        <label htmlFor='search-invoice'>Search</label>
                        <Input
                            id='search-invoice'
                            className='ms-50 me-2 w-100'
                            type='text'
                        // onChange={(e) => onSearch(e.target.value)}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SalesDashboardHeader