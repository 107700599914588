import { Fragment, useEffect } from "react"
import { Card, Button, ListGroup, ListGroupItem } from "reactstrap"
import { useDropzone } from "react-dropzone"
import { FileText, X, DownloadCloud } from "react-feather"
import _ from "lodash"

const FileUploaderSingle = ({
  files,
  setFiles,
  setupload,
  seterFiles,
  postDetails,
  setpostDetails
}) => {
  const uploadDocument = (res) => {
    const fileStore = _.cloneDeep(files)
    if (fileStore.length > 0) {
      res.map((value) => {
        const newFile = new File(
          [value],
          value.name.replaceAll(/[\\s+&=: #]/g, "-"),
          { type: value.type }
        )
        newFile.preview = URL.createObjectURL(newFile)
        newFile.unFormatedName = value.name
        const dup = fileStore.filter((v) => v.name === newFile.name)
        if (!dup.length) {
          fileStore.push(newFile)
        }
      })
    } else {
      res.map((value) => {
        const newFile = new File(
          [value],
          value.name.replaceAll(/[\\s+&=: #]/g, "-"),
          { type: value.type }
        )
        newFile.preview = URL.createObjectURL(newFile)
        newFile.unFormatedName = value.name
        fileStore.push(newFile)
      })
    }
    setFiles(fileStore)
  }
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: "application/pdf",
    onDrop: (Result) => {
      uploadDocument(Result)
    },
    onDropRejected: (result) => {
      seterFiles(result)
    }
  })

  useEffect(() => {
    if (files.length) {
      setupload(true)
    } else {
      setupload(false)
    }
  }, [files])
  const renderFilePreview = (file) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          className="rounded"
          alt={file.name}
          src={URL.createObjectURL(file)}
          height="28"
          width="28"
        />
      )
    } else {
      return <FileText size="28" />
    }
  }

  const handleRemoveFile = (file, i) => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter((i) => i.name !== file.name)
    setFiles([...filtered])
    postDetails.splice(i, 1)
    setpostDetails(postDetails)
  }

  const renderFileSize = (size) => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
    }
  }

  const fileList = files.map((file, index) => (
    <ListGroupItem
      key={`${file.name}-${index}`}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="file-details d-flex align-items-center">
        <div className="file-preview me-1">{renderFilePreview(file)}</div>
        <div>
          <p className="file-name mb-0 text-wrap text-break">
            {file.unFormatedName}
          </p>
          <p className="file-size mb-0">{renderFileSize(file.size)}</p>
        </div>
      </div>
      <Button
        color="danger"
        outline
        size="sm"
        className="btn-icon"
        onClick={() => handleRemoveFile(file, index)}
        id="file-delete-btn"
      >
        <X size={14} />
      </Button>
    </ListGroupItem>
  ))

  const handleRemoveAllFiles = () => {
    setFiles([])
    seterFiles([])
    setpostDetails([])
    setupload(false)
  }

  return (
    <Card
      onClick={() => {
        // setupload(true)
      }}
    >
      <div
        {...getRootProps({ className: "dropzone mt-2" })}
        style={{ minHeight: "140px", minWidth: "140px" }}
      >
        <input {...getInputProps()} id="upload-pdf-files" />
        <div className="d-flex align-items-center justify-content-center flex-column p-1">
          <DownloadCloud size={35} />
          <p className="fw-bold" style={{ fontSize: "12px" }}>
            Drop <span className="fw-bolder">PDF</span> Files here or click to
            upload <span className="fw-bolder">PDF</span>
          </p>
          <p className="text-secondary">
            <a href="/" onClick={(e) => e.preventDefault()}></a>
          </p>
        </div>
      </div>
      {files.length ? (
        <Fragment>
          <ListGroup className="my-2">{fileList}</ListGroup>
          <div className="d-flex justify-content-center">
            <Button
              className="me-1"
              color="danger"
              outline
              onClick={handleRemoveAllFiles}
              id="remove-all-btn"
            >
              Remove All
            </Button>
          </div>
        </Fragment>
      ) : null}

      <Button
        type="submit"
        color="primary"
        block
        className="mt-2 mb-0"
        onClick={(e) => {
          e.preventDefault()
          // setupload(true)
        }}
        id="add-document"
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <a href="/" onClick={(e) => e.preventDefault()}></a>
          Add Document
        </div>
      </Button>
    </Card>
  )
}

export default FileUploaderSingle
