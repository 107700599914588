// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { handleLogout } from "../../../redux/authentication"

// export const getAllEntities = createAsyncThunk('entities/getAll', async () => {
//     const response = await axios.get(`${GET_ALL_CUSTOM_ROLE}${TENANT_ID}`)
//     return {
//         data: response.data
//     }
// })

const initialState = {
  activeEntity: {},
  activeCompany: {},
  clientEntityList: []
  // allEntityList: []
}
export const orgSlice = createSlice({
  name: "organizationStructure",
  initialState,
  reducers: {
    updateEntityList: (state, action) => {
      state.clientEntityList = action.payload
    },
    // updateAllEntityList: (state, action) => {
    //   state.allEntityList = action.payload
    // },
    updateActiveEntity: (state, action) => {
      state.activeEntity = action.payload
    },
    updateActiveCompany: (state, action) => {
      state.activeCompany = action.payload
    },
    updateOrganizationStructure: (state, action) => {
      state.clientEntityList = action.payload.clientEntityList
      // state.allEntityList = action.payload.allEntityList
      state.activeEntity = action.payload.activeEntity
      state.activeCompany = action.payload.activeCompany
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogout.type, () => initialState)
  }
})

export const {
  updateEntityList,
  // updateAllEntityList,
  updateActiveEntity,
  updateActiveCompany,
  updateOrganizationStructure
} = orgSlice.actions

export default orgSlice.reducer
