import { useEffect, useState } from "react"
import axios from "axios"
import qs from "qs"
const defaultOptions = {
  apiParams: {
    method: "GET"
  },
  noFetch: false,
  callback: (d) => d
}
const useFetch = (
  url,
  payload = {
    apiParams: {
      method: "GET"
    },
    callback: (d) => d
  }
) => {
  const options = { ...defaultOptions, ...payload }
  const noFetch = options.noFetch
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [isFirstLoad, setisFirstLoad] = useState(true)

  const fetchData = async () => {
    try {
      let apiParams = [url]
      if (options?.apiParams) {
        apiParams = [
          ...apiParams,
          {
            ...options.apiParams,
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: "repeat" })
          }
        ]
      }
      setLoading(true)
      const res = await axios(...apiParams)
      const modifiedResponse = options.callback(res.data)
      setData(modifiedResponse)
      return modifiedResponse
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      if (isFirstLoad) setisFirstLoad(false)
    }
  }
  const { apiParams = {} } = options
  const { params = {}, data: payloadData = {} } = apiParams
  useEffect(() => {
    if (noFetch) return
    fetchData()
  }, [noFetch, JSON.stringify(params), JSON.stringify(payloadData)])

  return [
    data,
    {
      loading,
      refetch: async () => await fetchData(),
      setData,
      isFirstLoad
    }
  ]
}

export default useFetch
