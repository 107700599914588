import React from "react"
import { Button, Spinner } from "reactstrap"

const ProgressButton = (props) => {
  const {
    disabled = false,
    loader = false,
    name = props.children ?? "Save",
    color = "primary",
    loaderName = props.children ?? "Saving",
    ...params
  } = props
  return (
    <Button
      color={color}
      disabled={disabled || loader}
      {...params}
      id={params.id ?? "save-link-now"}
    >
      {loader ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner size="sm" />
          <span className="align-middle ms-25">{loaderName}</span>
        </div>
      ) : (
        <span>{name}</span>
      )}
    </Button>
  )
}

export default ProgressButton
