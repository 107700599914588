// ** Redux Imports
import rootReducer from "./rootReducer"
import { configureStore } from "@reduxjs/toolkit"
import logger from "redux-logger"

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_TYPE !== "prod",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      // serializableCheck: false
    }).concat(logger)
  }
})

export { store }
