import * as XLSX from "xlsx"
import { NativeToolTip } from "@utils"
import { ListGroup, ListGroupItem } from "reactstrap"
import { AlertCircle, XCircle } from "react-feather"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

export const handleXlFile = async (file) => {
  return new Promise((resolve, reject) => {
    if (!file) return reject("File not provided")

    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    reader.onload = (e) => {
      const bstr = e.target.result
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true
      })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const _data =
        XLSX.utils.sheet_to_json(ws, {
          raw: false
        }) ?? []

      const wb2 = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        sheetRows: 1
      })
      const wsname2 = wb2.SheetNames[0]
      const ws2 = wb2.Sheets[wsname2]
      const headers =
        XLSX.utils.sheet_to_json(ws2, {
          raw: false,
          header: 1
        })?.[0] ?? []

      const defaultValue = {}
      for (const item of headers) {
        defaultValue[item] = ""
      }
      const data = _data
        .filter((row) => {
          if (Object.values(row).some((value) => value !== "")) return true
          return false
        })
        .map((v) => ({ ...defaultValue, ...v }))

      resolve({ data, headers })
    }

    reader.onerror = (error) => reject(error)

    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  })
}

export const failureHtmlContent = (invalidFiles) => {
  const invalidFilesList = (
    <ol>
      {invalidFiles.map((v, i) => (
        <ListGroupItem
          className="d-flex justify-content-between align-items-center"
          key={i}
          style={{ width: "24rem" }}
        >
          <NativeToolTip value={v?.file?.name ?? "-"} style={{ margin: 0 }}>
            <span>{v?.file?.name ?? "-"}</span>
          </NativeToolTip>
          <AlertCircle color="red" />
        </ListGroupItem>
      ))}
    </ol>
  )
  return (
    <div className="px-1">
      <div className="mb-1 text-start d-flex align-items-center">
        <span className="text-danger me-50 text-align-start">
          <XCircle />
        </span>{" "}
        <div>
          <strong>{invalidFiles.length}</strong> documents failed due to invalid
          format.
        </div>
      </div>

      <ListGroup className="pl-0">{invalidFilesList}</ListGroup>
    </div>
  )
}

export const successHtmlContent = (count) => (
  <div className="px-1">
    <div className="mb-1 text-start d-flex align-items-center">
      {/* <span className="text-primary me-50">
        <CheckCircle />
      </span>{" "} */}
      <div>
        <strong>{count}</strong> document(s) uploaded successfully.
      </div>
    </div>
  </div>
)
export const warningHtmlContent = (fileName, count) => (
  <div>
    <h3>
      <strong>Warning !</strong>
    </h3>
    <p className="mb-1">
      <strong>"{fileName ? fileName : "File"}"</strong> has {count} incomplete
      rows with blank values, please remove those rows before import
    </p>
  </div>
)

export const swalFire = ({ ...props }) => {
  const MySwal = withReactContent(Swal)
  return MySwal.fire({
    customClass: { confirmButton: "btn btn-primary" },
    buttonsStyling: false,
    ...(props ?? {})
  })
}

export const handleDownloadTemplate = (headers) => {
  // const mandatoryFields = headers
  //   .map(({ value }) => (mandatoryHeaders.includes(value) ? "Mandatory" : ""))
  //   .join(",")

  const headerFields = headers.map(({ label }) => label).join(",")
  const csv = headerFields //.join("\r\n")

  const uri = `data:text/csv;charset=utf-8,${escape(csv)}`
  const link = document.createElement("a")
  link.href = uri
  link.download = `Line Item template.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
