// ** React Import
import React from "react"
import { Badge } from "reactstrap"

// ** Third-Party Import
import moment from "moment"

// ** Utils Import
import { TableStatusFormatter, statusColor } from "@utils"

const salesJournalTableColunm = (userData, link, entity) => {
  return [
    {
      name: "Txn.Number",
      sortable: true,
      sortField: "salesId",
      cell: (row) => (
        <div onClick={() => link(row.salesId)}>
          {row.salesId ? row.salesId : ""}
        </div>
      )
    },
    // {
    //     name: 'Pos Name',
    //     sortable: true,
    //     sortField: '',
    //     cell: (row) => <div onClick={() => link(row.salesId)}>

    //     </div>
    // },
    {
      name: "Txn.Date",
      center: true,
      sortable: true,
      sortField: "salesDate",
      cell: (row) => (
        <div onClick={() => link(row.salesId)}>
          {" "}
          {row.salesDate
            ? moment(row.salesDate).format(userData.basicProfile.dateFormat)
            : ""}
        </div>
      )
    },
    {
      name: "Posting Date",
      center: true,
      sortable: true,
      sortField: "postingDate",
      cell: (row) => (
        <div onClick={() => link(row.salesId)}>
          {row.postingDate
            ? moment(row.postingDate).format(userData.basicProfile.dateFormat)
            : ""}
        </div>
      )
    },
    {
      name: "Description",
      minWidth: "280px",
      maxWidth: "280px",
      cell: (row) => (
        <div onClick={() => link(row.salesId)}>{row.description}</div>
      )
    },
    {
      name: "Business Unit",
      center: true,
      sortable: true,
      sortField: "",
      cell: (row) => (
        <div onClick={() => link(row.salesId)}>
          {(entity ?? []).find((v) => v.value === row?.clientEntityId)?.label ??
            "-"}
        </div>
      )
    },
    {
      name: "Status",
      center: true,
      sortable: true,
      sortField: "status",
      cell: (row) => (
        <div onClick={() => link(row.salesId)}>
          <Badge pill color={statusColor(row.status)}>
            {TableStatusFormatter(row.status)}
          </Badge>
        </div>
      )
    }
  ]
}

export default salesJournalTableColunm
