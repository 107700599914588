// ** Imports
import axios from "axios"
import {
  uploadDocumentsUrl,
  clientApi,
  invoiceApi
} from "@service/apiUrl"
import {
  allEntityApi,
  getBankSummaryUrl,
  getInvoiceBillCount
} from "../../../services/apiUrl"

export const uploadDocumentApi = (filename, clientId) => {
  console.log(filename)
  return axios.get(
    `${uploadDocumentsUrl}?fileName=${filename}&clientId=${clientId}&File-Extension=pdf`
  )
}

export const getAllClientApi = () => {
  return axios.get(`${clientApi}`)
}
export const getAllEntity = (clientId) =>  axios.get(`${allEntityApi}/${clientId}`)

export const postInvoiceApi = (payload) => {
  // console.log(payload)
  return axios.post(`${invoiceApi}`, payload)
}

export const uploadUrlApi = (url, name) => {
  return axios.put(url, name, {
    headers: {
      "Content-Type": "application/pdf",
      "File-Extension": "pdf"
    }
  })
}

export const getBillProcessingData = (clientId) =>
  `${getInvoiceBillCount}/billprocesscount?clientId=${clientId}`

export const getTop5Vendor = (clientId) => {
  return axios.get(`${getInvoiceBillCount}/topvendor?clientId=${clientId}`)
}

export const getTopExceptions = (clientId) => {
  return axios.get(`${getInvoiceBillCount}/topexceptions?clientId=${clientId}`)
}

export const getTopExpense = (clientId) => {
  return axios.get(`${getInvoiceBillCount}/topexpense?clientId=${clientId}`)
}

export const getPerformanceMatrix = (clientId) => {
  return axios.get(
    `${getInvoiceBillCount}/performancematrix?clientId=${clientId}`
  )
}

export const getBillPayments = (clientId) => {
  return axios.get(`${getInvoiceBillCount}/billpayments?clientId=${clientId}`)
}

export const getBankAccSummary = (clientId, startDate, endDate) => {
  return axios.get(
    `${getBankSummaryUrl}/${clientId}?startDate=${startDate}&endDate=${endDate}`
  )
}
