import React, { Fragment } from "react"
import { Row, Col, Card } from "reactstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
export const tRows = () => {
  return (
    <>
      <div
        style={{
          padding: ".5rem 1rem",
          display: "flex",
          gap: "1rem",
          width: "100%"
        }}
      >
        <div style={{ flexGrow: "2" }}>
          <div
            style={{
              height: "40px"
            }}
          >
            <Skeleton baseColor="#ebebeb" height={40} />
          </div>
        </div>
      </div>
      {new Array(10).fill(
        <div
          style={{
            padding: ".5rem 1rem",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            width: "100%"
          }}
        >
          <div
            style={{
              height: "50px",
              aspectRatio: "1",
              borderRadius: "50%",
              background: "#ebebeb",
              overflow: "hidden"
            }}
          >
            {/* <Skeleton baseColor="#ebebeb" height="500" /> */}
          </div>
          <div style={{ flexGrow: "1" }}>
            <div
              style={{
                height: "18px",
                marginLeft: "1rem"
              }}
            >
              <Skeleton baseColor="#ebebeb" />
            </div>
          </div>
          <div style={{ flexGrow: "2" }}>
            <div
              style={{
                height: "18px"
              }}
            >
              <Skeleton baseColor="#ebebeb" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export const Skeletone = (isloading) => {
  return (
    isloading && (
      <Fragment>
        <SkeletonTheme>
          <div className="m-1 ">
            <Row>
              <Col className="m-1 ms-6">
                <Skeleton width={75} height={30} />
              </Col>

              <Col className="m-1 ">
                <Skeleton height={30} />
              </Col>
            </Row>

            {tRows()}
          </div>
        </SkeletonTheme>
      </Fragment>
    )
  )
}

export const AgingSkeleton = () => (
  <SkeletonTheme>
    <Skeleton
      height={70}
      style={{ width: "100%", padding: "0.72rem", marginBottom: "1.5rem" }}
    />
    <Skeleton height={200} style={{ width: "100%", padding: "0.72rem" }} />
  </SkeletonTheme>
)

export const BreadCrumbSkeleton = () => {
  return (
    <SkeletonTheme>
      <Skeleton width={450} height={30} />
    </SkeletonTheme>
  )
}

export const Skul = (isloading) => {
  return (
    <SkeletonTheme>
      <div>
        <Row className="mb-3">
          <Col>{isloading ? <Skeleton height={90} /> : <p></p>} </Col>
        </Row>
      </div>
    </SkeletonTheme>
  )
}
export const SkulFilter = () => (
  <SkeletonTheme>
    <div style={{ marginRight: "1rem" }}>
      <Row>
        <Col>
          <Skeleton height={90} />
        </Col>
      </Row>
    </div>
  </SkeletonTheme>
)

export const SkeletonInvoice = (isloading) => {
  return (
    <SkeletonTheme>
      <div>
        <Row className="m-2">
          <Col lg="7">
            {isloading ? <Skeleton height={35} width={120} /> : <p></p>}
          </Col>
          <Col lg="2" sm="8" md="2" className=" w-25">
            {isloading ? (
              <Skeleton
                height={
                  35
                } /* width={200} */ /* style={{ marginLeft: '100%' }} */
              />
            ) : (
              <p></p>
            )}
          </Col>
          <Col lg="2" sm="4" md="3" className=" w-20">
            {isloading ? (
              <Skeleton
                height={
                  35
                } /*  width={180} */ /* style={{ marginLeft: '50%' }}  */
              />
            ) : (
              <p></p>
            )}
          </Col>
        </Row>
      </div>
      <div className="mt-3">
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
      </div>
    </SkeletonTheme>
  )
}

export const SkeletonApplyDeposit = (isloading) => {
  return (
    <SkeletonTheme>
      <div>
        <Row className="m-2">
          <Col lg="7" sm="7">
            {isloading ? <Skeleton height={30} width={120} /> : <p></p>}
          </Col>
          <Col lg="5" sm="5" className=" w-20">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
      </div>
      <div className="mt-1">
        <Row className="m-1">
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
      </div>
    </SkeletonTheme>
  )
}

export const SkeletonBillprocessing = (isloading) => {
  return (
    <SkeletonTheme>
      <div>
        <Row className="mb-2 mt-1 ms-1 me-0">
          <Col sm="10" lg="4">
            {isloading ? <Skeleton width={120} height={40} /> : <p></p>}
          </Col>
          <Col sm="5" lg="3" className="w-20">
            {isloading ? <Skeleton height={40} /> : <p></p>}
          </Col>
          <Col sm="10" lg="4" className="ms-3">
            {isloading ? <Skeleton height={40} /> : <p></p>}
          </Col>
        </Row>
        <Row>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
      </div>
    </SkeletonTheme>
  )
}

export const SkeletonRemittence = (isloading) => {
  return (
    <SkeletonTheme>
      <div>
        <Row className="m-2">
          <Col lg="6">
            {isloading ? <Skeleton height={35} width={120} /> : <p></p>}
          </Col>
          <Col lg="3" sm="8" md="2" className="me-2 w-25">
            {isloading ? (
              <Skeleton
                height={
                  35
                } /* width={200} */ /* style={{ marginLeft: '100%' }} */
              />
            ) : (
              <p></p>
            )}
          </Col>
          <Col lg="2" sm="4" md="3" className="ms-5 ">
            {isloading ? (
              <Skeleton
                height={
                  35
                } /*  width={180} */ /* style={{ marginLeft: '50%' }}  */
              />
            ) : (
              <p></p>
            )}
          </Col>
        </Row>
      </div>
      <div className="mt-3">
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
      </div>
    </SkeletonTheme>
  )
}

export const SkeletonDeduction = (isloading) => {
  return (
    <SkeletonTheme>
      <div>
        <Row className="m-2">
          <Col lg="6">
            {isloading ? <Skeleton height={35} width={120} /> : <p></p>}
          </Col>
          <Col lg="3" sm="8" md="2" className="me-2 w-25">
            {isloading ? (
              <Skeleton
                height={
                  35
                } /* width={200} */ /* style={{ marginLeft: '100%' }} */
              />
            ) : (
              <p></p>
            )}
          </Col>
          <Col lg="2" sm="4" md="3" className="ms-5 ">
            {isloading ? (
              <Skeleton
                height={
                  35
                } /*  width={180} */ /* style={{ marginLeft: '50%' }}  */
              />
            ) : (
              <p></p>
            )}
          </Col>
        </Row>
      </div>
      <div className="mt-3">
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
        <Row className="m-1">
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
          <Col>{isloading ? <Skeleton height={30} /> : <p></p>}</Col>
        </Row>
        <hr></hr>
      </div>
    </SkeletonTheme>
  )
}

export const SkeletonCratobot = (isloading) => {
  return (
    <SkeletonTheme>
      <div>
        <Row className="mb-2 mt-1 ms-1 me-0">
          <Col sm="10" lg="4">
            {isloading ? <Skeleton width={120} height={40} /> : <p></p>}
          </Col>
        </Row>
        <Row>
          <Col className="m-1 ms-6">
            {isloading ? <Skeleton height={30} width={130} /> : <p></p>}
          </Col>
          <Col>
            <p></p>
          </Col>
          <Col>
            <p></p>
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} width={300} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="m-1">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
      </div>
    </SkeletonTheme>
  )
}
export const DashSkeleton = (isloading) => {
  return (
    <Row>
      <Col>
        <Col className="m-1">
          {isloading ? <Skeleton height={420} /> : <p></p>}
        </Col>
      </Col>
    </Row>
  )
}
export const CreditSkeleton = (isloading) => {
  return (
    <Row>
      <Col>
        <Col className="m-1">
          {isloading ? <Skeleton height={250} /> : <p></p>}
        </Col>
      </Col>
    </Row>
  )
}
export const CompanyManagementSkeleton = (isloading) => {
  return (
    <Row>
      <Col>
        <Col className="m-1">
          {isloading ? <Skeleton height={80} /> : <p></p>}
        </Col>
      </Col>
    </Row>
  )
}

export const DocSkeleton = (isloading) => {
  return (
    <SkeletonTheme>
      <div>
        <Row>
          <Col className="m-1 ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <hr></hr>
        <Row>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
          <Col className="ms-2">
            {isloading ? <Skeleton height={30} /> : <p></p>}
          </Col>
        </Row>
        <hr></hr>
      </div>
    </SkeletonTheme>
  )
}

export const SkeletonManage = (isloading) => {
  return (
    <Fragment>
      <SkeletonTheme>
        <div className="m-1 ">
          <Row>
            <Col className="m-1 ms-6">
              {isloading ? <Skeleton width={75} height={40} /> : <P></P>}
            </Col>
            <Col className="m-1 ms-6">
              {/* {isloading ? <Skeleton style={{ marginLeft: '100%', width: '80%' }} height={40} /> : <P></P>} */}
            </Col>
            <Col className="m-1 ms-6 ">
              {isloading ? (
                <Skeleton
                  style={{
                    marginLeft: "75%",
                    marginRight: "10%",
                    width: "80%"
                  }}
                  height={40}
                />
              ) : (
                <P></P>
              )}
            </Col>
            <Col className="m-1 ">
              {isloading ? (
                <Skeleton
                  style={{ marginLeft: "50%", width: "80%" }}
                  height={40}
                />
              ) : (
                <P></P>
              )}
            </Col>
            <Col className="m-1 ">
              {isloading ? (
                <Skeleton
                  style={{ marginLeft: "25%", width: "80%" }}
                  width={150}
                  height={40}
                />
              ) : (
                <P></P>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2 ">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2 ">
              {isloading ? <Skeleton height={20} style={{}} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>

            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2 ">
              {isloading ? <Skeleton height={20} style={{}} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2  ">
              {isloading ? <Skeleton height={20} style={{}} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2  ">
              {isloading ? <Skeleton height={20} style={{}} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
        </div>
      </SkeletonTheme>
    </Fragment>
  )
}

export const FilterSkeleton = (isloading) => {
  return (
    <Row>
      <Col className="m-1">
        {isloading ? <Skeleton height={40} /> : <p></p>}
      </Col>
      <Col className="m-1">
        {isloading ? <Skeleton height={40} /> : <p></p>}
      </Col>
      <Col className="m-1">
        {isloading ? <Skeleton height={40} /> : <p></p>}
      </Col>
      <Col className="m-1">
        {isloading ? <Skeleton height={40} /> : <p></p>}
      </Col>
      <Col className="m-1">
        {isloading ? <Skeleton height={40} /> : <p></p>}
      </Col>
      <Col className="m-1">
        <p></p>
      </Col>
    </Row>
  )
}

export const RoleSkeleton = (isloading) => {
  return (
    <Fragment>
      <SkeletonTheme>
        <div className="m-1 ">
          <Row>
            <Col className="m-1 ms-6">
              {isloading ? <Skeleton width={75} height={40} /> : <P></P>}
            </Col>
            <Col className="m-1 ms-6">
              {/* {isloading ? <Skeleton style={{ marginLeft: '100%', width: '80%' }} height={40} /> : <P></P>} */}
            </Col>
            <Col className="m-1 ms-6 ">
              {/* {isloading ? <Skeleton style={{ marginLeft: '75%', marginRight: '10%', width: '80%' }} height={40} /> : <P></P>} */}
            </Col>
            <Col className="m-1 ">
              {/* {isloading ? <Skeleton style={{ marginLeft: '50%', width: '80%' }} height={40} /> : <P></P>} */}
            </Col>
            <Col className="m-1 ">
              {isloading ? (
                <Skeleton
                  style={{ marginLeft: "25%", width: "80%" }}
                  width={150}
                  height={40}
                />
              ) : (
                <P></P>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2 ">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2 ">
              {isloading ? <Skeleton height={20} style={{}} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>

            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2 ">
              {isloading ? <Skeleton height={20} style={{}} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2  ">
              {isloading ? <Skeleton height={20} style={{}} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
          <Row>
            <Col className="mt-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2  ">
              {isloading ? <Skeleton height={20} style={{}} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? <Skeleton height={20} /> : <p></p>}
            </Col>
            <Col className="m-2">
              {isloading ? (
                <Skeleton height={20} style={{ marginTop: "20px" }} />
              ) : (
                <p></p>
              )}
            </Col>
            <hr></hr>
          </Row>
        </div>
      </SkeletonTheme>
    </Fragment>
  )
}

export const EditBillSkeleton = () => {
  return (
    <Fragment>
      <Row>
        <Col sm="6" className="m-1">
          <Skeleton height={40} />
        </Col>
      </Row>
      <Row className="m-1">
        <Col sm="12">
          <Skeleton height={40} />
        </Col>
      </Row>
      <Row className="mx-1">
        <Col sm="4">
          <Skeleton height={550} />
        </Col>
        <Col sm="8">
          <Skeleton height={550} />
        </Col>
      </Row>
    </Fragment>
  )
}

export const BankIntegrationSkeleton = () => {
  return (
    <Row>
      <Col>
        <Skeleton height={120} />
      </Col>
      <Col>
        <Skeleton height={120} />
      </Col>
      <Col>
        <Skeleton height={120} />
      </Col>
      <Col>
        <Skeleton height={120} />
      </Col>
    </Row>
  )
}

export const DeductionCardSkeleton = () => {
  return (
    <Row>
      {new Array(4).fill("").map((_, id) => (
        <Col lg="3" sm="6" key={id}>
          <IndividualCard />
        </Col>
      ))}
    </Row>
  )
}
const IndividualCard = () => (
  <Card className="col pt-2 pb-2">
    <Row>
      <Col className="">
        <Skeleton width={"35%"} className="ms-1" height={15} />
        <Skeleton className="mt-1 ms-1" width={"80%"} height={15} />
      </Col>
      <Col
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end"
        }}
        className="me-1"
      >
        <Skeleton width={50} height={50} borderRadius={"50%"} />
      </Col>
    </Row>
  </Card>
)
