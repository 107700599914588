import React from "react"
import { Triangle } from "react-feather"
import { Button } from "reactstrap"
import "./style.scss"
const NextButton = (props) => {
  const { children } = props
  return (
    <Button {...props} className={`icon-anim ${props?.className}`}>
      {children}
      <Triangle size={12} fill="white" />
    </Button>
  )
}

export default NextButton
