import React, { useState } from "react"

// ** React-pdf
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

// ** Third party components
import PerfectScrollbar from 'react-perfect-scrollbar'

const PdfViewer = ({url, style}) => {

    const [numPages, setNumPages] = useState(null)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    return (
        <div style={style}>
            <PerfectScrollbar options={{ wheelPropagation: true }} className='w-100'>
                <Document file={url} onLoadError={console.error} 
                    className="full-window-div" 
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={500} scale={1.0}/>
                    ))}
                </Document>
            </PerfectScrollbar>
        </div>
        
    )
}

export default PdfViewer