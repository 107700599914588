import axios from "axios"
import { adminsetupApi1, allEntityApi, changePassword, clientApi, clientDetailsApi, getUserDetailsApi1, getUserDetailsApi2, GET_ALL_CUSTOM_ROLE, GET_BASE_ROLE, ROLES_API } from "../../../services/apiUrl"

export const getUserProfileByEmail = (emailId) => {
    return axios.get(`${getUserDetailsApi1}/user/email/${emailId}`)
}

export const updateUserProfile = (formData) => {
    return axios.post(`${getUserDetailsApi1}/updateUser`, formData)
}

//roles
export const getRolesByTenantId = (tenantId) => {
    return axios.get(`${GET_ALL_CUSTOM_ROLE}${tenantId}`)
}

export const getBaseRoles = (formData) => {
    return axios.post(`${GET_BASE_ROLE}`, formData)
}

// ** Client Call
export const getClientById = (clientId) => {
    return axios.get(`${clientDetailsApi}/${clientId}`)
}

// ** All Entity Call
export const getAllEntity = (clientId) => {
    return axios.get(`${allEntityApi}/${clientId}`)
}

// ** Client Call
export const getAllClient = () => {
    return axios.get(`${clientApi}`)
}

//admin api
export const getNextLvlManagerList = (clientId) => {
    return axios.get(`${adminsetupApi1}/jobtitlelist?clientId=${clientId}`)
}

// changePassword
export const postChangePassword = (formData) => {
    return axios.post(`${changePassword}`, formData)
}

//timeline
export const getTimelineData = (userId) => {
    return axios.get(`${getUserDetailsApi2}/audit-trail/getaudit?userId=${userId}`)
}


// add new user
export const registerNewUser = (formData) => {
    return axios.put(`${getUserDetailsApi1}/registerUser`, formData)
}
export const inviteUser = (formData) => {
    return axios.post(`${getUserDetailsApi1}/inviteUser`, formData)
}
export const mapRoles = (formData) => {
    return axios.put(`${ROLES_API}/mapRoles`, formData)
}

// manager user - get all users
export const getAllUsers = (clientId, url) => {
    return axios.get(`${getUserDetailsApi1}/userlistdetails?clientId=${clientId}${url}`)
}

export const getUsersCount = (clientId) => {
    return axios.get(`${getUserDetailsApi1}/userscount?clientId=${clientId}`)
}

export const postjobTitileApi = (payload) => {
    return axios.post(`${adminsetupApi1}/jobtitlelist`, payload)
}

export const deleteJobtitileApi = (id) => {
    return axios.delete(`${adminsetupApi1}/jobtitle?id=${id}`)
}