import React from "react"
import DropZoneWrapper from "@custom-components/upload-card"
import { failureHtmlContent, handleXlFile, swalFire } from "./utils"
import toast from "react-hot-toast"

const XLImport = (props) => {
  const { validateImportData } = props

  const handleOnDrop = async (result) => {
    if (!result?.length) return
    try {
      toast.loading("Loading..", {
        id: "import-load",
        style: { backgroundColor: "#7f848c", color: "whitesmoke" }
      })
      const file = result[0]
      const { data } = await handleXlFile(file)
      validateImportData(data, file, result)
    } catch (error) {
      console.log(error)
      toast.error("Error in importing", {
        id: "import-load"
      })
    } finally {
      toast.dismiss()
    }
  }

  const dropZoneOptions = {
    multiple: false,
    accept: ".csv, .xlsx, .xls",
    onDropAccepted: handleOnDrop,
    onDropRejected: (result) => {
      swalFire(failureHtmlContent({ html: result, icon: "error" }))
    }
  }

  return <DropZoneWrapper dropZoneOptions={dropZoneOptions} />
}

export default XLImport
