// ** React Imports
import { useEffect } from "react"
import { NavLink } from "react-router-dom"

// ** Icons Imports
import { Disc, X, Circle, ChevronsRight } from "react-feather"

// ** Custom Hooks
import { useLayout } from "@hooks/useLayout"

// ** Config
import themeConfig from "@configs/themeConfig"

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "@utils"

const VerticalMenuHeader = (props) => {
  // ** Props
  // ** setMenuCollapsed => Use this for Collapsing the Sidebar Menu
  const { menuCollapsed, setGroupOpen, menuHover, setMenuVisibility } = props

  // ** Vars
  const user = getUserData()

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    // ** Hooks
    const { layout, setLayout, setLastLayout } = useLayout()

    if (!menuCollapsed) {
      return (
        <ChevronsRight
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => {
            setLayout(layout)
            setLastLayout(layout)
          }}
        />
      )
    } else {
      return (
        <ChevronsRight
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => {
            setLayout("horizontal")
            setLastLayout("horizontal")
          }}
        />
      )
    }
  }

  return (
    <div
      className="navbar-header"
      style={{
        height: "fit-content",
        padding: "1rem .5rem 0 0.7rem",
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <NavLink
        to={user ? getHomeRouteForLoggedInUser(user.role) : "/"}
        className="navbar-brand p-0 m-0"
        style={{
          marginLeft: "1.3rem !important",
          marginTop: "1rem !important"
        }}
      >
        <span className="brand-logo">
          <img src={themeConfig.app.appLogoImage} alt="logo" />
        </span>
        <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2>
      </NavLink>

      {/* Commenting Sidebar Toggle Icon */}

      <div className="nav-link modern-nav-toggle cursor-pointer">
        <X
          onClick={() => setMenuVisibility(false)}
          className="toggle-icon icon-x d-block d-xl-none"
          size={20}
        />
      </div>
    </div>
  )
}

export default VerticalMenuHeader
