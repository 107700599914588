// ** React Iport
import { Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"

// ** Third Party Components
import ReactPaginate from "react-paginate"
import DataTable from "react-data-table-component"
import { ChevronDown } from "react-feather"

// ** Reactstrap Import
import { Card, Col, Row } from "reactstrap"

// ** Component Imports
import { tableColumn } from "./tableColumn"
import TableHeader from "./TableHeader"
import SalesLoaderModal from "./SalesLoaderModal"

// ** Styles
// import "@styles/react/libs/react-select/_react-select.scss"
// import "@styles/react/libs/tables/react-dataTable-component.scss"

const SalesTable = ({ viewType }) => {
  // ** Bootstrap Checkbox Component
  const [show, setShow] = useState(false)
  const nav = useNavigate()

  const handleClick = (salesEntriesID) => {
    setShow(true)
    setTimeout(() => {
      setShow(false)
      nav(`/v2/ar/salesdashboard/view/${salesEntriesID}`)
    }, 2000)
  }

  // ** Custom Pagination
  const CustomPagination = () => {
    return (
      <Row className="align-items-center">
        <Col md={5} sm={12}>
          <div className="ps-md-1 ps-0 text-center text-md-start">
            {/* {formatedPage} of {vendorCount} */} 1 - 1 of 1
          </div>
        </Col>
        <Col md={7} sm={12} className="text-sm-center text-md-start">
          <ReactPaginate
            previousLabel={""}
            nextLabel={""}
            pageCount={1}
            activeClassName="active"
            pageClassName={"page-item"}
            nextLinkClassName={"page-link"}
            nextClassName={"page-item next"}
            previousClassName={"page-item prev"}
            previousLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            containerClassName={
              "pagination react-paginate justify-content-md-start justify-content-center my-2 pe-1"
            }
          />
        </Col>
      </Row>
    )
  }

  return (
    <Fragment>
      <Card className="overflow-hidden">
        <div className="react-dataTable">
          <DataTable
            keyField={viewType}
            noHeader
            subHeader
            // sortServer
            pagination
            responsive
            paginationServer
            columns={tableColumn(viewType, handleClick)}
            // onSort={handleSort}
            sortIcon={<ChevronDown />}
            className="react-dataTable"
            paginationComponent={CustomPagination}
            data={data}
            // onSelectedRowsChange={getSelectedRow}
            // customStyles={rowHoverStyle}
            onRowClicked={() => {
              // setShow(true)
            }}
            subHeaderComponent={<TableHeader />}
          />
        </div>
      </Card>
      <SalesLoaderModal show={show} setShow={setShow} />
    </Fragment>
  )
}

export default SalesTable

const data = [
  {
    salesEntriesId: "SJE2005",
    id: "POS1100",
    uploadDate: "20-11-2022",
    posName: "Toast",
    mode: "Sync",
    fileName: "Sales Report",
    date: "08-12-2023",
    entity: "Acme Inc",
    location: "Washington",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [true],
    summaryStatus: "Yet to post",
    actions: "Create JE"
  },
  {
    salesEntriesId: "SJE2006",
    id: "POS1101",
    uploadDate: "20-11-2022",
    posName: "Toast",
    mode: "Sync",
    fileName: "Sales Report",
    date: "08-12-2023",
    entity: "Acme Inc",
    location: "Newyork",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [true],
    summaryStatus: "Yet to post",
    actions: "Create JE"
  },
  {
    salesEntriesId: "SJE2007",
    id: "POS1102",
    uploadDate: "08-12-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "TB Report",
    date: "08-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [true, true, true],
    summaryStatus: "Yet to post",
    actions: "Create JE"
  },
  {
    salesEntriesId: "SJE2008",
    id: "POS1103",
    uploadDate: "08-13-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "TB Report",
    date: "08-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [true, true, true],
    summaryStatus: "Yet to post",
    actions: "Create JE"
  },
  {
    salesEntriesId: "SJE2008",
    id: "POS1104",
    uploadDate: "08-12-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "Market Audit Report",
    date: "08-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [false, false, true],
    summaryStatus: "Needs Review",
    actions: "Create"
  },
  {
    salesEntriesId: "SJE2009",
    id: "POS1105",
    uploadDate: "08-12-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "Manager's Flash",
    date: "08-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [false, true, true],
    summaryStatus: "Needs Review",
    actions: "Create"
  },
  {
    salesEntriesId: "SJE2010",
    id: "POS1106",
    uploadDate: "08-12-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "TB Report",
    date: "09-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [true, true, false],
    summaryStatus: "Needs Review",
    actions: "Create"
  },
  {
    salesEntriesId: "SJE2011",
    id: "POS1107",
    uploadDate: "08-12-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "Market Audit Report",
    date: "09-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [true, true, false],
    summaryStatus: "Needs Review",
    actions: "Create"
  },
  {
    salesEntriesId: "SJE2012",
    id: "POS1108",
    uploadDate: "08-12-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "Manager's Flash",
    date: "09-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [false, true, true],
    summaryStatus: "Needs Review",
    actions: "Create"
  },
  {
    salesEntriesId: "SJE2013",
    id: "POS1109",
    uploadDate: "08-12-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "TB Report",
    date: "10-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [false, true, true],
    summaryStatus: "Needs Review",
    actions: "Create"
  },
  {
    salesEntriesId: "SJE2014",
    id: "POS1110",
    uploadDate: "08-12-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "Market Audit Report",
    date: "10-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [false, true, true],
    summaryStatus: "Needs Review",
    actions: "Create"
  },
  {
    salesEntriesId: "SJE2015",
    id: "POS1111",
    uploadDate: "08-12-2023",
    posName: "PMS",
    mode: "Upload",
    fileName: "Manager's Flash",
    date: "10-12-2023",
    entity: "Acme Inc",
    location: "Park Central Hotel",
    status: "Yet to post",
    attachments: "Pdf",
    detailsReceived: [true, false, false],
    summaryStatus: "Needs Review",
    actions: "Create"
  }
]
