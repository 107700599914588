import { useState, useEffect } from "react"
import Chart from "react-apexcharts"
import { ShortListSkeleton } from "@custom-components/skeleton/Loader"
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import { useSelector } from "react-redux"
import { getTopExceptions } from "../../Service/Service"

// const getDayFilterfn = (val) => {
//     switch (val) {
//         case "Last 7":
//             return "lastWeek"
//         case "Last Month":
//             return "lastMonth"
//         case "Last Quarter":
//             return "lastQuarter"
//         case "Last Year":
//             return "lastYear"
//         default:
//             return ""
//     }
// }
const colors = [
  "#ea5455",
  "#4b4b4b",
  "#00cfe8",
  "#40dc65",
  "#82868b",
  "#28c76f",
  "#ff9f43"
]

const getDayFilterPercentagefn = (val) => {
  switch (val) {
    case "Last 7":
      return "lastWeekPercentage"
    case "Last Month":
      return "lastMonthPercentage"
    case "Last Quarter":
      return "lastQuarterPercentage"
    case "Last Year":
      return "lastYearPercentage"
    default:
      return ""
  }
}
const chartOptions = {
  type: "radialBar",
  series: [0],
  height: 35,
  width: 35,
  options: {
    grid: {
      show: false,
      padding: {
        left: -15,
        right: -15,
        top: -12,
        bottom: -15
      }
    },
    colors: [colors[0]],
    plotOptions: {
      radialBar: {
        hollow: {
          size: "22%"
        },
        track: {
          background: "#e9ecef"
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false
          },
          value: {
            show: false
          }
        }
      }
    },
    stroke: {
      lineCap: "round"
    }
  }
}
const TopFiveExceptionTypes = () => {
  const { userData } = useSelector((state) => state.auth)
  const [selectedRange, setSelectedRange] = useState("Last Month")
  const [selectedFilterData, setSelectedFilterData] = useState([])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    getTopExceptions(userData.clientId)
      .then((res) => {
        //console.log(res.data)
        setData(res.data)
        res.data.map((item) => {
          if (item.filter === selectedRange) {
            setSelectedFilterData(item.topExceptionsFilter)
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
    return () => setData(null)
  }, [])

  const onChangeFilter = (selectedFilter) => {
    data.map((data) => {
      if (data.filter === selectedFilter) {
        setSelectedFilterData(data.topExceptionsFilter)
      }
    })
  }

  const renderStates = () => {
    return (
      selectedFilterData &&
      selectedFilterData.map((state, index) => {
        const chart = {
          ...chartOptions,
          series: [state[getDayFilterPercentagefn(selectedRange)]],
          options: { ...chartOptions.options, colors: [colors[index]] }
        }
        return (
          <div key={state.tagName} className="browser-states">
            <div className="d-flex">
              <h6 className="align-self-center mb-0">{state.tagName}</h6>
            </div>
            <div className="d-flex align-items-center">
              <div className="fw-bold text-body-heading me-1">
                {state[getDayFilterPercentagefn(selectedRange)]}%
              </div>
              <Chart
                options={chart.options}
                series={chart.series}
                type="radialBar"
                height={chart.height}
                width={chart.width}
              />
            </div>
          </div>
        )
      })
    )
  }

  return (
    <Card className="card-browser-states">
      <CardHeader className="pb-0">
        <CardTitle className="flex-center" tag="h4">
          Top 5 Tag Types
        </CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
            id="tag-types-date-btn"
          >
            {selectedRange}
          </DropdownToggle>
          <DropdownMenu end id="select-tag-types-date">
            {data &&
              data.map((item, i) => (
                <DropdownItem
                  className="w-100"
                  key={i}
                  onClick={() => {
                    onChangeFilter(item.filter)
                    setSelectedRange(item.filter)
                  }}
                >
                  {item.filter}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      {loading ? (
        <div className="ms-1">
          <ShortListSkeleton />
        </div>
      ) : (
        <CardBody>
          {selectedFilterData.length ? (
            renderStates()
          ) : (
            <h3 className="text-center mt-1">
              No data available for the selected Period
            </h3>
          )}
        </CardBody>
      )}
    </Card>
  )
}

export default TopFiveExceptionTypes
