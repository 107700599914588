import { createSlice } from "@reduxjs/toolkit"

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    documentCount: {
      vendorApprovalCount: "0",
      awaitingResolutionCount: "0",
      awaitingApprovalCount: "0",
      paymentApprovalCount: "0",
      purchaseOrderAwaitingApprovalCount: "0"
    }
  },
  reducers: {
    handleDocumentCount: (state, action) => {
      state.documentCount = action.payload
    }
  }
})
export const { handleDocumentCount } = notificationSlice.actions
export default notificationSlice.reducer
