import classNames from "classnames"
import { Fragment } from "react"
import { X } from "react-feather"
import { Link, useNavigate } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap"

const Breadcrumbs = ({
  data,
  title,
  home,
  noHome,
  withGoBack,
  backUrl = -1
}) => {
  const navigate = useNavigate()

  const renderBreadCrumbs = () => {
    return data.map((item, index) => {
      const Wrapper = item.link ? Link : Fragment
      const isLastItem = data.length - 1 === index
      return (
        <BreadcrumbItem
          tag={item.link ? "li" : "span"}
          key={index}
          active={isLastItem}
          // className={classnames({ "text-primary": !isLastItem })}
        >
          <Wrapper {...(item.link ? { to: item.link } : {})}>
            {item.title}
          </Wrapper>
        </BreadcrumbItem>
      )
    })
  }

  return (
    <div
      className={`content-header-left ${
        withGoBack ? "" : "col-md-9"
      } col-12 mb-50`}
    >
      <div className="row breadcrumbs-top">
        <div className={`${withGoBack ? " col-10" : " col-12"}`}>
          {title ? (
            <h2
              className={classNames("float-start mb-0", {
                "content-header-title": !!data?.length,
                "me-50": noHome,
                "mb-1": !data?.length
              })}
            >
              {title}
            </h2>
          ) : (
            ""
          )}
          <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-block d-none col-12">
            <Breadcrumb>
              {noHome ? (
                <></>
              ) : (
                <BreadcrumbItem
                  tag="li"
                  onClick={() => {
                    home ? navigate(home) : navigate("/v2/dashboard")
                  }}
                  className="mb-1 ms-1 cursor-pointer text-primary"
                >
                  Home
                </BreadcrumbItem>
              )}
              {renderBreadCrumbs()}
            </Breadcrumb>
          </div>
        </div>
        <div className={`${withGoBack ? " col-2 text-end px-1" : "d-none"}`}>
          <Button
            color="flat-primary"
            size="sm"
            onClick={() => navigate(backUrl)}
            id="breadcrumbs-close"
          >
            <X />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs
