// ** Icons Import
import {
  Home,
  CreditCard,
  Activity,
  ShoppingCart,
  DollarSign,
  User,
  Cpu
} from "react-feather"

export default [
  {
    id: "dashboards",
    title: "Dashboard",
    icon: Home,
    navLink: "/v2/dashboard"
    // badge: 'light-warning',
    // badgeText: '2'
  }
]
