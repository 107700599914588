import React, { useState } from "react"
import { salesInputBreadcrumb } from "@custom-components/breadcrumb/breadcrumb-data/breadcrumbData"
import Breadcrumbs from "@custom-components/breadcrumb/Breadcrumbs"
import Filter from "./Filter"
import SalesInputTable from "./sales-input-table/index"

const Sales = () => {
  const defaultView = "SUMMARY"

  const [viewType, setViewType] = useState(defaultView)

  return (
    <div>
      <Breadcrumbs data={salesInputBreadcrumb} title="Sales Input" withGoBack />
      <Filter viewType={viewType} setViewType={setViewType} />
      <SalesInputTable viewType={viewType} />
    </div>
  )
}

export default Sales
