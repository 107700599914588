import { useNavigate } from "react-router"
import Chart from "react-apexcharts"
import { useSelector } from "react-redux"
import { CardHeader, CardTitle, Card, Button, CardBody } from "reactstrap"
import useFetch from "../../../../utility/hooks/useFetch"
import { getInvoiceBillCount } from "../../../../services/apiUrl"
import { decimalFormatter as DNF } from "@utils"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import CF from "@utility/helper/currencyFormatter"

const BillPayments = () => {
  const { userData } = useSelector((state) => state.auth)

  const [res, { isFirstLoad: loading }] = useFetch(
    `${getInvoiceBillCount}/report/posted-invoices`,
    {
      apiParams: { params: { clientId: userData.clientId } },
      callback: (res) => ({
        data: [
          res.first30DaysAmount,
          res.second30DaysAmount,
          res.third30DaysAmount,
          res.above90DaysAmount
        ]
          .map(DNF)
          .map((x) => Math.max(x, 0)),
        totalAmount: CF(DNF(res.totalAmount))
      })
    }
  )
  const navigateTo = useNavigate()

  const options = {
    chart: {
      // sparkline: { enabled: true },
      toolbar: { show: false }
    },
    xaxis: {
      categories: ["0 to 30", "31 to 60", "61 to 90", "> 90"]
    },
    plotOptions: {
      bar: {
        borderRadius: [10],
        columnWidth: "70%",
        horizontal: true
      }
    },
    dataLabels: {
      formatter: (d) => CF(d)
    },

    grid: {
      yaxis: {
        lines: {
          show: false
        }
      },
      xaxis: {
        lines: {
          show: false
        },
        padding: {
          left: 200,
          right: 0
        }
      }
    },
    colors: ["#34A353"]
  }

  return (
    <Card>
      <CardHeader className="pb-0 align-items-start">
        <CardTitle className="flex-center" tag="h4">
          Bill Payments
        </CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <PaymentSkeleton />
        ) : (
          <>
            {" "}
            <div
              style={{
                position: "relative"
              }}
            >
              <div
                className="mt-1"
                style={{ position: "absolute", right: "0px", top: "-14%" }}
              >
                <h4 className="fw-bolder  text-start">
                  {" "}
                  {res?.totalAmount} USD
                </h4>
                <div className="font-small-2">Total Amount Due</div>

                {/* <h5>Total Amount Due</h5> */}
              </div>
              <p
                className=" mb-0"
                style={{
                  transform: "rotate(-90deg)",
                  position: "absolute",
                  width: "200px",
                  top: "25px",
                  left: "-100px"
                }}
              >
                Days
              </p>{" "}
              <div>
                <Chart
                  options={options}
                  series={[
                    {
                      name: "",
                      data: res?.data ?? []
                    }
                  ]}
                  type="bar"
                  height={250}
                  // width={360}
                />
              </div>
            </div>
            <div>
              <p
                className="text-center mb-0"
                style={{ transform: "translateY(-12px)" }}
              >
                Aging Bucket
              </p>
              <Button
                color="primary"
                block
                className="cratoButton"
                onClick={() => navigateTo("/v2/ap/cratopay/make-payments")}
                id="make-payment-btn"
              >
                Make payment
              </Button>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  )
}
export default BillPayments

const PaymentSkeleton = () => (
  <SkeletonTheme>
    <Skeleton height={260} />
    <Skeleton className="mt-1" height={35} />
  </SkeletonTheme>
)
