import { Fragment, useState, useEffect } from "react"
import DataTable from "react-data-table-component"
import {
  Badge,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Row,
  Label,
  Form,
  Col,
  FormFeedback
} from "reactstrap"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import { useSelector } from "react-redux"
import { getBankAccounts } from "../../service/cratoPayService"
import { CheckCircle } from "react-feather"
import toast from "@custom-components/toast.jsx"
import useFetch from "../../../../../utility/hooks/useFetch"
import { getLikedAccUrl } from "../../../../../services/apiUrl"
import addBanks from "../../../../../assets/images/illustration/addBanks.png"

import Select from "react-select"
import Mandatory from "@custom-components/mandatoy-star/Mandatory"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { selectThemeColors, toCapitalize as TC } from "@utils"
import * as yup from "yup"
import classNames from "classnames"
import usePost from "@hooks/usePost"
import { getCratoPayUrl } from "@service/apiUrl"

const tableColumn = (selectedBank, handleRadio) => [
  {
    name: "",
    minWidth: "50px",
    maxWidth: "50px",
    cell: (row, index) => (
      <div>
        <Input
          name="bank"
          type="radio"
          checked={selectedBank.idx === index}
          onChange={() => handleRadio(row, index)}
        />
      </div>
    )
  },
  {
    name: "Institution",
    // minWidth: "160px",
    // maxWidth: "200px",
    cell: (row) => <div>{row.institutionName ?? "-"}</div>
  },
  {
    name: "Acct Number",
    // minWidth: "160px",
    // maxWidth: "200px",
    cell: (row) => <div>{row.accountNo ?? "-"}</div>
  },
  {
    name: "Routing Number",
    // minWidth: "160px",
    // maxWidth: "200px",
    cell: (row) => <div>{row.routingNumber ?? "-"}</div>
  },
  {
    name: "Acc type",
    // minWidth: "160px",
    // maxWidth: "200px",
    cell: (row) => <div>{TC(row.accountType) ?? "-"}</div>
  },
  {
    name: "Account Holder Number",
    // minWidth: "160px",
    // maxWidth: "200px",
    cell: (row) => <div>{row.accountHolderName ?? "-"}</div>
  }
  // {
  //   name: "Business Unit",
  //   // minWidth: "160px",
  //   // maxWidth: "200px",
  //   cell: (row) => (
  //     <div>
  //       {row.clientEntityName ? (
  //         row.clientEntityName.length > 1 ? (
  //           <div>
  //             <Badge className="me-50">{row.clientEntityName[0]}</Badge>
  //             <Badge>+{row.clientEntityName.length - 1}</Badge>
  //           </div>
  //         ) : (
  //           <Badge>{row.clientEntityName[0]}</Badge>
  //         )
  //       ) : (
  //         "-"
  //       )}
  //     </div>
  //   )
  // },
  // {
  //   name: "Status",
  //   // minWidth: "160px",
  //   // maxWidth: "200px",
  //   center: true,
  //   cell: () => <Badge color="primary">Linked</Badge>
  // }
]

const TableComponent = ({
  bankAccounts,
  tableColumn,
  selectedBank,
  handleRadio,
  filter
}) => (
  <DataTable
    noHeader
    responsive
    fixedHeader
    fixedHeaderScrollHeight="400px"
    columns={tableColumn(selectedBank, handleRadio).filter(
      (v, i) => filter !== i
    )}
    className="react-dataTable"
    data={bankAccounts}
  />
)

// console.log(bankAccounts, "bankAccounts")

const BankRegistraction = () => {
  const { clientId } = useSelector((state) => state.auth.userData)

  // const [institutionOption = []] = useFetch(
  //   `${allAccountDataUrl}/${clientId}`,
  //   {
  //     callback: (response) => {
  //       const store = response.map((item) => ({
  //         label: `${item.institutionName}${
  //           !item?.institutionName?.includes(item.mask) ? `- ${item.mask}` : ""
  //         }`,
  //         value: item.accountId
  //       }))
  //       return store
  //     }
  //   }
  // )
  const accounType = [
    { label: "Checking", value: "checking" },
    { label: "Savings", value: "savings" }
  ]

  const bankInfoScema = yup.object().shape({
    institutionName: yup.object().shape({
      label: yup.string().required()
    }),
    accountHolderName: yup.string().required(),
    accountNumber: yup.number().required(),
    routingNumber: yup
      .string()
      .required("Routing Number is Required")
      .matches(/^\d{9}$/, "Routing Number must be exactly 9 digits"),
    accountType: yup.object().shape({
      label: yup.string().required()
    })
  })
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(bankInfoScema)
  })

  const [bankAccounts, setBankAccounts] = useState([])
  const [selectedBank, setSelectedBank] = useState({})
  const [isSaving, setIsSaving] = useState("")
  const [show, setShow] = useState(false)

  const [linkedBankAccount = [], { refetch }] = useFetch(
    `${getLikedAccUrl}${clientId}`
  )
  console.log(linkedBankAccount, "linkedBankAccount")
  useEffect(async () => {
    try {
      const { data = [] } = await getBankAccounts(clientId)
      setBankAccounts(
        data
          .filter((v) => !v.paymentEnabled)
          .map((v) => ({ label: `${v.institutionName}-${v.mask}`, value: v }))
      )
    } catch (err) {
      console.log(err)
    }
  }, [])

  const handleRadio = async (e, idx) => {
    setSelectedBank({
      bank: e,
      idx
    })
  }

  const onClose = () => {
    setShow(false)
    setSelectedBank({})
    setIsSaving("")
    reset()
  }
  const { makeRequest: linkAstraBankAcc } = usePost(
    `${getCratoPayUrl}/business-user/link-bank-account`,
    "post"
  )

  const linkBankAcc = async (e) => {
    setIsSaving("processing")
    try {
      const payload = {
        ...e.institutionName.value,
        paymentEnabled: true,
        routingNumber: e.routingNumber,
        accountNumber: e.accountNumber,
        accountType: e.accountType.value,
        accountHolderName: e.accountHolderName
      }
      await linkAstraBankAcc({
        data: payload,
        params: {
          clientId
        }
      })
      toast.success("Linked Successfully")
      setIsSaving("successfull")
      refetch()
    } catch (err) {
      console.log(err)
      toast.error("Try Again")
      onClose()
    } finally {
      setSelectedBank({})
    }
  }

  return (
    <Fragment>
      <h3 className="mb-2">Registered Bank</h3>
      {linkedBankAccount.length >= 1 && (
        <div className="mb-3">
          <Button color="primary" size="sm" onClick={() => setShow(true)}>
            Add Bank
          </Button>
        </div>
      )}
      {linkedBankAccount.length == 0 ? (
        <div
          className="d-flex align-items-center mb-3 justify-content-center flex-column"
          style={{ height: "300px" }}
        >
          <img src={addBanks} width="500px" height="500px" />
          <Button
            color="primary"
            onClick={() => setShow(true)}
            id="bank-reg-btn"
          >
            Link Bank Account
          </Button>
        </div>
      ) : (
        <div className="react-dataTable">
          <TableComponent
            bankAccounts={linkedBankAccount}
            tableColumn={tableColumn}
            selectedBank={selectedBank}
            handleRadio={handleRadio}
            filter={0}
          />
        </div>
      )}
      <Modal
        isOpen={show}
        toggle={onClose}
        className={`modal-dialog-centered ${
          isSaving === "" ? "modal-lg" : "modal-md"
        }`}
        backdrop={"static"}
        keyboard={false}
        id="link-bank-acc-modal"
      >
        {isSaving !== "processing" && (
          <ModalHeader
            className="bg-transparent"
            toggle={onClose}
          ></ModalHeader>
        )}
        <ModalBody className="px-3">
          {isSaving === "processing" ? (
            <div className="text-center p-2 mb-3 mt-3">
              <div>
                <Spinner className="mb-1" color="primary" />
              </div>
              <h2>Processing Linking your bank account as a Funding</h2>
            </div>
          ) : isSaving === "successfull" ? (
            <div className="text-center p-2 mb-1">
              <div className="text-primary mb-2">
                <CheckCircle size={50} />
              </div>
              <h2 className="mb-2">
                Selected bank account has been added as a Funding Source
              </h2>
              <Button color="primary" onClick={onClose}>
                Ok
              </Button>
            </div>
          ) : (
            <div>
              {/* <h2 className="mb-2 text-center">Available List of Validated Bank Accounts</h2> */}
              <h2 className="mb-2 text-center">Add Bank Accounts</h2>
              <div className="react-dataTable mb-2">
                {/* <TableComponent
                  bankAccounts={bankAccounts}
                  tableColumn={tableColumn}
                  selectedBank={selectedBank}
                  handleRadio={handleRadio}
                  filter={4}
                /> */}

                <Form onSubmit={handleSubmit(linkBankAcc)}>
                  <Row>
                    <Col md={6} xs={12} className="mb-1">
                      <Label>
                        Institution Name <Mandatory />
                      </Label>
                      <Controller
                        control={control}
                        id="institutionName"
                        name="institutionName"
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              classNamePrefix="select"
                              options={bankAccounts}
                              maxMenuHeight={"100px"}
                              className={classNames("react-select", {
                                "is-invalid": errors.institutionName && true
                              })}
                              theme={selectThemeColors}
                            />
                            {errors.institutionName && (
                              <FormFeedback>
                                {"Institution Name is Required"}
                              </FormFeedback>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col md={6} xs={12} className="mb-1">
                      <Label>
                        Account Holder Name <Mandatory />
                      </Label>
                      <Controller
                        control={control}
                        id="accountHolderName"
                        name="accountHolderName"
                        render={({ field }) => (
                          <>
                            <Input
                              type="text"
                              {...field}
                              invalid={errors.accountHolderName && true}
                            />
                            {errors.accountHolderName && (
                              <FormFeedback>
                                {"Account Holder Name is Required"}
                              </FormFeedback>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col md={6} xs={12} className="mb-1">
                      <Label>
                        Account Number <Mandatory />
                      </Label>
                      <Controller
                        control={control}
                        id="accountNumber"
                        name="accountNumber"
                        render={({ field }) => (
                          <>
                            <Input
                              type="text"
                              {...field}
                              invalid={errors.accountNumber && true}
                            />
                            {errors.accountNumber && (
                              <FormFeedback>
                                {"Account Number is Required"}
                              </FormFeedback>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col md={6} xs={12} className="mb-1">
                      <Label>
                        Rounting Number <Mandatory />
                      </Label>
                      <Controller
                        control={control}
                        id="routingNumber"
                        name="routingNumber"
                        render={({ field }) => (
                          <>
                            <Input
                              type="number"
                              {...field}
                              invalid={errors.routingNumber && true}
                            />
                            {errors.routingNumber && (
                              <FormFeedback>
                                {errors.routingNumber.message}
                              </FormFeedback>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col md={6} xs={12} className="mb-1">
                      <Label>
                        Account Type <Mandatory />
                      </Label>
                      <Controller
                        control={control}
                        id="accountType"
                        name="accountType"
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              classNamePrefix="select"
                              options={accounType}
                              maxMenuHeight={"100px"}
                              className={classNames("react-select", {
                                "is-invalid": errors.accountType && true
                              })}
                              theme={selectThemeColors}
                            />
                            {errors.accountType && (
                              <FormFeedback>
                                {"Account Type is Required"}
                              </FormFeedback>
                            )}
                          </>
                        )}
                      />
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end gap-1 pb-3 pt-2">
                    <Button outline onClick={onClose} id="data-cancel">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      // onClick={linkBankAcc}
                      // disabled={!selectedBank.bank}

                      id="data-link-now"
                    >
                      Link Now
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default BankRegistraction

// const defaultPayload = {
//   "accountId": "GXeozBDX9GhKE8ZnBqEDT84keP39egtGmJmZX",
//   "type": "depository",
//   "subtype": "savings",
//   "name": "Plaid Saving",
//   "mask": "1111",
//   "bankId": "64674f9ce0aa4418de2b2a45",
//   "institutionId": "ins_56",
//   "institutionName": "Chase",
//   "officialName": "Plaid Silver Standard 0.1% Interest Saving",
//   "verificationStatus": null,
//   "availableBalance": 200,
//   "currentBalance": 210,
//   "limit": null,
//   "currencyCode": "USD",
//   "unofficialCurrencyCode": null,
//   "clientId": "10022",
//   "clientEntityId": null,
//   "clientEntityCode": null,
//   "clientEntityName": null,
//   "syncStartDate": null,
//   "glCode": null,
//   "status": "PENDING_CONFIG",
//   "createdType": "PLAID",
//   "toSync": true,
//   "paymentEnabled": true,
//   "routingNumber" : "222222226",
//   "accountNumber" : "123238768732",
//   "accountType" : "checking",
//   "accountHolderName" : "Acme"
// }
