import hotTost from "react-hot-toast"
import { notification } from "antd"

const toast = (message, options) => {
  if (options?.id) hotTost.dismiss(options.id)
  notification.info({ message })
}
toast.error = (message, options) => {
  if (options?.id) hotTost.dismiss(options.id)
  notification.error({ message })
}
toast.success = (message, options) => {
  if (options?.id) hotTost.dismiss(options.id)
  notification.success({ message })
}
toast.loading = (message = "", options = {}) => {
  hotTost.loading(message, { ...options, position: "top-center" })
}
toast.dismiss = hotTost.dismiss

export default toast
