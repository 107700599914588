// ** React Imports
import { useRef, Fragment } from "react"
import { Search } from "react-feather"
import { useNavigate } from "react-router-dom"

// ** Reactstrap Import
import { Button, Input, InputGroup } from "reactstrap"

const TableHeader = () => {
  const searchTermRef = useRef(null)

  const nav = useNavigate()

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 0"
        }}
      >
        <div className="d-flex align-items-center ">
          <label htmlFor="rows-per-page">Show</label>
          <Input
            className="mx-50"
            type="select"
            id="rows-per-page"
            // value={}
            // onChange={}
            style={{ width: "5rem" }}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </Input>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-1">
          <InputGroup>
            <Input
              placeholder="Search"
              style={{ width: "10vw" }}
              type="search"
              // defaultValue={}
              innerRef={searchTermRef}
            />
            <Button outline size="sm" type="button" color="primary">
              <Search size={14} />
            </Button>
          </InputGroup>
          <Button
            onClick={() => {
              nav("/v2/ar/sales-input/sales-upload")
            }}
            color="primary"
          >
            Upload
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

export default TableHeader
