import { store } from "@src/redux/store.js"

export default function checkTenantId() {
  try {
    const tenantIdList = ["uat_sbsnova", "sbsnova"]
    const currentTenantId = store.getState().auth.userData.tenantId
    return tenantIdList.includes(currentTenantId)
  } catch (error) {
    return false
  }
}
