import React, { useContext, useRef, useState, forwardRef } from "react"
import { FixedSizeList } from "react-window"
const VirtualTableContext = React.createContext({
  top: 0,
  setTop: (value) => {
    console.log(value)
  },
  header: <></>,
  footer: <></>
})

const VirtualTable = ({
  row,
  header,
  footer,
  itemSize = 50,
  height = 350,
  width = "100%",
  ...rest
}) => {
  const listRef = useRef()
  const [top, setTop] = useState(0)
  const inner = forwardRef(Inner)
  //   const viewport = window.innerHeight
  //   const offset = listRef.current?._outerRef?.offsetTop ?? 300
  //   console.log(viewport, offset)
  const props = { ...rest, height, width, itemSize }
  return (
    <VirtualTableContext.Provider value={{ top, setTop, header, footer }}>
      <FixedSizeList
        {...props}
        innerElementType={inner}
        onItemsRendered={(props) => {
          const style =
            listRef.current &&
            // @ts-ignore private method access
            listRef.current._getItemStyle(props.overscanStartIndex)
          setTop((style && style.top) || 0)

          // Call the original callback
          if (rest.onItemsRendered) rest.onItemsRendered(props)
        }}
        ref={(el) => (listRef.current = el)}
      >
        {row}
      </FixedSizeList>
    </VirtualTableContext.Provider>
  )
}

export default VirtualTable
function Inner({ children, ...rest }, ref) {
  const { header, footer, top } = useContext(VirtualTableContext)
  return (
    <div {...rest} ref={ref}>
      <table style={{ top, position: "absolute", width: "100%" }}>
        {header}
        <tbody>{children}</tbody>
        {footer}
      </table>
    </div>
  )
}
