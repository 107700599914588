import React from "react"
import { Badge, Card, CardBody, CardTitle } from "reactstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { kFormatter } from "@utils"
import { unBookedSummary } from "@service/apiUrl"
import NextButton from "@custom-components/buttons/next-button/NextButton"
// import Loader from "@custom-components/skeleton/Loader"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import AccessRestrictionPopover from "../../AccessRestriction"
import useFetch from "@hooks/useFetch"
import { Popover } from "antd"

const defultData = {
  totalCount: 0,
  totalUnBookedTransactionAmount: 0,
  unBookedTransactionCount: 0,
  activeAccountsCount: 0,
  inActiveAccountCount: 0,
  unBookedCashInAmount: 0,
  unBookedCashOutAmount: 0
}
const BankAccountSummary = () => {
  const { clientId, currentRoleAccess } = useSelector(
    (state) => state.auth.userData
  )
  const { accessRestriction } = currentRoleAccess ?? {}
  const { Bank_Unbooked_Transactions } = accessRestriction ?? {}
  const { access = "WRITE" } = Bank_Unbooked_Transactions ?? {}
  const [accountSummary = defultData, { isFirstLoad: loading }] = useFetch(
    unBookedSummary,
    {
      apiParams: { params: { clientId } },
      callback: (res) => res
    }
  )
  const nav = useNavigate()
  const navigate = () => {
    nav("/v2/cratomatch/uncategorized-transactions")
  }

  if (loading) return <BankSleleton />

  return (
    <div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div style={{ borderRight: "0px solid gray" }}>
              {/* <h4 className="flex-center">Bank Account Summary</h4> */}
              <CardTitle tag="h4" className="flex-center">
                Bank Account Summary
              </CardTitle>
              <div className="d-flex  align-items-center justify-content-between mt">
                <Badge style={{ margin: "1.3rem 0 1.3rem 0" }}>
                  Total Unbooked Txn(s)
                </Badge>
                <h6 class="transaction-title mb-0">
                  {" "}
                  {kFormatter(
                    accountSummary.unBookedTransactionCount ?? 0
                  )}{" "}
                </h6>
              </div>{" "}
              <div className="d-flex  align-items-center justify-content-between">
                <p style={{ marginBottom: ".5rem" }}> Cash In (value)</p>
                <h6 class="transaction-title">
                  {kFormatter(accountSummary.unBookedCashInAmount ?? 0)}
                </h6>
              </div>
              <div className="d-flex  align-items-center justify-content-between">
                <p style={{ marginBottom: "0" }}> Cash Out (value)</p>
                <h6 class="transaction-title" style={{ marginBottom: "0" }}>
                  {kFormatter(accountSummary.unBookedCashOutAmount ?? 0)}
                </h6>
              </div>
            </div>
            <div>
              <p style={{ textAlign: "center", margin: "0.2rem" }}>
                Active Accounts
              </p>
              <p style={{ textAlign: "center" }}>
                <span
                  style={{
                    background: "#00b050",
                    color: "white",
                    padding: ".2rem .5rem",
                    borderRadius: "8px"
                  }}
                >
                  {accountSummary.activeAccountsCount ?? 0}
                </span>
              </p>
              <p style={{ textAlign: "center", margin: "0.2rem" }}>
                Accounts Expired
              </p>
              <p style={{ textAlign: "center" }}>
                <span
                  style={{
                    background: "#f7caac",
                    color: "white",
                    padding: ".2rem .5rem",
                    borderRadius: "8px"
                  }}
                >
                  {accountSummary.inActiveAccountCount ?? 0}
                </span>
              </p>
              <Popover
                content={<AccessRestrictionPopover />}
                showArrow={false}
                placement="right"
                {...(access === "HIDE" ? {} : { open: access === "HIDE" })}
              >
                <div className="cursor-pointer">
                  <NextButton
                    onClick={navigate}
                    color="primary"
                    size="sm"
                    id="dashboard-view-tran"
                    disabled={access === "HIDE"}
                  >
                    View Transactions
                  </NextButton>
                </div>
              </Popover>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default BankAccountSummary

const BankSleleton = () => (
  <Card className="p-1">
    <h4 className="flex-center">Bank Account Summary</h4>
    <SkeletonTheme>
      <div className="row">
        <div className="col">
          <Skeleton className="my-50" height={20} />
          <Skeleton className="my-50" height={20} />
          <Skeleton className="my-50" height={20} />
        </div>
        <div className="col-2"></div>
        <div className="col text-end">
          <Skeleton className="my-25" height={25} />
          <Skeleton className="my-25" height={25} />
          <Skeleton className="mt-1" width={75} />
        </div>
      </div>
    </SkeletonTheme>
  </Card>
)
