// ** Axios Import
import axios from "axios"
import qs from "qs"

// ** URL's Import
import {
  allAccountDataUrl,
  allEntityApi,
  bookedTransactionUrl,
  categoryGroupUrl,
  categorytransactionUrl,
  saveAllExpenseUrl,
  isErpSyncedUrl,
  plaidBankDataUrl,
  saveTransactionUrl,
  segmentGlCodeUrl,
  transactionByIdUrl,
  getSegmentUrl,
  getUserDetailsApi1,
  clientApi,
  postingSendResolutionUrl,
  GET_ROLE_BY_CLIENT_ID,
  getUserbyClientId,
  depositBookingUrl,
  updateErpStatusUrl,
  saveExpenseErpUrl,
  postRuleUrl,
  getResolutionUrl,
  updateResolutionUrl,
  deleteJEErpTransactionUrl,
  deleteExpenseErpTransactionUrl,
  moveTxnToCashAppUrl,
  moveToPayAppUrl,
  validateBankUploadUrl,
  getStartDate,
  updateReconList,
  getReconByIdUrl,
  matchReconUrl,
  removeTransGlUrl,
  createVarianceUrl,
  reconcileUrl,
  reverseReconUrl,
  initialAutoMatchRecon
} from "../../../services/apiUrl"

// ** Get Transactions
export const getBookedTransaction = (url) =>
  axios.get(`${bookedTransactionUrl}${url}`)

// ** Get All Accounts
export const getAllAccountData = (clientId) =>
  axios.get(`${allAccountDataUrl}/${clientId}`)

// ** Get  Transaction By ID
export const getTransactionById = (transactionId) =>
  axios.get(`${transactionByIdUrl}${transactionId}`)
export const getTransactionByIdUrl = (transactionId) =>
  `${transactionByIdUrl}${transactionId}`
// ** Get Category Group
export const getCategoryGroup = (query) =>
  axios.get(`${categoryGroupUrl}${query}`)

// ** Get Category Transaction
export const getCategoryTransaction = (query) =>
  axios.get(`${categorytransactionUrl}${query}`)

// ** Save Transaction
export const saveAllTransaction = (payload, status) =>
  axios.post(`${saveTransactionUrl}?status=${status}`, payload)

export const moveToPayApp = (payload) =>
  axios.post(`${moveToPayAppUrl}`, payload)

// ** Get Gl Code
export const getGlCode = (entityId, clientId) =>
  axios.get(
    `${segmentGlCodeUrl}clientEntityId=${entityId}&clientId=${clientId}`
  )

// ** Get All Entity
export const getAllEntity = (clientId) =>
  axios.get(`${allEntityApi}/${clientId}`)

// ** Get Plaid Bank Data
export const getPlaidBankData = (clientId) =>
  axios.get(`${plaidBankDataUrl}/${clientId}`)

// ** Create Expense
export const saveAllExpense = (payload) =>
  axios.post(saveAllExpenseUrl, payload)

// ** Get Erp Synced
export const getErpSync = (clientId) =>
  axios.get(`${isErpSyncedUrl}${clientId}`)

// ** Get Segment List
export const getSegmentList = (clientId) =>
  axios.get(`${getSegmentUrl}${clientId}`)

// ** Get Approver List
export const getApproverList = (url, tenantId) => {
  if (process.env.REACT_APP_TYPE == "dev") {
    return axios.get(`${getUserDetailsApi1}/userlistdetails?${url}`, {
      headers: { "crato-tenant-id": tenantId }
    })
  } else {
    return axios.get(`${getUserDetailsApi1}/userlistdetails?${url}`)
  }
}

// ** Get All Client
export const getAllClient = () => axios.get(clientApi)

// ** Send Resolution
export const sendResolution = (payload) =>
  axios.post(postingSendResolutionUrl, payload)

export const updateSendResolution = (payload, loggedInUser) => {
  const header = {
    loggedInUser
  }
  return axios.put(updateResolutionUrl, payload, {
    headers: process.env.REACT_APP_TYPE == "dev" ? header : {}
  })
}

export const getRoleByEntity = (clientId) => {
  const options = {
    params: {
      clientId
    }
  }
  // options =
  //   process.env.REACT_APP_TYPE == "dev"
  //     ? {
  //         ...options,
  //         headers: {
  //           "crato-tenant-id": tenantId
  //         }
  //       }
  //     : options
  return axios.get(GET_ROLE_BY_CLIENT_ID, options)
}
export const getUserByEntityApi = (clientId) =>
  axios.get(getUserbyClientId, { params: { clientId } })

export const depositBooking = (payload) =>
  axios.post(depositBookingUrl, payload)

export const updateErpStatus = (transId, payload) =>
  axios.post(`${updateErpStatusUrl}${transId}`, payload)

export const saveExpenseErp = (id) => axios.post(`${saveExpenseErpUrl}${id}`)

// ** Save Rule
export const saveRules = (payload) => axios.post(postRuleUrl, payload)

// ** Get Resolution History
export const getResolutionList = (id) => axios.get(`${getResolutionUrl}${id}`)

// ** Delete Erp JE Transaction
export const deleteJEErpTransaction = (transactionId, erpId, clientId) => {
  return axios.delete(`${deleteJEErpTransactionUrl}?id=${erpId}`, {
    data: {
      id: erpId,
      transactionId,
      clientId
    }
  })
}

// ** Delete Erp Expense Transaction
export const deleteExpenseErpTransaction = (
  expenseId,
  clientId,
  transactionId
) =>
  axios.delete(
    `${deleteExpenseErpTransactionUrl}id=${expenseId}&clientId=${clientId}&transactionId=${transactionId}`
  )

// ** Move To Cash App
export const moveTxnToCashApp = (payload, loggedInUser) => {
  const header = {
    loggedInUser
  }
  return axios.post(moveTxnToCashAppUrl, payload, {
    headers: process.env.REACT_APP_TYPE == "dev" ? header : {},
    params: {
      isArDeposit: true
    }
  })
}

export const validateBankUpload = (payload, params) =>
  axios.post(validateBankUploadUrl, payload, {
    params
  })

export const getBankStartDate = (params) =>
  axios.get(getStartDate, {
    params
  })

// Bank Recon
export const updateReconlist = (payload) => axios.post(updateReconList, payload)

export const getReconById = (reconId) =>
  axios.get(`${getReconByIdUrl}/${reconId}`)

export const matchRecon = (reconId) => axios.post(`${matchReconUrl}/${reconId}`)

export const removeMatched = (params) =>
  axios.put(
    `${removeTransGlUrl}`,
    {},
    {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" })
    }
  )

export const createVariance = (payload, params) =>
  axios.post(createVarianceUrl, payload, {
    params
  })

export const reconcile = (reconId) => axios.post(`${reconcileUrl}/${reconId}`)

export const reverseRecon = (params) =>
  axios.post(
    reverseReconUrl,
    {},
    {
      params
    }
  )

export const initialAutoMatch = (reconId) =>
  axios.put(`${initialAutoMatchRecon}/${reconId}`, {})
