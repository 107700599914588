import { useState } from "react"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"

const ConfirmationModal = ({ show, setShow, onContinue }) => {
  const [isAccept, setIsAccept] = useState(false)

  const onClose = () => {
    setShow(false)
    setIsAccept(false)
  }

  return (
    <Modal
      isOpen={show}
      toggle={onClose}
      className="modal-dialog-centered modal-lg"
      backdrop={"static"}
      keyboard={false}
      id="business-reg-modal"
    >
      <ModalHeader className="bg-transparent" toggle={onClose}></ModalHeader>
      <ModalBody className="px-3">
        <h3 className="mb-2">Registration Disclaimer</h3>
        <div className="">
          By clicking "Agree," you authorize Cratoflow to debit your bank
          account via ACH. You authorize, if necessary, adjustments for any
          debit entries made in error to your account. This authority will
          remain in effect until your Cratoflow account has been terminated or
          you update your payment preferences or we are notified by you in
          writing to cancel it in such time as to afford Cratoflow commercially
          reasonable opportunity to act on it. You represent and warrant that
          LINKED BANK ACCOUNT details referenced herein belong to you and you
          have the authority to provide Cratoflow with this authorization to
          credit or debit your account.
        </div>
        {/* <div className="mb-2">
          Thank you for choosing CratoPay! We want to make sure you're aware of and agree to our Terms &
          Conditions before you start using our product. By clicking "Agree" or continuing to use our product, you
          acknowledge that you have read, understood, and agreed to the following:
        </div>
        <div className="ps-3 mb-2">
          <Row className="mb-1">
            <Col xs={1} className='text-center'>
              <srong>-</srong>
            </Col>
            <Col xs={11}>
              Acceptance of Terms: By using our product, you agree to be bound by our Terms & Conditions,
              which constitute a legally binding agreement between you and [Your Company Name].
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={1} className='text-center'>
              <srong>-</srong>
            </Col>
            <Col xs={11}>
              Privacy and Data Collection: We may collect and use certain personal and non-personal
              information in accordance with our Privacy Policy. By using our product, you consent to the
              collection, storage, and use of your information as described in our Privacy Policy.
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={1} className='text-center'>
              <srong>-</srong>
            </Col>
            <Col xs={11}>
              User Responsibilities: You are responsible for maintaining the confidentiality of your account
              credentials, for any activity that occurs under your account, and for ensuring that your use of
              our product complies with applicable laws and regulations.
            </Col>
          </Row>
        </div>
       
          <div className="mb-2">
            Please read our complete Terms & Conditions, available on our website, for a comprehensive
            understanding of your rights and obligations.
          </div> */}
        <div className="mt-2">
          <div className="mb-2">
            <Input
              type="checkbox"
              className="me-75"
              checked={isAccept}
              onChange={() => setIsAccept((e) => !e)}
              id="register-agree-check"
            />
            <span className="fw-bold">
              I agree to the{" "}
              <a
                href="https://www.cratoflow.com/terms-and-conditions"
                target="_blank"
              >
                Terms & Condition{" "}
              </a>{" "}
              &{" "}
              <a
                href="https://www.cratoflow.com/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center mb-2">
          <Button
            color="primary"
            onClick={onContinue}
            disabled={!isAccept}
            id="registration-submit"
          >
            Continue
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal
