import { Fragment } from "react"
import { Card, CardBody } from "reactstrap"

const Filter = () => {
    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className='demo-inline-spacing'>
                        <div className="mt-0">Filter by : </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default Filter