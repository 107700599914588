import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Col, Input, Label, Row } from "reactstrap"
import { getBaseRoles, putClientDetail } from "../../service/cratoPayService"
import Select from "react-select"
import { selectThemeColors } from "@utils"
import toast from "@custom-components/toast.jsx"

const Notification = ({ clientData, setClientData }) => {
  //console.log(clientData)
  const { userData } = useSelector((state) => state.auth)
  const [paymentProcessingStatus, setpaymentProcessingStatus] = useState(
    clientData?.cratoPaySetup?.sendPaymentStatusNotification
      ? clientData?.cratoPaySetup?.sendPaymentStatusNotification
      : false
  )
  // eslint-disable-next-line no-unneeded-ternary
  const [includeOthers, setIncludeOthers] = useState(false)
  const [sendRemittance, setSendRemittance] = useState(
    clientData?.cratoPaySetup?.sendRemittanceNotice
      ? clientData?.cratoPaySetup?.sendRemittanceNotice
      : false
  )
  const [selectedUsers, setSelectedUsers] = useState(
    clientData?.cratoPaySetup?.users ? clientData?.cratoPaySetup?.users : []
  )
  const [approverOpt, setapproverOpt] = useState([])

  useEffect(() => {
    const form_data = {
      clientCode: userData.client.clientCode,
      clientId: "string",
      roleCode: "CRATO_APPROVER",
      roleFetchCriteria: "CLIENTCODE_AND_ROLECODE",
      roleId: "string",
      userId: "string",
      userName: "string"
    }

    getBaseRoles(form_data)
      .then((res) => {
        const optList = []
        if (res.data.clientRoleUsersResponse.users) {
          // console.log(res.data.clientRoleUsersResponse.users)
          res.data.clientRoleUsersResponse.users.map((data) => {
            const obj = {
              value: `${data.firstName} ${data.lastName}`,
              label: `${data.firstName} ${data.lastName}`
            }
            optList.push(obj)
          })
          setapproverOpt(optList)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleSubmit = () => {
    const cloneObj = _.cloneDeep(clientData)
    const userArr = []
    selectedUsers.map((user) => userArr.push(user.value))
    cloneObj.cratoPaySetup.sendPaymentStatusNotification =
      paymentProcessingStatus
    cloneObj.cratoPaySetup.users = userArr
    cloneObj.cratoPaySetup.sendRemittanceNotice = sendRemittance
    //console.log(cloneObj)
    //return
    setClientData(cloneObj)
    putClientDetail(cloneObj)
      .then((data) => {
        console.log(data.data)
        toast.success("Record Updated Successfully", {
          duration: 3000,
          position: "top-center"
        })
      })
      .catch((err) => {
        console.log(err)
        toast.error("Something went wrong", {
          duration: 3000,
          position: "top-center"
        })
      })
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="fw-bolder">Remittance notice</h5>
        <Button color="primary" onClick={handleSubmit} id="crato-rem-save-btn">
          Save
        </Button>
      </div>
      {false && (
        <>
          <Row className="mt-2">
            <Col md={6}>
              <p>
                Send payment processing status to batch creator and approver
              </p>
            </Col>
            <Col md={3}>
              <div className="form-check form-switch">
                <Input
                  type="switch"
                  checked={paymentProcessingStatus}
                  onChange={(e) => {
                    setpaymentProcessingStatus(e.target.checked)
                  }}
                />
                <Label className="form-check-label switch-icon-left switch-icon-right">
                  Yes
                </Label>
              </div>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md={3} className="mt-2">
              <Input
                type="checkbox"
                checked={includeOthers}
                onChange={(e) => {
                  setIncludeOthers(e.target.checked)
                }}
              />
              <Label className="form-check-label switch-icon-left switch-icon-right ms-1">
                Include Others
              </Label>
            </Col>
            <Col md={3}>
              <Label>Select Users</Label>
              <Select
                className="react-select"
                classNamePrefix="select"
                isMulti={true}
                //value={selectedUsers}
                isDisabled={includeOthers === false}
                options={approverOpt}
                theme={selectThemeColors}
                isClearable={true}
                onChange={(val) => setSelectedUsers(val)}
              />
            </Col>
          </Row>
          <hr />
        </>
      )}
      <div>
        <Row className="mt-2 mb-1">
          <Col md={6}>
            <p>Send Remittance notice to Payee / Vendor</p>
          </Col>
          <Col md={3}>
            <div className="form-check form-switch">
              <Input
                type="switch"
                checked={sendRemittance}
                onChange={(e) => {
                  setSendRemittance(e.target.checked)
                }}
                id="send-rem-vendor"
              />
              <Label className="form-check-label switch-icon-left switch-icon-right">
                Yes
              </Label>
            </div>
          </Col>
        </Row>
        <Link to={"/v2/ap/cratopay/configuration/remittanceNoticeSample"}>
          Show Remittance notice sample
        </Link>
      </div>
    </div>
  )
}

export default Notification
