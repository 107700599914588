import { useState } from "react"
import Avatar from "@components/avatar"
import {
  Card,
  CardHeader,
  CardTitle,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  CardBody,
  CardSubtitle
} from "reactstrap"
const rangeOptions = [
  {
    range: "Last 7 Days",
    data: [
      { customerName: "Dot Foods, Inc", amount: 160471.33, percentage: 49 },
      {
        customerName: "Amazon.com dedc, LLC",
        amount: 15865.33,
        percentage: 30
      },
      { customerName: "Grocery Outlet,Inc,", amount: 14869.33, percentage: 10 },
      { customerName: "WinCo Foods, Inc", amount: 3556.33, percentage: 5 },
      { customerName: "Dollar Genaral Corp", amount: 2753.33, percentage: 4 }
    ]
  },
  {
    range: "Last month",
    data: [
      { customerName: "Grocery Outlet,Inc,", amount: 23132.33, percentage: 10 },
      { customerName: "Amazon.com dedc, LLC", amount: 3221.33, percentage: 30 },
      { customerName: "WinCo Foods, Inc", amount: 2331.33, percentage: 5 },
      { customerName: "Dollar Genaral Corp", amount: 4323.33, percentage: 4 },
      { customerName: "Dot Foods, Inc", amount: 31122.33, percentage: 49 }
    ]
  },
  {
    range: "Last Quater",
    data: [
      { customerName: "Dollar Genaral Corp", amount: 2753.33, percentage: 4 },
      { customerName: "Dot Foods, Inc", amount: 160471.33, percentage: 49 },
      {
        customerName: "Amazon.com dedc, LLC",
        amount: 15865.33,
        percentage: 30
      },
      { customerName: "Grocery Outlet,Inc,", amount: 14869.33, percentage: 10 },
      { customerName: "WinCo Foods, Inc", amount: 3556.33, percentage: 5 }
    ]
  },
  {
    range: "Last Year",
    data: [
      { customerName: "Dollar Genaral Corp", amount: 4323.33, percentage: 4 },
      { customerName: "Grocery Outlet,Inc,", amount: 23132.33, percentage: 10 },
      { customerName: "Amazon.com dedc, LLC", amount: 3221.33, percentage: 30 },
      { customerName: "Dot Foods, Inc", amount: 31122.33, percentage: 49 },
      { customerName: "WinCo Foods, Inc", amount: 2331.33, percentage: 5 }
    ]
  }
]
const colors = [
  "#28c76f",
  "#cebf2c",
  "#7367f0",
  "#299aff",
  "#4f5d70",
  "#e88bff"
]
const TopFiveCustomersWidget = () => {
  const [activeRange, setActiveRange] = useState(rangeOptions[0])

  const renderData = () => {
    return activeRange.data.map((item, index) => {
      return (
        <div
          key={item.vendorId}
          className="browser-states d-flex justify-content-between"
        >
          <div className="d-flex align-items-center">
            <Avatar
              initials
              className="me-1"
              content={`${item.customerName
                .charAt(0)
                .toUpperCase()} ${item.customerName.charAt(1).toUpperCase()}`}
            />
            <h6 className="align-self-center mb-0">{item.customerName}</h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="fw-bold text-body-heading me-1 text-nowrap">
              $ {item.amount}
            </div>
            <div
              style={{
                height: "40px",
                width: "40px",
                border: `2px solid ${colors[index]}`,
                borderRadius: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <span style={{ fontSize: "11px" }}>{item.percentage}%</span>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
        <CardTitle tag="h4">Top 5 Customers</CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
          >
            {activeRange.range}
          </DropdownToggle>
          <DropdownMenu end>
            {rangeOptions.map((item, i) => (
              <DropdownItem
                className="w-100"
                key={i}
                onClick={() => {
                  setActiveRange(item)
                }}
              >
                {item.range}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      <CardBody>
        <CardSubtitle
          style={{
            position: "absolute",
            left: "24px",
            top: "56px",
            fontSize: "12px"
          }}
        >
          By Revenue
        </CardSubtitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.8rem",
            justifyContent: "space-between"
          }}
        >
          {renderData()}
        </div>
      </CardBody>
    </Card>
    // <div>DeductionWidget</div>
  )
}
export default TopFiveCustomersWidget
