import { useDispatch } from "react-redux"
import { removeMiscData, setMiscData } from "../../redux/misc"

const useMisc = (page) => {
  const dispatch = useDispatch()
  const handleSetMiscData = (payload) => {
    dispatch(setMiscData({ key: page, value: payload }))
  }
  const handleRemoveMiscData = () => {
    dispatch(removeMiscData({ key: page }))
  }

  return [handleSetMiscData, handleRemoveMiscData]
}

export default useMisc
