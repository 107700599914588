import { createSlice } from "@reduxjs/toolkit"
import { removeKey } from "../utility/Utils"

export const miscSlice = createSlice({
  name: "miscellaneous",
  initialState: {
    corpList: [],
    corpHookData: {
      isMulti: false,
      isSubmitted: false,
      isEditable: true,
      mandatorySegmentCodes: ["A Child 1", "A Parent 1"]
    }
  },
  reducers: {
    handleCreateAllCorp: (state, { payload }) => {
      state.corpList = new Array(payload.initialCount).fill(payload.corpDetails)
    },
    handleRemoveCorp: (state, { payload }) => {
      state.corpList = state.corpList.filter((_, i) => i != payload)
    },
    handleAddCorp: (state, { payload }) => {
      state.corpList.push(payload)
    },
    handleClearAllCorp: (state) => {
      state.corpList = []
    },
    handleUpdateCorp: (state, { payload }) => {
      state.corpList = state.corpList.map((item, idx) =>
        payload.index === idx ? payload.data : item
      )
    },
    handleUpdateAllCorp: (state, { payload }) => {
      state.corpList = payload
    },
    handleUpdateCorpDetails: (state, { payload }) => {
      state.corpHookData = {
        ...state.corpHookData,
        ...payload
      }
    },
    setMiscData: (state, action) => ({
      ...state,
      [action.payload.key]: action.payload.value
    }),
    removeMiscData: (state, action) => removeKey(state, action.payload.key)
  }
})

export const {
  handleCreateAllCorp,
  handleRemoveCorp,
  handleAddCorp,
  handleClearAllCorp,
  handleUpdateCorp,
  handleUpdateAllCorp,
  handleUpdateCorpDetails,
  setMiscData,
  removeMiscData
} = miscSlice.actions

export default miscSlice.reducer
