// ** Reducers Imports
import layout from "./layout"
// import navbar from "./navbar"
import auth from "./authentication"
import misc from "./misc"
// import vendorList from "../views/expenses/vendor/store/index"
// import roleManagement from "../views/role-management/store/index"
import organizationStructure from "../views/navbar/store/index"
import filters from "./filters"
import notification from "./notification"
const rootReducer = {
  // navbar,
  layout,
  auth,
  // vendorList,
  // roleManagement,
  organizationStructure,
  notification,
  filters,
  misc
}

export default rootReducer
