//  ** React Imports
import { useState } from "react"

// ** Third Party Imports
import { ChevronDown, ChevronRight } from "react-feather"
import Select from "react-select"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Col,
  Input,
  Label,
  Row
} from "reactstrap"

// ** Utils Imports
import { selectThemeColors } from "@utils"
import moment from "moment"
import useFetch from "../../../../../utility/hooks/useFetch"
import { allEntityApi } from "../../../../../services/apiUrl"
import { TableStatusFormatter, statusColor } from "../../../../../utility/Utils"

const JournalDetails = ({ salesDetails, dateFormat, setSalesDetails }) => {
  // ** State
  const [open, setOpen] = useState("")

  const toggle = (id) => {
    open === id ? setOpen() : setOpen(id)
  }

  const [entity = []] = useFetch(
    `${allEntityApi}/${salesDetails?.clientId ?? ""}`,
    {
      callback: (response) =>
        response.map((value) => ({
          label: `${value.clientEntityCode} - ${value.clientEntityName}`,
          value: {
            clientEntityId: value.clientEntityId,
            clientEntityCode: value.clientEntityCode
          }
        }))
    }
  )

  const handleChange = (fieldName, value) => {
    const salesDetailsClone = { ...salesDetails }
    if (fieldName === "entity") {
      salesDetailsClone.clientEntityId = value.clientEntityId
      salesDetailsClone.clientEntityCode = value.clientEntityCode
    } else {
      salesDetailsClone[fieldName] = value
    }
    console.log(salesDetailsClone)
    setSalesDetails(salesDetailsClone)
  }
  return (
    <div className="my-1">
      <Accordion
        className="accordion-without-arrow"
        open={open}
        toggle={toggle}
      >
        {salesDetails && (
          <AccordionItem>
            <AccordionHeader targetId="1">
              {!open ? <ChevronRight size={20} /> : <ChevronDown size={20} />}
              <div className="ms-1 d-flex align-items-center justify-content-between w-100">
                <div className="me-1">Journal Headers</div>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center me-1">
                    <div className="me-50">Sale Date : </div>
                    <div>
                      {salesDetails.salesDate
                        ? moment(salesDetails.salesDate).format(dateFormat)
                        : "-"}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="me-50">Status : </div>
                    <Badge pill color={statusColor(salesDetails.status)}>
                      {TableStatusFormatter(salesDetails.status)}
                    </Badge>
                  </div>
                </div>
              </div>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <Row className="m-0 px-3 align-items-center">
                <Col md={6}>
                  <Row className="mb-1 align-items-center">
                    <Col sm={6}>Transaction Number :</Col>
                    <Col
                      sm={6}
                      style={{ height: "38px" }}
                      className="d-flex align-items-center"
                    >
                      {salesDetails?.salesId ?? "89271827218"}
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-1 align-items-center">
                    <Col sm={6}>Posting Date :</Col>
                    <Col
                      sm={6}
                      style={{ height: "38px" }}
                      className="d-flex align-items-center"
                    >
                      {moment(salesDetails.postingDate).format(dateFormat)}
                    </Col>
                  </Row>
                </Col>
                {false && (
                  <Col md={6}>
                    <Row className="mb-1 align-items-center">
                      <Col sm={6}>Reference Number :</Col>
                      <Col
                        sm={6}
                        style={{ height: "38px" }}
                        className="d-flex align-items-center"
                      >
                        {salesDetails.salesId ? salesDetails.salesId : "-"}
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col md={6}>
                  <Row className="mb-1 align-items-center">
                    <Col sm={6}>Description :</Col>
                    <Col sm={6}>
                      <Input
                        onChange={(e) =>
                          handleChange("description", e.target.value)
                        }
                        type="text"
                        value={salesDetails?.description ?? ""}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-1 align-items-center">
                    <Col sm={6}>Business Unit :</Col>
                    <Col sm={6}>
                      <Select
                        classNamePrefix="select"
                        theme={selectThemeColors}
                        className="react-select"
                        value={entity.find(
                          (e) =>
                            e.value.clientEntityId ===
                            salesDetails.clientEntityId
                        )}
                        onChange={(e) => handleChange("entity", e.value)}
                        options={entity}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </AccordionBody>
          </AccordionItem>
        )}
      </Accordion>
    </div>
  )
}

export default JournalDetails
