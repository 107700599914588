import { lazy } from "react"

const CashBalancingReport = lazy(() =>
  import("../../views/reportings/reporting-view/revenue/cash-balancing-report")
)

const ReportingList = lazy(() =>
  import("../../views/reportings/reporting-list/index")
)
const AgaingReport = lazy(() =>
  import("../../views/reportings/reporting-view/ap-aging-report/index")
)
const AccuralReport = lazy(() =>
  import("../../views/reportings/reporting-view/accural-report/index")
)
const SalesTaxReport = lazy(() =>
  import("../../views/reportings/reporting-view/sales-tax-report/index")
)
const BusinessUserReport = lazy(() =>
  import("../../views/reportings/reporting-view/business-user-aging-report")
)

const BankTransaction = lazy(() =>
  import("../../views/reportings/reporting-view/banking/bank-transaction")
)

const CashReciptReport = lazy(() =>
  import("../../views/reportings/reporting-view/revenue/cash-receipt-report")
)
const CashTrendFlow = lazy(() =>
  import("../../views/reportings/reporting-view/banking/cash-flow-trend")
)

const ThroughputReport = lazy(() =>
  import("../../views/reportings/reporting-view/throughput-report/index")
)
const UnpaidAgingReport = lazy(() =>
  import("../../views/reportings/reporting-view/unpaid-aging-report")
)
const baseRoute = "/reportings"
const ReportingsRoutes = [
  {
    path: baseRoute,
    id: "Reporting",
    element: <ReportingList />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/aging-report`,
    element: <AgaingReport />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/unpaid-aging-report`,
    element: <UnpaidAgingReport />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/accural-report`,
    element: <AccuralReport />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/sales-tax-report`,
    element: <SalesTaxReport />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/business-user-aging-report`,
    element: <BusinessUserReport />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/banking/bank-transactions`,
    element: <BankTransaction />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/revenue/cash-app/cash-recipt-report`,
    element: <CashReciptReport />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/revenue/cash-balancing-report`,
    element: <CashBalancingReport />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/banking/cash-trend-flow`,
    element: <CashTrendFlow />,
    meta: {
      restricted: true
    }
  },
  {
    path: `${baseRoute}/throughput-report`,
    element: <ThroughputReport />,
    meta: {
      restricted: true
    }
  }
]

export default ReportingsRoutes
