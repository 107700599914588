import React, { useState, useEffect } from "react"
import Breadcrumbs from "@custom-components/breadcrumb/Breadcrumbs"
import { useSelector } from "react-redux"

import {
  getAccountCodingUrl as accountCodingUrl,
  allEntityApi as entityUrl,
  listUpdateApi as corpUrl
} from "@service/apiUrl"
import usePost from "@hooks/usePost"
import useFetch from "@hooks/useFetch"
import { formatCorpData } from "@utils"
import LineItemTable from "./LineItemTable"
import xlsx from "json-as-xlsx"
import {
  successHtmlContent,
  swalFire,
  warningHtmlContent
} from "@custom-components/xl-import/utils"
import XLImport from "@custom-components/xl-import/XLImport"

const UploadLinetems = () => {
  const [data, setData] = useState([])
  const [corpData, setCorpData] = useState([])
  const [headers, setHeaders] = useState([])
  const [selectedItem, setSelectedItem] = useState([])
  const { clientId, isAllEntity, entityId, preferredRole } = useSelector(
    (state) => state.auth.userData
  )
  const isApproverUser = preferredRole === "CRATO_APPROVER"
  const tenantId = useSelector((state) => state.auth.userData.tenantId)
  const isDivUser = ["div-industrial", "uat-testing", "m2_softledger"].includes(
    tenantId
  )

  const { makeRequest: accountCodingApi } = usePost(
    `${accountCodingUrl}/getsegmentswithcoding`,
    "get"
  )

  const { makeRequest: entityApi } = usePost(`${entityUrl}/${clientId}`, "get")

  const [accountcodingOption = []] = useFetch(`${corpUrl}/bysegment`, {
    apiParams: {
      params: {
        clientId,
        includeInActive: false,
        clientEntityId: isAllEntity ? "ALL" : entityId
      }
    },
    callback: (res) =>
      res
        ?.find(({ accountCodeName }) => accountCodeName == "GL Code")
        ?.clientAccountSegmentList?.map((v) => ({
          label: `${v.accountCode ?? "-"}-${v.description ?? "-"}`,
          value: v
        }))
  })

  const getMandatoryLabel = ({ label, value, isMandatory }) => {
    const labels = ["description", "product_code"]
    const checkMandatoryField = labels.includes(value) && isDivUser
    const mandatoryLabel = checkMandatoryField ? `${label}*` : label
    return {
      label: mandatoryLabel,
      value,
      isMandatory: checkMandatoryField || isMandatory
    }
  }
  const getcorpOptions = async () => {
    try {
      const accData = await accountCodingApi({ params: { clientId } })
      let data = formatCorpData(accData)
      const entityData = await entityApi()
      data = formatCorpData(accData, entityData)
      setCorpData(data)
      const _data = data.map(
        ({ name: label, segmentCode: value, isMandatory }) => ({
          label: isMandatory ? `${label}*` : label,
          value,
          isMandatory
        })
      )

      const mandatoryDefaultHeaders = defaultHeaders.map(getMandatoryLabel)
      const _header = [...mandatoryDefaultHeaders, ..._data]
      setHeaders(_header)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getcorpOptions()
  }, [])

  const validateImportData = (data, file, importData) => {
    const headerLength = headers.length
    const fileName = file.path
    const errorLength = data.filter((r) => {
      if (headerLength != Object.values(r).length) return true
      return false
    }).length

    const _selectedItem = []
    const _data = data.map((v) => {
      const _id = crypto.randomUUID()
      _selectedItem.push(_id)
      return { ...v, _id }
    })
    setData(_data)
    setSelectedItem(_selectedItem)

    if (!errorLength)
      return swalFire({
        html: successHtmlContent(importData?.length ?? 0),
        icon: "success"
      })
    return swalFire({
      html: warningHtmlContent(fileName, errorLength ?? 0),
      icon: "warning"
    })
  }

  const lineItemTableProps = {
    data,
    corpData,
    selectedItem,
    headers,
    accountcodingOption,
    setSelectedItem,
    setData
  }

  const onDownloadTemplate = () => {
    const data = [
      {
        sheet: "Invoice",
        columns: headers,
        content: []
      }
    ]
    const settings = {
      fileName: `Line_Item_Template_${new Date().getTime()}`,
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
      RTL: false
    }
    xlsx(data, settings)
  }

  return (
    <>
      <Breadcrumbs
        title="Upload Line Items"
        data={isApproverUser ? approverBcData : bcData}
        withGoBack
        noHome={isApproverUser}
      />
      {data?.length ? (
        <LineItemTable {...lineItemTableProps} />
      ) : (
        <>
          <h2 className="pb-1 text-center">Upload your Line Items below</h2>
          <div style={{ padding: "0 6rem", marginTop: "1rem" }}>
            <XLImport
              validateImportData={validateImportData}
              headers={headers}
            />
            <div
              onClick={onDownloadTemplate}
              className="mt-2 d-block text-primary text-decoration-underline cursor-pointer"
            >
              Download Template
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default UploadLinetems

const bcData = [
  { title: "Payables", link: "" },
  { title: "Edit Bills", link: "" },
  { title: "Upload Line Items", link: "" }
]

const approverBcData = [
  { title: "My Box", link: "" },
  { title: "Edit Bills", link: "" },
  { title: "Upload Line Items", link: "" }
]

const defaultHeaders = [
  { label: "Item code", value: "product_code", isMandatory: false },
  { label: "Description", value: "description", isMandatory: false },
  { label: "Uom", value: "unit", isMandatory: false },
  { label: "QTY*", value: "quantity", isMandatory: true },
  { label: "Unit Price*", value: "unit_price", isMandatory: true },
  { label: "Amount*", value: "amount", isMandatory: true },
  { label: "Payables Code*", value: "glCodeDescription", isMandatory: true }
]
