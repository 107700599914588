// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt"
import EncryptionDecryptionService from "../services/EncryptionDecryptionService"

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem("userData")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleEmail: (state, action) => {
      state.email = action.payload.username
      localStorage.setItem("email", action.payload.username)
    },
    handleLogin: (state, action) => {
      // state.userData = action.payload
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName]
      // localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(action.payload.accessToken)
      )
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken)
      )
    },
    handleUserData: (state, action) => {
      state.userData = action.payload
    },
    handleRoleUpdate: (state, action) => {
      state.auth.userData.currentRoleAccess =
        state.auth.userData.currentRoleAccess.roleId == action.payload.roleId
          ? action.payload
          : state.auth.userData.currentRoleAccess
      state.auth.userData.roleAccess = state.auth.userData.roleAccess.map(
        (role) => (role.roleId == action.payload.roleId ? action.payload : role)
      )
    },

    handleLogout: (state) => {
      // ** Remove user, accessToken & refreshToken from localStorage
      // localStorage.removeItem("userData")
      // localStorage.removeItem("email")
      // localStorage.removeItem(config.storageTokenKeyName)
      // localStorage.removeItem(config.storageRefreshTokenKeyName)
      localStorage.clear()
      sessionStorage.clear()
      state.userData = {}
      state.email = ""
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
    }
  }
})

export const {
  handleLogin,
  handleLogout,
  handleRoleUpdate,
  handleEmail,
  handleUserData
} = authSlice.actions

export default authSlice.reducer
