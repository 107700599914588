// ** React Imports
import { useState } from "react"

// ** Third Party Imports
import { Button, Card, Table } from "reactstrap"

// ** Theme Imports
import { decimalFormatter } from "@utils"

// ** Components Import
import Mandatory from "../../../../../../custom-components/mandatoy-star/Mandatory"
import TableRow from "./TableRow"
import AddRowModal from "../../../../../settings/revenue/sales/right-side-components/detail-view/journal-table/AddRowModal"

const JournalTable = ({
  salesDetails,
  currency,
  glCodeOption,
  handleParentDelete,
  handleChildDelete,
  handleParentInputChange,
  handleChildInputChange,
  checklength,
  handleParentAdd,
  handleChildAdd,
  totalAmount,
  isEditable,
  handleParentDebitCredit,
  handleChildDebitCredit,
  toggle,
  isOpen
}) => {
  const [show, setShow] = useState(false)

  return (
    <Card title="Basic" noBody>
      <div className="d-flex justify-content-between p-1 align-items-center">
        <div>Journal Line items</div>
        <div>
          <Button color="primary" onClick={() => setShow(true)}>
            Add Item
          </Button>
        </div>
      </div>
      <Table responsive>
        <thead>
          <tr>
            {false && (
              <th
                className="py-1 px-50 text-center"
                style={{ width: "23px" }}
              ></th>
            )}
            <th className="py-1 ps-1 px-50">Item</th>
            <th className="py-1 px-50 text-end">POS Amount</th>
            <th className="py-1 px-50 text-center">
              Debit <Mandatory />
            </th>
            <th className="py-1 px-50 text-center">
              Credit <Mandatory />
            </th>
            <th className="py-1 px-50 text-center">
              Default GL Code <Mandatory />
            </th>
            <th className="py-1 px-50 text-center">Comment</th>
            <th className="py-1 px-50 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <TableRow
            salesInfo={salesDetails.salesInfo}
            toggle={toggle}
            isOpen={isOpen}
            currency={currency}
            glCodeOption={glCodeOption}
            handleParentDelete={handleParentDelete}
            handleChildDelete={handleChildDelete}
            handleParentInputChange={handleParentInputChange}
            handleChildInputChange={handleChildInputChange}
            checklength={checklength}
            handleChildAdd={handleChildAdd}
            isEditable={isEditable}
            handleParentDebitCredit={handleParentDebitCredit}
            handleChildDebitCredit={handleChildDebitCredit}
          />
          <tr className="fw-bolder">
            {false && <td className="p-1"></td>}
            <td className="px-50 py-1"></td>
            <td className="px-50 py-1 text-end">Total</td>
            <td className="px-50 py-1 text-end">
              <div style={{ width: "160px" }} className="mx-auto">
                {decimalFormatter(totalAmount.debitTotal)}{" "}
                {currency && currency}
              </div>
            </td>
            <td className="px-50 py-1 text-end">
              <div style={{ width: "160px" }} className="mx-auto">
                {decimalFormatter(totalAmount.creditTotal)}{" "}
                {currency && currency}
              </div>
            </td>
            <td className="px-50 py-1"></td>
            <td className="px-50 py-1"></td>
            <td className="px-50 py-1"></td>
          </tr>
        </tbody>
      </Table>

      <AddRowModal
        show={show}
        setShow={setShow}
        handleParentAdd={handleParentAdd}
      />
    </Card>
  )
}

export default JournalTable
