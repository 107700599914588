// ** Imports
import React, { Fragment, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import { X } from "react-feather"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"

// ** Service
import {
  uploadDocumentApi,
  getAllClientApi,
  getAllEntity,
  postInvoiceApi,
  uploadUrlApi
} from "../Service/Service"

// ** Components
import DocumentUpload from "./DocumentUpload"
import InformationCard from "./InformationCard"
import StatusCard from "./StatusCard"

// ** Third Party
import _ from "lodash"
import { clientUrl, getSegmentUrl } from "../../../services/apiUrl"
import useFetch from "../../../utility/hooks/useFetch"
import { useDidUpdateEffect } from "../../../utility/hooks/useDidUpdateEffect"

const UploadDocuments = ({ noHeader, customStyle, activeTab, isComponent }) => {
  const invoiceValues = {
    accountCodings: [],
    clientEntityCode: null,
    clientEntityId: null,
    clientEntityName: null,
    clientId: "",
    clientName: "",
    currency: "",
    comments: [],
    invoiceDocument: {
      fileName: "",
      size: "",
      uploadedOn: "",
      uploadedUser: ""
    },
    loggedInUser: "",
    status: "",
    submittedDate: "",
    totalAmount: Number.parseFloat(0)
  }
  // ** State
  const [files, setFiles] = useState([])
  const [erfiles, seterFiles] = useState([])
  const [company, setcomapny] = useState([])
  const [entity, setEntity] = useState([])
  const [postDetails, setpostDetails] = useState([])
  const [upload, setupload] = useState(false)
  const [uploadModal, setuploadModal] = useState(false)
  const [progressWidth, setprogressWidth] = useState(0)
  const [toast, settoast] = useState(false)
  const [successCount, setsuccessCount] = useState(0)
  const [errorCount, seterrorCount] = useState(0)

  const selector = useSelector((state) => state.auth.userData)
  const navigate = useNavigate()
  const locationData = useLocation().state
  const isApproverUser = selector.preferredRole == "CRATO_APPROVER"

  // const redirectUrl =
  //   locationData === "approver"
  //     ? "/v2/approver/dashboard"
  //     : isComponent
  //     ? -1
  //     : "/v2/ap/invoice/processing"

  const navigateHandler = (fromTabChange) => {
    if (!isApproverUser) {
      if ("TAB_CHANGE" !== fromTabChange)
        return navigate("/v2/ap/invoice/processing")
    }
    if (!isComponent) return navigate(-1)
    setuploadModal(false)
    setFiles([])
    setpostDetails([])
  }

  useDidUpdateEffect(() => {
    if (isComponent) navigateHandler("TAB_CHANGE")
  }, [activeTab])
  useEffect(() => {
    getAllClientApi()
      .then((res) => {
        console.log(res)
        setcomapny(res.data)
      })
      .catch((res) => console.log(res))

    getAllEntity(selector.clientId).then(
      (response) => {
        setEntity(response.data)
      },
      (response) => console.log(response)
    )
    if (locationData !== "approver") {
      setFiles([])
    }
  }, [])

  const [documentType = {}] = useFetch(
    `${clientUrl}${selector.clientId}/documenttype`,
    {
      callback: (data) =>
        data.find((v) => v.documentType.toLowerCase() === "credit memo") ?? ""
    }
  )
  const [entitySegment = {}] = useFetch(
    `${getSegmentUrl}${selector.clientId}`,
    {
      callback: (data) => data?.segments?.[0] ?? {}
    }
  )

  let n = 0
  let e = 0
  const uploadURL = (url, name, post) => {
    uploadUrlApi(url, name)
      .then((res) => {
        console.log(res)
        postInvoiceApi(post)
          .then((response) => {
            n += 1
            console.log(response)
            setprogressWidth((n / files.length) * 100)
            settoast(true)
            setsuccessCount(n)
            setupload(false)
          })
          .catch((response) => {
            console.log(response)
            e += 1
            seterrorCount(e)
          })
      })
      .catch((res) => {
        console.log(res)
      })
  }

  const getAdditionalSegment = (invoice) => {
    const item = entity.find((v) => v.clientEntityId === invoice.clientEntityId)
    if (!item) return {}
    return {
      Location: {
        _id: item.clientEntityId,
        accountCodeName: item.clientEntityName,
        accountCode: item.clientEntityId,
        clientId: item.clientId,
        clientCode: item.clientCode,
        parentUnitName: item.parentUnitName,
        parentUnitCode: item.parentUnitCode,
        address: item.billToAddress ? item.billToAddress : "",
        isActive: true,
        segmentCode: entitySegment?.segmentCode,
        segmentName: entitySegment?.name
      }
    }
  }

  const UploadFiles = () => {
    const postDetailsCopy = _.cloneDeep(postDetails)
    const date = new Date()
    const arr = []
    postDetailsCopy.map((val, idx) => {
      val.invoiceValues.clientId = selector.clientId
      val.invoiceValues.clientName = selector.client.clientName
      val.invoiceValues.invoiceDocument.fileName = files[idx].name
      val.invoiceValues.invoiceDocument.size = files[idx].size
      val.invoiceValues.additionalSegment = getAdditionalSegment(
        val.invoiceValues
      )
      val.invoiceValues.invoiceDocument.uploadedOn = date
      val.invoiceValues.submittedDate = date
      val.invoiceValues.invoiceDocument.uploadedUser = selector.userName
      val.invoiceValues.loggedInUser = selector.userName
      val.invoiceValues.currency =
        selector.basicProfile.defaultCurrency ?? "USD"
      val.invoiceValues.status = "Create"
      arr.push(_.cloneDeep(val))
    })
    files.map((v) => {
      uploadDocumentApi(v.name, selector.clientId)
        .then((res) => {
          console.log(res)
          const url = res.data.uploadURL.trim()
          // **url upload
          uploadURL(url, v, arr[files.indexOf(v)].invoiceValues)
        })
        .catch((res) => console.log(res))
    })
  }

  return (
    <Fragment>
      <div className="m-1  d-flex justify-content-between">
        {!noHeader && (
          <div>
            <span
              className="fs-2 border-end border-3 pe-50 me-1 mb-0"
              style={{ marginLeft: "15px" }}
            >
              Upload Documents
            </span>
          </div>
        )}
        <div style={{ marginRight: "15px", ...customStyle }} className="d-flex">
          {process.env.REACT_APP_TYPE !== "Prod" && (
            <UncontrolledDropdown className="me-1">
              <DropdownToggle color="primary" caret id="create">
                Create
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  className="w-100"
                  onClick={() => navigate("/v2/ap/expense/create-invoice")}
                  id="create-invoice"
                >
                  Invoice
                </DropdownItem>
                {documentType?.active && (
                  <DropdownItem
                    className="w-100"
                    onClick={() =>
                      navigate("/v2/ap/expense/create-credit-memo")
                    }
                    id="create-credit-memo"
                  >
                    Credit Memo
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          <Button
            color="primary"
            className="me-2"
            disabled={!upload}
            onClick={() => {
              UploadFiles()
              setuploadModal(!uploadModal)
            }}
            id="upload"
          >
            Upload
          </Button>
          {!noHeader && (
            <Button
              color="flat-success"
              className="p-50"
              onClick={() => navigate(-1)}
              id="close"
            >
              <X />
            </Button>
          )}
        </div>
      </div>
      <Row>
        <Col lg="3" md="4">
          <DocumentUpload
            files={files}
            setFiles={setFiles}
            setupload={setupload}
            seterFiles={seterFiles}
            postDetails={postDetails}
            setpostDetails={setpostDetails}
          />
        </Col>
        <Col lg="9" md="8">
          <StatusCard files={files} erfiles={erfiles} />
          <InformationCard
            files={files}
            company={company}
            selector={selector}
            entity={entity}
            setFiles={setFiles}
            postDetails={postDetails}
            setpostDetails={setpostDetails}
            invoiceValues={invoiceValues}
          />
        </Col>
      </Row>

      <Modal
        isOpen={uploadModal}
        toggle={() => setuploadModal(!uploadModal)}
        className="modal-dialog-centered"
        backdrop={false}
        id="upload-documents-modal"
      >
        <ModalHeader>Uploading Your Documents</ModalHeader>
        <ModalBody className="my-1">
          {toast ? (
            <span>
              {" "}
              {successCount} / {files.length} Invoice(s) Created Successfully
            </span>
          ) : (
            <span>0 / {files.length} Invoice Created</span>
          )}
          <Progress value={progressWidth} />
          {errorCount > 0 ? (
            <span>Error in Creating {errorCount} invoices </span>
          ) : (
            ""
          )}
          {errorCount > 0 ? (
            <Progress
              className="progress-bar-danger"
              value={(errorCount / files.length) * 100}
            />
          ) : (
            ""
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={navigateHandler} id="close-btn">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default UploadDocuments
