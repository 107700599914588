// ** React Import
import { Fragment } from "react"

// ** Component Import
import Filter from "./Filter"
import SalesTable from "./sales-table"

// ** BreadCrumb Import
import { salesInputCrumb } from '../../../../../custom-components/breadcrumb/breadcrumb-data/breadcrumbData'
import Breadcrumbs from '../../../../../custom-components/breadcrumb/Breadcrumbs'

const SalesList = () => {
    return (
        <Fragment>
            <Breadcrumbs title='POS Data' data={salesInputCrumb} />
            <Filter />
            <SalesTable />
        </Fragment>
    )
}

export default SalesList