// ** Third Party Components
import { useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { useSelector } from "react-redux"
import { ShortListSkeleton } from "@custom-components/skeleton/Loader"
//import * as _ from 'lodash'

const getDayFilterPercentagefn = (val) => {
  switch (val) {
    case "Last 7":
      return "lastWeekPercentage"
    case "Last Month":
      return "lastMonthPercentage"
    case "Last Quarter":
      return "lastQuarterPercentage"
    case "Last Year":
      return "lastYearPercentage"
    default:
      return ""
  }
}

// ** Reactstrap Imports
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap"
import { getTopExpense } from "../../Service/Service"

const TopFiveExpenses = ({ tooltipShadow }) => {
  const { userData } = useSelector((state) => state.auth)
  const [data, setData] = useState([])
  const [selectedRange, setSelectedRange] = useState("Last Month")
  const colors = ["#ea5455", "#00cfe8", "#40dc65", "#ff9f43", "#c172e0"]
  const [selectedFilterData, setSelectedFilterData] = useState([])
  const [loading, setLoading] = useState(false)
  const [dataa, setDataa] = useState({
    datasets: [
      {
        labels: [],
        data: [],
        backgroundColor: colors,
        borderWidth: 2,
        pointStyle: "rectRounded"
      }
    ]
  })

  // ** Chart Options
  const options = {
    cutout: 80,
    animation: {
      resize: {
        duration: 500
      }
    },
    plugins: {
      legend: { display: false },
      tooltips: {
        callbacks: {
          label(context) {
            console.log(context)
            const label = context.label || ""
            if (label) {
              label += "Ronak: "
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
              }).format(context.parsed.y)
            }
            return label
          }
        },
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: tooltipShadow,
        backgroundColor: "#fff",
        titleFontColor: "#000",
        bodyFontColor: "#000"
      }
    },
    maintainAspectRatio: false
  }

  useEffect(() => {
    setLoading(true)
    getTopExpense(userData.clientId)
      .then((res) => {
        //console.log(res.data)
        setData(res.data)
        res.data.map((item) => {
          if (item.filter === selectedRange) {
            setSelectedFilterData(item.expenseCodes)
            const labelVal = []
            const dataset = []
            item?.expenseCodes?.map((label) => {
              labelVal.push("label.description")
              dataset.push(
                Number.parseInt(
                  label[getDayFilterPercentagefn(selectedRange)].toFixed(0)
                )
              )
            })
            setDataa({
              datasets: [
                {
                  labels: labelVal,
                  data: dataset,
                  backgroundColor: colors,
                  borderWidth: 2,
                  pointStyle: "rectRounded"
                }
              ]
            })
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
    return () => setData(null)
  }, [])

  const onChangeFilter = (selectedFilter) => {
    data.map((item) => {
      if (item.filter === selectedFilter) {
        setSelectedFilterData(item.expenseCodes)
        const labelVal = []
        const dataset = []
        item?.expenseCodes?.map((d) => {
          //console.log(d)
          labelVal.push(d.description)
          dataset.push(
            Number.parseInt(
              d[getDayFilterPercentagefn(selectedFilter)].toFixed(0)
            )
          )
        })
        setDataa({
          labels: labelVal,
          datasets: [
            {
              data: dataset,
              backgroundColor: colors,
              borderWidth: 2,
              pointStyle: "rectRounded"
            }
          ]
        })
      }
    })
  }

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
        <CardTitle tag="h4">Top 5 Payables </CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
            id="expenses-date-btn"
          >
            {selectedRange}
          </DropdownToggle>
          <DropdownMenu end id="select-expenses-date">
            {data &&
              data.map((item, i) => (
                <DropdownItem
                  className="w-100"
                  key={i}
                  onClick={() => {
                    setSelectedRange(item.filter)
                    onChangeFilter(item.filter)
                  }}
                >
                  {item.filter}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      {loading ? (
        <div class="ms-1">
          <ShortListSkeleton />
        </div>
      ) : (
        <CardBody>
          {!selectedFilterData.length && (
            <h3 className="text-center">
              No data available for the selected Period
            </h3>
          )}
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ height: "250px" }}>
              {dataa && (
                <Doughnut data={dataa} options={options} height="250" />
              )}
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center gap-1 ">
              {selectedFilterData &&
                selectedFilterData.map((item, index) => (
                  <div
                    className="d-flex align-items-center"
                    id={`chartTip${index}`}
                  >
                    <div
                      style={{
                        height: "40px",
                        width: "40px",
                        border: `2px solid ${colors[index]}`,
                        borderRadius: "80px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <UncontrolledTooltip
                        placement="top"
                        target={`chartTip${index}`}
                      >
                        {item.description}
                        {"- "}
                        {item[getDayFilterPercentagefn(selectedRange)].toFixed(
                          0
                        )}
                        %
                      </UncontrolledTooltip>
                      <span style={{ fontSize: "11px" }}>
                        {item[getDayFilterPercentagefn(selectedRange)].toFixed(
                          0
                        )}
                        %
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </CardBody>
      )}
    </Card>
  )
}

export default TopFiveExpenses
