// ** React Imports
import { NavLink } from "react-router-dom"

// ** Third Party Components
import classnames from "classnames"
import useFilter from "@hooks/useFilter"

// ** Reactstrap Imports
import { Badge } from "reactstrap"

const VerticalNavMenuLink = ({ item, activeItem }) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? "a" : NavLink

  // ** Hooks
  const { clearAllFiter } = useFilter()[3]
  const Icon = item.icon

  return (
    <li
      onClick={() => clearAllFiter()}
      className={classnames({
        "nav-item": !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem
      })}
    >
      <LinkTag
        className="d-flex align-items-center m-0"
        style={{ marginRight: "10px", display: "flex", alignItems: "center" }}
        target={item.newTab ? "_blank" : undefined}
        /*eslint-disable */
        {...(item.externalLink === true
          ? {
              href: item.navLink || "/"
            }
          : {
              to: item.navLink || "/",
              className: ({ isActive }) => {
                if (isActive && !item.disabled) {
                  return "d-flex align-items-center active"
                }
              }
            })}
        onClick={(e) => {
          if (
            item.navLink.length === 0 ||
            item.navLink === "#" ||
            item.disabled === true
          ) {
            e.preventDefault()
          }
        }}
      >
        {/* {item.icon} */}
        <div>
          <Icon style={{ width: "16px", aspectRatio: "1/1" }} />
        </div>

        <div
          className="menu-item text-truncate"
          style={{
            marginTop: "0.4rem",
            paddingBottom: ".2rem",
            fontSize: ".9rem"
          }}
          id={item.id}
        >
          {item.title}
        </div>

        {item.badge && item.badgeText ? (
          <Badge className="ms-auto me-1" color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </LinkTag>
    </li>
  )
}

export default VerticalNavMenuLink
