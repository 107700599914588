import React, { useState } from "react"
import toast from "@custom-components/toast.jsx"
import { Button, Col, Input, Label, Row } from "reactstrap"
import { putClientDetail } from "../../service/cratoPayService"

const BatchPayments = ({ clientData, setClientData }) => {
  // console.log(clientData)

  // console.log(clientData?.cratoPaySetup?.enableBatchPayments)
  const [createBatch, setCreateBatch] = useState(
    clientData?.cratoPaySetup?.enableBatchPayments
  )
  const [limitValue, setLimitValue] = useState(
    clientData?.cratoPaySetup?.amountLimit
  )
  const [batchTotalAmountLimit, setbatchTotalAmountLimit] = useState(
    clientData?.cratoPaySetup?.batchTotalLimit
  )

  const handleSubmit = () => {
    const cloneObj = _.cloneDeep(clientData)
    cloneObj.cratoPaySetup.enableBatchPayments = createBatch
    cloneObj.cratoPaySetup.amountLimit = limitValue
    cloneObj.cratoPaySetup.batchTotalLimit = batchTotalAmountLimit
    //console.log(cloneObj)
    setClientData(cloneObj)
    putClientDetail(cloneObj)
      .then((data) => {
        console.log(data.data)
        toast.success("Record Updated Successfully", {
          duration: 3000,
          position: "top-center"
        })
      })
      .catch((err) => {
        console.log(err)
        toast.error("Something went wrong", {
          duration: 3000,
          position: "top-center"
        })
      })
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="fw-bolder">Payment Batches</h5>
        <Button color="primary" onClick={handleSubmit} id="paybatch-save-btn">
          Save
        </Button>
      </div>
      <Row className="mt-2">
        <Col md={3}>
          <p>Enable Create Batch</p>
        </Col>
        <Col md={3}>
          <div className="form-check form-switch">
            <Input
              type="switch"
              name="isActive"
              checked={createBatch}
              onChange={(e) => {
                setCreateBatch(e.target.checked)
              }}
              id="create-batch"
            />
            <Label className="form-check-label switch-icon-left switch-icon-right ps-1">
              {createBatch ? "Yes" : "No"}
            </Label>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={3}>
          <p>Batch Total Amount Limit</p>
        </Col>
        <Col md={4}>
          <div className="mb-1">
            <Input
              type="radio"
              name="BP"
              id="BP"
              defaultChecked={batchTotalAmountLimit === "UNLIMITED"}
              value="UNLIMITED"
              disabled={createBatch === false}
              onChange={(e) => setbatchTotalAmountLimit(e.target.value)}
            />
            <Label className="form-check-label switch-icon-left switch-icon-right ms-1">
              Unlimited
            </Label>
          </div>
          <div className="d-flex justify-content-start align-items-center mb-1">
            <Input
              type="radio"
              name="BP"
              id="BP"
              value="LIMITUPTO"
              defaultChecked={batchTotalAmountLimit === "LIMITUPTO"}
              disabled={createBatch === false}
              onChange={(e) => setbatchTotalAmountLimit(e.target.value)}
            />
            <Label className="form-check-label switch-icon-left switch-icon-right ms-1">
              Limit Upto
            </Label>
            <Input
              type="text"
              className="w-50 ms-1"
              size="sm"
              value={limitValue}
              onChange={(e) => setLimitValue(e.target.value)}
              disabled={createBatch === false}
            />
          </div>
          <div className="">
            <Input
              type="radio"
              name="BP"
              id="BP"
              value="NOTEXCEEDBANKCASH"
              defaultChecked={batchTotalAmountLimit === "NOTEXCEEDBANKCASH"}
              // disabled={createBatch === false}
              disabled={true}
              onChange={(e) => setbatchTotalAmountLimit(e.target.value)}
            />
            <Label className="form-check-label switch-icon-left switch-icon-right ms-1">
              Do NOT Exceed Cash in Bank Total
            </Label>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default BatchPayments
