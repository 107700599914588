import { useState } from "react"
import { Doughnut } from "react-chartjs-2"
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  CardSubtitle
} from "reactstrap"
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
const rangeOptions = [
  {
    range: "Last 7 Days",
    data: [10, 30, 60]
  },
  {
    range: "Last month",
    data: [30, 40, 30]
  },
  {
    range: "Last Quater",
    data: [60, 35, 5]
  },
  {
    range: "Last Year",
    data: [20, 35, 45]
  }
]
const colors = ["#ffe700", "#00d4bd", "#826bf8", "#2b9bf4", "#FFA1A1"]
const DeductionWidget = () => {
  const [activeRange, setActiveRange] = useState(rangeOptions[0])
  const data = {
    labels: ["Open", "Approved", "Denied"],

    datasets: [
      {
        data: activeRange.data,
        backgroundColor: colors,
        borderWidth: 2,
        pointStyle: "rectRounded"
      }
    ]
  }
  /*eslint-disable */
  const options = {
    cutout: 80,
    animation: {
      resize: {
        duration: 500
      }
    },
    plugins: {
      legend: { display: false },
      tooltips: {
        callbacks: {
          label(context) {
            console.log(context)
            const label = context.label || ""
            if (label) {
              label += "Ronak: "
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
              }).format(context.parsed.y)
            }
            return label
          }
        },
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: "rgba(0, 0, 0, 0.25)",
        backgroundColor: "#fff",
        titleFontColor: "#000",
        bodyFontColor: "#000"
      }
    },
    maintainAspectRatio: false
  }

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
        <CardTitle tag="h4">Deductions</CardTitle>

        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
          >
            {activeRange.range}
          </DropdownToggle>
          <DropdownMenu end>
            {rangeOptions.map((item, i) => (
              <DropdownItem
                className="w-100"
                key={i}
                onClick={() => {
                  setActiveRange(item)
                }}
              >
                {item.range}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      <CardBody className="d-flex align-items-center flex-column justify-content-between">
        <CardSubtitle
          style={{
            position: "absolute",
            left: "24px",
            top: "56px",
            fontSize: "12px"
          }}
        >
          By Count
        </CardSubtitle>

        <div style={{ height: "280px" }}>
          <Doughnut data={data} options={options} height="280" />
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          {data.labels.map((name, i) => (
            <div className="me-2">
              <span
                className="bullet bullet-sm bullet-bordered me-50"
                style={{ backgroundColor: colors[i] }}
              ></span>
              <span className="align-middle ">{name}</span>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
    // <div>DeductionWidget</div>
  )
}

export default DeductionWidget
