import classnames from "classnames"
import { User, DollarSign, PieChart, ShoppingCart } from "react-feather"
import { useState } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import {
  Card,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Row,
  Col,
  CardTitle,
  CardText,
  CardBody
} from "reactstrap"
import Avatar from "@components/avatar"
import NextButton from "@custom-components/buttons/next-button/NextButton"
import { kFormatter as KF, decimalNumberFormatter as D } from "@utils"
import { transactionSummary } from "@service/apiUrl"
import useFetch from "@hooks/useFetch"
import CF from "@utility/helper/currencyFormatter"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const defaultData = [
  {
    title: "0",
    subtitle: "Txn(s) count",
    color: "light-primary",
    icon: <PieChart size={24} />
  },
  {
    title: "0",
    subtitle: "Cash Inflow",
    color: "light-info",
    icon: <User size={24} />
  },
  {
    title: "0",
    subtitle: "Cash Outflow",
    color: "light-danger",
    icon: <ShoppingCart size={24} />
  },
  {
    title: "0",
    subtitle: "Net Balance",
    color: "light-success",
    icon: <DollarSign size={24} />
  }
]
const CashFlowTrend = ({ cols = { xl: "3", sm: "6" } }) => {
  const { clientId } = useSelector((state) => state.auth.userData)
  const navigate = useNavigate()
  const [activeRange, setActiveRange] = useState(rangeOptions[0])

  const [data = defaultData, { loading }] = useFetch(transactionSummary, {
    apiParams: {
      params: {
        clientId,
        pageNumber: 1,
        pageSize: 100,
        ...getFromDate(activeRange)
      }
    },
    callback: (res) => {
      return data.map((value) => {
        for (const key in res) {
          if (dataFormat[key] === value.subtitle) {
            value.title = CF(KF(D(res[key])))
            return value
          }
        }
        return value
      })
    }
  })
  const goTo = () => navigate("/v2/reportings/banking/cash-trend-flow")

  const renderData = () => {
    return data.map((item, index) => {
      const colMargin = Object.keys(cols)
      const margin = index === 2 ? "sm" : colMargin[0]
      return (
        <Col
          key={index}
          {...cols}
          className={classnames({
            [`mb-2 mb-${margin}-0`]: index !== data.length - 1
          })}
        >
          <div className="d-flex align-items-center mt-1">
            <div style={{ transform: "scale(1)" }}>
              <Avatar
                color={item.color}
                icon={item.icon}
                className="me-2"
                // size="sm"
              />
            </div>
            <div className="my-auto">
              <h4 className="fw-bolder mb-0 text-center ">{item.title}</h4>
              <CardText className="font-small-3 mb-0 text-nowrap">
                {item.subtitle}
              </CardText>
            </div>
          </div>
        </Col>
      )
    })
  }

  return (
    <Card className="card-statistics">
      <CardHeader>
        <CardTitle tag="h4" className="flex-center">
          Cash Flow Trend
        </CardTitle>
        <div className="d-flex gap-1">
          <NextButton
            onClick={goTo}
            size="sm"
            id="dashboard-detail-report"
            color="primary"
          >
            View Detailed Report
          </NextButton>
          <CardText className="card-text font-small-2 me-25 mb-0">
            <UncontrolledDropdown className="chart-dropdown">
              <DropdownToggle
                color=""
                style={{ color: "#33cc33" }}
                className="bg-transparent btn-sm border-0 p-50"
              >
                {activeRange}
              </DropdownToggle>
              <DropdownMenu end>
                {rangeOptions.map((item, i) => (
                  <DropdownItem
                    className="w-100"
                    key={i}
                    onClick={() => {
                      setActiveRange(item)
                    }}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </CardText>
        </div>
      </CardHeader>
      <CardBody>
        {/* <div className="d-flex align-item-center justify-content-center"> */}
        {loading ? (
          <FlowSkeleton />
        ) : (
          <Row className="mt-1">{renderData()}</Row>
        )}
        {/* </div> */}
      </CardBody>
    </Card>
  )
}

export default CashFlowTrend

const rangeOptions = ["Last 7 Days", "Last month", "Last Quater", "Last Year"]
const dataFormat = {
  totalCount: "Txn(s) count",
  cashInAmount: "Cash Inflow",
  cashOutAmount: "Cash Outflow",
  totalAmount: "Net Balance"
}
function getFromDate(value) {
  const dateValue = {
    "Last 7 Days": "w",
    "Last month": "M",
    "Last Quater": "Q",
    "Last Year": "y"
  }
  const date = moment().subtract("1", dateValue[value]).format(dateFormat)
  if (value === "Last 7 Days")
    return { dateFrom: date, dateTo: moment().format(dateFormat) }
  const startDate = moment(date).startOf(dateValue[value]).format(dateFormat)
  const endDate = moment(date).endOf(dateValue[value]).format(dateFormat)
  return { dateFrom: startDate, dateTo: endDate }
}
const dateFormat = "YYYY-MM-DD"

const FlowSkeleton = () => (
  <SkeletonTheme>
    <div className="row my-1">
      {new Array(4).fill("").map((_, id) => (
        <div className="col flex-center" key={id}>
          <Skeleton
            width={"50px"}
            style={{ borderRadius: "40px" }}
            height={"50px"}
          />
          <Skeleton className="ms-25" width={"60px"} />
        </div>
      ))}
    </div>
  </SkeletonTheme>
)

// {
//   totalCount: 3,
//   totalAmount: 42000,
//   cashInAmount: 0,
//   cashOutAmount: 42000,
//   date: null,
//   year: null,
//   period: null
// }
