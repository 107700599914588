/*eslint-disable */
const posSystemData = {
  systems: [
    { value: "SHOPIFY", label: "Shopify" },
    { value: "TOAST", label: "Toast" },
    { value: "SQUARE", label: "Square" }
  ],
  formData: {
    SHOPIFY: [
      {
        name: "storeName",
        rules: { required: true },
        label: "Store Name",
        type: "text"
      },
      {
        name: "remitanceEntry",
        rules: {},
        label: "Remitance Entry",
        type: "radio",
        options: [
          {
            defaultChecked: true,
            label: "Yes",
            id: "remitanceEntryYes",
            value: "1"
          },
          {
            defaultChecked: false,
            label: "No",
            id: "remitanceEntryNo",
            value: "0"
          }
        ]
      }
    ],
    TOAST: [
      {
        name: "apiKey",
        rules: { required: true },
        label: "Toast API key",
        type: "text"
      },
      {
        name: "storeName",
        rules: { required: true },
        label: "Store Name",
        type: "text"
      },
      {
        name: "remitanceEntry",
        rules: {},
        label: "Remitance Entry",
        type: "radio",
        options: [
          {
            defaultChecked: true,
            label: "Yes",
            id: "remitanceEntryYes",
            value: "1"
          },
          {
            defaultChecked: false,
            label: "No",
            id: "remitanceEntryNo",
            value: "0"
          }
        ]
      }
    ],
    SQUARE: [
      {
        name: "storeName",
        rules: { required: true },
        label: "Square store Name",
        type: "text"
      }
    ]
  },
  scopes: "read_orders,read_customers,read_locations",
  secrets: ["cratosec", "cratosecert", "cratokey", "cratosecretkey"]
}

const defaultTemplateHeaders = [
  "Cash Deposits",
  "Non Cash Payments",
  "Sales",
  "GCs Sold",
  "Surcharge",
  "Non-Sales Revenue",
  "Taxes",
  "Discounts & Comps",
  "Promos",
  "GCs Comps",
  "GCs Promoed",
  "Paid in/Out",
  "Over/Short",
  "Guest Count"
]

const performanceMetrics = {
  "Turn Around": {
    brief: "Submission to Completion",
    description:
      "Average time taken between submission of a program for execution and return of the complete output to customer"
  },
  "First Touch": {
    brief: "Submission to first manual touch",
    description:
      "Average time taken between submission of a program for execution to first manual action by a user"
  },
  Resolve: {
    brief: "Average Resolution time ",
    description:
      "Average Time taken by business user to provide resolution for items received in ther inbox"
  },
  Approval: {
    brief: "Average Approval time	",
    description:
      "Average Time taken by approvers to approve items received for action in their inbox"
  },
  Followup: {
    brief: "Average Followup time	",
    description: "Average Time spent for items waiting for Follow up"
  }
}

const CU_MOCK_DATA = []

const dataConfig = {
  posSystemData,
  defaultTemplateHeaders,
  performanceMetrics,
  CU_MOCK_DATA: CU_MOCK_DATA.map((item, transactionId) => {
    const {
      Reference: referenceId,
      Description: description,
      Date: date
    } = item
    return {
      referenceId,
      description,
      date,
      amount: item["Cash In"] ?? item["Cash Out"],
      glCodeName: item["Suggested GL"],
      transactionId,
      institutionName: "Vizo",
      transactionCategory: "UNCATEGORIZED"
      // transactionCategory: "BOOKED",
    }
  })
}

export default dataConfig
