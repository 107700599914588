import React from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "./styles.scss"
const Loader = () => <span className="dashboard-custom-loader" />
export default Loader

export const ShortListSkeleton = () =>
  Array(5)
    .fill("")
    .map((_, id) => (
      <div className="flex-center my-25" key={id}>
        <SkeletonTheme>
          <Skeleton
            width={"50px"}
            style={{ borderRadius: "40px" }}
            height={"50px"}
          />
          <Skeleton className="ms-25" width={"20rem"} />
        </SkeletonTheme>
      </div>
    ))
