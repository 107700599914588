import { useState, useCallback } from "react"
import api from "axios"

const usePost = (url, method = "post") => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()
  const [data, setData] = useState()

  const makeRequest = useCallback(
    async (config) => {
      setIsLoading(true)
      setError(null)
      try {
        const { data } = await api({
          method,
          url,
          ...config
        })
        setData(data)
        return data
      } catch (error) {
        setError(error)
        throw error
      } finally {
        setIsLoading(false)
      }
    },
    [url, method]
  )

  return { makeRequest, data, isLoading, error }
}
export default usePost
