import { Fragment } from "react"
import Select from "react-select"

// ** Theme Imports
import { selectThemeColors, decimalFormatter } from "@utils"
import {
  ChevronDown,
  ChevronRight,
  PlusCircle,
  Settings,
  Trash
} from "react-feather"
import { Button, Collapse, Input } from "reactstrap"

const TableRow = ({
  salesInfo,
  toggle,
  isOpen,
  currency,
  glCodeOption,
  handleParentDelete,
  handleChildDelete,
  handleParentInputChange,
  handleChildInputChange,
  checklength,
  handleChildAdd,
  isEditable,
  handleParentDebitCredit,
  handleChildDebitCredit
}) => {
  const getBorder = (subData, length, index, isOpen) => {
    if (subData) {
      if (length !== index + 1) {
        return "border-0"
      } else {
        return ""
      }
    } else {
      if (length > 1 && isOpen) {
        return "border-0"
      } else {
        return ""
      }
    }
  }

  const getGlId = (glCode) => {
    if (glCode) {
      if (Object.keys(glCode).length !== 0) {
        return glCode["_id"]
      } else {
        return "-"
      }
    } else {
      return ""
    }
  }

  return (
    <Fragment>
      {Array.isArray(salesInfo) &&
        salesInfo.map((parentItem, parentIdx) => (
          <Fragment key={parentIdx}>
            <tr>
              {false && (
                <td
                  className={`text-nowrap p-1 ${getBorder(
                    false,
                    checklength(parentItem.children),
                    null,
                    isOpen[parentIdx]
                  )}`}
                  style={{ width: "23px" }}
                >
                  {checklength(parentItem.children) && (
                    <div>
                      {isOpen[parentIdx] ? (
                        <ChevronDown
                          size={20}
                          onClick={() => toggle(parentIdx)}
                          className="cursor-pointer"
                        />
                      ) : (
                        <ChevronRight
                          size={20}
                          onClick={() => toggle(parentIdx)}
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                  )}
                </td>
              )}
              <td
                className={`text-nowrap ps-1 px-50 py-1 ${getBorder(
                  false,
                  checklength(parentItem.children),
                  null,
                  isOpen[parentIdx]
                )}`}
              >
                {parentItem.name ? parentItem.name : "-"}
              </td>
              <td
                className={`text-nowrap text-end px-50 py-1 ${getBorder(
                  false,
                  checklength(parentItem.children),
                  null,
                  isOpen[parentIdx]
                )}`}
              >
                {!checklength(parentItem.children) && (
                  <div>
                    {decimalFormatter(parentItem.amount)} {currency && currency}
                  </div>
                )}
              </td>
              {/* Debit */}
              <td
                className={`text-nowrap px-50 py-1 ${getBorder(
                  false,
                  checklength(parentItem.children),
                  null,
                  isOpen[parentIdx]
                )} text-center`}
              >
                {!checklength(parentItem.children) && (
                  <div style={{ width: "160px" }} className="mx-auto text-end">
                    {isEditable ? (
                      <Input
                        type="number"
                        className="mx-auto text-end"
                        onChange={(e) =>
                          handleParentDebitCredit(
                            e.target.value,
                            "DEBIT",
                            parentIdx
                          )
                        }
                        disabled={
                          parentItem.action === "CREDIT" ||
                          (parentItem.amount > 0 && parentItem.amount < 0)
                        }
                        value={
                          parentItem.action === "CREDIT"
                            ? "0.00"
                            : parentItem.amount
                        }
                      />
                    ) : (
                      <div>
                        {decimalFormatter(parentItem.amount)}{" "}
                        {currency ? currency : ""}
                      </div>
                    )}
                  </div>
                )}
              </td>
              {/* Credit */}
              <td
                className={`text-nowrap px-50 py-1 ${getBorder(
                  false,
                  checklength(parentItem.children),
                  null,
                  isOpen[parentIdx]
                )} text-center`}
              >
                {!checklength(parentItem.children) && (
                  <div style={{ width: "160px" }} className="mx-auto text-end">
                    {isEditable ? (
                      <Input
                        type="number"
                        className="mx-auto text-end"
                        disabled={
                          parentItem.action === "DEBIT" ||
                          (parentItem.amount > 0 && parentItem.amount < 0)
                        }
                        value={
                          parentItem.action === "DEBIT"
                            ? "0.00"
                            : parentItem.amount
                        }
                        onChange={(e) =>
                          handleParentDebitCredit(
                            e.target.value,
                            "CREDIT",
                            parentIdx
                          )
                        }
                      />
                    ) : (
                      <div>
                        {decimalFormatter(parentItem.amount)}{" "}
                        {currency ? currency : ""}
                      </div>
                    )}
                  </div>
                )}
              </td>
              <td
                className={`text-nowrap px-50 py-1 ${getBorder(
                  false,
                  checklength(parentItem.children),
                  null,
                  isOpen[parentIdx]
                )} text-center`}
              >
                {!checklength(parentItem.children) &&
                  (isEditable ? (
                    <div
                      style={{ width: "200px" }}
                      className="text-start mx-auto"
                    >
                      <Select
                        classNamePrefix="select"
                        theme={selectThemeColors}
                        options={glCodeOption}
                        onChange={(e) =>
                          handleParentInputChange(e.value, "glCode", parentIdx)
                        }
                        value={glCodeOption.find(
                          (item) =>
                            item.value["_id"] === getGlId(parentItem.glCode)
                        )}
                        className="react-select"
                      />
                    </div>
                  ) : parentItem.glCode ? (
                    parentItem.glCode.description ? (
                      parentItem.glCode.description
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  ))}
              </td>
              <td
                className={`text-nowrap px-50 py-1 ${getBorder(
                  false,
                  checklength(parentItem.children),
                  null,
                  isOpen[parentIdx]
                )} text-center`}
              >
                {!checklength(parentItem.children) &&
                  (isEditable ? (
                    <Input
                      type="text"
                      className="mx-auto"
                      style={{ width: "160px" }}
                      onChange={(e) =>
                        handleParentInputChange(
                          e.target.value,
                          "comment",
                          parentIdx
                        )
                      }
                      value={parentItem.comment}
                    />
                  ) : parentItem.comment ? (
                    parentItem.comment
                  ) : (
                    "-"
                  ))}
              </td>
              <td
                className={`text-nowrap px-50 py-1 ${getBorder(
                  false,
                  checklength(parentItem.children),
                  null,
                  isOpen[parentIdx]
                )} text-center`}
              >
                {!checklength(parentItem.children) && (
                  <div className="demo-inline-spacing justify-content-center">
                    {false && (
                      <div
                        className="mx-50 my-0 cursor-pointer"
                        onClick={() => handleChildAdd(parentIdx, -1)}
                      >
                        <PlusCircle size={20} />
                      </div>
                    )}
                    <div className="mx-50 my-0">
                      <Button
                        disabled={parentItem.name === "Over-short"}
                        color="flat-danger"
                        size="sm"
                        onClick={() => handleParentDelete(parentIdx)}
                      >
                        <Trash size={20} />
                      </Button>
                    </div>
                  </div>
                )}
              </td>
            </tr>
            {false && checklength(parentItem.children) &&
              parentItem.children.map((childItem, childIdx) => (
                <Fragment key={childIdx}>
                  <Collapse
                    isOpen={isOpen[parentIdx]}
                    tag="tr"
                    className={`${!(childIdx % 2) && "striped-bg"}`}
                  >
                    <td
                      className={`text-nowrap p-1 ${getBorder(
                        true,
                        checklength(parentItem.children),
                        childIdx
                      )}`}
                    ></td>
                    <td
                      className={`text-nowrap px-50 ${getBorder(
                        true,
                        checklength(parentItem.children),
                        childIdx
                      )}`}
                    >
                      <div
                        className="d-flex justify-content-start align-items-center journal-table-border ps-2"
                        style={{ height: "50px", borderLeft: "3px soild red" }}
                      >
                        {isEditable ? (
                          <Input
                            type="text"
                            style={{ width: "160px" }}
                            value={childItem.name}
                            onChange={(e) =>
                              handleChildInputChange(
                                e.target.value,
                                "name",
                                parentIdx,
                                childIdx
                              )
                            }
                          />
                        ) : childItem.name ? (
                          childItem.name
                        ) : (
                          "-"
                        )}
                      </div>
                    </td>
                    <td
                      className={`text-nowrap px-50 ${getBorder(
                        true,
                        checklength(parentItem.children),
                        childIdx
                      )} text-end`}
                    >
                      {decimalFormatter(childItem.amount)}{" "}
                      {currency && currency}
                    </td>
                    {/* Debit */}
                    <td
                      className={`text-nowrap px-50 ${getBorder(
                        true,
                        checklength(parentItem.children),
                        childIdx
                      )} text-center`}
                    >
                      <div
                        style={{ width: "160px" }}
                        className="mx-auto text-end"
                      >
                        {isEditable ? (
                          <Input
                            type="number"
                            className="mx-auto text-end"
                            disabled={
                              childItem.action === "CREDIT" ||
                              (childItem.amount > 0 && childItem.amount < 0)
                            }
                            value={
                              childItem.action === "CREDIT"
                                ? "0.00"
                                : childItem.amount
                            }
                            onChange={(e) =>
                              handleChildDebitCredit(
                                e.target.value,
                                "DEBIT",
                                parentIdx,
                                childIdx
                              )
                            }
                          />
                        ) : (
                          <div>
                            {decimalFormatter(childItem.amount)}{" "}
                            {currency ? currency : ""}
                          </div>
                        )}
                      </div>
                    </td>
                    {/* Credit */}
                    <td
                      className={`text-nowrap px-50 ${getBorder(
                        true,
                        checklength(parentItem.children),
                        childIdx
                      )} text-center`}
                    >
                      <div
                        style={{ width: "160px" }}
                        className="mx-auto text-end"
                      >
                        {isEditable ? (
                          <Input
                            type="number"
                            className="mx-auto text-end"
                            disabled={
                              childItem.action === "DEBIT" ||
                              (childItem.amount > 0 && childItem.amount < 0)
                            }
                            value={
                              childItem.action === "DEBIT"
                                ? "0.00"
                                : childItem.amount
                            }
                            onChange={(e) =>
                              handleChildDebitCredit(
                                e.target.value,
                                "CREDIT",
                                parentIdx,
                                childIdx
                              )
                            }
                          />
                        ) : (
                          <div>
                            {decimalFormatter(childItem.amount)}{" "}
                            {currency ? currency : ""}
                          </div>
                        )}
                      </div>
                    </td>
                    <td
                      className={`text-nowrap px-50 ${getBorder(
                        true,
                        checklength(parentItem.children),
                        childIdx
                      )} text-center`}
                    >
                      {isEditable ? (
                        <div
                          style={{ width: "200px" }}
                          className="text-start mx-auto"
                        >
                          <Select
                            classNamePrefix="select"
                            theme={selectThemeColors}
                            options={glCodeOption}
                            value={glCodeOption.find(
                              (item) =>
                                item.value["_id"] === getGlId(childItem.glCode)
                            )}
                            onChange={(e) =>
                              handleChildInputChange(
                                e.value,
                                "glCode",
                                parentIdx,
                                childIdx
                              )
                            }
                            className="react-select"
                          />
                        </div>
                      ) : childItem.glCode ? (
                        childItem.glCode.description ? (
                          childItem.glCode.description
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className={`text-nowrap px-50 ${getBorder(
                        true,
                        checklength(parentItem.children),
                        childIdx
                      )} text-center`}
                    >
                      {isEditable ? (
                        <Input
                          type="text"
                          className="mx-auto"
                          onChange={(e) =>
                            handleChildInputChange(
                              e.target.value,
                              "comment",
                              parentIdx,
                              childIdx
                            )
                          }
                          style={{ width: "160px" }}
                          value={childItem.comment}
                        />
                      ) : childItem.comment ? (
                        childItem.comment
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      className={`text-nowrap px-50 ${getBorder(
                        true,
                        checklength(parentItem.children),
                        childIdx
                      )} text-center`}
                    >
                      <div className="demo-inline-spacing justify-content-center">
                        {false && (
                          <div
                            className="mx-50 my-0 cursor-pointer"
                            onClick={() => handleChildAdd(parentIdx, childIdx)}
                          >
                            <PlusCircle size={20} />
                          </div>
                        )}
                        <div
                          className="mx-50 my-0 cursor-pointer"
                          onClick={() => handleChildDelete(parentIdx, childIdx)}
                        >
                          <Trash size={20} />
                        </div>
                      </div>
                    </td>
                  </Collapse>
                </Fragment>
              ))}
          </Fragment>
        ))}
    </Fragment>
  )
}

export default TableRow
