import React from "react"
import DataTable from "react-data-table-component"
import { ChevronDown, Edit } from "react-feather"
import { Badge, Card, Col, Row } from "reactstrap"
import TableHeader from "./AddNewUserTableHeader"
import ReactPaginate from "react-paginate"
import { formatedValue as F } from "@utils"

const AddNewUserTable = ({ tableData }) => {
  const CustomPagination = () => {
    return (
      <Row className="align-items-center">
        <Col md={5} sm={12}>
          <div className="ps-md-1 ps-0 text-center text-md-start">
            {/* {formatedPage} of {vendorCount} */} 1 - 1 of 1
          </div>
        </Col>
        <Col md={7} sm={12} className="text-sm-center text-md-start">
          <ReactPaginate
            previousLabel={""}
            nextLabel={""}
            pageCount={1}
            activeClassName="active"
            pageClassName={"page-item"}
            nextLinkClassName={"page-link"}
            nextClassName={"page-item next"}
            previousClassName={"page-item prev"}
            previousLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            containerClassName={
              "pagination react-paginate justify-content-md-start justify-content-center my-2 pe-1"
            }
          />
        </Col>
      </Row>
    )
  }
  const handleChange = () => {}
  return (
    <div>
      <Card className="overflow-hidden">
        <div className="react-dataTable">
          <DataTable
            noHeader
            subHeader
            // sortServer
            pagination
            responsive
            paginationServer
            columns={tableColumn()}
            // onSort={handleSort}
            sortIcon={<ChevronDown />}
            className="react-dataTable"
            paginationComponent={CustomPagination}
            onSelectedRowsChange={handleChange}
            selectableRows
            data={tableData}
            // onSelectedRowsChange={getSelectedRow}
            // customStyles={rowHoverStyle}
            onRowClicked={() => {
              // setShow(true)
            }}
            subHeaderComponent={<TableHeader />}
          />
        </div>
      </Card>
    </div>
  )
}

export default AddNewUserTable

const tableColumn = () => {
  return [
    {
      name: "Code",
      cell: (row) => F(10, row.id)
    },
    {
      name: "Name",
      cell: (row) => F(25, row.name)
    },
    {
      name: "Address",
      cell: (row) => F(30, row.address)
    },
    {
      name: "Tax Id",
      cell: (row) => F(10, row.taxId)
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge
          color={
            row.activeStatus || row.isActive ? "light-primary" : "light-danger"
          }
        >
          {row.activeStatus || row.isActive ? "Active" : "In-Active"}
        </Badge>
      )
    },
    {
      name: "Action",
      center: true,
      cell: () => (
        <div
          className="cursor-pointer text-primary"
          // onClick={() => ()}
          id="edit-btn"
        >
          <Edit size={20} />
        </div>
      )
    }
  ]
}
