// ** React Imports
import React, { useState } from 'react'

// Third Party Imports
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import toast from "react-hot-toast"
import * as _ from 'lodash'

// ** Component Imports
import Mandatory from '../../../../../../../custom-components/mandatoy-star/Mandatory'

const AddRowModal = ({ show, setShow, handleParentAdd }) => {
    const [payload, setPayload] = useState({
        action: 'DEBIT',
        amount: '',
        children: [],
        glCode: {},
        name: '',
        comment: ''
    })

    const onClose = () => {
        setShow(false)
        setPayload({
            action: 'DEBIT',
            amount: '',
            children: [],
            glCode: {},
            name: '',
            comment: ''
        })
    }

    const handleSubmit = () => {
        if (payload.action === "" || payload.name === "") {
            toast.error("All fields are required", {
                position: "top-center"
            })
            return
        }
        handleParentAdd(payload)
        onClose()
    }

    const handleChange = (value, fieldName) => {
        const payloadStore = _.cloneDeep(payload)
        payloadStore[fieldName] = value
        setPayload(payloadStore)
    }

    return (
        <Modal isOpen={show} toggle={onClose} className='modal-dialog-centered modal-lg'>
            <ModalHeader toggle={onClose}></ModalHeader>
            <ModalBody>
                <h2 className='text-center'>Add Row</h2>
                <div>
                    <Label>Name <Mandatory /></Label>
                    <Input type="text" value={payload.name} onChange={(e) => handleChange(e.target.value, 'name')} />
                </div>
                <div className="mt-2">
                    <Label>Actions <Mandatory /></Label>
                    <div className='demo-inline-spacing'>
                        <div className='form-check'>
                            <Input
                                type='radio'
                                value="DEBIT"
                                checked={payload.action === 'DEBIT'}
                                onChange={(e) => handleChange(e.target.value, 'action')}
                            />
                            <Label className='form-check-label'>
                                Debit
                            </Label>
                        </div>
                        <div className='form-check'>
                            <Input
                                type='radio'
                                value="CREDIT"
                                checked={payload.action === 'CREDIT'}
                                onChange={(e) => handleChange(e.target.value, 'action')}
                            />
                            <Label className='form-check-label'>
                                Credit
                            </Label>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center my-2'>
                    <Button color="primary" className='mx-1' onClick={handleSubmit}>Submit</Button>
                    <Button outline className='mx-1' onClick={onClose}>Discard</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AddRowModal

