import React from "react"
import { Badge } from "reactstrap"
import "./style.scss"
import { Paperclip } from "react-feather"
import { Tooltip } from "antd"
// import { formatedValue as F } from "@utils"

export const tableColumn = (viewType, handleClick) => {
  const isSummary = viewType === "SUMMARY"
  const tooltips = [
    "Trail balance report",
    "Market Audit report",
    "Managers Flash report"
  ]
  const salesToolTips = ["Sales report"]
  return [
    {
      name: "id",
      sortable: true,
      sortField: "id",
      selector: (row) => row.id,
      omit: isSummary,
      // sortField: "id",
      selector: (row) => row.id,
      minWidth: "112px",
      maxWidth: "112px",
      cell: (row) => <span>{row.id}</span>
    },
    {
      name: isSummary ? (
        "date"
      ) : (
        <Tooltip title="Uploaded Date">Upload...</Tooltip>
      ),
      sortable: true,
      sortField: "uploadDate",
      selector: (row) => row.uploadDate,
      minWidth: "133px",
      maxWidth: "133px",
      cell: (row) => <span>{row.uploadDate}</span>
    },
    {
      name: <Tooltip title="Pos Name">Pos...</Tooltip>,
      sortable: true,
      minWidth: "120px",
      maxWidth: "120px",
      sortField: "posName",
      selector: (row) => row.posName,
      cell: (row) => <span>{row.posName}</span>
      // cell: (row) => <div>{F(10, row.posName)}</div>
    },
    {
      name: "mode",
      omit: isSummary,
      sortable: true,
      minWidth: "100px",
      maxWidth: "105px",
      sortField: "mode",
      selector: (row) => row.mode,
      cell: (row) => <span>{row.mode}</span>
      // cell: (row) => <div>{F(4, row.mode)}</div>
    },
    {
      name: "file name",
      sortable: true,
      omit: isSummary,
      minWidth: "135px",
      // maxWidth: "135px",
      sortField: "fileName",
      selector: (row) => row.fileName,
      // cell: (row) => <span>{row.fileName}</span>
      cell: (row) => <div>{row.fileName}</div>
    },
    {
      name: "date",
      sortable: true,
      omit: isSummary,
      minWidth: "133px",
      maxWidth: "133px",
      sortField: "date",
      selector: (row) => row.date,
      cell: (row) => <span>{row.date}</span>
    },
    {
      name: "entity",
      sortable: true,
      omit: !isSummary,
      minWidth: "150px",
      maxWidth: "150px",
      sortField: "entity",
      selector: (row) => row.entity,
      cell: (row) => <span>{row.entity}</span>
    },
    {
      name: "location",
      sortable: true,
      minWidth: "134px",
      // maxWidth: "100px",
      sortField: "location",
      selector: (row) => row.location,
      cell: (row) => <div>{row.location}</div>
    },
    {
      name: "status",
      sortable: true,
      omit: isSummary,
      minWidth: "125px",
      maxWidth: "125px",
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => {
        const status = row.status
        return (
          <Badge
            color={status === "Yet to post" ? "light-primary" : "light-danger"}
            pill
          >
            {status}
          </Badge>
        )
      }
    },
    {
      name: <Tooltip title="Attachments">Atta...</Tooltip>,
      omit: isSummary,
      rigth: true,
      sortable: true,
      maxWidth: "112px",
      minWidth: "112px",
      text: "center",
      sortField: "attachments",
      selector: (row) => row.attachments,
      cell: (row) => (
        <span>{row.attachments == "Pdf" && <Paperclip size={18} />}</span>
      )
    },
    {
      name: <Tooltip title="Details Received">details...</Tooltip>,
      sortable: true,
      omit: !isSummary,
      minWidth: "160px",
      maxWidth: "160px",
      sortField: "detailsReceived",
      selector: (row) => row.detailsReceived,
      cell: (row) => (
        <>
          {row.detailsReceived.map((status, i) => (
            <Tooltip
              className="cursor-pointer"
              color="#6c757d"
              title={row.posName == "Toast" ? salesToolTips : tooltips[i]}
            >
              <div
                className={`dot ${status ? "success" : ""}`}
                key={i}
                id={`tooltip-${i}`}
              />
            </Tooltip>
          ))}
        </>
      )
    },
    {
      name: "status",
      sortable: true,
      omit: !isSummary,
      minWidth: "142px",
      // maxWidth: '200px',
      sortField: "summaryStatus",
      selector: (row) => row.summaryStatus,
      cell: (row) => {
        const status = row.summaryStatus
        return (
          <Badge
            color={status === "Yet to post" ? "light-primary" : "light-danger"}
            pill
          >
            {status}
          </Badge>
        )
      }
    },
    {
      name: "actions",
      // sortable: true,
      omit: !isSummary,
      minWidth: "150px",
      maxWidth: "150px",
      sortField: "actions",
      selector: (row) => row.actions,
      cell: (row) => {
        return (
          <p
            className={`text-decoration-underline ${
              row.actions == "Create"
                ? "grey-out"
                : "text-primary cursor-pointer"
            }`}
            onClick={() => handleClick(row.salesEntriesId)}
          >
            {row.actions}
          </p>
        )
      }
    }
  ]
}
