import { useEffect, useState } from "react"

export default function useKeyBinding(ref, key, modifierState) {
  const [isKeyTriger, setIsKeyTriger] = useState(false)
  useEffect(() => {
    function onKeyPress(e) {
      if (e.which !== key) return
      const checktriger = modifierState
        ? e?.getModifierState(modifierState)
        : e.which === key
      setIsKeyTriger(checktriger)
    }
    ref.current?.addEventListener("keydown", onKeyPress)
    return () => {
      ref.current?.removeEventListener("keydown", onKeyPress)
    }
  }, [key, modifierState, ref])
  return isKeyTriger
}

/* 
=>For Caps Lock 
key = 20 
modifierState = "CapsLock"
className = capslock-warning
errorMessage ClassName = capslock-warning-error
*/
