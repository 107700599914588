import { kFormatter as K } from "@utils"
import {
  Row,
  Col,
  Card,
  CardBody,
  Progress,
  CardHeader,
  CardTitle
} from "reactstrap"
import { Info } from "react-feather"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Avatar from "@components/avatar"
import { agedCashReceiptsSummary } from "@service/apiUrl"
import NextButton from "@custom-components/buttons/next-button/NextButton"
import useFetch from "@hooks/useFetch"
import Chart from "./Chart"
import "@styles/react/libs/charts/recharts.scss"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const defaultChartData = ["0", "1-2", "3-5", "6-10", "11-30", "<30"].map(
  (name) => ({
    name,
    value: 0,
    count: 0
  })
)
const defaultData = { checkAmount: 0, achAmount: 0, wireAmount: 0 }
const AgedCashReceiptsSummary = () => {
  const { clientId } = useSelector((state) => state.auth.userData)
  const nav = useNavigate()
  const [
    { chartData = defaultChartData, amounts = defaultData } = {},
    { isFirstLoad: loading }
  ] = useFetch(agedCashReceiptsSummary, {
    apiParams: { params: { clientId } },
    callback: (res) => {
      const { wireAmount, checkAmount, achAmount } = res
      const formatedData = defaultChartData.map(({ name }) => ({
        name,
        count: res[formatApiData[name]?.count] ?? 0,
        value: K(res[formatApiData[name]?.value] ?? 0)
      }))
      const amounts = { checkAmount, achAmount, wireAmount }
      return { chartData: formatedData, amounts }
    }
  })
  function navigateTo() {
    nav("/v2/reportings/revenue/cash-app/cash-recipt-report")
  }

  const totalPercent =
    amounts.checkAmount + amounts.achAmount + amounts.wireAmount
  const checkPercent = (amounts.checkAmount / totalPercent) * 100
  const achPercent = (amounts.achAmount / totalPercent) * 100
  const wirePercent = (amounts.wireAmount / totalPercent) * 100
  return (
    <Card>
      <CardHeader className="pb-1">
        <CardTitle className="flex-center" tag="h4">
          Aged Cash Receipts Summary
        </CardTitle>
        <NextButton
          color="primary"
          size="sm"
          id="dashboard-cash-app"
          onClick={navigateTo}
        >
          View Detailed Report
        </NextButton>
      </CardHeader>
      {loading ? (
        <AgedSkeleton />
      ) : (
        <CardBody>
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: "2", width: "165%" }}>
              <Chart data={chartData} />
            </div>
            <div
              style={{
                padding: "3rem 0rem 3rem 3rem"
              }}
            >
              <Row
                style={{
                  borderLeft: "solid 1px #ebe9f1"
                }}
              >
                <Col className="mb-2" md="12" sm="12">
                  <p className="mb-50">
                    <Avatar
                      color={"light-primary"}
                      icon={<Info size={12} />}
                      className="me-1"
                      size="sm"
                    />
                    Check: {K(amounts.checkAmount ?? 0)}
                  </p>
                  <Progress
                    className="avg-session-progress mt-25"
                    value={checkPercent}
                  />
                </Col>
                <Col className="mb-2" md="12" sm="12">
                  <p className="mb-50">
                    <Avatar
                      color={"light-info"}
                      icon={<Info size={12} />}
                      className="me-1"
                      size="sm"
                    />
                    ACH: {K(amounts.achAmount ?? 0)}
                  </p>
                  <Progress
                    className="avg-session-progress progress-bar-info mt-25"
                    value={achPercent}
                  />
                </Col>
                <Col md="12" sm="12">
                  <p className="mb-50">
                    {" "}
                    <Avatar
                      color={"light-warning"}
                      icon={<Info size={12} />}
                      className="me-1"
                      size="sm"
                    />
                    Wire: {K(amounts.wireAmount ?? 0)}
                  </p>
                  <Progress
                    className="avg-session-progress progress-bar-warning mt-25"
                    value={wirePercent}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      )}
    </Card>
  )
}

export default AgedCashReceiptsSummary

const formatApiData = {
  0: { count: "zeroDaysCount", value: "zeroDaysAmount" },
  "1-2": { count: "oneToTwoDaysCount", value: "oneToTwoDaysAmount" },
  "3-5": { count: "threeToFiveDaysCount", value: "threeToFiveDaysAmount" },
  "6-10": { count: "sixToTenDaysCount", value: "sixToTenDaysAmount" },
  "11-30": {
    count: "elevenToThirtyDaysCount",
    value: "elevenToThirtyDaysAmount"
  },
  "<30": { count: "aboveThirtyDaysCount", value: "aboveThirtyDaysAmount" }
}

const AgedSkeleton = () => (
  <div className="mx-1 pb-50">
    <SkeletonTheme>
      <Skeleton height={245} />
    </SkeletonTheme>
  </div>
)
