// ** UrlConfig Import
import UrlService from "./urlConfig"

const service = new UrlService()

// ** URL Paths

// ** Client API URLs
export const clientApi = `${service.getClientUrl()}/all`
export const clientDetailsApi = `${service.getClientUrl()}`
export const getClientBaseUrl = `${service.getClientUrl()}`
export const GET_CLIENT_MIN_INFO = `${service.getClientUrl()}/all-minimal`

// ** Entity API URLs
export const entityApi = `${service.getEntityUrl()}/all`
export const allEntityApi = `${service.getEntityUrl()}`
export const getEntitiesByClientIds = `${service.getEntityUrl()}/allbyclientids`

// ** Vendor API URLs
export const vendorListApi = `${service.getVendorUrl()}/all?`
export const vendorViewApi = `${service.getVendorUrl()}`
export const CreateVendorApi = `${service.getVendorUrl()}?approverUserId=`
export const uploadForm = `${service.getInvoiceUrl()}`
export const updateVendorApi = `${service.getVendorUrl()}/updateandnotify?`
export const vendorCountApi = `${service.getVendorUrl()}/vendorscount`
export const vendorByNameApi = `${service.getVendorUrl()}/vendorbyname`
export const vendorFilterApi = `${service.getVendorUrl()}/vendorlistdetails`
export const vendorPostApi = `${service.getVendorUrl()}?approverUserId=`
export const vendorBulkUpload = `${service.getVendorUrl()}/multiple`
export const audittrailApi = `${service.getVendorUrl()}/audittrail`

// ** Customer API URLs
export const CustomerListApi = `${service.getCustomerUrl()}/all?`
export const CustomerAccountApi = `${service.getCratomatchUrl()}`
export const cratomatchUrl = `${service.getCratomatchUrl()}`
export const customerApi = `${service.getCustomerUrl()}`
export const customerCountApi = `${service.getCustomerUrl()}/customerscount?`
export const customerFilterApi = `${service.getCustomerUrl()}/customerlistdetails`
export const customerViewApi = `${service.getCustomerUrl()}`
export const updateCustomerApi = `${service.getCustomerUrl()}/updateandnotify?`
export const customerAuditTrialApi = `${service.getCustomerUrl()}/audittrail`

// ** Admin API URLs
export const adminsetupApi = `${service.getAdminUrl()}/setupinfo`
export const adminsetupApi1 = `${service.getAdminUrl()}`
export const adminsetupApi2 = `${service.getAdminUrl()}/setup`
export const bankListUrl = `${service.getAdminUrl()}/bankaccountlist?`
export const basicProfileUrl = `${service.getAdminUrl()}/basicprofile?`

// ** Role API URLs
export const GET_BASE_ROLE = `${service.getBaseAuthUrl()}/roles-api/fetchRoles`
export const GET_ALL_CUSTOM_ROLE = `${service.getBaseAuthUrl()}/roles-api/getCustomRolesByTenant?tenantId=`
export const SAVE_CUSTOM_ROLE = `${service.getBaseAuthUrl()}/roles-api/createCustomRoles?tenantId=`
export const UPDATE_CUSTOM_ROLE = `${service.getBaseAuthUrl()}/roles-api/updateCustomRoleById?tenantId=`
export const ROLES_API = `${service.getBaseAuthUrl()}/roles-api`
export const GET_ROLE_BY_CLIENT_ID = `${service.getBaseAuthUrl()}/roles-api/getCustomRoleByClientId`

// ** Invoice API URLs
export const allInvoiceApi = `${service.getInvoiceUrl()}/getInvoicesListByVendor`
export const W9FormApi = `${service.getInvoiceUrl()}/getdownloadurl`

export const invoiceApi = `${service.getInvoiceUrl()}`
export const putInvoice = `${service.getInvoiceUrl()}`
export const deleteInvoiceUrl = `${service.getInvoiceUrl()}`
export const isErpSyncedUrl = `${service.getErpBaseUrl()}/test/clientStatus?clientId=`
export const postInvoiceToErpUrl = `${service.getInvoiceUrl()}/post-to-erp`
export const followUpUrl = `${service.getInvoiceUrl()}/followup`
export const invoiceAudittrailUrl = `${service.getAudittrailUrl()}`
export const followUpReasonUrl = `${service.getClientUrl()}`
export const getAllUsersUrl = `${service.getBaseAuthUrl()}/roles-api/fetchRoles`
export const sendApprovalUrl = `${service.getInvoiceUrl()}/setApprovers`
export const transactionTableApi = `${service.getArUrl()}/invoices/customer?customerUrn=`

// ** Invoice Filter Api URLs
export const invoiceFilterApi = `${service.getFilterUrl()}/paginationfilter?`
export const invoiceFilterApiUrl = `${service.getFilterUrl()}/paginationfilter`
export const invoiceFilterArchivedApiUrl = `${service.getFilterUrl()}/paginationfilter-archived`
export const invoiceFilterApi1 = `${service.getFilterUrl()}/filter`
export const getInvoiceCountUrl = `${service.getFilterUrl()}/invoicescount?`
export const getInvoiceCountWithUrl = `${service.getFilterUrl()}/invoicescount`
export const getInvoiceBillCount = `${service.getFilterUrl()}`
export const getDuplicateInvoiceUrl = `${service.getFilterUrl()}/duplicate`
export const updateMultiInvoiceUrl = `${service.getInvoiceUrl()}/update-multiple`

// ** User API URLs
export const getUserDetailsApi = `${service.getBaseAuthUrl()}/user-api/userDetails`
export const getUserDetailsApi1 = `${service.getBaseAuthUrl()}/user-api`
export const getStoreData = `${service.getBaseAuthUrl()}/user-api/userDetailsInfo`
export const getUserDetailsApi2 = `${service.getBaseAuthUrl()}`
export const getUserbyClientId = `${service.getBaseAuthUrl()}/user-api/usersbyclient`
export const getUserByUsername = `${service.getBaseAuthUrl()}/user-api/user/email`
export const getUserByUsernameSignUp = `${service.getBaseAuthUrlOrg()}/user-api/user`
export const getUserByPermissions = `${service.getBaseAuthUrl()}/user-api/usersbypermissions`

// ** Forgot Password URL
export const ForgetPasswordApi = `${service.getBaseAuthUrlOrg()}/pass-api/forgotPassword`
export const updateUserSingup = `${service.getBaseAuthUrlOrg()}/user-api`

//Crop structure update call
export const updateCropApi = `${service.getAccountCodingUrl()}`
export const listUpdateApi = `${service.getAccountCodingUrl()}`

// ** S3 File Urls
export const getDownloadUrl = `${service.getInvoiceUrl()}/getdownloadurl`

// ** Reset Password URL
export const resetPassword = `${service.getBaseAuthUrlOrg()}/pass-api/resetPassword`
export const changePassword = `${service.getBaseAuthUrlOrg()}/pass-api/changePassword`
export const loginApi = `${service.getBaseAuthUrlOrg()}/login/getToken`
// ** Graph Ql Inoice Edit
export const graphqlUrl = `${service.getGraphQlUrl()}`

// Ar Url
export const getArUrl = `${service.getArUrl()}`

// ** AR Invoice
export const arInvoice = `${service.getArUrl()}/invoices/customer?`

export const invoiceLineItemsUrl = `${service.getCustomerUrl()}`
export const customerInvoiceUrl = `${service.getArUrl()}/invoices/customerandstatus?`
export const saveInvoiceUrl = `${service.getArUrl()}/invoices/`
export const getPdfUrl = `${service.getArUrl()}/files`
export const arSendMailUrl = `${service.getBaseAuthUrl()}/ar-api/mailArInvoice`
export const postToErpUrl = `${service.getErpBaseUrl()}`
export const arInvoiceAuditrailUrl = `${service.getArUrl()}/audittrial/all/`

// ** Apply Deposit URLs
export const depositCount = `${service.getCratomatchUrl()}/depositcount?`
export const depositList = `${service.getCratomatchUrl()}/transactionfilter?`
export const depositListCashApp = `${service.getCratomatchUrl()}/transactionfilter`
export const customerDetailsUrl = `${service.getCratomatchUrl()}/deposit/getdepositbyid`
export const getTransactionUrl = `${service.getCratomatchUrl()}/deposit/getdepositbyid`
export const allCustomerUrl = `${service.getCustomerUrl()}/all?`
export const addCustomerUrl = `${service.getCratomatchUrl()}/deposit/savedeposit`
export const applyDepositUrl = `${service.getCratomatchUrl()}/deposit/saveapplydeposit`
export const getBankSummaryUrl = `${service.getCratomatchUrl()}/banksummary`
export const bankDetailsUrl = `${service.getCratomatchUrl()}/getallaccountdata/`
export const saveTransactionUrl = `${service.getCratomatchUrl()}/savealltransactions`
export const moveToPayAppUrl = `${service.getCratomatchUrl()}/movetxntopayment`
export const depositUrl = `${service.getCratomatchUrl()}/deposit`
export const newDepositUrl = `${service.getArUrl()}/ar-deposit`
export const customerLabelValueUrl = `${service.getArUrl()}/deposit/label-value`
export const arDepositFilterUrl = `${service.getArUrl()}/deposit/filter`
// export const newDepositUrl = `http://localhost:9082/ar/ar-deposit`
export const newDepositSaveUrl = `${service.getArUrl()}/deposit/save`
export const transAudittrailUrl = `${service.getCratomatchUrl()}/audittrail/getbyid?transactionid=`
export const getStartDate = `${service.getCratomatchUrl()}/get-txn-startDate`
export const getAllDepositDetailsUrl = `${service.getArUrl()}/deposit/get`
export const getNewDepositDetailsUrl = `${service.getArUrl()}/ar-deposit/getDeposit`
// export const getNewDepositDetailsUrl = `http://localhost:9082/ar/ar-deposit/getDeposit`
export const updateDepositUrl = `${service.getArUrl()}/deposit/update`
export const updateNewDepositUrl = `${service.getArUrl()}/deposit/updateDeposit`
// export const updateNewDepositUrl = `http://localhost:9082/ar/deposit/updateDeposit`
export const applyDepositUrl2 = `${service.getArUrl()}/deposit/apply-deposit?depositId=`
export const newapplyDepositUrl = `${service.getArUrl()}/ar-deposit/applyArDeposit/new`
// export const newapplyDepositUrl = `http://localhost:9082/ar/ar-deposit/applyArDeposit`
export const validateApplyDepositUrl = `${service.getArUrl()}/deposit/validate-apply-deposit`
export const validateNewApplyDepositUrl = `${service.getArUrl()}/ar-deposit/validateArDeposit`
// export const validateNewApplyDepositUrl = `http://localhost:9082/ar/ar-deposit/validateArDeposit`
export const updateValidateUrl = `${service.getArUrl()}/deposit/validate-apply-deposit/update`
export const reverseDepositUrl = `${service.getArUrl()}/ar-deposit/reverseArDeposit`
// export const reverseDepositUrl = `http://localhost:9082/ar/ar-deposit/reverseArDeposit`
// export const deleteDepositUrl = `http://localhost:9082/ar/ar-deposit/deleteArDeposit`
export const deleteDepositUrl = `${service.getCratomatchUrl()}/deposit/deleteArDeposit`
// export const getDeductionInvoiceUrl = `http://localhost:9082/ar/ar-deposit/getValidatingInvoice`
export const getDeductionInvoiceUrl = `${service.getArUrl()}/ar-deposit/getValidatingInvoice`
export const bookDeductionUrl = `${service.getArUrl()}/ar-deposit/book-deduction`
export const moveNonInvUrl = `${service.getArUrl()}/ar-deposit/move-all-noninvoice`
// export const updateDeductionInvoiceUrl = `http://localhost:9082/ar/deposit/updateDepositInvoice`
export const updateDeductionInvoiceUrl = `${service.getArUrl()}/deposit/updateDepositInvoice`
export const createNonInvoiceUrl = `${service.getArUrl()}/deposit/save-noninvoice`
export const updateNonInvoiceUrl = `${service.getArUrl()}/deposit/update-noninvoice`
export const updateNonInvDeductionUrl = `${service.getArUrl()}/deposit/save-noninvoice-deduction`
export const deleteNonInvoiceUrl = `${service.getArUrl()}/deposit/delete-noninvoice`

// ** Remitance URL
export const remitanceTable = `${service.getArUrl()}/remittance`
export const headerFormatUrl = `${service.getCustomerUrl()}/remittanceheaderbycustomer?`
export const saveHeaderUrl = `${service.getCustomerUrl()}/remittanceheader`
export const saveRemittanceUrl = `${service.getArUrl()}/remittance/save-remittance`
export const saveDeductionUrl = `${service.getArUrl()}/invoices/create-deductions`
export const saveRemettanceUrl = `${service.getArUrl()}/remittance/multiple-apply`
export const remittanceListFilterUrl = `${service.getArUrl()}/remittance/remitance/filter`
export const remittanceAudittrailUrl = `${service.getNotificationUrl()}/audit-trail/remittance`
export const audittrailUrl = `${service.getNotificationUrl()}/audit-trail`

//Remittance advice URL
export const remittanceTable = `${service.getArUrl()}/remittance/remittancebyid?remittanceid=`

//Remittance update API
export const updateRemittanceTable = `${service.getArUrl()}/remittance/update-remittnace`

// ** Upload documents URL
export const uploadDocumentsUrl = `${service.getInvoiceUrl()}/getuploadurl`

// ** Business profile ImageUpload
export const getImageUpload = `${service.getInvoiceUrl()}/getuploadurl`

// ** Deductions
export const getDeduction = `${service.getArUrl()}/deductions`

// ** Configuration AccountCode
export const getAccountCode = `${service.getAccountCodingUrl()}`

// ** getAccountCodingUrl
export const getAccountCodingUrl = `${service.getAccountCodingUrl()}`

// ** All Users URL
export const allUsersUrl = `${service.getBaseAuthUrl()}/user-api/userlistdetails?`

// ** Banking
export const categoryGroupUrl = `${service.getCratomatchUrl()}/categorygroup?`
export const categorytransactionUrl = `${service.getCratomatchUrl()}/categorytransaction?`
export const clientTransactionUrl = `${service.getCratomatchUrl()}/transactions`
export const saveAllExpenseUrl = `${service.getCratomatchUrl()}/expense/saveallexpenses`
export const saveExpenseTransactionUrl = `${service.getCratomatchUrl()}/matchexpensetransaction`
export const expenseErpPostingUrl = `${service.getErpBaseUrl()}/test/post-expense?id=`
export const updateTransactionErpStatusUrl = `${service.getCratomatchUrl()}/updatetranserpstatus?transId=`
export const postingSendResolutionUrl = `${service.getCratomatchUrl()}/sendforresolution`
export const depositBookingUrl = `${service.getErpBaseUrl()}/test/post-journalentry-from-banktxn`
export const updateErpStatusUrl = `${service.getCratomatchUrl()}/updatetranserpstatus?transId=`
export const saveExpenseErpUrl = `${service.getErpBaseUrl()}/test/post-expense?id=`
export const getResolutionUrl = `${service.getCratomatchUrl()}/get-txn-batchByid?id=`
export const updateResolutionUrl = `${service.getCratomatchUrl()}/update-txn-batch`
export const getAllResolutionTransUrl = `${service.getCratomatchUrl()}/get-txn-batch-by-client`
export const deleteJEErpTransactionUrl = `${service.getErpBaseUrl()}/test/delete-journalentry-from-transaction`
export const deleteExpenseErpTransactionUrl = `${service.getErpBaseUrl()}/test/delete-expense-by-id?`
export const moveTxnToCashAppUrl = `${service.getCratomatchUrl()}/deposit/movetxntodeposit`
export const validateBankUploadUrl = `${service.getCratomatchUrl()}/validate-upload`

// ** Banking Configuration
export const plaidBankDataUrl = `${service.getCratomatchUrl()}/getplaidbankdata`
export const allAccountDataUrl = `${service.getCratomatchUrl()}/getallaccountdata`
export const segmentGlCodeUrl = `${service.getAccountCodingUrl()}/bysegment?`
export const bookedTransactionUrl = `${service.getCratomatchUrl()}/transactionfilter?`
export const transactionByIdUrl = `${service.getCratomatchUrl()}/getbyid?id=`
export const saveAccountUrl = `${service.getCratomatchUrl()}/saveallaccounts`
export const removeAccountUrl = `${service.getCratomatchUrl()}/removeplaidaccountsbyid?`
export const removePlaidTransactionUrl = `${service.getCratomatchUrl()}/removeplaidtransactions`
export const plaidLinkTokenUrl = `${service.getCratomatchUrl()}/getlinktoken`
export const addBankUrl = `${service.getCratomatchUrl()}`
export const editBankAccountUrl = `${service.getCratomatchUrl()}/savebanksyncdata`
export const plaidUpdateLinkTokenUrl = `${service.getCratomatchUrl()}/getupdatelinktoken?token=`

// ** Cratopay url
export const getCratoPayUrl = `${service.getCratoPayUrl()}`
export const getPaymentsUrl = `${service.getInvoiceUrl()}/payments/list?clientId=`
export const getPaymentsFilterUrl = `${service.getInvoiceUrl()}/payments/filter-payment?clientId=`
export const getPaymentByIdUrl = `${service.getInvoiceUrl()}/payments/get/`
export const updatePaymentUrl = `${service.getInvoiceUrl()}/payments/update`
export const applyPaymentUrl = `${service.getInvoiceUrl()}/payments/save-apply-payments`
export const reversePaymentUrl = `${service.getCratomatchUrl()}/deposit/reverseapplydeposit?depositId=`
export const markAsPaidUrl = `${service.getCratoPayUrl()}/markaspaid`
export const createManualApplyPayment = `${service.getInvoiceUrl()}/payments/create`
export const inviteAstraVendorUrl = `${service.getCratoPayUrl()}/business-user/invite-vendor`
//Code rules
export const getAccountCodeRules = `${service.getAccountCodingUrl()}`
export const getVendorList = `${service.getVendorUrl()}/vendorlistdetails`
export const postVendorRule = `${service.getVendorUrl()}/savelineitem`

// ** Segaments Url's
export const getSegmentUrl = `${service.getAccountCodingUrl()}/getsegmentswithcoding?clientId=`
// ** Ar Sales
export const salesDetailsById = `${service.getArUrl()}/sales/info?id=`
export const updateSalesUrl = `${service.getArUrl()}/sales/update`
export const segmantDetailsUrl = `${service.getAccountCodingUrl()}/getbyclientid?`
export const accountSegmentUrl = `${service.getClientUrl()}/`
export const clientUrl = `${service.getClientUrl()}/`
export const postNewSaleUrl = `${service.getErpBaseUrl()}/test/post-journal-entry?salesId=`
export const getSalesTemplateUrl = `${service.getArUrl()}/sales/template/getbypos?`
export const getPosInfoUrl = `${service.getPosUrl()}/info/get-pos-info?clientId=`
export const getPosConfigUrl = `${service.getPosUrl()}/info/get-posbyclient?`
export const savePosConfigUrl = `${service.getPosUrl()}/info/save-pos-config`

// ** Banking Rules Url's
export const getRuleListUrl = `${service.getCratomatchUrl()}/rule/getruledetails`
export const postRuleUrl = `${service.getCratomatchUrl()}/rule/saveruledata`
export const getRuleByIdUrl = `${service.getCratomatchUrl()}/rule/getrulebyid?ruleid=`
export const applyRuleUrl = `${service.getCratomatchUrl()}/rule/saveapplyrules`

// ** Erp Integration Url's
export const qbLoginUrl = `${service.getErpBaseUrl()}${
  service.erpRoutes.QUICKBOOKS_ONLINE
}auth?`
export const sage50Url = `${service.getErpBaseUrl()}${
  service.erpRoutes.SAGE50_ONPREMISE_USA
}auth`
export const isErpSyncUrl = `${service.getErpBaseUrl()}/test/clientStatus?`
export const integrationSummaryUrl = `${service.getClientUrl()}/erp/getSummary?`
export const unLinkErpSyncUrl = `${service.getBaseAuthUrl()}/qbaccess-api/delete-qbaccess?`
export const saveQbAuthDetailsUrl = `${service.getErpBaseUrl()}${
  service.erpRoutes.QUICKBOOKS_ONLINE
}oauth2redirect?redirectPath=`
export const getSyncErpDataUrl = `${service.getErpBaseUrl()}/test/syncData?clientId=`
export const erpIntegrationInfoUrl = `${service.getClientUrl()}/erp/get-erp-info?clientId=`
export const erpBaseUrl = `${service.getErpBaseUrl()}/erp`
// ** Cash Application Configration
export const getCahAppConfigUrl = `${service.getAdminUrl()}/cashapp-setup-by-clientid?clientId=`
export const saveCashAppConfigUrl = `${service.getAdminUrl()}/cashappsetup`
export const reverseTransactionUrl = `${service.getCratomatchUrl()}/deposit/reverseapplydeposit?depositId=`

// ** Sales Order URL's
export const createSalesOrderUrl = `${service.getArUrl()}/salesorder/create-multiple-salesorder`
export const getSalesOrderList = `${service.getArUrl()}/salesorder/get-orderbyclientid?clientId=`
export const getSalesOrderByIdUrl = `${service.getArUrl()}/salesorder/get-orderbyid?id=`
export const updateSalesOrderUrl = `${service.getArUrl()}/salesorder/update-salesorder`

// ** Reporting
export const agingReportUrl = `${service.getFilterUrl()}/report/detailed`
export const accuralReportUrl = `${service.getFilterUrl()}/report/acural`
export const accountingcalenderUrl = `${service.getAdminUrl()}/accountingcalender?clientId=`
export const cashReciptUrl = `${service.getCratomatchUrl()}/deposit-report/filter-report`

// ** General Ledger
export const getLedgerConfigUrl = `${service.getCratomatchUrl()}/gl-setup/find/`
export const uploadLedgerTransUrl = `${service.getCratomatchUrl()}/generalLedger/create/multiple`
export const glFilterUrl = `${service.getCratomatchUrl()}/generalLedger/filter`

// ** Recon Rules URL
export const getReconRuleById = `${service.getCratomatchUrl()}/bank-recon/rules/fetch/`

export const getApInvoiceAudittrailUrl = `${service.getAudittrailUrl()}/all/`
export const getApInvoiceAudittrailByIdUrl = `${service.getAudittrailUrl()}`

// ** Astra Urls
export const saveAstraDetailsUrl = `${service.getCratoPayUrl()}/business-user/submit`
export const getAstraDetailsUrl = `${service.getCratoPayUrl()}/business-user/get-businessuser-byclientid?clientId=`
export const saveAstraAuthCodeUrl = `${service.getCratoPayUrl()}/business-user/save-user-authorization`
export const linkAstraBankAccUrl = `${service.getCratoPayUrl()}/business-user/link-account?clientId=`
export const getLikedAccUrl = `${service.getCratoPayUrl()}/business-user/get-linked-accounts?clientId=`
export const linkVendorAccUrl = `${service.getCratoPayUrl()}/business-user/link-vendor-account?clientId=`
export const makePaymentApiUrl = `${service.getCratoPayUrl()}/makepayment?batchId=`
export const getLikedAccountUrl = `${service.getCratoPayUrl()}/business-user/get-linked-accounts`

export const agedCashReceiptsSummary = `${service.getArUrl()}/ar-deposit/cash-receipt-summary`
export const cashReceiptsSummary = `${service.getArUrl()}/ar-deposit/generate-summary-date`
export const unBookedSummary = `${service.getCratomatchUrl()}/bank-report/unbooked-summary`
export const transactionSummary = `${service.getCratomatchUrl()}/bank-report/cash-flow-report`

//Cash Flow Trend
export const cashFlowTrend = `${service.getCratomatchUrl()}/bank-report/cash-flow-trend`
//cash Balancing Report
export const cashBalancingReport = `${service.getCratomatchUrl()}/cash-app-report/calculate-report`
// ** Payment Subscription
export const savePaymentKeyUrl = `${service.getCratoAireporting()}/auth/stripe`
export const getConnectedPaymentUrl = `${service.getCratoAireporting()}/auth/`

// ** Saas-dashboard
export const getMRRByYearUrl = `${service.getCratoAireporting()}/getmrrbyyear`
export const getInvoiceReportUrl = `${service.getCratoAireporting()}/get-invoice-report`
export const getPaymentReportUrl = `${service.getCratoAireporting()}/get-payment-report`

//dashboard
export const getUploadCount = `${service.getArUrl()}/remittance/document-by-date`

export const trackPaymentFilterApi = `${service.getCratoPayUrl()}/getpaymenbatchdetails`

// Bank recon
export const getReconByIdUrl = `${service.getCratomatchUrl()}/bank-recon/getByReconId`
export const updateReconList = `${service.getCratomatchUrl()}/bank-recon/update`
export const getReconTransUrl = `${service.getCratomatchUrl()}/bank-recon/transaction/get`
export const getReconGLUrl = `${service.getCratomatchUrl()}/generalLedger/filter`
export const matchReconUrl = `${service.getCratomatchUrl()}/bank-recon/save-matched`
export const getSlotsUrl = `${service.getCratomatchUrl()}/bank-recon/get-matched-slotId`
export const removeTransGlUrl = `${service.getCratomatchUrl()}/bank-recon/update-matched-recon`
export const reconcileUrl = `${service.getCratomatchUrl()}/bank-recon/reconcile`
export const reverseReconUrl = `${service.getCratomatchUrl()}/bank-recon/reverse`
export const createVarianceUrl = `${service.getCratomatchUrl()}/bank-recon/create-bulkGl`
export const getMatchedSummaryUrl = `${service.getCratomatchUrl()}/bank-recon/matched-summary-beta`
export const unMatch = `${service.getCratomatchUrl()}/bank-recon/update-matched-recon`
export const getCountByAccount = `${service.getCratomatchUrl()}/bank-recon/count-by-account`

export const exportApiUrl = `${service.getReportApiUrl()}/export`
export const aiParsingImport = `${service.getAiParsingUrl()}/import`
export const apAgingReportUrl = `${service.getReportApiUrl()}/agingreport/export`

export const resolutionCategoryUrl = `${service.getAdminUrl()}/rule/resolution`

export const initialAutoMatchRecon = `${service.getCratomatchUrl()}/bank-recon//initial-match`
