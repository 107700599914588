export const searchArr = [
    {
        id: 1,
        target: 'analyticsDash',
        isBookmarked: true,
        title: 'Home',
        icon: 'Home',
        link: '/v2/company'
    },
    {
        id: 2,
        target: 'roleManagement',
        isBookmarked: true,
        title: 'Role Management',
        icon: 'Shield',
        link: '/v2/role-management'
    },
    {
        id: 3,
        target: 'manageUsers',
        isBookmarked: true,
        title: 'Manage Users',
        icon: 'Users',
        link: '/v2/manage-users'
    }
]