// ** Imports
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Alert, Col, Row, TabContent, TabPane } from "reactstrap"
import { useSelector } from "react-redux"

// ** Service
import {
  getAllClient,
  getUserProfileByEmail
} from "../service/manageUserService"

// ** Components
import CompanyTab from "./components/CompanyTab"
import Tabs from "./components/Tabs"
import UserCard from "./components/UserCard"
import TimeLineTab from "./components/TimeLineTab"
import SecurityTab from "./components/SecurityTab"

const DetailUserViewTable = () => {
  const emailParam = useParams()
  const paramValue = emailParam?.email ? "manageUsers" : "Profile"
  const { userData } = useSelector((state) => state.auth)
  const [userProfileData, setUserProfileData] = useState()
  const [companyData, setCompanyData] = useState([])
  const [errors, setErrors] = useState(false)

  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = (tab) => {
    setActiveTab(tab)
  }

  const getCall = async () => {
    try {
      const { data } = await getUserProfileByEmail(
        emailParam?.email ?? userData.email
      )
      setUserProfileData(data)
    } catch (error) {
      console.error(error)
      setErrors(true)
    }
  }

  useEffect(() => {
    getCall()

    getAllClient()
      .then((data) => {
        setCompanyData(data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className="app-user-list">
      {userProfileData && (
        <Row>
          <Col xl="3" lg="6" xs={{ order: 1 }} md={{ order: 0, size: 5 }}>
            <UserCard
              userProfileData={userProfileData}
              paramValue={paramValue}
              getCall={getCall}
            />
          </Col>
          <Col xl="9" lg="8" xs={{ order: 0 }} md={{ order: 1, size: 7 }}>
            <Tabs
              className="mb-2"
              activeTab={activeTab}
              toggleTab={toggleTab}
              paramValue={paramValue}
            />
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" id="account-tab-wrapper">
                <CompanyTab
                  userProfileData={userProfileData}
                  userData={userData}
                  getCall={getCall}
                  companyData={companyData}
                  paramValue={paramValue}
                />
              </TabPane>
              <TabPane tabId="2" id="security-tab-wrapper">
                <SecurityTab userProfileData={userProfileData} />
              </TabPane>
              <TabPane tabId="3" id="timeline-tab-wrapper">
                <TimeLineTab userProfileData={userProfileData} />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      )}
      {errors && (
        <Alert color="danger">
          <h4 className="alert-heading">Invalid User Email</h4>
          <div className="alert-body">
            Unable to get User detail for this {emailParam.email}
          </div>
        </Alert>
      )}
    </div>
  )
}

export default DetailUserViewTable
