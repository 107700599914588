import { Modal, ModalBody, Spinner } from "reactstrap"

const SalesLoaderModal = ({ show }) => {
  console.log("show", show)
  return (
    <Modal isOpen={show} className="modal-dialog-centered modal-sm">
      <ModalBody>
        <div className="text-center p-2">
          <Spinner className="text-primary" />
          <h3 className="mt-1 text-primary">Creating Journal ...</h3>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SalesLoaderModal
