import React, { useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"
import { updateUserProfile } from "../../service/manageUserService"
import toast from "react-hot-toast"
import Select from "react-select"
import { useSelector } from "react-redux"
// import { handleUserData } from "../../../../redux/authentication"

import EncryptionDecryptionService from "../../../../services/EncryptionDecryptionService"
import { useLayout } from "../../../../utility/hooks/useLayout"
// import { useLayout } from '@hooks/useLayout'

const prefOption = [
  {
    value: "Daily",
    label: "Daily"
  },
  {
    value: "Weekly",
    label: "Weekly"
  },
  {
    value: "Monthly",
    label: "Monthly"
  }
]

const PreferencesModal = ({ show, setShow, userProfileData }) => {
  // const encryptionService = new EncryptionDecryptionService()
  const [layout] = useState(userProfileData.mainLayout)

  // const dispatch = useDispatch()
  const [emailNotification, setEmailNotification] = useState(
    userProfileData.emailNotification
  )
  const [activeLayout, setActiveLayout] = useState(layout)
  const [emailFreq, setEmailFreq] = useState(userProfileData.emailFrequency)
  const [desktopNotification, setDesktopNotification] = useState(
    userProfileData.desktopNotification
  )

  const { setLayout } = useLayout()

  const selector = useSelector((state) => state.auth.userData)
  const handleSubmit = (e) => {
    e.preventDefault()
    userProfileData.emailNotification = emailNotification
    userProfileData.emailFrequency = emailFreq
    userProfileData.desktopNotification = desktopNotification
    userProfileData.mainLayout = activeLayout
    userProfileData.updatedInfo = {
      createdBy: selector.email,
      createdOn: new Date().toISOString(),
      updatedBy: selector.email,
      updatedOn: new Date().toISOString()
    }
    if (!userProfileData.status) {
      userProfileData.status = "ACTIVE"
    }
    //console.log(userProfileData)
    updateUserProfile(userProfileData)
      .then((res) => {
        const response = res.data.user
        // const data = _.cloneDeep(selector)
        // data.mainLayout = response.mainLayout
        console.log("tes", data)
        setLayout(response.mainLayout)
        // dispatch(handleUserData(data))
        setShow(!show)
        toast.success("Record updated Successfully", {
          position: "top-center"
        })
      })
      .catch((err) => {
        console.log(err)
        //setShow(!show)
        toast.error("Something went wrong", {
          position: "top-center"
        })
      })
  }

  //console.log(layout)

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        setShow(!show)
      }}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        toggle={() => {
          setShow(!show)
        }}
      ></ModalHeader>
      <ModalBody>
        <h2 className="text-center">Preferences</h2>
        <Row>
          <Col md={6} className="my-1">
            <h5 className="fw-bold">Turn on Email Notification</h5>
            <div className="form-check form-switch mt-2">
              <Input
                type="switch"
                name="active"
                checked={emailNotification}
                onChange={(e) => {
                  setEmailNotification(e.target.checked)
                }}
              />
              <Label className="form-check-label switch-icon-left switch-icon-right ps-2">
                {emailNotification ? "Yes" : "No"}
              </Label>
            </div>
          </Col>
          <Col md={6} className="my-1">
            <h5 className="fw-bold">Main Layout Switch</h5>
            <div className="demo-inline-spacing">
              <div className="form-check">
                <Input
                  type="radio"
                  id="ex1-active"
                  value="vertical"
                  name="ex1"
                  defaultChecked={userProfileData.mainLayout === "vertical"}
                  onChange={(e) => setActiveLayout(e.target.value)}
                />
                <Label className="form-check-label" for="ex1-active">
                  Vertical
                </Label>
              </div>
              <div className="form-check">
                <Input
                  type="radio"
                  name="ex1"
                  value="horizontal"
                  id="ex1-inactive"
                  defaultChecked={userProfileData.mainLayout === "horizontal"}
                  onChange={(e) => setActiveLayout(e.target.value)}
                />
                <Label className="form-check-label" for="ex1-inactive">
                  Horizontal
                </Label>
              </div>
            </div>
          </Col>
          <Col md={6} className="my-1">
            <Label>Email Frequency</Label>
            <Select
              className="react-select"
              classNamePrefix="select"
              value={prefOption.filter((opt) => opt.value === emailFreq)}
              options={prefOption}
              isClearable={false}
              onChange={(val) => setEmailFreq(val.value)}
            />
          </Col>
          <Col md={6} className="my-1">
            <h5 className="fw-bold">Turn on Desktop Notification</h5>
            <div className="form-check form-switch mt-2">
              <Input
                type="switch"
                name="active"
                checked={desktopNotification}
                onChange={(e) => {
                  setDesktopNotification(e.target.checked)
                }}
              />
              <Label className="form-check-label switch-icon-left switch-icon-right ps-2">
                {desktopNotification ? "Yes" : "No"}
              </Label>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-center align-items-center my-2">
          <Button color="primary" className="mx-1" onClick={handleSubmit}>
            Submit
          </Button>
          <Button
            outline
            className="mx-1"
            onClick={() => {
              setShow(!show)
            }}
          >
            Discard
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PreferencesModal
