import {
  Modal,
  Row,
  Label,
  Form,
  Col,
  Input,
  ModalBody,
  ModalHeader,
  Button,
  FormFeedback
} from "reactstrap"
import Select from "react-select"
import { selectThemeColors } from "@utils"
import Mandatory from "@custom-components/mandatoy-star/Mandatory"
import * as yup from "yup"
import Flatpickr from "react-flatpickr"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { useMemo, useContext, useEffect, useState } from "react"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { BusinessRegContext } from ".."
import ProgressButton from "@custom-components/buttons/ProgressButton"
import moment from "moment"

const BusinessRegistrationModal = ({ show, setShow, setIsVerified }) => {
  const businessInfoSchema = yup.object().shape({
    doingBusinessAs: yup.string().required(),
    businessType: yup.object().shape({
      label: yup.string().required()
    }),
    businessName: yup.string().required(),
    taxId: yup
      .string()
      .matches(/^\d{9}$/, "Tax Id must be exactly 9 digits")
      .required("Tax Id is Required"),
    taxIdType: yup.object().shape({
      label: yup.string().required()
    }),
    incorporationDate: yup.date().required(),
    address1: yup.string().required(),
    accountHolderName: yup.string().required(),
    country: yup.string().required(),
    state: yup
      .string()
      .required("State is Required")
      .matches(/^[A-Z]{2}$/, "Two Character State Code"),
    postalCode: yup.number().required(),
    city: yup.string().required()
  })

  const { saveBusinessRegDetails } = useContext(BusinessRegContext)

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(businessInfoSchema)
  })
  const businessTypeOption = [
    { label: "Sole proprietorship", value: "soleProprietorship" },
    { label: "Corporation", value: "corporation" },
    { label: "LLCs/Partnerships", value: "llc" },
    {
      label: "Publicly traded corporations",
      value: "publiclyTradedCorporation"
    },
    { label: "Non-profits", value: "nonProfit" },
    {
      label: "Unincorporated association",
      value: "unincorporatedAssociation"
    },
    { label: "Trust", value: "trust" }
  ]

  const taxIdType = [
    { label: "Ein", value: "ein" },
    { label: "Tin", value: "tin" }
  ]

  const [loading, SetLoading] = useState(false)

  const selector = useSelector((state) => state.auth.userData)
  console.log(selector, "selectorData")

  useEffect(() => {
    setValue("doingBusinessAs", selector?.basicProfile?.legalName)
    setValue("businessName", selector?.client?.clientName)
    setValue(
      "businessType",
      businessTypeOption.find(
        (option) =>
          option.label == selector?.basicProfile?.businessType ||
          option.value == selector?.basicProfile?.businessType
      )
    )

    setValue(
      "address1",
      selector?.basicProfile?.accountAdminInfo?.businessAddress?.addressLine1
    )
    setValue(
      "city",
      selector?.basicProfile?.accountAdminInfo?.businessAddress?.city
    )
    setValue(
      "state",
      selector?.basicProfile?.accountAdminInfo?.businessAddress?.state
    )
    setValue(
      "country",
      selector?.basicProfile?.accountAdminInfo?.businessAddress?.country
    )
    setValue(
      "postalCode",
      selector?.basicProfile?.accountAdminInfo?.businessAddress?.zipCode
    )
  }, [])
  const {
    clientId,
    client: { clientCode = "" },
    basicProfile: { dateFormat = "MM/DD/YYYY" }
  } = useSelector((state) => state.auth.userData)
  const datePickerDate = useMemo(() => {
    return dateFormat === "MM/DD/YYYY" ? "m-d-Y" : "d-m-Y"
  }, [dateFormat])
  const onSubmit = async (e) => {
    const formData = {
      doingBusinessAs: e.doingBusinessAs,
      businessType: e.businessType.value,
      businessName: e.businessName,
      tax_id: e.taxId,
      taxIdType: e.taxIdType.value,
      incorporatedDate: moment(e.incorporationDate).format("YYYY-MM-DD"),
      address1: e.address1,
      address2: "",
      city: e.city,
      postalCode: e.postalCode,
      state: e.state,
      country: e.country,
      ein: "",
      firstName: "",
      lastName: "",
      adminEmail: selector.userName,
      dateOfBirth: "",
      clientId,
      clientCode,
      email: selector.userName,
      accountHolderName: e.accountHolderName
    }
    console.log(formData, "formData")
    try {
      SetLoading(true)
      await saveBusinessRegDetails(formData, "controller", () => {
        // setIsSaving(false)
        // handleClose()
      })
    } finally {
      SetLoading(false)
    }
  }

  const onClose = () => {
    setIsVerified(false)
    setShow(false)
    reset()
  }

  console.log(errors, "err")
  return (
    <>
      <Form>
        <Modal
          isOpen={show}
          toggle={onClose}
          className="modal-dialog-centered modal-lg"
          backdrop={"static"}
          keyboard={false}
        >
          <ModalHeader
            toggle={onClose}
            className="bg-transparent"
          ></ModalHeader>
          <h3 className="text-center w-100 mb-2">Business Registration</h3>
          <ModalBody>
            <Row>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Legal Name <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="doingBusinessAs"
                  name="doingBusinessAs"
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        {...field}
                        invalid={errors.doingBusinessAs && true}
                      />
                      {errors.doingBusinessAs && (
                        <FormFeedback>{"Legal Name is Required"}</FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Business Name <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="businessName"
                  name="businessName"
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        {...field}
                        invalid={errors.businessName && true}
                      />
                      {errors.businessName && (
                        <FormFeedback>
                          {"Business Name is Required"}
                        </FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Business Type
                  <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="businessType"
                  name="businessType"
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        classNamePrefix="select"
                        options={businessTypeOption}
                        maxMenuHeight={"100px"}
                        className={classNames("react-select", {
                          "is-invalid": errors.businessType && true
                        })}
                        theme={selectThemeColors}
                      />
                      {errors.businessType && (
                        <FormFeedback>
                          {"Business Type is Required"}
                        </FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Tax Id
                  <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="taxId"
                  name="taxId"
                  render={({ field }) => (
                    <>
                      <Input {...field} type="number" invalid={errors.taxId} />
                      {errors.taxId && (
                        <FormFeedback> {errors.taxId.message}</FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Tax Id Type <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="taxIdType"
                  name="taxIdType"
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        classNamePrefix="select"
                        options={taxIdType}
                        maxMenuHeight={"100px"}
                        className={classNames("react-select", {
                          "is-invalid": errors.taxIdType && true
                        })}
                        theme={selectThemeColors}
                      />
                      {errors.taxIdType && (
                        <FormFeedback>{"Tax Id Type is Required"}</FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Incorporation Date
                  <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="incorporationDate"
                  name="incorporationDate"
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        className={classNames("form-control", {
                          "is-invalid": errors.incorporationDate && true
                        })}
                        options={{
                          dateFormat: datePickerDate
                        }}
                        {...field}
                      />
                      {errors.incorporationDate && (
                        <FormFeedback>
                          {"Incorporation Date is Required"}
                        </FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Legal Address <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="address1"
                  name="address1"
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        invalid={errors.address1 && true}
                        {...field}
                      />
                      {errors.address1 && (
                        <FormFeedback>
                          {"Legal Address is Required"}
                        </FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Account Holder Name <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="accountHolderName"
                  name="accountHolderName"
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        {...field}
                        invalid={errors.accountHolderName && true}
                      />
                      {errors.accountHolderName && (
                        <FormFeedback>
                          {"Account Holder Name is Required"}
                        </FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Country <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="country"
                  name="country"
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        {...field}
                        invalid={errors.country && true}
                      />
                      {errors.country && (
                        <FormFeedback>{"Country is Required"}</FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  State <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="state"
                  name="state"
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        {...field}
                        invalid={errors.state && true}
                        onChange={(e) => {
                          const uppercaseValue = e.target.value.toUpperCase()
                          field.onChange(uppercaseValue)
                        }}
                      />
                      {errors.state && (
                        <FormFeedback>{errors.state.message}</FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  City <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="city"
                  name="city"
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        {...field}
                        invalid={errors.city && true}
                      />
                      {errors.city && (
                        <FormFeedback>{"city is Required"}</FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
              <Col md={6} xs={12} className="mb-1">
                <Label>
                  Zipcode
                  <Mandatory />
                </Label>
                <Controller
                  control={control}
                  id="postalCode"
                  name="postalCode"
                  render={({ field }) => (
                    <>
                      <Input
                        type="number"
                        {...field}
                        invalid={errors.postalCode && true}
                      />
                      {errors.postalCode && (
                        <FormFeedback>{"Zipcode is Required"}</FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end gap-1 mt-2 mb-2">
              <Button onClick={onClose} outline>
                Cancel
              </Button>
              <ProgressButton
                type="submit"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                loader={loading}
              >
                Submit
              </ProgressButton>
            </div>
          </ModalBody>
        </Modal>
      </Form>
    </>
  )
}

export default BusinessRegistrationModal

// const modalViewData={
//   "doingBusinessAs": "tst",
//   "businessType": "corporation",
//   "businessName": "test",
//   "tax_id": "123456789",
//   "taxIdType": "tin",
//   "incorporationDate": "2023-11-09T18:30:00.000Z",
//   "address1": "No-123",
//   "address2": "",
//   "city": "dubai",
//   "postalCode": "678553",
//   "state": "UAI",
//   "country": "India",
//   "ein": "",
//   "firstName": "",
//   "lastName": "",
//   "adminEmail": "admin@test.com",
//   "dateOfBirth": "",
//   "clientId": "10154",
//   "clientCode": "Capital-Brands",
//   "email": "admin@test.com",
//   "accountHolderName": "test",
//   "ipAddress": "121.242.124.2"
// }
