import classNames from "classnames"
import { useState } from "react"
import Avatar from "@components/avatar"
import Chart from "react-apexcharts"
import { CheckCircle, HelpCircle, Info } from "react-feather"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  CardSubtitle
} from "reactstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import moment from "moment"
import { Popover } from "antd"
import { kFormatter as KF, ToolTipWrapper } from "@utils"
import { cashReceiptsSummary } from "@service/apiUrl"
import NextButton from "@custom-components/buttons/next-button/NextButton"
import AccessRestrictionPopover from "../../AccessRestriction"
import useFetch from "@hooks/useFetch"

const defaultData = [
  {
    count: "0",
    amount: "0",
    subtitle: "Applied deposits",
    color: "light-primary",
    icon: <CheckCircle size={22} />
  },
  {
    count: "0",
    amount: "0",
    subtitle: "Unapplied deposits",
    color: "light-info",
    icon: <Info size={22} />
  },
  {
    count: "0",
    amount: "0",
    subtitle: "Unidentified deposits",
    color: "light-warning",
    icon: <HelpCircle size={22} />
  }
]
const defaultTotalDeopsitVaue = {
  count: 0,
  amount: 0
}

const CashReceiptSummary = () => {
  const nav = useNavigate()
  const [activeRange, setActiveRange] = useState(rangeOptions[0])
  const { clientId, currentRoleAccess } = useSelector(
    (state) => state.auth.userData
  )
  const { accessRestriction } = currentRoleAccess ?? {}
  const { Rev_CA_AD } = accessRestriction ?? {}
  const { access = "WRITE" } = Rev_CA_AD ?? {}
  const series = [0]
  const [
    {
      receiptSummary = defaultData,
      totalDeposits = defaultTotalDeopsitVaue,
      totalAppliedDepositPercentage = series
    } = {},
    { isFirstLoad: loading }
  ] = useFetch(cashReceiptsSummary, {
    apiParams: {
      params: {
        clientId,
        ...getFromDate(activeRange)
      }
    },
    callback: (res) => {
      const receiptSummary = defaultData.map((value) => {
        const { subtitle } = value
        const { count, amount } = dataFormat[subtitle]
        value.count = res[count] ?? 0
        value.amount = res[amount] ?? 0
        return value
      })
      const totalDeposits = {
        count: res.totalDeposits,
        amount: res.totalDepositAmount
      }
      return {
        receiptSummary,
        totalDeposits,
        totalAppliedDepositPercentage: [res.totalAppliedDepositPercentage ?? 0]
      }
    }
  })

  const options = {
    plotOptions: {
      radialBar: {
        size: 150,
        offsetY: 20,
        startAngle: -150,
        endAngle: 150,
        hollow: {
          size: "65%"
        },
        track: {
          background: "#fff",
          strokeWidth: "100%"
        },
        dataLabels: {
          name: {
            offsetY: -5,
            fontSize: "1rem"
          },
          value: {
            offsetY: 15,
            fontSize: "1.714rem"
          }
        }
      }
    },
    colors: ["#34a253"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#ea5455", "#FFBF00"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      dashArray: 8
    },
    labels: ["Completed Tickets"]
  }

  function navigateTo() {
    nav("/v2/ar/revenue/applydeposits")
  }
  return (
    <Card>
      <CardHeader className="pb-0">
        <CardTitle className="flex-center" tag="h4">
          Cash Reciept Summary
        </CardTitle>
        <Popover
          content={<AccessRestrictionPopover />}
          showArrow={false}
          placement="right"
          {...(access === "HIDE" ? {} : { open: access === "HIDE" })}
        >
          <div className="cursor-pointer">
            <NextButton
              color="primary"
              size="sm"
              id="dashboard-cash-app"
              disabled={access === "HIDE"}
              onClick={navigateTo}
            >
              Go to Cash App
            </NextButton>
          </div>
        </Popover>
      </CardHeader>
      {loading ? (
        <SummarySkeleton />
      ) : (
        <CardBody>
          <CardSubtitle>
            {" "}
            <UncontrolledDropdown className="chart-dropdown">
              <DropdownToggle
                color=""
                style={{ color: "#33cc33" }}
                className="bg-transparent btn-sm border-0 p-50"
              >
                {activeRange}
              </DropdownToggle>
              <DropdownMenu end>
                {rangeOptions.map((item, i) => (
                  <DropdownItem
                    className="w-100"
                    key={i}
                    onClick={() => setActiveRange(item)}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </CardSubtitle>
          <Row>
            <Col sm="2" className="d-flex flex-column flex-wrap">
              <h3 className=" fw-bolder mt-2 mb-0 text-nowrap">
                <ToolTipWrapper
                  isTooltipNeed
                  toolTipValue={`Total number of Total Deposits`}
                >
                  <span>{totalDeposits.count ?? 0} </span>
                </ToolTipWrapper>
                |
                <ToolTipWrapper
                  isTooltipNeed
                  toolTipValue={`Total value of Total Deposits`}
                >
                  <span> {KF(totalDeposits.amount ?? 0)} </span>
                </ToolTipWrapper>
              </h3>
              <CardText className="mb-0">Total Deposits</CardText>
              {renderData(receiptSummary)}
            </Col>
            <Col sm="10" className="d-flex justify-content-end">
              <Chart
                options={options}
                series={totalAppliedDepositPercentage}
                type="radialBar"
                height={270}
                id="support-tracker-card"
              />
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  )
}
export default CashReceiptSummary

function renderData(data = []) {
  return data.map((item, index) => {
    const margin = "sm"
    return (
      <div
        key={index}
        className={classNames({
          [`mb-1 mb-${margin}-0`]: index !== data.length - 1
        })}
      >
        <div className={`d-flex align-items-center mt-${index ? 1 : 0}`}>
          <div style={{ transform: "scale(1)" }}>
            <Avatar
              color={item.color}
              icon={item.icon}
              className="me-2"
              // size="sm"
            />
          </div>
          <div className="my-auto">
            <CardText className="font-small-3 mb-0 text-nowrap">
              {item.subtitle}
            </CardText>
            <h5 className="fw-bolder mb-0 text-start text-nowrap">
              <ToolTipWrapper
                isTooltipNeed
                toolTipValue={`Total number of ${item.subtitle ?? "Count"}`}
              >
                <span>{item.count} </span>
              </ToolTipWrapper>
              |
              <ToolTipWrapper
                isTooltipNeed
                toolTipValue={`Total value of ${item.subtitle ?? "Count"}`}
              >
                <span> {KF(item.amount ?? 0)}</span>
              </ToolTipWrapper>
            </h5>
          </div>
        </div>
      </div>
    )
  })
}
const rangeOptions = ["Last 7 Days", "Last month", "Last Quater", "Last Year"]
const dataFormat = {
  "Applied deposits": {
    amount: "totalAppliedDepositAmount",
    count: "totalAppliedDeposits"
  },
  "Unapplied deposits": {
    amount: "totalUnAppliedDepositAmount",
    count: "totalUnAppliedDeposits"
  },
  "Unidentified deposits": {
    amount: "totalUnIdentifiedDepositAmount",
    count: "totalUnIdentifiedDeposits"
  }
}
function getFromDate(value) {
  const dateValue = {
    "Last 7 Days": "w",
    "Last month": "M",
    "Last Quater": "Q",
    "Last Year": "y"
  }
  const date = moment().subtract("1", dateValue[value]).format(dateFormat)
  if (value === "Last 7 Days")
    return { transFrom: date, transTo: moment().format(dateFormat) }
  const startDate = moment(date).startOf(dateValue[value]).format(dateFormat)
  const endDate = moment(date).endOf(dateValue[value]).format(dateFormat)
  return { transFrom: startDate, transTo: endDate }
}
const dateFormat = "YYYY-MM-DD"

const SummarySkeleton = () => (
  <div className="row ms-1">
    <div className="col">
      <SkeletonTheme>
        <Skeleton height={35} className="my-50" />
        {Array(3)
          .fill("")
          .map((_, id) => (
            <div className="flex-center my-25" key={id}>
              <SkeletonTheme>
                <Skeleton
                  width={"50px"}
                  style={{ borderRadius: "40px" }}
                  height={"50px"}
                />
                <Skeleton className="ms-25" width={"15rem"} />
              </SkeletonTheme>
            </div>
          ))}
      </SkeletonTheme>
    </div>
    <div className="col">
      <SkeletonTheme>
        <Skeleton
          style={{
            borderRadius: "100px",
            width: "200px",
            height: "14rem",
            marginTop: "1rem",
            marginLeft: "1rem"
          }}
        />
      </SkeletonTheme>
    </div>
  </div>
)
