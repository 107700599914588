/* 
  Don't change order of the object keys
  and also key name
  it was used in role management restriction for sidebar menus and in the routes.
  if change anything in this file please check sidebar menu and route restriction
*/
export const dummy = {
  Configuration: {
    Master_Data: "HIDE",
    User_Management: "HIDE",
    Integration: "HIDE",
    Expenses: "HIDE",
    Revenue: "HIDE",
    Crato_Ledge: "HIDE"
  },
  Expenses: {
    Vendor_Management: "HIDE",
    Bill_Processing: "HIDE",
    Workflow: "HIDE",
    Pay_Bills: "HIDE"
  },
  Revenue: {
    Sales: "HIDE",
    Customers: "HIDE",
    Invoicing: "HIDE",
    Cash_Application: "HIDE"
  },
  // Banking: {
  //   Unbooked_Transactions: 'HIDE',
  //   Booked_Transactions: 'HIDE',
  //   Bank_Reconciliation: 'HIDE',
  //   Upload_Bank_Txns: 'HIDE'
  //   // Reconcile_Banks: 'HIDE',
  //   // AP_to_GL: 'HIDE',
  //   // AR_to_GL: 'HIDE'
  // },
  CratoLedge: {
    Bank_and_Credit_Card_Posting: "HIDE",
    Booked_Transactions: "HIDE"
  },
  Analytics: {
    Expenses: "HIDE",
    Revenue: "HIDE",
    General_Ledger: "HIDE"
  }
}
export const permissions = {
  Configuration: {
    Master_Data: "",
    User_Management: "",
    Integration: "",
    Expenses: "",
    Revenue: "",
    Crato_Ledge: ""
  },
  Expenses: {
    Vendor_Management: "",
    Bill_Processing: "",
    Workflow: "",
    Pay_Bills: ""
  },
  Revenue: {
    Sales: "",
    Customers: "",
    Invoicing: "",
    Cash_Application: ""
  },
  Banking: {
    Unbooked_Transactions: "",
    Booked_Transactions: "",
    Bank_Reconciliation: "",
    Upload_Bank_Txns: ""
    // Reconcile_Banks: "",
    // AP_to_GL: "",
    // AR_to_GL: ""
  },
  CratoLedge: {
    Bank_and_Credit_Card_Posting: "",
    Booked_Transactions: ""
  },
  Analytics: {
    Expenses: "",
    Revenue: "",
    General_Ledger: ""
  }
}
export const booleanPermissions = {
  Configuration: {
    Master_Data: false,
    User_Management: false,
    Integration: false,
    Expenses: false,
    Revenue: false,
    Crato_Ledge: false
  },
  Expenses: {
    Vendor_Management: false,
    Bill_Processing: false,
    Workflow: false,
    Pay_Bills: false
  },
  Revenue: {
    Sales: false,
    Customers: false,
    Invoicing: false,
    Cash_Application: false
  },
  Banking: {
    Unbooked_Transactions: false,
    Booked_Transactions: false,
    Bank_Reconciliation: false,
    Upload_Bank_Txns: false,
    Reconcile_Banks: false,
    AP_to_GL: false,
    AR_to_GL: false
  },
  CratoLedge: {
    Bank_and_Credit_Card_Posting: false,
    Booked_Transactions: false
  },

  Analytics: {
    Expenses: false,
    Revenue: false,
    General_Ledger: false
  }
}

export const moduleAccessPermission = {
  Configuration: {
    Master_Data: true,
    User_Management: true,
    Integration: true,
    Expenses: true,
    Revenue: true,
    Crato_Ledge: true
  },
  Expenses: {
    Vendor_Management: true,
    Bill_Processing: true,
    Workflow: true,
    Pay_Bills: true
  },
  Revenue: {
    Sales: true,
    Customers: true,
    Invoicing: true,
    Cash_Application: true
  },
  Banking: {
    Unbooked_Transactions: true,
    Booked_Transactions: true,
    Bank_Reconciliation: true,
    Upload_Bank_Txns: true
    // Reconcile_Banks: true,
    // AP_to_GL: true,
    // AR_to_GL: true
  },
  CratoLedge: {
    Bank_and_Credit_Card_Posting: true,
    Booked_Transactions: true
  },

  Analytics: {
    Payables: true,
    Revenue: true,
    General_Ledger: true
  }
}

///active role management
export const moduleMap = {
  Exp_Bill_Processing: ["Exp_BP_AB", "Exp_BP_IP"],
  Exp_Payments: ["Exp_Payments_Mk"]
}
export const hasApproval = [
  "Vendor_Management",
  "Bill_Processing",
  "Workflow",
  "Payments",
  "Customers",
  "Invoicing",
  "Exp_Bill_Processing",
  "Exp_Vendor_Management",
  "Exp_BP_AB",
  "Exp_BP_IP",
  "Exp_Payments",
  "Exp_Payments_Mk",
  "Exp_Workflow",
  "Exp_PO",
  "Rev_Customers"
]
export const hasResolution = [
  "Bill_Processing",
  "Customers",
  //new
  "Exp_Bill_Processing",
  "Exp_BP_AB",
  "Exp_BP_IP",
  "Exp_PO",
  "Rev_Customers",
  "Bank_Unbooked_Transactions"
]
export const businessUserRoles = [
  "Approver",
  "Accountant Approver",
  "Accounting Clerk",
  "Account Admin",
  "Super User"
]
//page vise restriction
export const permissionsArray = [
  {
    id: "Payables",
    name: "Payables",
    children: [
      { id: "Exp_Vendor_Management", name: "Vendor_Management" },
      {
        id: "Exp_Bill_Processing",
        name: "Bill_Processing",
        children: [
          { id: "Exp_BP_AB", name: "All_Bills" },
          { id: "Exp_BP_IP", name: "In_Process" }
        ]
      },
      {
        id: "Exp_Payments",
        name: "Payments",
        children: [
          { id: "Exp_Payments_Mk", name: "Make_Payment" },
          { id: "Exp_Payments_Tk", name: "Track_Payment" },
          { id: "Exp_Payments_AP", name: "Apply_Payment" }
        ]
      },
      { id: "Exp_PO", name: "Purchase Order" }
    ]
  },
  {
    id: "Revenue",
    name: "Revenue",
    children: [
      { id: "Rev_Customers", name: "Customers" },
      { id: "Rev_Invoicing", name: "Invoicing" },
      {
        id: "Rev_Sales",
        name: "Sales",
        children: [
          { id: "Rev_Sales_Sales_Entries", name: "Sales_Entries" },
          { id: "Rev_Sales_Sales_Input", name: "Sales_Input" }
        ]
      },
      {
        id: "Rev_CA",
        name: "Cash_Application",
        children: [
          { id: "Rev_CA_AD", name: "Apply_Deposits" },
          { id: "Rev_CA_Remittance", name: "Remittance" },
          { id: "Rev_CA_Deductions", name: "Deductions" }
        ]
      },
      { id: "Rev_Sales_Order", name: "Sales_Order" }
    ]
  },
  {
    id: "Banking",
    name: "Banking",
    children: [
      {
        id: "Bank_Bank_Posting",
        name: "Bank_Posting",
        children: [
          { id: "Bank_Unbooked_Transactions", name: "Unbooked_Transactions" },
          { id: "Bank_Booked_Transactions", name: "Booked_Transactions" }
        ]
      },
      { id: "Bank_Bank_Reconciliation", name: "Bank_Reconciliation" },
      { id: "Bank_Upload_Bank_Transaction", name: "Upload_Bank_Transaction" }
    ]
  },
  {
    id: "CratoLedge",
    name: "CratoLedge",
    children: [{ id: "CL_General_Ledger", name: "General_Ledger" }]
  },
  {
    id: "Reporting",
    name: "Reporting"
    // children: [
    //   { id: "Bank_Unbooked_Transactions", name: "Unbooked_Transactions" },
    //   { id: "Bank_Booked_Transactions", name: "Booked_Transactions" },
    //   { id: "Bank_Bank_Reconciliation", name: "Bank_Reconciliation" },
    //   { id: "Bank_Upload_Bank_Transaction", name: "Upload_Bank_Transaction" }
    // ]
  },
  {
    id: "Configuration",
    name: "Settings",
    children: [
      {
        id: "Con_MD",
        name: "Master_Data",
        children: [
          { id: "Con_MD_COA", name: "Chart of accounts" },
          { id: "Con_MD_BP", name: "Business_Profile" },
          { id: "Con_MD_CS", name: "Corporate_Structure" }
        ]
      },

      { id: "Con_UM", name: "User_Management" },
      {
        id: "Con_Int",
        name: "Integration",
        children: [
          { id: "Con_Int_Acc", name: "Accounting" },
          { id: "Con_Int_Bank", name: "Banking" },
          { id: "Con_Int_POS", name: "POS System" }
        ]
      },
      {
        id: "Con_Exp",
        name: "Payables",
        children: [
          { id: "Con_Exp_BP", name: "Bill_Processing" },
          { id: "Con_Exp_VS", name: "Vendor_setup" },
          { id: "Con_Exp_CP", name: "Cratopay" },
          { id: "Con_Exp_PO", name: "PO settings" }
        ]
      },
      {
        id: "Con_Rev",
        name: "Revenue",
        children: [
          { id: "Con_Rev_Inv", name: "Invoicing" },
          { id: "Con_Rev_CC", name: "Customer_Creation" },
          { id: "Con_Rev_Sales", name: "Sales" },
          { id: "Con_Rev_CA", name: "Cash_Application" }
        ]
      },
      {
        id: "Con_Cratobot",
        name: "Cratobot",
        children: [
          { id: "Con_CratoBot_Post_Rule", name: "Bank_Posting_Rules" },
          { id: "Con_CratoBot_Recon_Rule", name: "Bank_Recon_Rules" },
          { id: "Con_CratoBot_BPR", name: "AP_Bill_Processing_Rules" },
          { id: "Con_CratoBot_CR", name: "AP_Coding_Rules" }
        ]
      },
      {
        id: "Con_Cratoledge",
        name: "CratoLedge",
        children: [{ id: "Con_CL_GL", name: "General_Ledger" }]
      }
    ]
  }
]
export const getPermissionArray = (roleId = null) => {
  if (roleId == "CRATO_APPROVER") {
    return [
      {
        id: "Payables",
        name: "Payables",
        children: [
          { id: "Exp_Vendor_Management", name: "Vendor_Management" },
          {
            id: "Exp_Bill_Processing",
            name: "Bill_Processing"
          },
          {
            id: "Exp_Payments",
            name: "Payments"
          },
          { id: "Exp_PO", name: "Purchase Order" }
        ]
      },
      {
        id: "Revenue",
        name: "Revenue",
        children: [{ id: "Rev_Customers", name: "Customers" }]
      }
    ]
  }
  return permissionsArray
}

export const extractedSubmenus = getPermissionArray().reduce(
  (p, c) => ({
    ...p,
    [c.id]: c?.children?.reduce(
      (p, { id, children = [] }) => [
        ...p,
        id,
        ...children?.map(({ id }) => id)
      ],
      []
    )
  }),
  {}
)

export const menuAccessForRoles = {
  ACCOUNTING_CLERK: {
    HIDE: []
  },
  CRATO_ACCOUNTADMIN: {
    HIDE: []
  },
  CRATO_ACCOUNTANT: {
    HIDE: ["Configuration"]
  },
  CRATO_ACCOUNTANT_PLUS: {
    HIDE: ["Configuration"]
  },
  CRATO_APPROVER: {
    HIDE: ["Configuration", "CratoLedge", "Banking"]
  },
  CRATO_VENDOR: {
    HIDE: [
      "Payables",
      "Revenue",
      "Configuration",
      "CratoLedge",
      "Banking",
      "Reporting"
    ]
  },
  CRATO_CUSTOMER: {
    HIDE: [
      "Payables",
      "Revenue",
      "Configuration",
      "CratoLedge",
      "Banking",
      "Reporting"
    ]
  },
  SUPER_USER: {
    HIDE: []
  }
}

export const tenantModuleMap = {
  modules: {
    Vendors: ["Exp_Vendor_Management", "Con_Exp_VS"],
    Bill_Processing: [
      "Exp_BP_AB",
      "Exp_BP_IP",
      "Con_Exp_BP",
      "Con_CratoBot_BPR",
      "Con_CratoBot_CR"
    ],
    Payments: [
      "Exp_Payments_Mk",
      "Exp_Payments_Tk",
      "Exp_Payments_AP",
      "Con_Exp_CP"
    ],
    Purchase_Order: ["Exp_PO", "Con_Exp_PO"],
    Customers: ["Rev_Customers", "Con_Rev_CC"],
    Invoicing: ["Rev_Invoicing", "Con_Rev_Inv"],
    Sales: [
      "Rev_Sales_Sales_Entries",
      "Rev_Sales_Sales_Input",
      "Con_Int_POS",
      "Con_Rev_Sales"
    ],
    Cash_Application: [
      "Rev_CA_AD",
      "Rev_CA_Remittance",
      "Rev_CA_Deductions",
      "Con_Rev_CA"
    ],
    Sales_Order: ["Rev_Sales_Order"],
    Bank_Posting: ["Bank_Unbooked_Transactions", "Bank_Booked_Transactions"],
    Bank_Reconciliation: [
      "Bank_Bank_Reconciliation",
      "Con_CratoBot_Recon_Rule"
    ],
    Upload_Bank_Transaction: ["Bank_Upload_Bank_Transaction"],
    General_Ledger: ["CL_General_Ledger", "Con_CL_GL"]
  },
  pages: [
    {
      page: "Con_Int_Bank",
      modules: ["Cash_Application", "Bank_Posting", "Bank_Reconciliation"]
    },
    {
      page: "Con_CratoBot_Post_Rule",
      modules: ["Cash_Application", "Bank_Posting", "Bank_Reconciliation"]
    }
  ]
}
//module restrictions
const tenantAccessModel = [
  {
    module: "Expense",
    subModules: [
      { name: "Vendors", active: true },
      { name: "Bill_Processing", active: true },
      { name: "Payments", active: true },
      {
        name: "Purchase_Order",
        active: true
      }
    ]
  },
  {
    module: "Revenue",
    subModules: [
      { name: "Customers", active: true },
      { name: "Invoicing", active: true },
      { name: "Sales", active: true },
      { name: "Cash_Application", active: true },
      { name: "Sales_Order", active: true }
    ]
  },
  {
    module: "Banking",
    subModules: [
      { name: "Bank_Posting", active: true },
      { name: "Bank_Reconciliation", active: true },
      { name: "Upload_Bank_Transaction", active: true }
    ]
  },
  {
    module: "Crato_Ledge",
    subModules: [{ name: "General_Ledger", active: true }]
  }
]
export const getModuleRestrictedPages = (arr = tenantAccessModel) => {
  const modMap = arr.reduce(
    (p, c) => ({
      ...p,
      ...c.subModules.reduce((q, w) => ({ ...q, [w.name]: w.active }), {})
    }),
    {}
  )
  const pageCheck = tenantModuleMap.pages
    .filter((r) => r.modules.every((s) => modMap[s] == false))
    .map(({ page }) => page)
  return [
    ...arr.reduce(
      (p, c) => [
        ...p,
        ...c.subModules.reduce((p1, c1) => {
          if (c1.active) return p1

          const data = tenantModuleMap.modules[c1.name]
          return [...p1, ...data]
        }, [])
      ],
      []
    ),
    ...pageCheck
  ]
}
