import { Fragment, useEffect, useState } from "react"
import JournalDetails from "./JournalDetails"
import JournalTable from "./journal-table"
import { useParams } from "react-router-dom"
import {
  getAccountSegment,
  getErpSync,
  getGlCode,
  getSalesDetailsById,
  getSegmentDetails,
  postNewSale,
  updateSales
} from "../../service/salesService"
import { useSelector } from "react-redux"

// ** Utils Imports
import { numberFormatter } from "@utils"
import PageHeader from "./PageHeader"
import toast from "react-hot-toast"
import { Skeletone } from "../../../../../custom-components/skeleton/skeleton"
import { Card } from "reactstrap"

const SalesView = () => {
  const param = useParams()
  const selector = useSelector((state) => state.auth.userData)

  const [totalAmount, setTotalAmount] = useState({
    creditTotal: 0,
    debitTotal: 0
  })
  const [salesDetails, setSalesDetails] = useState({})
  const [glCodeOption, setGlCodeOption] = useState([])
  const [isEditable, setIsEditable] = useState(true)
  const [isErpSynced, setIsErpSynced] = useState(false)
  const [isOpen, setIsOpen] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const toggle = (index) => {
    const isOpenClone = _.cloneDeep(isOpen)
    isOpenClone[index] = !isOpenClone[index]
    setIsOpen(isOpenClone)
  }

  const addDeleteOpen = (type, index) => {
    const isOpenStore = _.cloneDeep(isOpen)
    if (type === "delete") {
      isOpenStore.splice(index, 1)
    } else {
      isOpenStore.push(true)
    }
    setIsOpen(isOpenStore)
  }

  const checklength = (item) => {
    if (Array.isArray(item)) {
      if (item.length !== 0) {
        return item.length
      }
    } else {
      return 0
    }
  }

  const getTotalAmount = (salesDetails) => {
    let debitTotal = 0
    let creditTotal = 0
    if (Array.isArray(salesDetails.salesInfo)) {
      salesDetails.salesInfo.map((parentItem) => {
        if (checklength(parentItem.children)) {
          parentItem.children.map((childItem) => {
            if (childItem.action === "DEBIT") {
              debitTotal += numberFormatter(childItem.amount)
            } else {
              creditTotal += numberFormatter(childItem.amount)
            }
          })
        } else {
          if (parentItem.action === "DEBIT") {
            debitTotal += numberFormatter(parentItem.amount)
          } else {
            creditTotal += numberFormatter(parentItem.amount)
          }
        }
      })
    }
    console.log(debitTotal)
    console.log(creditTotal)
    setTotalAmount({
      debitTotal,
      creditTotal
    })
  }

  const onInit = () => {
    // ** Gl Code List
    getGlCode("ALL", selector.clientId)
      .then((res) => {
        const glCodeArr = []
        res.data[0].clientAccountSegmentList.map((item) => {
          glCodeArr.push({
            label: item.description,
            value: item
          })
        })
        setGlCodeOption(glCodeArr)
      })
      .catch((err) => console.log(err))

    // ** Get Segment
    getAccountSegment(selector.clientId)
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => console.log(err))
    getSegmentDetails(selector.clientId)
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => console.log(err))

    // ** Get Erp
    getErpSync(selector.clientId)
      .then((res) => {
        const erpSyncInfo = res.data
        const isErpSynced = erpSyncInfo["loggedInStatus"] === "AUTHENTICATED"
        setIsErpSynced(isErpSynced)
      })
      .catch((err) => console.log(err))
  }

  const getSales = () => {
    getSalesDetailsById(param.id)
      .then((res) => {
        console.log(res.data)
        const isOpenStore = []
        setSalesDetails(res.data)
        if (Array.isArray(res.data.salesInfo)) {
          res.data.salesInfo.map(() => {
            isOpenStore.push(true)
          })
          setIsOpen(isOpenStore)
        }
        getTotalAmount(res.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getSales()

    onInit()
  }, [])

  const handleParentAdd = (payload) => {
    const salesDetailsStore = _.cloneDeep(salesDetails)
    salesDetailsStore.salesInfo.splice(
      salesDetailsStore.salesInfo.length - 1,
      0,
      payload
    )
    setSalesDetails(salesDetailsStore)
    addDeleteOpen("add")
  }

  const handleChildAdd = (parentIdx, childIdx) => {
    const childObj = {
      action: "",
      amount: "",
      glCode: {},
      comment: "",
      name: "",
      operation: ""
    }
    const salesDetailsStore = _.cloneDeep(salesDetails)
    salesDetailsStore.salesInfo[parentIdx].children.splice(
      childIdx + 1,
      0,
      childObj
    )
    setSalesDetails(salesDetailsStore)
    getTotalAmount(salesDetailsStore)
  }

  const handleParentDelete = (index) => {
    console.log(index)
    const salesDetailsStore = _.cloneDeep(salesDetails)
    salesDetailsStore.salesInfo.splice(index, 1)
    console.log(salesDetailsStore)
    setSalesDetails(salesDetailsStore)
    getTotalAmount(salesDetailsStore)

    addDeleteOpen("delete")
  }

  const handleChildDelete = (parentIdx, childIdx) => {
    console.log(parentIdx, childIdx)
    const salesDetailsStore = _.cloneDeep(salesDetails)
    salesDetailsStore.salesInfo[parentIdx].children.splice(childIdx, 1)
    console.log(salesDetailsStore)
    setSalesDetails(salesDetailsStore)
    getTotalAmount(salesDetailsStore)
  }

  const handleParentInputChange = (value, fieldName, index) => {
    console.log(value, fieldName, index)
    const salesDetailsStore = _.cloneDeep(salesDetails)
    salesDetailsStore.salesInfo[index][fieldName] = value
    console.log(salesDetailsStore)
    setSalesDetails(salesDetailsStore)
    getTotalAmount(salesDetailsStore)
  }

  const handleChildInputChange = (value, fieldName, parentIdx, childIdx) => {
    console.log(value, fieldName, parentIdx, childIdx)
    const salesDetailsStore = _.cloneDeep(salesDetails)
    salesDetailsStore.salesInfo[parentIdx].children[childIdx][fieldName] = value
    console.log(salesDetailsStore)
    setSalesDetails(salesDetailsStore)
    getTotalAmount(salesDetailsStore)
  }

  const handleParentDebitCredit = (value, type, index) => {
    console.log(value, type, index)
    const salesDetailsStore = _.cloneDeep(salesDetails)
    setSalesDetails(salesDetailsStore)
    if (!value) {
      salesDetailsStore.salesInfo[index].action = ""
    } else {
      salesDetailsStore.salesInfo[index].action = type
    }
    salesDetailsStore.salesInfo[index].amount = value
    console.log(salesDetailsStore.salesInfo[index])
    getTotalAmount(salesDetailsStore)
  }

  const handleChildDebitCredit = (value, type, parentIdx, childIdx) => {
    console.log(value, type, parentIdx, childIdx)
    const salesDetailsStore = _.cloneDeep(salesDetails)
    if (!value) {
      salesDetailsStore.salesInfo[parentIdx].children[childIdx].action = ""
    } else {
      salesDetailsStore.salesInfo[parentIdx].children[childIdx].action = type
    }
    salesDetailsStore.salesInfo[parentIdx].children[childIdx].amount = value
    console.log(salesDetailsStore.salesInfo[parentIdx].children[childIdx])
    getTotalAmount(salesDetailsStore)
    setSalesDetails(salesDetailsStore)
  }

  const getPayload = (type) => {
    const salesDetailsStore = _.cloneDeep(salesDetails)
    salesDetailsStore.status = "PENDING_REVIEW"
    if (type === "post") {
      salesDetailsStore.postingDate = new Date()
    }
    setSalesDetails(salesDetailsStore)
    return salesDetailsStore
  }

  const saveSaleDetails = (type) => {
    toast.loading("Saving...", { id: "save" })
    updateSales(getPayload("save"))
      .then((res) => {
        console.log(res.data)
        if (type === "save") {
          toast.success("Saved Sucessfully", { id: "save" })
        } else {
          postNewSale(res.data.salesId)
            .then(() => {
              toast.success("Posted Successfully")
            })
            .catch((err) => {
              console.log(err)
              toast.error("Try Again")
            })
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error("Try Again")
      })
  }

  const checkMandatory = (type) => {
    console.log(type)
    let count = 0
    salesDetails.salesInfo.map((parentItem) => {
      if (!checklength(parentItem.children)) {
        if (!parentItem.name) {
          count++
          // return toast.error('Please Fill All Names')
        } else if (!parentItem.amount) {
          count++
          // return toast.error('Please Fill Amount')
        } else if (parentItem.glCode) {
          if (Object.keys(parentItem.glCode).length === 0) {
            count++
            // return toast.error('Please Choose ALl Gl Code')
          } else if (Object.keys(parentItem.glCode).length !== 0) {
            if (!parentItem.glCode.description) {
              count++
              // return toast.error('Please Choose ALl Gl Code')
            }
          }
        }
      } else {
        parentItem.children.map((childItem) => {
          if (!childItem.name) {
            count++
            // return toast.error('Please Fill All Names')
          } else if (!childItem.amount) {
            count++
            // return toast.error('Please Fill Amount')
          } else if (childItem.glCode) {
            if (Object.keys(childItem.glCode).length === 0) {
              count++
              // return toast.error('Please Choose ALl Gl Code')
            } else if (Object.keys(childItem.glCode).length !== 0) {
              if (!childItem.glCode.description) {
                count++
                // return toast.error('Please Choose ALl Gl Code')
              }
            }
          }
        })
      }
    })
    console.log(count)
    if (count !== 0) {
      toast.error("Plese fill requried fields")
    } else {
      toast.success(type)
      saveSaleDetails("post")
    }
  }

  const onSave = (type) => {
    if (type === "save") {
      saveSaleDetails("save")
    } else {
      if (
        numberFormatter(totalAmount.debitTotal) !==
        numberFormatter(totalAmount.creditTotal)
      ) {
        toast.error("Amount on debits is not equal to credits")
      } else if (!isErpSynced) {
        toast.error("Erp Sync Requried")
      } else {
        checkMandatory(type)
      }
    }
  }

  if (isLoading)
    return (
      <Card>
        <Skeletone isloading={isLoading} />
      </Card>
    )

  return (
    <Fragment>
      <PageHeader
        isEditable={isEditable}
        onSave={onSave}
        setIsEditable={setIsEditable}
      />

      <JournalDetails
        salesDetails={salesDetails}
        isEditable={isEditable}
        setSalesDetails={setSalesDetails}
        dateFormat={selector.basicProfile.dateFormat}
      />

      <JournalTable
        salesDetails={salesDetails}
        dateFormat={selector.basicProfile.dateFormat}
        currency={selector.basicProfile.defaultCurrency}
        glCodeOption={glCodeOption}
        handleParentDelete={handleParentDelete}
        handleChildDelete={handleChildDelete}
        handleParentInputChange={handleParentInputChange}
        handleChildInputChange={handleChildInputChange}
        checklength={checklength}
        handleParentAdd={handleParentAdd}
        handleChildAdd={handleChildAdd}
        totalAmount={totalAmount}
        isEditable={isEditable}
        handleChildDebitCredit={handleChildDebitCredit}
        handleParentDebitCredit={handleParentDebitCredit}
        toggle={toggle}
        addDeleteOpen={addDeleteOpen}
        isOpen={isOpen}
      />
    </Fragment>
  )
}

export default SalesView
