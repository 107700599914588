import { Book, BookOpen } from "react-feather"
import checkTenantId from "@utility/helper/checkTenantId"

const isTenantMatch = checkTenantId()
export default isTenantMatch
  ? []
  : [
      {
        title: "CratoLedge",
        icon: Book,
        accessId: "Payables",
        children: [
          {
            id: "CL_General_Ledger",
            title: "General Ledger",
            icon: BookOpen,
            accessId: "CL_General_Ledger",
            accessRestricted: true,
            navLink: "/v2/ledger/general-ledger"
          }
        ]
      }
    ]
