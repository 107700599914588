// ** Icons Import
import { Book, DollarSign, ShoppingBag, TrendingUp } from "react-feather"

export default [
  {
    id: "analytics",
    title: "Analytics",
    icon: TrendingUp,
    children: [
      /* {
        id: 'expense-analytics',
        title: 'Expenses',
        icon: <ShoppingBag style={{ height: "10px", aspectRatio: "1" }} />,
        navLink: '/datatables/basic'
      },
      {
        id: 'revenue-analytics',
        title: 'Revenue',
        icon: <DollarSign style={{ height: "10px", aspectRatio: "1" }} />,
        navLink: '/datatables/advance'
      },
      {
        id: 'general-ledger',
        title: 'General Ledger',
        icon: <Book style={{ height: "10px", aspectRatio: "1" }} />,
        navLink: '/apps/todo'
      } */
    ]
  }
]
