import UrlService from "../../../services/urlConfig"

const service = new UrlService()

// ** Auth Endpoints
export default {
  loginEndpoint: service.getLoginApiUrl(),
  validateUser: `${service.getBaseAuthUrlOrg()}/user-api/validateUser`,
  registerEndpoint: "/jwt/register",
  refreshEndpoint: "/jwt/refresh-token",
  logoutEndpoint: `${service.getBaseAuthUrlOrg()}/user-api/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",

  // ** API headers
  authHeaders: {
    headers: {
      "Content-type": "application/x-www-form-urlencoded"
    }
  },
  clientHeaders: {
    headers: {
      "Content-type": "application/json"
    }
  }
}
