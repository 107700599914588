import axios from "axios"
import { accountSegmentUrl, getArUrl, isErpSyncedUrl, postNewSaleUrl, salesDetailsById, segmantDetailsUrl, segmentGlCodeUrl, updateSalesUrl } from "../../../../services/apiUrl"

export const getSalesData = (clientId, entityId, startDate, endDate) => {
    return axios.get(`${getArUrl}/sales/datefilter?clientId=${clientId}&clientEntityId=${entityId}&startDate=${startDate}&endDate=${endDate}`)
}

// ** Get Single Sales Details
export const getSalesDetailsById = (id) => axios.get(`${salesDetailsById}${id}`)

// ** Get GL Cods
export const getGlCode = (entityId, clientId) => axios.get(`${segmentGlCodeUrl}clientEntityId=${entityId}&clientId=${clientId}`)

// ** Update Sales
export const updateSales = (payload) => axios.post(updateSalesUrl, payload)

// ** Post New Sale to erp
export const postNewSale = (salesId) => axios.post(`${postNewSaleUrl}${salesId}`)

// ** Get Account Segment Details
export const getSegmentDetails = (clientId) => axios.get(`${segmantDetailsUrl}clientid=${clientId}`)

// ** Get Account Segment
export const getAccountSegment = (clientId) => axios.get(`${accountSegmentUrl}${clientId}/accountsegment`)

// ** Get Erp Sync
export const getErpSync = (clientId) => axios.get(`${isErpSyncedUrl}${clientId}`)