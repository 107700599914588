import TickMark from "./TickMark"

const errorList = [
  { label: "8 to 16 characters" },
  { label: "Atleast 1 uppercase character", value: /[A-Z]/ },
  { label: "Atleast 1 lowercase character", value: /[a-z]/ },
  { label: "Atleast 1 numerical character", value: /[0-9]/ },
  { label: "Atleast 1 special character", value: /[@#$%^&+=]/ }
]

export default function PasswordCriteria({ value: formValues }) {
  return (
    <div
      class="popover"
      style={{
        inset: "0px auto auto 0px",
        position: "unset",
        margin: "0 auto",
        width: "275px"
      }}
    >
      <div style={{ left: "5rem" }}>
        <h3 class="popover-header">Password must contain :</h3>
        <div class="popover-body">
          <ul style={{ width: "235px", paddingLeft: "1rem" }}>
            {errorList.map(({ value, label }, id) => (
              <li key={id} className="my-25" style={{ width: "235px" }}>
                <div
                  className="d-flex justify-content-between"
                  id={`password-${id}`}
                >
                  <span> {label}&nbsp;&nbsp; </span>
                  <TickMark active={isSatisfy(value, formValues)} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

function isSatisfy(value, formValue = "") {
  return value
    ? value.test(formValue)
    : formValue.length >= 8 && formValue.length <= 16
}
