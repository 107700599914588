// ** Reactstrap Imports
import { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
// ** Custom Components
import Avatar from "@components/avatar"
import { ShortListSkeleton } from "@custom-components/skeleton/Loader"
import { getTop5Vendor } from "../../Service/Service"
import { useSelector } from "react-redux"
import { decimalFormatter as DNF, formatedValue as F } from "@utils"
import CF from "@utility/helper/currencyFormatter"

const getDayFilterfn = (val) => {
  switch (val) {
    case "Last 7":
      return "lastWeek"
    case "Last Month":
      return "lastMonth"
    case "Last Quarter":
      return "lastQuarter"
    case "Last Year":
      return "lastYear"
    default:
      return ""
  }
}

const getDayFilterPercentagefn = (val) => {
  switch (val) {
    case "Last 7":
      return "lastWeekPercentage"
    case "Last Month":
      return "lastMonthPercentage"
    case "Last Quarter":
      return "lastQuarterPercentage"
    case "Last Year":
      return "lastYearPercentage"
    default:
      return ""
  }
}

const TopFiveVendors = () => {
  const { userData } = useSelector((state) => state.auth)
  const colors = [
    "#ea5455",
    "#4b4b4b",
    "#00cfe8",
    "#40dc65",
    "#82868b",
    "#28c76f",
    "#ff9f43"
  ]
  const [selectedRange, setSelectedRange] = useState("Last Month")
  const [selectedFilterData, setSelectedFilterData] = useState([])
  const [loading, setLoading] = useState(false)
  const defaultCurrency =
    useSelector((s) => s.auth.userData?.basicProfile?.defaultCurrency) ?? "USD"
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    getTop5Vendor(userData.clientId)
      .then((res) => {
        // console.log(res.data)
        setData(res.data)
        res.data.map((item) => {
          if (item.filter === selectedRange) {
            setSelectedFilterData(item.vendorFilterValue)
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
    return () => setData(null)
  }, [])

  const onChangeFilter = (selectedFilter) => {
    data.map((data) => {
      if (data.filter === selectedFilter) {
        setSelectedFilterData(data.vendorFilterValue)
      }
    })
  }

  const renderStates = () => {
    return (
      selectedFilterData &&
      selectedFilterData.map((item, index) => {
        return (
          <div key={item.vendorId} className="browser-states">
            <div className="d-flex">
              <Avatar
                initials
                className="me-1"
                content={`${item.vendorName
                  .charAt(0)
                  .toUpperCase()} ${item.vendorName.charAt(1).toUpperCase()}`}
              />
              <h6 className="align-self-center mb-0">{item.vendorName}</h6>
            </div>
            <div className="d-flex align-items-center">
              <div className="fw-bold text-body-heading me-1 text-nowrap">
                {F(6, CF(DNF(item[getDayFilterfn(selectedRange)])))}{" "}
                {defaultCurrency}
              </div>
              <div
                style={{
                  height: "40px",
                  width: "40px",
                  border: `2px solid ${colors[index]}`,
                  borderRadius: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <span style={{ fontSize: "11px" }}>
                  {item[getDayFilterPercentagefn(selectedRange)].toFixed(0)}%
                </span>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  return (
    <Card className="card-browser-states">
      <CardHeader className="pb-0">
        <CardTitle className="flex-center" tag="h4">
          Top 5 Vendors
        </CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
            id="vendors-date-btn"
          >
            {selectedRange}
          </DropdownToggle>
          <DropdownMenu end id="select-vendors-date">
            {data &&
              data.map((item, i) => (
                <DropdownItem
                  className="w-100"
                  key={i}
                  onClick={() => {
                    onChangeFilter(item.filter)
                    setSelectedRange(item.filter)
                  }}
                >
                  {item.filter}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      {loading ? (
        <div className="ms-1">
          <ShortListSkeleton />
        </div>
      ) : (
        <CardBody>
          {selectedFilterData.length ? (
            renderStates()
          ) : (
            <h3 className="text-center mt-1">
              No data available for the selected Period
            </h3>
          )}
        </CardBody>
      )}
    </Card>
  )
}

export default TopFiveVendors
