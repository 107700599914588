// ** React Imports
import { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"

// ** Layouts

import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"
import Layout from "@src/@core/layouts"

// ** Routes Imports
import Authentication from "./Authentication"
import ExpenseRoutes from "./Expense"
import AdminRoutes from "./AdminControls"
import SettingsRoutes from "./SettingsRoutes"
import RevenueRoutes from "./Revenue"
import CratomatchRoutes from "./Cratomatch"
import RepoeringsRoute from "./Reportings"
import CustomerPortalRoutes from "./CustomerProtal"
import MyBoxRoutes from "./MyBox"
import NewSettings from "./NewSettings"
import LedgerRoutes from "./Ledger"

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute"
import PrivateRoute from "@components/routes/PrivateRoute"
import { routeFilterCheck } from "./routesControler"

// ** Utils
import { isObjEmpty } from "@utils"
import Dashboard from "../../views/dashboard/dashboard-view/dashboard"
import ManageUsersRoute from "./ManageUser"
import BusinessUserRoute from "./BusinessUser"
import UploadDocuments from "../../views/dashboard/upload-documents"
import useJwt from "../../auth/jwt/useJwt"

const Env = process.env.REACT_APP_TYPE
// ** Document title
const TemplateTitle = "%s - Cratoflow UI 2.O"

// ** Default Route
const DefaultRoute = localStorage.getItem("activeCompany")
  ? "/v2/dashboard"
  : "/v2/company"

// ** BaseName
export const BaseRoute = "/v2"

// const Home = lazy(() => import('../../views/Home'))
const Home = lazy(() => import("../../views/expenses/vendor/vendor-list"))
const CompanyManagement = lazy(() =>
  import("../../views/authentication/company-management")
)
const Error = lazy(() => import("../../views/authentication/error/Error"))
const BetaRoutes = lazy(() => import("../../views/beta"))

// window.location.hostname.includes("login") || false
//     ? Authentication
//     : []
// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
    meta: {
      restricted: false,
      layout: "blank"
    }
  },
  {
    path: "/home",
    element: <CompanyManagement />,
    meta: {
      restricted: true
    }
  },
  {
    path: "/company",
    element: <CompanyManagement />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    meta: {
      restricted: true
    }
  },
  {
    path: "/uploadDocuments",
    element: <UploadDocuments />,
    meta: {
      restricted: true
    }
  },
  {
    path: "/beta",
    element: <BetaRoutes />,
    meta: {
      restricted: true
    }
  },
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank"
    }
  },
  ...Authentication,
  ...ExpenseRoutes,
  ...AdminRoutes,
  ...RevenueRoutes,
  ...CratomatchRoutes,
  ...SettingsRoutes,
  ...ManageUsersRoute,
  ...BusinessUserRoute,
  ...RepoeringsRoute,
  ...CustomerPortalRoutes,
  ...MyBoxRoutes,
  ...NewSettings,
  ...LedgerRoutes
]

const getAllRoutes = (routes) =>
  routes.filter(routeFilterCheck).map((route) => ({
    ...route,
    path: BaseRoute + route.path
  }))
const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const config = useJwt.jwtConfig

  const routes = getAllRoutes(
    !localStorage.getItem(config.storageTokenKeyName) && false
      ? Authentication
      : Routes
  )
  console.log(routes)
  if (!routes) return []
  return routes
    .filter(
      (route) =>
        route.meta.layout === layout ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
    )
    .map((route) => {
      // ** Adding Base Route to all paths
      // route.path = `${BaseRoute}${route.path}`

      const isBlank = route?.meta?.layout === "blank"
      const RouteTag = route?.meta?.publicRoute ? PublicRoute : PrivateRoute
      const Wrapper =
        isObjEmpty(route?.element?.props) && isBlank === false
          ? LayoutWrapper
          : Fragment
      const element = (
        <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
          <RouteTag route={route}>{route?.element}</RouteTag>
        </Wrapper>
      )

      return { ...route, element }
    })
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank"]

  const allRoutes = layouts.map((layoutItem) => {
    const children = MergeLayoutRoutes(layoutItem, defaultLayout)
    return {
      path: "/",
      element: <Layout layout={layoutItem || defaultLayout} />,
      children
    }
  })

  return allRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
