import { useState } from "react"
import toast from "@custom-components/toast.jsx"
import { Button, Col, Input, Label, Row } from "reactstrap"
import { putClientDetail } from "../../service/cratoPayService"

const ApprovalSetup = ({ clientData, setClientData }) => {
  // console.log(clientData)
  const [applyPayments, setApplyPayment] = useState(
    clientData?.cratoPaySetup?.enableApplyPaymentApproval
  )
  const [vendorPayments, setVendorPayment] = useState(
    clientData?.cratoPaySetup?.enableSinglePaymentApproval
  )
  const [batchPayments, setBatchPayment] = useState(
    clientData?.cratoPaySetup?.enableBatchPaymentApproval
  )

  const handleSubmit = () => {
    const cloneObj = _.cloneDeep(clientData)
    cloneObj.cratoPaySetup.enableApplyPaymentApproval = applyPayments
    cloneObj.cratoPaySetup.enableSinglePaymentApproval = vendorPayments
    cloneObj.cratoPaySetup.enableBatchPaymentApproval = batchPayments
    //console.log(cloneObj)
    setClientData(cloneObj)
    putClientDetail(cloneObj)
      .then((data) => {
        console.log(data.data)
        toast.success("Record Updated Successfully", {
          duration: 3000,
          position: "top-center"
        })
      })
      .catch((err) => {
        console.log(err)
        toast.error("Something went wrong", {
          duration: 3000,
          position: "top-center"
        })
      })
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="fw-bolder">Approval Routing</h5>
        <Button color="primary" onClick={handleSubmit} id="app-rout-save-btn">
          Save
        </Button>
      </div>
      <Row className="mt-2">
        <Col md={5}>
          <p>Enable Approval Routing for Apply Payments</p>
        </Col>
        <Col md={3}>
          <div className="form-check form-switch">
            <Input
              type="switch"
              name="isActive"
              checked={applyPayments}
              onChange={(e) => {
                setApplyPayment(e.target.checked)
              }}
              id="app-payments"
            />
            <Label className="form-check-label switch-icon-left switch-icon-right ps-1">
              {applyPayments ? "Yes" : "No"}
            </Label>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={5}>
          <p>Enable Approval Routing for single vendor Payments</p>
        </Col>
        <Col md={3}>
          <div className="form-check form-switch">
            <Input
              type="switch"
              name="isActive"
              checked={vendorPayments}
              onChange={(e) => {
                setVendorPayment(e.target.checked)
              }}
              id="vendor-payments"
            />
            <Label className="form-check-label switch-icon-left switch-icon-right ps-1">
              {vendorPayments ? "Yes" : "No"}
            </Label>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={5}>
          <p>Enable Approval Routing for Batch Payments</p>
        </Col>
        <Col md={3}>
          <div className="form-check form-switch">
            <Input
              type="switch"
              name="isActive"
              checked={batchPayments}
              onChange={(e) => {
                setBatchPayment(e.target.checked)
              }}
              id="batch-payments"
            />
            <Label className="form-check-label switch-icon-left switch-icon-right ps-1">
              {batchPayments ? "Yes" : "No"}
            </Label>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ApprovalSetup
