import { lazy } from "react"

const BusinessUser = lazy(() =>
  import("../../views/business-user/components/bu-listView")
)
const InvoiceEdit = lazy(() =>
  import("../../views/expenses/invoice-edit/index")
)
const VendorView = lazy(() =>
  import("../../views/expenses/vendor/vendor-view/index")
)
const CustomerView = lazy(() =>
  import("../../views/revenue/customer/customer-list/customer-view/index")
)
const TrackPayment = lazy(() =>
  import("../../views/expenses/cratopay/track-payment/viewbyid")
)
const PurchaseOrderView = lazy(() => import("../../views/expenses/PO/view"))
const UploadLinetems = lazy(() =>
  import("../../views/expenses/invoice-edit/components/upload-line-items")
)

const BASE_ROUTE = "/approver"
const BusinessUserRoute = [
  {
    path: `${BASE_ROUTE}/dashboard`,
    element: <BusinessUser />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  },
  {
    path: `${BASE_ROUTE}/ap/invoice/view/:urn`,
    element: <InvoiceEdit />,
    meta: {
      restricted: true,
      layout: "blank"
    }
  },
  {
    path: `${BASE_ROUTE}/archived`,
    element: <BusinessUser />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  },
  {
    path: `${BASE_ROUTE}/submitted-by-me`,
    element: <BusinessUser />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  },
  {
    path: `${BASE_ROUTE}/vendor/view/:id`,
    element: <VendorView />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  },
  {
    path: `${BASE_ROUTE}/customer/view/:id`,
    element: <CustomerView />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  },
  {
    path: `${BASE_ROUTE}/ap/cratopay/track-payment/:id`,
    element: <TrackPayment />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  },
  {
    path: `${BASE_ROUTE}/upload-documents`,
    element: <BusinessUser />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  },
  {
    path: `${BASE_ROUTE}/purchase-order/:mode`,
    element: <PurchaseOrderView />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  },
  {
    path: `${BASE_ROUTE}/invoice/edit/upload-line-items`,
    element: <UploadLinetems />,
    meta: {
      restricted: true,
      menuHidden: true
    }
  }
]

export default BusinessUserRoute
