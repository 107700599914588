/* eslint-disable no-unused-vars */
// ** React Imports
import { Row, Col } from "reactstrap"
import { useContext } from "react"
// ThemeConext
import { ThemeColors } from "@src/utility/context/ThemeColors"
// ** Card Imports
import FileUplod from "./FileUplod"
import TopFiveExpenses from "./components/TopFiveExpenses"
import TopFiveExceptionTypes from "./components/TopFiveExceptionTypes"
import TopFiveVendors from "./components/TopFiveVendors"
import PerformanceMatrix from "./components/PerformanceMatrix"
import BillProcessing from "./components/BillProcessing"
import BillPayments from "./components/BillPayments"
import { useSelector } from "react-redux"
// ** Third Party Components
import SwiperCore, {
  Grid,
  Lazy,
  Virtual,
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
  EffectCube,
  EffectCoverflow
} from "swiper"
import "@styles/react/libs/swiper/swiper.scss"
import {
  CreditSkeleton,
  DashSkeleton
} from "../../../custom-components/skeleton/skeleton"

// ** Service Imoprt
import DeductionWidget from "../widgets/ar/DeductionWidget"
import DepositWidget from "../widgets/ar/DepositWidget"
import UnAppliedDeposits from "../widgets/ar/UnAppliedDeposits"
import CashInWidget from "../widgets/ar/CashInWidget"
import TopFiveCustomersWidget from "../widgets/ar/TopFiveCustomersWidget"
import RemitanceWidget from "../widgets/ar/RemitanceWidget"

// ** Init Swiper Functions
SwiperCore.use([
  Navigation,
  Grid,
  Pagination,
  EffectFade,
  EffectCube,
  EffectCoverflow,
  Autoplay,
  Lazy,
  Virtual
])
import CashFlowTrend from "../widgets/banking/bank-posting/CashFlowTrend"
import BankAccountSummary from "../widgets/banking/bank-posting/BankAccountSummary"
import AgedCashReceiptsSummary from "../widgets/ar/cash-app/AgedCashReceiptsSummary"
import CashReceiptSummary from "../widgets/ar/cash-app/CashReceiptSummary"

import { Navigate } from "react-router-dom"
import UploadChart from "./components/UploadChart"
import "../styles.scss"

const Dashboard = () => {
  const { colors } = useContext(ThemeColors)
  const { userData } = useSelector((state) => state.auth)

  const tenantAccess = userData.tenantAccess.tenantAccessObjects
    .reduce(
      (p, { subModules, module }) => [
        ...p,
        ...subModules,
        { name: module, active: subModules.some(({ active }) => active) }
      ],
      []
    )
    .reduce((p, { name, active }) => ({ ...p, [name]: active }), {})
  console.log(tenantAccess)

  if (userData.tenantAccess.productType === "SAAS_METRICES") {
    return <Navigate to="/v2/saas-dashboard" replace={true} />
  }

  // ** vars
  const trackBgColor = "#e9ecef"

  const widgets = [
    {
      Component: BankAccountSummary,
      module: "Banking",
      props: { primary: colors.primary.main },
      span: 1.5
    },
    {
      Component: CashFlowTrend,
      module: "Banking",
      props: { primary: colors.primary.main },
      span: 1.5
    },
    {
      Component: BillPayments,
      module: "Expenses",
      props: { primary: colors.primary.main }
    },
    {
      Component: BillProcessing,
      module: "Expenses",
      props: { primary: colors.primary.main, danger: colors.danger.main }
    },
    {
      Component: PerformanceMatrix,
      module: "Expenses"
    },
    {
      Component: TopFiveVendors,
      module: "Expenses",
      props: { colors, trackBgColor }
    },
    {
      Component: TopFiveExceptionTypes,
      module: "Expenses",
      props: { colors, trackBgColor }
    },
    {
      Component: TopFiveExpenses,
      module: "Expenses",
      props: {
        tooltipShadow: "rgba(0, 0, 0, 0.25)",
        successColorShade: "#28dac6",
        warningLightColor: "#FDAC34",
        primary: colors.primary.main
      }
    },
    {
      Component: AgedCashReceiptsSummary,
      module: "Cash_Application",
      props: { primary: colors.primary.main },
      span: 1.5
    },
    {
      Component: CashReceiptSummary,
      module: "Cash_Application",
      props: { primary: colors.primary.main },
      span: 1.5
    },

    {
      Component: DeductionWidget,
      module: "AR"
    },
    {
      Component: DepositWidget,
      module: "AR"
    },
    {
      Component: UnAppliedDeposits,
      module: "AR"
    },
    {
      Component: CashInWidget,
      module: "AR"
    },
    {
      Component: TopFiveCustomersWidget,
      module: "AR"
    },
    {
      Component: RemitanceWidget,
      module: "AR"
    }
  ]
    .filter(({ module }) => module != "AR")
    .filter(({ module }) => tenantAccess[module])

  return (
    <div id="dashboard-ecommerce">
      <div>
        <Row className="match-height">
          <Col xl="8" md="6" xs="12">
            <UploadChart />
          </Col>

          <Col xl="4" md="6" xs="12">
            <FileUplod tenantAccess={tenantAccess} />
          </Col>
        </Row>

        <Row className="match-height wiggle-container">
          {widgets.map(({ Component, props, span = 1 }, index) => (
            <Col
              xl={span * 4}
              md="6"
              xs="12"
              style={{
                position: "relative",
                animationDelay: ` -.${0.5 * (index + 1)}s`,
                animationDuration: `.${3}s`
              }}
              className=""
            >
              <Component {...props} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default Dashboard
