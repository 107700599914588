import React from "react"
import { toCapitalize } from "@utils"
import { Badge } from "reactstrap"

const BusinessRegistrationView = ({
  businessRegDetails: bankAccountViewData
}) => {
  const businessTypeOption = [
    { label: "Sole proprietorship", value: "soleProprietorship" },
    { label: "Corporation", value: "corporation" },
    { label: "LLCs/Partnerships", value: "llc" },
    {
      label: "Publicly traded corporations",
      value: "publiclyTradedCorporation"
    },
    { label: "Non-profits", value: "nonProfit" },
    {
      label: "Unincorporated association",
      value: "unincorporatedAssociation"
    },
    { label: "Trust", value: "trust" }
  ]

  const taxIdType = [
    { label: "Ein", value: "ein" },
    { label: "Tin", value: "tin" }
  ]
  return (
    <div>
      <div className="d-flex mb-2 justify-content-start align-items-center">
        <h3>Business Registration </h3>
        {bankAccountViewData?.status && (
          <h4 className="ms-1">
            <Badge pill color="primary">
              {toCapitalize(
                bankAccountViewData?.status == "created"
                  ? "pending verification"
                  : bankAccountViewData?.status
              )}
            </Badge>
          </h4>
        )}
      </div>
      <Wrapper
        label={"Legal Name"}
        value={bankAccountViewData?.doingBusinessAs}
      />
      <Wrapper
        label={"Business Name"}
        value={bankAccountViewData?.businessName}
      />
      <Wrapper
        label={"Business Type"}
        value={
          businessTypeOption.find(
            (option) => option.value == bankAccountViewData?.businessType
          )?.label ?? "-"
        }
      />
      <Wrapper label={"Tax Id"} value={bankAccountViewData?.tax_id} />
      <Wrapper
        label={"Tax Id Type"}
        value={
          taxIdType.find(
            (option) => option.value == bankAccountViewData?.taxIdType
          )?.label ?? "-"
        }
      />

      <Wrapper
        label={"Account Holder Name"}
        value={bankAccountViewData?.accountHolderName ?? "-"}
      />
      <Wrapper
        label={"Incorporation Date"}
        value={bankAccountViewData?.incorporatedDate ?? "-"}
      />
      <Wrapper
        label={"Legal Address"}
        value={
          <>
            {" "}
            {bankAccountViewData?.address1 ?? "-"},<br />{" "}
            {bankAccountViewData?.city ?? "-"},
            {bankAccountViewData?.state ?? "-"},<br />{" "}
            {bankAccountViewData?.countr ?? "-"},{" "}
            {bankAccountViewData?.postalCode ?? "-"}
          </>
        }
      />
    </div>
  )
}

export default BusinessRegistrationView

const Wrapper = ({ label, value }) => {
  return (
    <div className="d-flex gap-2 justify-content-start  ">
      <p className="mb-2" style={{ width: "15rem" }}>
        {label}
      </p>
      <p className="fw-bold">{value}</p>
    </div>
  )
}

// const bankAccountViewData = {
//   phone: "+19492925079",
//   address1: "230 5th Avenue",
//   address2: "",
//   doingBusinessAs: "Acme",
//   businessName: "Acme",
//   businessType: "corporation",
//   city: "New York",
//   ein: "12-3121212",
//   postalCode: "10001",
//   state: "NY",
//   country: "India",
//   firstName: "Crato",
//   lastName: "Admin",
//   adminEmail: "admin@demo.com",
//   dateOfBirth: "1989-06-07",
//   clientId: "10022",
//   clientCode: "ACME",
//   ipAddress: "192.0.2.0",
//   email: "admin@demo.com",
//   taxIdType: "ein",
//   incorporatedDate: "2002-10-11",
//   tax_id: "123456799"
// }
