import { lazy } from "react"

const GeneralLedger = lazy(() =>
  import("../../views/ledger/general-ledger/index")
)

const BASE_ROUTE = "/ledger/"
const LedgerRoutes = [
  {
    path: `${BASE_ROUTE}general-ledger`,
    element: <GeneralLedger />
  }
].map((r) => ({
  ...r,
  meta: {
    restricted: true
  }
}))

export default LedgerRoutes
