import React from "react"
import "./styles.scss"
import { ReactComponent as CratoLoader } from "@src/assets/images/svg/crato-loader.svg"

const SplashScreen = () => {
  return (
    <div id="splashWrapper">
      <div className="context">
        <div id="splash-screen">
          <div className="loadWrapper">
            <CratoLoader className="splashLogo" />
            <div className="loader" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen
