class UrlService {
  // Environment based API base urls
  baseUrl = process.env.REACT_APP_BASE_URL
  baseAuthUrl = process.env.REACT_APP_BASE_AUTH_URL
  erpBaseUrl = process.env.REACT_APP_ERP_BASE_URL
  posUrl = process.env.REACT_APP_POS_URL
  qbBaseUrl = process.env.REACT_APP_QB_BASE_URL

  // All API paths
  urlList = {
    loginApiUrl: `${this.baseUrl}/cratoauth/oauth/token`,
    baseUrlAuth: this.baseAuthUrl,
    baseUrlAuthOrg: `${this.baseUrl}/cratoauth`,
    clientUrl: `${this.baseUrl}/clientapi`,
    entityUrl: `${this.baseUrl}/entityapi`,
    vendorUrl: `${this.baseUrl}/vendorapi`,
    aiParsingUrl: `${this.baseUrl}/aiparsingapi`,
    accountCodingUrl: `${this.baseUrl}/accountcodingapi`,
    notificationUrl: `${this.baseUrl}/notificationapi`,
    graphQlUrl: `${this.baseUrl}/cratographql`,
    customerUrl: `${this.baseUrl}/customer`,
    cratomatchUrl: `${this.baseUrl}/cratomatchapi`,
    arUrl: `${this.baseUrl}/ar`,
    adminUrl: `${this.baseUrl}/adminapi`,
    filterUrl: `${this.baseUrl}/filterapi`,
    cratoPayUrl: `${this.baseUrl}/cratopay`,
    cratoPayApiUrl: `${this.baseUrl}/cratopayapi`,
    documentUrl: `${this.baseUrl}/adminapi/documentinfo`,
    emailTemplateUrl: `${this.baseUrl}/emailtemplateapi`,
    invoiceUrl: `${this.baseUrl}/invoiceapi/invoice`,
    loginUrl: window.location.origin.endsWith(".com")
      ? "https://login.cratoflow.com/"
      : window.location.origin.endsWith(".net")
      ? "https://login.cratoflow.net/"
      : "https://login.cratoflow.org/",
    googleMapsUrl:
      "https://maps.googleapis.com/maps/api/place/findplacefromtext/json",
    autoCompleteUrl: "https://us-autocomplete-pro.api.smartystreets.com/lookup",
    streetUrl: "https://us-street.api.smartystreets.com/street-address",
    ticketUrl: `${this.baseUrl}/ticketapi`,
    qbUrl: this.qbBaseUrl,
    auditTrail: `${this.baseUrl}/audittrailapi`,
    aireporting: `${this.baseUrl}/aireportingapi`,
    reportApi: `${this.baseUrl}/reportapi`
  }

  erpRoutes = {
    QUICKBOOKS_ONLINE: "/qb/",
    NETSUITE: "/erp/",
    SAGE50_ONPREMISE_USA: "/sage50/",
    SAP_ONPREMISE_S4HANA: "/erp/",
    SOFTLEDGER: "/erp/"
  }

  // API paths Getter methods
  getErpRoutes(integrationType) {
    return this.erpRoutes[integrationType]
  }
  getLoginApiUrl = () => {
    return this.urlList["loginApiUrl"]
  }
  getBaseAuthUrl = () => {
    return this.urlList[`baseUrlAuth`]
  }
  getBaseAuthUrlOrg = () => {
    return this.urlList[`baseUrlAuthOrg`]
  }
  getInvoiceUrl = () => {
    return this.urlList[`invoiceUrl`]
  }

  getClientUrl = () => {
    return this.urlList[`clientUrl`]
  }

  getEntityUrl = () => {
    return this.urlList[`entityUrl`]
  }
  getVendorUrl = () => {
    return this.urlList[`vendorUrl`]
  }
  getNotificationUrl = () => {
    return this.urlList[`notificationUrl`]
  }

  getGraphQlUrl = () => {
    return this.urlList[`graphQlUrl`]
  }

  getCustomerUrl = () => {
    return this.urlList[`customerUrl`]
  }
  getCratomatchUrl = () => {
    return this.urlList["cratomatchUrl"]
  }
  getArUrl = () => {
    return this.urlList[`arUrl`]
  }
  getAdminUrl = () => {
    return this.urlList[`adminUrl`]
  }
  getFilterUrl() {
    return this.urlList["filterUrl"]
  }
  getCratoPayUrl = () => {
    return this.urlList["cratoPayUrl"]
  }
  getAccountCodingUrl = () => {
    return this.urlList[`accountCodingUrl`]
  }
  getDocumentUrl = () => {
    return this.urlList[`documentUrl`]
  }
  getEmailTemplateUrl = () => {
    return this.urlList[`emailTemplateUrl`]
  }
  getLoginUrl = () => {
    return this.urlList[`loginUrl`]
  }
  getGoogleMapsUrl = () => {
    return this.urlList[`googleMapsUrl`]
  }
  getAutoCompleteUrl = () => {
    return this.urlList["autoCompleteUrl"]
  }
  getStreetUrl = () => {
    return this.urlList["streetUrl"]
  }
  getTicketUrl = () => {
    return this.urlList["ticketUrl"]
  }
  getErpBaseUrl = () => {
    return this.erpBaseUrl
  }
  getPosUrl = () => {
    return this.posUrl
  }
  getQbUrl = () => {
    return this.urlList["qbUrl"]
  }
  getAudittrailUrl = () => {
    return this.urlList["auditTrail"]
  }
  getCratoPayApiUrl = () => {
    return this.urlList["cratoPayApiUrl"]
  }
  getCratoAireporting = () => this.urlList["aireporting"]
  getReportApiUrl = () => this.urlList["reportApi"]
  getAiParsingUrl = () => this.urlList["aiParsingUrl"]
}

export default UrlService
