// ** Imports
import { lazy } from "react"

const MyBox = lazy(() => import("../../views/my-box/index"))
const BusinessUser = lazy(() =>
  import("../../views/business-user/components/bu-listView")
)

const MyBoxRoutes = [
  {
    path: "/my-box",
    element: <MyBox />,
    meta: {
      restricted: true
      // accessRestricted: true
    }
  },
  {
    path: "/my-box/approver",
    element: <BusinessUser />,
    meta: {
      restricted: true
      // accessRestricted: true
    }
  },
  {
    path: "/my-box/archived",
    element: <BusinessUser />,
    meta: {
      restricted: true
      // accessRestricted: true
    }
  },
  {
    path: "/my-box/submitted-by-me",
    element: <BusinessUser />,
    meta: {
      restricted: true
      // accessRestricted: true
    }
  },
  {
    path: "/my-box/upload-documents",
    element: <BusinessUser />,
    meta: {
      restricted: true
      // accessRestricted: true
    }
  }
]

export default MyBoxRoutes
