// ** React Imports
import { lazy } from "react"

const PPIntegration = lazy(() =>
  import("../../views/settings/integration/pp-integration")
)
const EditRule = lazy(() =>
  import("../../views/settings/cratobot/components/edit-rule")
)
const AstraRedirect = lazy(() =>
  import(
    "../../views/expenses/cratopay/configuration/components/business-registration/components/AstraRedirect"
  )
)
const IntegrationPage = lazy(() =>
  import("../../views/settings/integration/accounting/index")
)
const AccountingList = lazy(() =>
  import("../../views/settings/integration/accounting/accounting-view")
)
const CorporateStructure = lazy(() =>
  import("../../views/settings/manage-company/corporate-structure")
)
const NewCorp = lazy(() =>
  import("../../views/settings/master-data/corporate-structure")
)

const ChartOfAccountsSettings = lazy(() =>
  import("../../views/settings/master-data")
)
const RevenueSettings = lazy(() => import("../../views/settings/revenue"))
const BankingRulesList = lazy(() =>
  import("../../views/settings/banking/banking-rules/banking-rules-list/index")
)
const BankingRulesView = lazy(() =>
  import("../../views/settings/banking/banking-rules/banking-rules-view/index")
)
const AccountingCalender = lazy(() =>
  import("../../views/settings/master-data/accounting-calender")
)
// ** CONFIG_CRATOBOT
const CratobotMain = lazy(() => import("../../views/settings/cratobot"))
const CratobotAction = lazy(() =>
  import("../../views/settings/cratobot/components/rules/Index")
)

const BillProcessing = lazy(() =>
  import("../../views/settings/expenses/bill-processing")
)
const Vendorsetup = lazy(() =>
  import("../../views/settings/expenses/vendor-setup")
)
const CropStructure = lazy(() =>
  import("../../views/settings/master-data/chart-of-accounts")
)
const BusinessProfile = lazy(() =>
  import("../../views/settings/manage-company/business-profile/index")
)
const BusinessProfileEdit = lazy(() =>
  import("../../views/settings/manage-company/business-profile/Edit")
)
const BankingIntegration = lazy(() =>
  import("../../views/settings/integration/banking/index")
)
const POSSystem = lazy(() =>
  import("../../views/settings/integration/pos-system/index")
)
const POSRedirect = lazy(() =>
  import("../../views/settings/integration/pos-system/POSRedirect")
)
const ApplyDepositConfig = lazy(() =>
  import("../../views/settings/revenue/cash-application/apply-deposit")
)
const ErpRedirect = lazy(() =>
  import(
    "../../views/settings/integration/accounting/components/ErpRedirectPage"
  )
)

const RevenueInvoiceConfig = lazy(() =>
  import("../../views/settings/revenue/invoicing/index")
)

const RevenueCustomerConfig = lazy(() =>
  import("../../views/settings/revenue/customer-config/index")
)
const CodingRules = lazy(() =>
  import("../../views/settings/expenses/coding-rules")
)
const CratopayConfiguration = lazy(() =>
  import("../../views/expenses/cratopay/configuration")
)
const RemittanceNoticeSample = lazy(() =>
  import(
    "../../views/expenses/cratopay/configuration/components/RemittanceNoticeSample"
  )
)
const CustomizeTemplate = lazy(() =>
  import("../../views/settings/revenue/customize-template")
)
const Sales = lazy(() => import("../../views/settings/revenue/sales/index"))

const GeneralLedger = lazy(() =>
  import("../../views/settings/ledger/general-ledger/index")
)

// ** Bank Recon
const BankReconCreate = lazy(() =>
  import("../../views/settings/banking/bank-recon/create/index")
)
const BankRecornRulesList = lazy(() =>
  import("../../views/settings/banking/bank-recon/list-view")
)

const ExpenseReminder = lazy(() =>
  import("../../views/settings/expenses/expense-reminder")
)
const DeductionSettings = lazy(() =>
  import("../../views/settings/cratobot/deduction-settings")
)
const AddEditDeduction = lazy(() =>
  import("../../views/settings/cratobot/deduction-settings/add-edit-deduction")
)

const POSettings = lazy(() => import("../../views/settings/PO/index"))

// ** Role Permission List
import { permissions } from "../../views/role-management/store/permissionList"

const permissionList = Object.keys(permissions)
const configurationList = Object.keys(permissions.Configuration)

const SettingsRoutes = [
  //master data
  {
    accessMap: "Con_MD_COA",
    path: "/ap/settings/master-data/chart-of-accounts",
    element: <ChartOfAccountsSettings />,
    menuGroup: permissionList[0],
    accessId: configurationList[0]
  },
  {
    accessMap: "Con_MD_BP",
    path: "/ap/settings/manageCompany/businessProfile/edit",
    element: <BusinessProfileEdit />,
    menuGroup: permissionList[0],
    accessId: configurationList[0]
  },
  //manage company
  {
    accessMap: "Con_MD_BP",
    path: "/ap/settings/manageCompany/businessProfile",
    element: <BusinessProfile />,
    menuGroup: permissionList[0],
    accessId: configurationList[0]
  },
  {
    accessMap: "Con_MD_CS",
    path: "/ap/settings/manageCompany/corporateStructure",
    element: <CorporateStructure />,
    menuGroup: permissionList[0],
    accessId: configurationList[0]
  },
  {
    accessMap: "Con_MD_CS",
    path: "/settings/master-data/corporate-structure",
    element: <NewCorp />,
    menuGroup: permissionList[0],
    accessId: configurationList[0]
  },
  //integrations
  {
    accessMap: "Con_Int_Acc",
    path: "/ap/settings/integration/accountingsystem",
    element: <IntegrationPage />,
    menuGroup: permissionList[0],
    accessId: configurationList[2]
  },
  {
    accessMap: "Con_Int_Acc",
    path: "/beta/ap/settings/integration/accountingsystem",
    element: <IntegrationPage />,
    menuGroup: permissionList[0],
    accessId: configurationList[2]
  },
  {
    accessMap: "Con_Int_Bank",
    path: "/cratomatch/settings/banking-integration",
    element: <BankingIntegration />,
    menuGroup: permissionList[0],
    accessId: configurationList[2]
  },
  {
    accessMap: "Con_Int_POS",
    path: "/cratomatch/settings/pos-system",
    element: <POSSystem />,
    menuGroup: permissionList[0],
    accessId: configurationList[2]
  },
  {
    accessMap: "Con_Int_POS",

    // id: "Con_Int_POS",
    path: "/pos-redirect",
    element: <POSRedirect />,
    menuGroup: permissionList[0],
    accessId: configurationList[2]
  },
  //Expense
  {
    accessMap: "Con_Exp_BP",
    path: "/ap/settings/expenses/billprocessing",
    element: <BillProcessing />,
    menuGroup: permissionList[0],
    accessId: configurationList[3]
  },
  {
    accessMap: "Con_Exp_VS",
    path: "/ap/settings/expenses/vendorsetup",
    element: <Vendorsetup />,
    menuGroup: permissionList[0],
    accessId: configurationList[3]
  },
  {
    accessMap: "Con_Exp_PO",
    element: <POSettings />,
    path: "/settings/expense/po",
    meta: { restricted: true }
  },
  //->cratobot
  {
    accessMap: "Con_CratoBot_BPR",
    path: "/ap/settings/cratobot",
    element: <CratobotMain />,
    menuGroup: permissionList[0],
    accessId: configurationList[0]
  },
  {
    accessMap: "Con_CratoBot_BPR",
    path: "/ap/settings/cratobot/rules",
    element: <CratobotAction />,
    menuGroup: permissionList[0],
    accessId: configurationList[0]
  },
  {
    accessMap: "Con_CratoBot_BPR",
    path: "/ap/settings/cratobot/rule/:id/edit",
    element: <EditRule />,
    menuGroup: permissionList[0],
    accessId: configurationList[0]
  },
  // =>
  {
    accessMap: "Con_Exp_CP",
    path: "/ap/cratopay/configuration",
    element: <CratopayConfiguration />,
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Con_Exp_CP",
    path: "/ap/cratopay/configuration/remittanceNoticeSample",
    element: <RemittanceNoticeSample />,
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Con_CratoBot_CR",
    path: "/ap/coding-rules",
    element: <CodingRules />,
    meta: {
      restricted: true
    }
  },
  //revenue
  {
    accessMap: "Con_Rev_Inv",
    path: "/ap/settings/revenue/invoicing",
    element: <RevenueInvoiceConfig />,
    menuGroup: permissionList[0],
    accessId: configurationList[4]
  },
  {
    accessMap: "Con_Rev_CC",
    path: "/ap/settings/revenue/customersetup",
    element: <RevenueCustomerConfig />,
    menuGroup: permissionList[0],
    accessId: configurationList[4]
  },
  {
    accessMap: "Con_Rev_Sales",
    path: "/ap/settings/revenue/customize-template",
    element: <Sales />,
    menuGroup: permissionList[0],
    accessId: configurationList[2],
    meta: {
      restricted: true,
      accessRestricted: true,
      className: "email-application"
    }
  },
  {
    accessMap: "Con_Rev_Sales",
    path: "/ap/settings/revenue/customize-template2",
    element: <CustomizeTemplate />,
    menuGroup: permissionList[0],
    accessId: configurationList[2]
  },
  {
    accessMap: "Con_Rev_CA",
    path: "/settings/revenue/cash-app/apply-deposit",
    element: <ApplyDepositConfig />,
    menuGroup: permissionList[4],
    accessId: permissionList[4]
  },
  //Banking
  {
    accessMap: "Con_CratoBot_Post_Rule",
    path: "/settings/banking/banking-rules/list",
    element: <BankingRulesList />,
    menuGroup: permissionList[4],
    accessId: permissionList[4]
  },
  {
    accessMap: "Con_CratoBot_Post_Rule",
    path: "/settings/banking/banking-rules/view/:id",
    element: <BankingRulesView />,
    menuGroup: permissionList[4],
    accessId: permissionList[4]
  },
  {
    accessMap: "Con_CratoBot_Post_Rule",
    path: "/settings/banking/banking-rules/create-rule",
    element: <BankingRulesView />,
    menuGroup: permissionList[4],
    accessId: permissionList[4]
  },
  {
    path: "/settings/integration/accountingsystem/redirect",
    element: <ErpRedirect />,
    menuGroup: permissionList[0],
    accessId: configurationList[2]
  },
  {
    path: "/settings/accountingList",
    element: <AccountingList />,
    menuGroup: permissionList[0],
    accessId: configurationList[2]
  },
  {
    accessMap: "Con_CL_GL",
    path: "/settings/ledger/general-ledger",
    element: <GeneralLedger />
  }, // => Bank recon
  {
    menuGroup: permissionList[0],
    accessId: configurationList[2],
    accessMap: "Con_CratoBot_Recon_Rule",
    path: "/settings/banking/bank-recon/:page",
    element: <BankReconCreate />
  },
  // {
  //   menuGroup: permissionList[0],
  //   accessId: configurationList[2],
  //   accessMap: "Con_CratoBot_Post_Rule",
  //   path: "/settings/banking/bank-recon/:id",
  //   element: <BankReconCreate />
  // },
  {
    accessMap: "Con_CratoBot_Recon_Rule",
    menuGroup: permissionList[0],
    accessId: configurationList[2],
    path: "/settings/banking/bank-recon/list",
    element: <BankRecornRulesList />
  },
  {
    accessMap: "Con_Exp_BP",
    menuGroup: permissionList[0],
    accessId: configurationList[2],
    path: "/ap/settings/expenses/reminder",
    element: <ExpenseReminder />
  },
  {
    accessMap: "Con_Exp_CP",
    element: <AstraRedirect />,
    path: "/settings/cratopay/configuration/astra-redirect",
    meta: {
      restricted: true
    }
  },
  {
    element: <AccountingCalender />,
    path: "/ap/settings/master-data/accounting-calender",
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Rev_CA_Deductions",
    element: <DeductionSettings />,
    path: "/ar/settings/cratobot/deductions",
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Rev_CA_Deductions",
    element: <AddEditDeduction />,
    path: "/ar/settings/cratobot/deductions/:id",
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Con_Int_Acc",
    element: <PPIntegration />,
    path: "/settings/integration/pp-integration",
    meta: {
      restricted: true
    }
  }
  // {
  //   accessMap: "Con_CratoBot_Post_Rule",
  //   menuGroup: permissionList[0],
  //   accessId: configurationList[2],
  //   path: "/settings/banking/bank-recon/list",
  //   element: <BankRecornRulesList />
  // }
].map((s) => ({
  ...s,
  meta: {
    restricted: true,
    accessRestricted: true
  }
}))

export default SettingsRoutes
