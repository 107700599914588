import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { User, Power, Settings, Box, Pocket } from "react-feather"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Dropdown,
  Badge
} from "reactstrap"
import Avatar from "@components/avatar"
import useJwt from "@src/auth/jwt/useJwt"
import { handleLogout } from "@store/authentication"
import { deleteCookies } from "@utils"
import defaultAvatar from "@src/assets/images/portrait/small/1.png"
import "./styles.scss"
import { useSocket } from "../../../../utility/context/WSProvider"
import { useState } from "react"
import Notification from "./Notification"

const Env = process.env.REACT_APP_TYPE
const domain = process.env.REACT_APP_DOMAIN

const UserDropdown = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation().pathname?.split("/")
  const { closeSocket } = useSocket()
  const store = useSelector((state) => state)

  const isCompanySelected = "currentRoleAccess" in store?.auth?.userData ?? {}
  const isBusinessUser =
    store?.auth?.userData?.roles?.includes("CRATO_APPROVER") ?? true
  const configValues =
    store?.auth?.userData?.currentRoleAccess?.accessRestriction ?? {}
  const isSaasMatrices =
    store?.auth?.userData?.tenantAccess?.productType === "SAAS_METRICES"
  const configValueArr = Object.entries(configValues)
  const settingsAccessCheck = configValueArr
    .filter((value) => value[0].includes("Con_"))
    .map((value) => value[1].access)
    .every((value) => value.includes("HIDE"))

  const { firstName, lastName } = store?.auth?.userData
  const roleNames = store?.auth?.userData?.currentRoleAccess?.roleName ?? ""
  const isValidUrl = !location.some((value) =>
    ["company", "role-management", "manage-users"].includes(value)
  )

  const [showNotification, setShowNotification] = useState(false)

  const navigateRoute = () => {
    const route = "/v2/login"
    navigate(route)
    return
    if (Env === "dev") {
    } else {
      const url = `https://login.${domain}${route}`
      window.location.href = url
    }
  }

  //  ** logout from session
  const logout = () => {
    // e.preventDefault()
    console.log("logged out from session")

    useJwt
      .logout()
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
    deleteCookies("localData")
    localStorage.clear()
    closeSocket()

    dispatch(handleLogout())
    navigateRoute()
  }
  const isShowNotificationWrapper = () => {
    setShowNotification(!showNotification)
  }

  return (
    <>
      {!isSaasMatrices && isCompanySelected && !isBusinessUser && isValidUrl && (
        <>
          {["dev", "Test", "develop"].includes(Env) && (
            <Button
              color="flat-danger"
              className="transition-button"
              onClick={() => navigate(`/v2/beta`)}
              id="beta-btn"
            >
              <span>Beta</span>
              <Pocket size={20} />
            </Button>
          )}
          {!settingsAccessCheck && (
            <Button
              color="flat-success"
              className="transition-button"
              onClick={() => navigate(`/v2/settings`)}
              id="settings-btn"
            >
              <span>Settings</span>
              <Settings size={20} />
            </Button>
          )}
          {!isSaasMatrices && (
            <Button
              className="transition-button"
              color="flat-success"
              onClick={() => navigate("/v2/my-box")}
              id="my-box-btn"
            >
              <span>My Box</span>
              <Box size={20} />
            </Button>
          )}
        </>
      )}
      {isCompanySelected && isValidUrl && (
        <Dropdown isOpen={showNotification} toggle={isShowNotificationWrapper}>
          <Notification />
        </Dropdown>
      )}

      <UncontrolledDropdown
        tag="li"
        className="dropdown-user nav-item"
        style={{ fontSize: ".9rem" }}
      >
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link dropdown-user-link"
          onClick={(e) => e.preventDefault()}
          id="user-profile"
        >
          <Avatar
            img={defaultAvatar}
            imgHeight="30"
            imgWidth="30"
            status="online"
          />
        </DropdownToggle>
        <DropdownMenu end style={{ width: "15rem", minHeight: "15rem" }}>
          <div>
            <div className="d-flex align-items-center mt-1 justify-content-center">
              <Avatar
                img={defaultAvatar}
                imgHeight="60"
                imgWidth="60"
                status="online"
              />
            </div>
            <div>
              {(firstName || lastName) && (
                <div className="text-center my-50 fs-4 fw-bold">
                  {firstName || ""} {lastName || ""}
                </div>
              )}
            </div>
            {isValidUrl && <div className="text-center">{roleNames}</div>}
            <hr className="border-bottom border-3" />
            <DropdownItem
              className="w-100"
              onClick={() => navigate(`/v2/view-profile`)}
            >
              <User size={14} />
              <span className="align-middle ms-50">Profile</span>
            </DropdownItem>
            <DropdownItem
              className="w-100"
              onClick={(e) => {
                e.preventDefault()
                logout(e)
              }}
            >
              <Power size={14} />
              <span className="align-middle ms-50">Log Out</span>
            </DropdownItem>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

const Wrapper = ({ label, value }) => {
  return (
    <div className="d-flex justify-content-between align-items-center notification-wrapper">
      <div style={{ paddingLeft: "0.5rem" }}>
        <p className="mb-0">{label}</p>
      </div>
      <div>
        <Badge pill color="light-primary" style={{ marginRight: "0.5rem" }}>
          {value}
        </Badge>
      </div>
    </div>
  )
}

export default UserDropdown
