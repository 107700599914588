import React, { Fragment, useState, useEffect } from "react"
import {
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Form,
  FormFeedback,
  Input,
  Spinner
} from "reactstrap"
import Select from "react-select"
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext
} from "react-hook-form"
import { useSelector } from "react-redux"
import { adminsetupApi1, getAccountCodingUrl } from "@service/apiUrl"
import {
  getAllEntity,
  updateUserProfile,
  getAllClient
} from "../../service/manageUserService"
import {
  getUserByEntityApi,
  getRoleByEntity
} from "../../../cratomatch/service/service"
import { selectThemeColors, cratoDeepClone } from "@utils"
import Mandatory from "@custom-components/mandatoy-star/Mandatory"
import YupMultiSelectAll from "@custom-components/react-multi-select-all/YupMultiSelectAll"
import { ModalSkeleton } from "@custom-components/skeleton/ModalSkeleton"
import { object, array, string } from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import classNames from "classnames"
import toast from "react-hot-toast"
import useFetch from "@hooks/useFetch"
import { useDidUpdateEffect } from "@hooks/useDidUpdateEffect"

const defaultPayload = {
  approvalLimitFrom: "",
  approvalLimitTo: "",
  clientCode: "",
  clientId: "",
  clientName: "",
  clientEntityId: "",
  clientEntityName: "",
  clientEntityCode: "",
  nextLevelUser: "",
  title: "",
  userRole: "",
  userRoleId: ""
}

const defaultValues = {
  title: "",
  companyCode: "",
  userRole: "",
  businessUnit: [],
  department: "",
  nxtLvl: "",
  appLimFrom: "",
  appLimTo: ""
}

const BASE_ROLES = [
  "CRATO_ACCOUNTANT_PLUS",
  "CRATO_APPROVER",
  "CRATO_ACCOUNTADMIN",
  "SUPER_USER"
]

const schema = object().shape(
  {
    // title: object().required(),
    companyCode: object().required(),
    userRole: object().required(),
    businessUnit: array().min(1, "Business Unit is Required").required(),
    // nxtLvl: object().required(),
    appLimFrom: string().when(["userRole", "hasApproverAccess"], {
      is: (value, access) =>
        BASE_ROLES.includes(value?.value?.roleCode ?? "") && access,
      then: string().required()
    }),
    appLimTo: string().when(["userRole", "hasApproverAccess"], {
      is: (value, access) =>
        BASE_ROLES.includes(value?.value?.roleCode ?? "") && access,
      then: string().required()
    })
  },
  ["userRole", "hasApproverAccess"]
)

export const Field = (props) => {
  const {
    label,
    options = [],
    isMulti = false,
    name,
    mandatory = true,
    isDisable = false,
    onFieldchange = () => {}
  } = props
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <>
      <Label>
        {label} {mandatory && <Mandatory />}
      </Label>
      {!isMulti && (
        <>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Select
                className={classNames("react-select", {
                  "is-invalid": errors[name] && true
                })}
                classNamePrefix="select"
                theme={selectThemeColors}
                options={options}
                isDisabled={isDisable}
                {...field}
                onChange={(e) => {
                  field.onChange(e)
                  onFieldchange(e, name)
                }}
              />
            )}
          />
          {errors[name] && (
            <FormFeedback>{`${label} is Required`}</FormFeedback>
          )}
        </>
      )}
      {isMulti && <YupMultiSelectAll name={name} options={options} />}
    </>
  )
}

const AddEditCompanyModal = (props) => {
  const { show, setShow, header, userProfileData, index, getCall } = props

  const { userData } = useSelector((state) => state.auth)

  const {
    clientId,
    tenantId: storeTenantId,
    roleAccess,
    customRoles
  } = userData

  const [changingClientId, setChangingClientId] = useState()
  const [entityOption, setEntityOption] = useState([])
  // const [nextLevelUsers, setNextLevelUsers] = useState([])
  const [userRoleOption, setUserRoleOption] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])
  const [loader, setLoader] = useState({
    submit: false,
    skeleton: false
  })

  const checkClientId = userProfileData?.userRoleAccess?.map(
    ({ clientId }) => clientId
  )

  const formProps = useForm({
      defaultValues,
      resolver: yupResolver(schema)
    }),
    {
      handleSubmit,
      reset,
      watch,
      control,
      setValue,
      formState: { errors }
    } = formProps
  const watchUserRole = watch("userRole", {})
  const hasApproverAccess = watch("hasApproverAccess", false)

  const [titleOptions = []] = useFetch(`${adminsetupApi1}/jobtitlelist`, {
    apiParams: {
      params: { clientId }
    },
    callback: (response) =>
      response.map(({ titleName, jobTitleId }) => ({
        label: titleName,
        value: { titleName, jobTitleId }
      }))
  })

  const getEntity = async (clientId) => {
      try {
        const { data } = await getAllEntity(clientId)
        const options = data.map(
          ({ clientEntityName, clientEntityCode, clientEntityId }) => ({
            value: {
              clientEntityName,
              clientEntityCode,
              clientEntityId
            },
            label: clientEntityName
          })
        )
        setEntityOption(options)
        return options
      } catch (error) {
        console.log(error)
      }
    },
    getNxtLvlOption = async (clientId) => {
      try {
        const { data } = await getUserByEntityApi(clientId)
        const options = data.map(
          ({
            firstName: userFirstName,
            lastName: userLastName,
            userName: userEmail,
            userId
          }) => ({
            label: `${userFirstName} ${userLastName} (${userEmail}) `,
            value: {
              userEmail,
              userFirstName,
              userLastName,
              userId
            }
          })
        )
        // setNextLevelUsers(options)
        return options
      } catch (error) {
        console.log(error)
      }
    },
    getRoles = async (clientId) => {
      try {
        const { data } = await getRoleByEntity(clientId)
        const rolesOption =
          data
            .filter(({ tenantId }) => tenantId === storeTenantId)[0]
            ?.userRoles?.map(({ roleName, roleId, baseRoles }) => ({
              label: roleName,
              value: { roleId, roleName, roleCode: baseRoles?.roleCode ?? "" }
            })) ?? []
        setUserRoleOption(rolesOption)
        return rolesOption
      } catch (error) {
        console.log(error)
      }
    },
    getCompanyOptions = async () => {
      try {
        const { data } = await getAllClient()
        const filteredOptions = data
          .map(({ clientName, clientCode, clientId }) => ({
            label: `${clientName} - ${clientCode}`,
            value: {
              clientName,
              clientCode,
              clientId
            }
          }))
          .filter(({ value }) => !checkClientId.includes(value.clientId))
        setCompanyOptions(filteredOptions)
      } catch (error) {
        console.error(error)
      }
    }

  useDidUpdateEffect(() => {
    getEntity(changingClientId)
    getNxtLvlOption(changingClientId)
    getRoles(changingClientId)
  }, [changingClientId])

  const onClientIdChange = (Id) => {
    const userRoleId = customRoles?.find(
      ({ clientId }) => clientId === Id
    )?.userRoleId
    const accessRestriction = roleAccess?.find(
      ({ roleId }) => roleId === userRoleId
    )?.accessRestriction
    const hasApproverAccess = Object.entries(accessRestriction ?? {})
      .map((v) => v[1].approval)
      .some((v) => v === true)
    setValue("hasApproverAccess", hasApproverAccess)
  }

  const onChange = ({ value }) => {
    setChangingClientId(value.clientId)
    onClientIdChange(value.clientId)
    setValue("businessUnit", [])
    setValue("department", "")
    setValue("nxtLvl", "")
    setValue("userRole", "")
  }

  const [docRes = []] = useFetch(
    `${getAccountCodingUrl}/getbyclientid?clientid=${userData.clientId}`,
    {
      apiParams: {},
      callback: (res) => {
        const option = []
        res?.forEach((val) => {
          if (val?.segmentName?.toLowerCase() === "department") {
            val?.clientAdditionalSegmentList?.forEach((client) => {
              option.push({
                value: client?._id,
                label: client?.accountCodeName
              })
            })
          }
        })
        return option
      }
    }
  )

  const fields = [
    {
      label: "Title",
      name: "title",
      options: titleOptions,
      mandatory: false
    },
    {
      label: "Company Code",
      name: "companyCode",
      options: companyOptions,
      isDisable: header === "Edit",
      onFieldchange(e, name) {
        onChange(e, name)
      }
    },
    {
      label: "User Role",
      name: "userRole",
      options: userRoleOption
    },
    {
      label: "Business Unit",
      name: "businessUnit",
      options: entityOption,
      isMulti: true
    },
    {
      label: "Department",
      name: "department",
      options: docRes,
      mandatory: false
    }
    // {
    //   label: "Next Level Manager",
    //   name: "nxtLvl",
    //   options: nextLevelUsers
    // }
  ]

  const toggle = () => {
    reset()
    setEntityOption([])
    setUserRoleOption([])
    // setNextLevelUsers([])
    setShow(false)
  }

  const updateCall = async (payload) => {
    setLoader({
      ...loader,
      submit: true
    })
    try {
      await updateUserProfile(payload)
      toggle()
      toast.success(
        header === "Add"
          ? "Company Information Added Successfully"
          : "Company Information Updated Successfully"
      )
    } catch (error) {
      console.error(error)
      toast.error(
        header === "Add"
          ? "Error in Adding Company Information"
          : "Error in Updating Company Information"
      )
    } finally {
      setLoader({
        ...loader,
        submit: false
      })
      getCall()
    }
  }

  const onSubmit = (response) => {
    const {
      title: { label: title },
      appLimFrom: approvalLimitFrom,
      appLimTo: approvalLimitTo,
      companyCode: {
        value: { clientCode, clientName, clientId }
      },
      businessUnit,
      department,
      nxtLvl,
      userRole: {
        value: { roleName: userRole, roleId: userRoleId }
      }
    } = response

    const businessUnitArray =
      businessUnit[0].label === "All" ? businessUnit[0].value : businessUnit

    const clientData = businessUnitArray.map(({ value }) => ({
      clientEntityCode: value.clientEntityCode,
      clientEntityId: value.clientEntityId,
      clientEntityName: value.clientEntityName
    }))

    const clone = cratoDeepClone(userProfileData)
    const payload = {
      ...defaultPayload,
      title,
      department: department?.value ?? "",
      approvalLimitFrom: BASE_ROLES.includes(watchUserRole?.value?.roleCode)
        ? approvalLimitFrom
        : "",
      approvalLimitTo: BASE_ROLES.includes(watchUserRole?.value?.roleCode)
        ? approvalLimitTo
        : "",
      clientCode,
      clientId,
      clientName,
      clientEntityId: clientData.map(({ clientEntityId }) => clientEntityId),
      clientEntityName: clientData.map(
        ({ clientEntityName }) => clientEntityName
      ),
      clientEntityCode: clientData.map(
        ({ clientEntityCode }) => clientEntityCode
      ),
      nextLevelUser: nxtLvl?.value?.userEmail,
      userRole,
      userRoleId
    }
    let payloadDetails = [...clone.userRoleAccess, payload]
    if (header === "Edit") {
      payloadDetails = clone.userRoleAccess.map((val, id) => {
        if (id === index) return payload
        return val
      })
    }
    const userRoleAccess = clone.userRoleAccess?.length
      ? payloadDetails
      : [payload]

    updateCall({
      ...clone,
      userRoleAccess
    })
  }

  const preFill = async (data) => {
    if (!data || header === "Add") return

    const {
      title,
      department,
      clientId,
      clientCode,
      clientName,
      nextLevelUser: userEmail,
      clientEntityId,
      userRole,
      approvalLimitFrom,
      approvalLimitTo
    } = data
    try {
      setLoader({
        ...loader,
        skeleton: true
      })
      onClientIdChange(clientId)
      const entityOption = await getEntity(clientId)
      const nextLevelUser = await getNxtLvlOption(clientId)
      const rolesOption = await getRoles(clientId)

      const titleValue =
        titleOptions?.filter(({ label }) => label === title)[0] ?? ""

      const departmentValue =
        docRes?.find(({ value }) => value === department) ?? ""
      const companyValue = {
        label: `${clientName} - ${clientCode}`,
        value: {
          clientName,
          clientCode,
          clientId
        }
      }
      const nxtLvlOption =
        nextLevelUser?.filter(
          ({ value }) => value.userEmail === userEmail
        )[0] ?? ""
      const entityValues = entityOption?.filter(({ value }) =>
        clientEntityId.includes(value.clientEntityId)
      )
      const rolesValue =
        rolesOption?.filter(({ label }) => userRole === label)[0] ?? ""

      // ** SET VALUES
      setValue("title", titleValue)
      setValue("companyCode", companyValue)
      setValue("nxtLvl", nxtLvlOption)
      setValue("businessUnit", entityValues)
      setValue("department", departmentValue)
      setValue("userRole", rolesValue)
      setValue("appLimFrom", approvalLimitFrom)
      setValue("appLimTo", approvalLimitTo)
    } catch (error) {
      console.error(error)
    } finally {
      setLoader({
        ...loader,
        skeleton: false
      })
    }
  }

  useEffect(() => {
    getCompanyOptions()
    if (show) return preFill(userProfileData?.userRoleAccess[index] ?? null)
  }, [show, index])

  return (
    <>
      <Modal
        isOpen={show}
        className="modal-dialog-centered"
        size="lg"
        id="edit-company-info-modal"
      >
        <ModalHeader className="bg-transparent" toggle={() => toggle()} />
        <ModalBody>
          <h2 className="text-center">{`${header} Company Information`}</h2>
          {loader.skeleton && <ModalSkeleton />}
          {!loader.skeleton && (
            <FormProvider {...formProps}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  {fields.map((props, id) => (
                    <Fragment key={id}>
                      <Col md="6" className="my-50">
                        <Field {...props} />
                      </Col>
                      {id === fields.length - 1 &&
                        hasApproverAccess &&
                        BASE_ROLES.includes(watchUserRole?.value?.roleCode) && (
                          <Col md="6" className="my-50">
                            <Label>
                              Approval Limit <Mandatory />
                            </Label>
                            <Row>
                              <Col>
                                <Controller
                                  name="appLimFrom"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      type="number"
                                      placeholder="From"
                                      invalid={errors.appLimFrom && true}
                                      {...field}
                                    />
                                  )}
                                />
                                {errors.appLimFrom && (
                                  <FormFeedback>From is Required</FormFeedback>
                                )}
                              </Col>
                              <Col>
                                <Controller
                                  name="appLimTo"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      type="number"
                                      placeholder="To"
                                      invalid={errors.appLimTo && true}
                                      {...field}
                                    />
                                  )}
                                />
                                {errors.appLimTo && (
                                  <FormFeedback>To is Required</FormFeedback>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        )}
                    </Fragment>
                  ))}
                </Row>
                <div className="d-flex my-1 justify-content-center align-items-center gap-1">
                  <Button
                    type="button"
                    outline
                    onClick={() => toggle()}
                    id="company-info-discard-btn"
                  >
                    Discard
                  </Button>
                  {loader.submit ? (
                    <Button color="primary" disabled id="submitting">
                      <Spinner size="sm" />
                      <span className="ms-25">Submitting</span>
                    </Button>
                  ) : (
                    <Button type="submit" color="primary" id="submit">
                      <span>Submit</span>
                    </Button>
                  )}
                </div>
              </Form>
            </FormProvider>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddEditCompanyModal
