// ** React Imports
import { Fragment, useState } from "react"
import { Link } from "react-router-dom"

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Form,
  Table,
  Alert,
  Input,
  Modal,
  Button,
  CardBody,
  CardTitle,
  ModalBody,
  CardHeader,
  ModalHeader,
  FormFeedback,
  InputGroup,
  InputGroupText,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody
} from "reactstrap"

// ** Custom Components
import InputPasswordToggle from "@components/input-password-toggle"

// ** Third Party Components
import * as yup from "yup"
import Cleave from "cleave.js/react"
import "cleave.js/dist/addons/cleave-phone.us"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import toast from "react-hot-toast"
import {
  Edit,
  Trash,
  Settings,
  MessageSquare,
  ChevronRight
} from "react-feather"
import { postChangePassword } from "../../service/manageUserService"
import ProgressButton from "../../../../custom-components/buttons/ProgressButton"
import PasswordCriteria from "../../../../custom-components/password-criteria"

const SignupSchema = yup.object().shape({
  // newPassword: yup.string().min(8).required(),
  oldPassword: yup.string().required("Current password is required"),
  newPassword: yup
    .string()
    .min(8, "Minimum 8 Character Requried")
    .max(16, "Password is too long")
    .matches(/[A-Z]/, "Password should contain atleast 1 uppercase letter.")
    .matches(/[0-9]/, "Password should contain atleast 1 number.")
    .matches(
      /[@#$%^&+=]/,
      "Password should contain atleast 1 special character."
    )
    .required("New Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm new password is required")
})

const defaultValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: ""
}

const SecurityTab = ({ userProfileData }) => {
  const [loader, setLoader] = useState(false)
  const options = { phone: true, phoneRegionCode: "US" }
  const {
    control,
    trigger,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({ defaultValues, resolver: yupResolver(SignupSchema) })
  const formValues = watch("newPassword")

  const onSubmit = async (data) => {
    trigger()
    const form_data = {
      emailId: userProfileData.userName,
      newPass: data.newPassword,
      oldPass: data.oldPassword
    }
    setLoader(true)
    try {
      await postChangePassword(form_data)
      reset()
      toast.success("Password updated Successfully", {
        position: "top-center"
      })
    } catch (err) {
      toast.error(err.response.data.errors[0].errorMessage, {
        position: "top-center"
      })
    } finally {
      setLoader(false)
    }
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Change Password</CardTitle>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row style={{ alignItems: "center" }}>
              <Col className="mb-2" lg={7}>
                <Col className="mb-2" md={12}>
                  <Controller
                    control={control}
                    id="oldPassword"
                    name="oldPassword"
                    render={({ field }) => (
                      <InputPasswordToggle
                        label="Current Password"
                        htmlFor="oldPassword"
                        className="input-group-merge"
                        invalid={errors.oldPassword && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.oldPassword && (
                    <FormFeedback className="d-block">
                      {errors.oldPassword.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col className="mb-2" md={12}>
                  <Controller
                    id="newpasswordInput"
                    name="newPassword"
                    control={control}
                    render={({ field }) => (
                      <InputPasswordToggle
                        label="New Password"
                        id="newpasswordInput"
                        htmlFor="newPassword"
                        className="input-group-merge"
                        invalid={errors.newPassword && true}
                        {...field}
                      />
                    )}
                  />
                  {errors.newPassword && (
                    <FormFeedback className="d-block">
                      {errors.newPassword.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col className="mb-2" md={12}>
                  <Controller
                    id="confirmPassword"
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                      <InputPasswordToggle
                        label="Confirm Password"
                        htmlFor="confirmPassword"
                        className="input-group-merge"
                        invalid={errors.confirmPassword && true}
                        noEye
                        {...field}
                      />
                    )}
                  />
                  {errors.confirmPassword && (
                    <FormFeedback className="d-block">
                      {errors.confirmPassword.message}
                    </FormFeedback>
                  )}
                </Col>
              </Col>
              <Col className="mb-2" lg={5}>
                <PasswordCriteria value={formValues} />
              </Col>
              <Col md="12">
                <div
                  style={{
                    display: "grid",
                    placeItems: "center"
                  }}
                >
                  <ProgressButton
                    type="submit"
                    color="primary"
                    loader={loader}
                    loaderName="Changing Password"
                  >
                    Change Password
                  </ProgressButton>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      {false && (
        <Card>
          <CardBody>
            <CardTitle className="mb-50">Two-steps verification</CardTitle>
            <span>Keep your account secure with authentication step.</span>
            <div className="mt-3 my-2">
              <Row>
                <Col md={2} className="text-center">
                  <h5>Mobile Number</h5>
                </Col>
                <Col md={5}>
                  <InputGroup className="input-group-merge">
                    <InputGroupText>US (+1)</InputGroupText>
                    <Cleave
                      className="form-control"
                      placeholder="1 234 567 8900"
                      options={options}
                      id="phone-number"
                      //value={contact}
                      //onChange={(e) => setContact(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col md={3} className="text-center">
                  <Button color="primary">Verify Number </Button>
                </Col>
              </Row>
            </div>
            <p className="mt-3 my-2">
              Two-factor authentication adds an additional layer of security to
              your account by requiring more than just a password to log in.
            </p>
          </CardBody>
        </Card>
      )}
    </Fragment>
  )
}

export default SecurityTab
