import { useState } from "react"
import Chart from "react-apexcharts"
import { useSelector } from "react-redux"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import { getBillProcessingData } from "../../Service/Service"
import useFetch from "../../../../utility/hooks/useFetch"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import CF from "@utility/helper/currencyFormatter"
import { decimalNumberFormatter as DNF } from "@utils"
const getDayFilterfn = (val) => {
  switch (val) {
    case "Last Day":
      return "lastDay"
    case "Last 7 Days":
      return "lastWeek"
    case "Last Month":
      return "lastMonth"
    case "Last Quater":
      return "lastQuarter"
    case "Last Year":
      return "lastYear"
    default:
      return ""
  }
}

const getDayFilterPercentagefn = (val) => {
  switch (val) {
    case "Last Day":
      return "lastDayPercentage"
    case "Last 7 Days":
      return "lastWeekPercentage"
    case "Last Month":
      return "lastMonthPercentage"
    case "Last Quater":
      return "lastQuarterPercentage"
    case "Last Year":
      return "lastYearPercentage"
    default:
      return ""
  }
}
const dateSelect = [
  "Last Day",
  "Last 7 Days",
  "Last Month",
  "Last Quater",
  "Last Year"
]
const BillProcessing = (props) => {
  const { userData } = useSelector((state) => state.auth)
  const [selectedRange, setSelectedRange] = useState("Last Month")

  const [data = [], { isFirstLoad: loading, loading: updating }] = useFetch(
    getBillProcessingData(userData.clientId)
  )

  const options = {
    width: "150px",
    plotOptions: {
      radialBar: {
        // size: 150,
        // offsetY: 20,
        // startAngle: -150,
        // endAngle: 150,
        hollow: {
          size: "65%"
        },
        track: {
          background: "#fff",
          strokeWidth: "100%"
        },
        dataLabels: {
          name: {
            offsetY: -5,
            color: "#33cc33",
            fontSize: "1rem"
          },
          value: {
            offsetY: 15,
            fontSize: "1.714rem"
          }
        }
      }
    },
    colors: [props.danger],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: [props.primary],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      dashArray: 8
    },
    labels: ["Posted Bills"]
  }

  return (
    <Card>
      <CardHeader className="pb-0">
        <CardTitle className="flex-center" tag="h4">
          Bills Processing
        </CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
            id="bills-Processing-date-btn"
          >
            {selectedRange}
          </DropdownToggle>
          <DropdownMenu end id="select-bills-Processing-date">
            {dateSelect.map((item) => (
              <DropdownItem
                className="w-100"
                key={item}
                onClick={() => setSelectedRange(item)}
              >
                {item}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      {loading || updating ? (
        <ProcessingSkeleton />
      ) : (
        <CardBody>
          <Row className="mt-2">
            <Col
              sm="4"
              className="d-flex flex-column flex-wrap justify-content-center align-items-center text-center gap-1"
            >
              <div>
                <span className="font-large-1 fw-bold">
                  {CF(
                    DNF(
                      data[2]?.billprocessingStats[
                        getDayFilterfn(selectedRange)
                      ]
                    )
                  )}
                </span>
                <CardText className="mb-50">Total in process Bills</CardText>
              </div>

              {/* <span style={{ fonstSize: "10px" }}>Total in process Bills</span> */}
              <div>
                <span className="font-large-1 fw-bold">
                  {CF(
                    DNF(
                      data[4]?.billprocessingStats[
                        getDayFilterfn(selectedRange)
                      ]
                    )
                  )}
                </span>
                {/* <span style={{ fonstSize: "10px" }}>Follow up</span> */}
                <CardText className="mb-50">Follow up</CardText>
              </div>
            </Col>
            <Col
              sm="8"
              className="d-flex justify-content-center align-items-center"
            >
              <Chart
                // width="100px"
                // height="100px"
                height={220}
                width={220}
                options={options}
                series={[
                  // 80
                  data[1]?.billprocessingStats[
                    getDayFilterPercentagefn(selectedRange)
                  ] ?? []
                ]}
                // series={[80]}
                type="radialBar"
                id="support-tracker-card"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={4}>
              <div className="text-center">
                <span className="font-large-1 fw-bold">
                  {CF(
                    DNF(
                      data[0]?.billprocessingStats[
                        getDayFilterfn(selectedRange)
                      ]
                    )
                  )}
                </span>
                <CardText className="mb-50 text-break">New Documents</CardText>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-center">
                <span className="font-large-1 fw-bold text-break">
                  {CF(
                    DNF(
                      data[5]?.billprocessingStats[
                        getDayFilterfn(selectedRange)
                      ]
                    )
                  )}
                </span>
                <CardText className="mb-50">Pending Approval</CardText>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-center">
                <span className="font-large-1 fw-bold text-break">
                  {CF(
                    DNF(
                      data[3]?.billprocessingStats[
                        getDayFilterfn(selectedRange)
                      ]
                    )
                  )}
                </span>
                <CardText className="mb-50">Needs Resolution</CardText>
              </div>
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  )
}
export default BillProcessing

const ProcessingSkeleton = () => (
  <SkeletonTheme>
    <Skeleton height={260} width={"95%"} className="ms-50 mt-1" />
  </SkeletonTheme>
)
