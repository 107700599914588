// ** React Imports
import { lazy } from "react"
import { Navigate } from "react-router"

const Login = lazy(() => import("../../views/authentication/login/index"))
const ForgotPassword = lazy(() =>
  import("../../views/authentication/forgot-password/ForgotPassword")
)
const ResetPassword = lazy(() =>
  import("../../views/authentication/reset-password/ResetPassword")
)
const NotAuthorized = lazy(() => import("../../views/NotAuthorized"))
const SignUp = lazy(() => import("../../views/authentication/sign-up/index"))
const ServerError = lazy(() =>
  import("../../views/error-boundry/PageBreakErrorFallback")
)
import UnderMaintenance from "../../custom-components/UnderMaintenance"

const AuthenticationRoutes = [
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/under-maintanence",
    element: <UnderMaintenance />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/not-authorized",
    element: <NotAuthorized />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/server-error",
    element: <ServerError />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/signup",
    element: <SignUp />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  }
]

export default AuthenticationRoutes
