import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts"
import { Card } from "reactstrap"

const SimpleBarChart = ({ data }) => {
  return (
    <Card style={{ maxHeight: "235px", margin: 0 }}>
      <div
        className="recharts-wrapper bar-chart"
        style={{ maxHeight: "235px" }}
      >
        <ResponsiveContainer>
          <BarChart height={235} data={data} barSize={15}>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              wrapperStyle={{ backgroundColor: "#f2f2f2" }}
              content={CustomTooltip}
            />
            <Bar
              dataKey="count"
              stackId="a"
              fill="#34A353"
              radius={[15, 15, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  )
}
export default SimpleBarChart

const CustomTooltip = (data) => {
  if (data.active && data.payload) {
    return (
      <div
        className="recharts-custom-tooltip"
        // style={{ backgroundColor: "#f2f2f2" }}
      >
        <p className="fw-bold mb-0">{data.label} days</p>
        <hr />
        <div className="active">
          {data.payload.map((i) => {
            return (
              <div className="align-items-center" key={i.dataKey}>
                <span className="align-middle text-capitalize me-75">
                  Total value of transaction(s) : {i.payload.value ?? 0}
                </span>
                <div>
                  <span className="align-middle text-capitalize me-75">
                    Total count of transaction(s) : {i.payload.count ?? 0}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return null
}
