// ** Icons Import
import { Grid, Trello, Upload, Circle, Columns } from "react-feather"

// ** Role Permission List
import { permissions } from "../views/role-management/store/permissionList"

const permissionList = Object.keys(permissions)
const banking = Object.keys(permissions?.Banking)

export default [
  {
    id: "Banking",
    title: "Banking",
    accessId: permissionList[3],
    icon: Grid,
    children: [
      {
        id: "Bank_Bank_Posting",
        title: "Bank Posting",
        accessId: permissionList[3],
        icon: Columns,
        children: [
          {
            id: "Bank_Unbooked_Transactions",
            title: "Unbooked Transaction",
            accessId: banking[0],
            icon: Circle,
            navLink: "/v2/cratomatch/uncategorized-transactions"
          },
          {
            id: "Bank_Booked_Transactions",
            title: "Booked Transaction",
            accessId: banking[1],
            icon: Circle,
            navLink: "/v2/cratomatch/categorized-transactions/list"
          }
        ]
      },

      {
        id: "Bank_Bank_Reconciliation",
        title: "Bank Reconciliation",
        accessId: banking[1],
        icon: Trello,
        navLink: "/v2/cratomatch/bank-reconciliation/list"
      },
      {
        id: "Bank_Upload_Bank_Transaction",
        title: "Upload Bank Txn(s)",
        accessId: banking[1],
        icon: Upload,
        navLink: "/v2/cratomatch/uploadBank-tnxs"
      }
      /*{
    id: 'ar-to-gl',
    title: 'AR to GL',
    icon: Columns ,
    navLink: '/apps/todo'
  } */
    ]
  }
]
// {
//   id: "uncategorized-transactions-cu",
//   title: "Unbooked Transaction (CU)",
//   accessId: banking[0],
//   icon: Command,
//   navLink: "/v2/cratomatch/uncategorized-transactions-cu"
// },
// {
//   id: "booked-transaction-cu",
//   title: "Booked Transaction  (CU)",
//   accessId: banking[1],
//   icon: Columns,
//   navLink: "/v2/cratomatch/categorized-transactions-cu/list"
// },
