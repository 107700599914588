import { DefaultRoute } from "../router/routes"
import { UncontrolledTooltip, Badge } from "reactstrap"
import { Tooltip } from "antd"
// Third Party Imports
import moment from "moment"
import axios from "axios"
import toast from "react-hot-toast"
import dayjs from "dayjs"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => {
  const { abs, sign } = Math
  if (abs(num) > 999999)
    return `${sign(num) * (abs(num) / 1000000).toFixed(1)}m`
  if (abs(num) > 999) return `${sign(num) * (abs(num) / 1000).toFixed(1)}k`
  return sign(num) * abs(num)
}

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Environment Vars
const Env = process.env.REACT_APP_TYPE
const domain = process.env.REACT_APP_DOMAIN

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  return localStorage.getItem("accessToken")
}

export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return DefaultRoute
  if (userRole === "client") return "/access-control"
  return "/v2/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#34A3531a", // for option hover bg-color
    primary: "#34A353", // for selected option bg-color
    neutral10: "#34A353", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return "&#36;"
    default:
      return "&#36;"
  }
}

// ** Load Google script for google map apis
export const loadGoogleMapScript = (callback) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY
  const googleMapScript = document.createElement("script")
  googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
  window.document.body.appendChild(googleMapScript)
  googleMapScript.addEventListener("load", callback)
}

// ** Store Cookies
export const bakeCookie = (name, value) => {
  const plusOneYear = new Date(new Date().setDate(new Date().getDate() + 5))
  const cookie = window.location.origin.includes("localhost")
    ? `${name}=${JSON.stringify(value)};expires=${plusOneYear}`
    : `${name}=${JSON.stringify(
        value
      )};domain=${domain};path=/;expires=${plusOneYear}`
  document.cookie = cookie
}

// ** Get cookie Obj
export const getCookieObj = (name) => {
  let result = document.cookie.match(new RegExp(`${name}=([^;]+)`))
  try {
    if (result) {
      result = JSON.parse(result[1] ?? "{}")
    }
  } catch (errors) {
    console.log(errors)
    result = ""
  }

  return result
}

// ** Delete All Site cookie Obj
export const deleteAllCookies = () => {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, `=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`)
  })
}

// ** Delete cookie obj
export const deleteCookies = (name) => {
  document.cookie = window.location.origin.includes("localhost")
    ? `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;Max-Age=${0}`
    : `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${domain};path=/;Max-Age=${0}`
}

// ** Format Date
export const getFormatedDate = (date, dateFormat) =>
  moment(date).format(dateFormat)

// ** Decimal Formatter
export const decimalFormatter = (data) => {
  if (!isNaN(data) && data !== "" && data !== null) {
    return parseFloat(data).toFixed(2)
  } else {
    return "0.00"
  }
}

// ** Number Formatter
export const numberFormatter = (val) => {
  const data = `${val},`.replaceAll(",", "")

  if (!isNaN(data) && data !== "" && data !== null) {
    return parseFloat(data)
  } else {
    return 0
  }
}

// ** Decimal Number Formatter
export const decimalNumberFormatter = (val) => {
  const data = `${val},`.replaceAll(",", "")
  if (!isNaN(data) && data !== "" && data !== null) {
    const deciNum = parseFloat(data).toFixed(2)
    return Number(deciNum)
  } else {
    return 0.0
  }
}

// ** Number Fromatter With Thousand's
export const numberWithCommas = (data) => {
  if (data !== "" && data !== undefined && data !== null) {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return ""
  }
}

// ** Tabel Row Hover Effect
export const rowHoverStyle = {
  rows: {
    style: {
      "&:hover": {
        cursor: "pointer",
        transform: "translateY(-3px)",
        boxShadow: "0 3px 10px 0 #bdbdbd",
        transition: "all 0.2s",
        zIndex: "1"
      }
    }
  }
}

//Table approval status formatter
export const statusFormatter = (status) => {
  switch (status) {
    case "AWAITINGAPPROVAL":
      return "Awaiting approval"
    case "Awaiting Approval":
      return "Awaiting approval"
    case "APPROVED":
      return "Approved"
    case "PREAPPROVED":
      return "PreApproved"
    case "WAITINGTOPOST":
      return "Awaiting to post"
    case "Awaiting resolution":
      return "Awaiting resolution"
    case "Awaiting approval":
      return "Awaiting approval"
    case "Posted":
      return "Posted"
    case "Verified":
      return "Verified"
    case "Create":
      return "Needs Review"
    case "Followup":
      return "Followup"
    case "Cancelled":
      return "Cancelled"
    case "Process":
      return "Approved"
    case "Resolved":
      return "Resolved"
    case "REJECTED":
      return "Rejected"
  }
}

export const status = {
  UNAPPLIED: { value: "Unapplied Payment", color: "light-warning" },
  UNIDENTIFIED: { value: "Unidentified Payment", color: "light-secondary" },
  APPLIED: { value: "Applied", color: "light-primary" },
  AWAITINGAPPROVAL: { value: "Awaiting approval", color: "light-warning" },
  AWAITING_APPROVAL: { value: "Awaiting approval", color: "light-warning" },
  "Awaiting Approval": { value: "Awaiting approval", color: "light-warning" },
  APPROVED: { value: "Active", color: "light-primary" },
  PREAPPROVED: { value: "Active", color: "light-primary" },
  AWAITINGTOPOST: { value: "Awaiting to post", color: "light-warning" },
  "Awaiting resolution": {
    value: "Awaiting resolution",
    color: "crimson"
  },
  "Awaiting approval": { value: "Awaiting approval", color: "light-warning" },
  Posted: { value: "Posted", color: "light-primary" },
  DRAFT: { value: "Drafted", color: "light-secondary" },
  POSTED: { value: "Posted", color: "light-warning" },
  Verified: { value: "Verified", color: "light-primary" },
  Create: { value: "Needs Review", color: "light-info" },
  Followup: { value: "Followup", color: "light-warning" },
  Cancelled: { value: "Cancelled", color: "light-secondary" },
  CANCELLED: { value: "Cancelled", color: "light-secondary" },
  PAYMENT_TRANSIT_FAIELD: {
    value: "Payment transit failed",
    color: "light-secondary"
  },
  PAYMENT_IN_TRANSIT: { value: "Payment in transit", color: "light-primary" },
  Process: { value: "Approved", color: "indigo" },
  Resolved: { value: "Resolved", color: "indigo" },
  REJECTED: { value: "Rejected", color: "light-danger" },
  PAYMENT_FAILED: { value: "Payment failed", color: "light-danger" },
  UNAPPROVED: { value: "Unapproved", color: "light-warning" },
  "Awaiting for API": { value: "Awaiting for API", color: "light-secondary" },
  "Awaiting Payment": { value: "Awaiting Payment", color: "light-warning" },
  Resolve: { value: "Resolve", color: "light-warning" },
  // selectedForPayment: {
  //   value: "Selected for payment",
  //   color: "light-secondary"
  // },
  "Selected for Payment": {
    value: "Selected for payment",
    color: "light-secondary"
  },
  Paid: { value: "Paid", color: "light-primary" },
  "Partially Paid": { value: "Partially Paid", color: "light-primary" },
  PARTIALLY_PAID: { value: "Partially Paid", color: "light-primary" },
  ACTIVE: { value: "Active", color: "light-primary" },
  INACTIVE: { value: "Inactive", color: "light-danger" },
  AWAITING_TO_POST: { value: "Awaiting to Sync", color: "light-warning" },
  POSTING_FAILED: { value: "Posting Failed", color: "light-danger" },
  POSTINGFAILED: { value: "Posting Failed", color: "light-danger" },
  RESOLVED: { value: "Resolution received", color: "indigo" },
  CASHAPP: { value: "Awaiting Cash application", color: "light-warning" },
  PAYMENTAPP: { value: "Awaiting payment application", color: "light-warning" },
  UNCATEGORIZED: { value: "Unbooked", color: "light-primary" },
  AWAITING_RESOLUTION: { value: "Awaiting resolution", color: "crimson" },
  AWAITINGTOPOST: { value: "Awaiting to Post", color: "light-primary" },
  "Awaiting to Post": { value: "Awaiting to Post", color: "light-primary" },
  MARKASPOSTED: { value: "Mark As Posted", color: "light-primary" },
  BOOKED: { value: "Booked", color: "light-primary" },
  AWAITING_ASSESSMENT: { value: "Awaiting Assessment", color: "indigo" },
  ASSIGNED: { value: "Assigned", color: "indigo" },
  REVERSED: { value: "Reversed", color: "light-secondary" },
  PENDING: { value: "Pending", color: "light-warning" },
  VOIDED: { value: "Posted", color: "light-primary" },
  SELECTED_FOR_PAYMENT: {
    value: "Selected For Payment",
    color: "light-secondary"
  },
  SUBMITTED_FOR_PAYMENT: {
    value: "Submitted For Payment",
    color: "light-secondary"
  },
  "Submitted for payment": {
    value: "Submitted For Payment",
    color: "light-secondary"
  },
  PAID: { value: "Paid", color: "light-primary" },
  COMPLETED: { value: "Completed", color: "light-primary" },
  INITIAL_SUBMISSION: { value: "Needs Review", color: "light-info" },
  PAYMENT_POSTING_FAILED: {
    value: "Payment posting failed",
    color: "light-danger"
  },
  PENDING_REVIEW: {
    value: "Needs Review",
    color: "light-secondary"
  }
}
export const TableStatusFormatter = (v) => status[v]?.value ?? "Active"

export const statusColor = (v) => status[v]?.color ?? "light-primary"

export const removeEmpty = (obj) =>
  Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([_, v]) => !(v == null || v.length == 0))
  )

export const removeEmptyFromArray = (obj) =>
  Object.fromEntries(
    Object.entries(obj)
      .map(([key, val]) => {
        val = val.filter((d) => d !== null && d !== undefined && d !== "")
        return [key, val]
      })
      // eslint-disable-next-line no-unused-vars
      .filter(([_, v]) => !(v == null || v.length == 0))
  )

export const cratoDeepClone = (data) => JSON.parse(JSON.stringify(data))

/* Get location values for tables with batches */
export const getLocationValue = (value = [], list = [], num = 2) => {
  const id = Math.random().toString(36).substring(3)

  const totalList = value.length === list.length

  const locationList = list
    .filter(({ clientEntityId }) => value.includes(clientEntityId))
    .map(({ clientEntityName }) => clientEntityName)

  const badgeList = locationList
    .filter((val, id) => id < num && val)
    .map((val) => val)

  const toolTipList = locationList
    .filter((val, id) => id >= num && val)
    .map((val) => val)

  const toolTipValue = locationList.length - badgeList.length

  return (
    <div>
      {totalList && <Badge pill>All</Badge>}
      {!totalList &&
        badgeList.map((val, i) => (
          <Badge key={`ID${id}${i}`} pill style={{ marginRight: "4px" }}>
            {val}
          </Badge>
        ))}
      {!totalList && toolTipValue > 0 && (
        <>
          <Badge pill className="cursor-pointer" id={`id${id}`}>
            + {toolTipValue}
          </Badge>
          <UncontrolledTooltip
            placement="top"
            target={`id${id}`}
            style={{ backgroundColor: "#6c757d" }}
          >
            {toolTipList.map((val, i) => (
              <Badge
                pill
                color="text-white bg-primary"
                style={{ margin: "2px" }}
                key={`Id${id}${i}`}
              >
                {val}
              </Badge>
            ))}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  )
}

/* Get formated values for tables with tooltip */
export const formatedValue = (value, data) => {
  if (!data) return "-"
  if (data.length <= value) return data
  return (
    <ToolTipWrapper toolTipValue={data} overlayStyle={{ maxWidth: "30rem" }}>
      <span>{`${data.slice(0, value)}..`}</span>
    </ToolTipWrapper>
  )
}

export const getEntityName = (entityId, entityTotalList) => {
  if (!entityId) return "-"
  if (!Array.isArray(entityTotalList)) return "-"
  const entityData = entityTotalList.find((v) => v.clientEntityId === entityId)
  return entityData?.clientEntityName ?? "-"
}

export const hasValue = (value, replaceValue = "") => {
  if (!value) return replaceValue
  return value
}

export const checkNegativeAmount = (data) =>
  decimalNumberFormatter(data) < 0 ? "text-danger" : ""

export const toCapitalizedChar = (string) => {
  if (!string) return "-"
  const strArr = string.trim().split(" ")
  if (strArr.length === 1) return string
  return strArr
    .map((s) => s.at(0).toUpperCase())
    .reduce((pre, next) => pre + next, "")
}

export const toCapitalize = (string, splitChar = " ") => {
  if (!string) return "-"
  return (
    string
      .trim()
      .split(splitChar)
      .map((s) => `${s.at(0).toUpperCase()}${s.slice(1).toLowerCase()}`)
      // .reduce((pre, nxt) => `${pre} ${nxt}`, "")
      .join(" ")
  )
}

export const selectMaxWidth = {
  menu: (base) => ({
    ...base,
    width: "max-content"
  })
}

export const toSlice = (length, string) => {
  if (!string) return "-"
  if (string.length <= length) return string
  return `${string.slice(0, length)}...`
}

export function ToolTipWrapper({
  children,
  isTooltipNeed = true,
  toolTipValue,
  ...props
}) {
  if (!isTooltipNeed) return children
  return (
    <Tooltip
      color="#6c757d"
      className="cursor-pointer"
      title={toolTipValue}
      {...props}
    >
      {children}
    </Tooltip>
  )
}
// Create our number formatter.
export const currencyFormater = ({ region, currency }) =>
  new Intl.NumberFormat(region, {
    currency,
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2 // (causes 2500.99 to be printed as $2,501)
  })

export const downloadFileByUrl = async (url, name = "file") => {
  const { data: blob } = await axios({
    url,
    method: "GET",
    responseType: "blob"
  })
  const fileURL = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = fileURL
  a.download = name
  a.click()
}

export function bytesToKB(bytes) {
  if (!bytes) return 0.0
  return (bytes / 1024).toFixed(2)
}

export function convertBytes(bytes) {
  if (!bytes) return 0
  const kilobytes = bytes / 1024
  if (kilobytes < 1024) return `${kilobytes.toFixed(2)} KB`
  const megabytes = kilobytes / 1024
  if (megabytes < 1024) return `${megabytes.toFixed(2)} MB`
  const gigabytes = megabytes / 1024
  return `${gigabytes.toFixed(2)} GB`
}

export const convertCamelCaseToSentence = (str) => {
  if (!str) return "-"
  if (typeof str !== "string") return str
  const words = str.split(/(?=[A-Z])/)
  const formattedName = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
  return formattedName
}
export const verifyTenant = (userData) => {
  if (Env === "dev") return
  const { tenantId: crato_tenant_id } = userData
  try {
    const { pathname, hostname } = window.location
    const host = hostname.split(".")
    if (host[0] == crato_tenant_id) return
    host[0] = crato_tenant_id
    window.location.href = `https://${host
      .toString()
      .replace(/,/g, ".")}${pathname}`
  } catch (error) {
    console.log(error)
    window.location.href = `http://${
      Env === "dev" ? "localhost:3000" : "login.cratoflow.com"
    }/v2/login`

    localStorage.clear()
  }
}

export const convertLabelObjPair = (array = []) =>
  array.map((label) => ({ label, value: label }))

export const removeKey = (obj = {}, keyToRemove = "") => {
  if (!obj || typeof obj !== "object") return {}
  if (!(keyToRemove in obj)) return { ...obj }
  const { [keyToRemove]: _, ...rest } = obj
  return rest
  console.log(_)
}

export function formatCorpData(param, entityData = [], needFilter = true) {
  if (!param) return []
  const { segments = [], segmentCoding = [] } = param ?? {}
  const formatedData = segments?.map(
    (
      { name, showAsLineItem, segmentCode, isMandatory, parentUnitCode },
      id
    ) => {
      if (id == 0)
        return {
          name,
          options: formatEntity(entityData),
          segmentCode,
          showAsLineItem,
          isMandatory,
          parentUnitCode
        }
      const checkHasValues = segmentCoding.find(
        ({ segmentName }) => segmentName == segmentCode
      )
      if (!checkHasValues)
        return {
          name,
          options: [],
          segmentCode,
          parentUnitCode,
          showAsLineItem,
          isMandatory
        }
      const options =
        checkHasValues?.clientAdditionalSegmentList
          ?.filter(({ isActive }) => isActive)
          ?.map((v) => ({
            label: `${v.accountCode}-${v.accountCodeName}`,
            value: v
          })) ?? []
      return {
        name,
        options,
        parentUnitCode,
        segmentCode,
        showAsLineItem,
        isMandatory
      }
    }
  )
  if (!needFilter) return formatedData
  return formatedData.filter(({ showAsLineItem }) => showAsLineItem)
}

export function formatEntity(param) {
  if (!param) return []
  if (!param?.length) return []
  return (
    param
      .filter(({ activeStatus }) => activeStatus)
      .map((v) => ({
        label: `[${v.clientEntityCode}]-${v.clientEntityName ?? ""}`,
        value: v
      })) ?? []
  )
}

export function filterCorpOptionsByCode(segCode, options) {
  if (!segCode) return []
  if (!options?.length) return []
  return (
    options.find(({ segmentCode }) => segmentCode == segCode)?.options ?? []
  )
}

export const checkDownloadUrl = async (url, fileName = "file") => {
  try {
    const response = await fetch(url)
    if (response.ok) return window.open(url, fileName)
    toast.error("Invalid URL")
  } catch (error) {
    toast.error("Invalid URL")
  }
}

export function NativeToolTip({ value, ...props }) {
  const { style, ...rest } = props ?? {}
  return (
    <p
      title={value}
      style={{
        marginTop: "1rem",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        ...(style ?? {})
      }}
      {...rest}
    >
      {value}
    </p>
  )
}

export const formatFilter = (
  { filters, filterType } = { filters: [], filterType: "" }
) => {
  const finalFilterObj = filters.reduce((filterObj, v) => {
    if (v.type === "date" && v.value?.length) {
      const dateSplit = v.fieldName.split(",")
      filterObj[dateSplit[0]] = (filterObj[dateSplit[0]] || []).concat(
        dayjs(v.value[0]).format("YYYY-MM-DD")
      )
      filterObj[dateSplit[1]] = (filterObj[dateSplit[1]] || []).concat(
        dayjs(v.value[1]).format("YYYY-MM-DD")
      )
    } else if (v.type === "multi select") {
      filterObj[v.fieldName] = [...(filterObj[v.fieldName] || []), ...v.value]
    } else {
      if (!filterObj[v.fieldName]) {
        filterObj[v.fieldName] = []
      }
      filterObj[v.fieldName] = [
        ...(filterObj[v.fieldName] || []),
        v.type === "number" ? decimalNumberFormatter(v.value) : v.value
      ]
    }
    return filterObj
  }, {})

  const finalFilterObjWithoutEmpty = removeEmptyFromArray(finalFilterObj)

  if (Object.keys(finalFilterObjWithoutEmpty).length === 0) {
    return {}
  }

  return {
    ...finalFilterObjWithoutEmpty,
    filterType
  }
}
