import React from "react"
import Chart from "react-apexcharts"
import { Card, CardHeader, CardTitle } from "reactstrap"
import { kFormatter, decimalFormatter as DF } from "@utils"
import dayjs from "dayjs"
import useFetch from "@hooks/useFetch"
import { getUploadCount } from "../../../../services/apiUrl"
import { useSelector } from "react-redux"
import UploadChartSkeleton from "./UploadChartSkeleton"
import "../../styles.scss"

const DATE_FORMAT = "YYYY-MM-DD"
const DEFAULT_VALUE = {
  apBills: [{ count: 0, _id: "" }],
  bankTransaction: [{ count: 0, _id: "" }],
  remittance: [{ count: 0, _id: "" }]
}
const UploadChart = () => {
  const { clientId } = useSelector((state) => state.auth.userData)

  const [res = DEFAULT_VALUE, { isFirstLoad: loading }] = useFetch(
    `${getUploadCount}/${clientId}`,
    {
      apiParams: {
        params: {
          startDate: dayjs().subtract(1, "week").format(DATE_FORMAT),
          endDate: dayjs().subtract(1, "day").format(DATE_FORMAT)
        },
        callback: (response) => response
      }
    }
  )

  const options = {
    // markers: {
    //   size: 3
    // },
    chart: {
      // sparkline: { enabled: true },
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%"
        // borderRadius: [10]
        // endingShape: "rounded"
      }
    },
    legend: {
      show: true,
      position: "right",
      offsetY: 30,
      horizontalAlign: "center"
    },
    xaxis: {
      categories: res.bankTransaction.map(({ _id }) =>
        dayjs(_id).format("MMM-DD")
      )
    },
    yaxis: {
      tickAmount: 1,

      labels: {
        show: true,
        formatter: (val) => kFormatter(DF(val))
      }
    },
    stroke: {
      curve: "straight"
    },
    dataLabels: {
      formatter: (val) => kFormatter(DF(val)),
      offsetY: -10,
      enabled: false,
      style: {},
      background: {
        enabled: true,
        // borderColor,
        foreColor: "#fff"
      }
    },
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      },
      xaxis: {
        lines: {
          show: false
        },
        padding: {
          left: 200,
          right: 0
        }
      }
    }
  }

  const series = [
    {
      name: "Bills",
      data: res.apBills.map(({ count }) => count)
    },
    {
      name: "Remittance",
      data: res.remittance.map(({ count }) => count)
    },
    {
      name: "Bank Statements",
      data: res.bankTransaction.map(({ count }) => count)
    }
  ]
  return (
    <Card>
      <CardHeader className="pb-0">
        <CardTitle tag="h4">Uploads</CardTitle>
      </CardHeader>
      <div className="px-25">
        {loading ? (
          <UploadChartSkeleton />
        ) : (
          <Chart options={options} series={series} type="bar" height={155} />
        )}
      </div>
    </Card>
  )
}

export default UploadChart
