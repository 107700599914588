import React, { Button } from "reactstrap"
import AddNewUserDrawer from "./AddNewUserDrawer"
import { useState } from "react"

const index = () => {
  const [show, setShow] = useState(false)
  return (
    <div>
      <Button color="primary" onClick={() => setShow(!show)}>
        Add New User
      </Button>
      <AddNewUserDrawer show={show} setShow={setShow}></AddNewUserDrawer>
    </div>
  )
}

export default index
