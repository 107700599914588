// ** Imports
import React, { Fragment } from "react"
import StatsHorizontal from '@components/widgets/stats/StatsHorizontal'
import { CheckSquare, Command, User, List } from "react-feather"
import { Row, Col } from "reactstrap"
const StatusCard = ({ files, erfiles }) => {
    let size = 0
    files.map(v => {
        /* const decimals = 2
        if (v.size === 0) { return '0 Bytes' }
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.floor(Math.log(v.size) / Math.log(k))
        // tslint:disable-next-line: radix
         size = `${parseInt((v.size / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        return size */

        size += v.size
        return size
    })
    // console.log((size / 1000000).toFixed(2))
    size = `${(size / 1000000).toFixed(1)} MB`
    if (size >= 1024) {
        size /= 1024
        return `${size.toFixed(1)} GB`
    }


    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    <Row>
                        <Col lg='3' sm='6'>
                            <StatsHorizontal icon={<CheckSquare size={21} />} color='primary' stats={files.length + erfiles.length} statTitle='Total Files' />
                        </Col>
                        <Col lg='3' sm='6'>
                            <StatsHorizontal icon={<Command size={21} />} color='danger' stats={erfiles.length} statTitle='Invalid Files' />
                        </Col>
                        <Col lg='3' sm='6'>
                            <StatsHorizontal icon={<User size={21} />} color='success' stats={files.length} statTitle='Valid Files' />
                        </Col>
                        <Col lg='3' sm='6'>
                            <StatsHorizontal icon={<List size={21} />} color='warning' stats={size} statTitle='Total Bytes' />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
}

export default StatusCard