import React, { useState } from "react"
import { Edit, MoreVertical, Trash } from "react-feather"
import { EditFilled } from "@ant-design/icons"
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import { useSelector } from "react-redux"
import toast from "react-hot-toast"
import PreferencesModal from "../../components/modals/PreferencesModal"
import VacationSetupModal from "../../components/modals/VacationSetupModal"
import AddEditCompanyModal from "../../components/modals/AddEditCompanyModal"
import { updateUserProfile } from "../../service/manageUserService"
// import { formatedValue } from "@utils"
import { ROLES_API } from "@service/apiUrl"
import useFetch from "@hooks/useFetch"
import checkTenantId from "@utility/helper/checkTenantId"
import moment from "moment"
import { getUserDetailsApi1 } from "../../../../services/apiUrl"

export const getLocation = (value) => {
  const id = Math.random().toString(36).substring(3)
  const badgeValue = value?.[0] ?? "-"
  const toolTipValue = value?.length - 1 ?? 0
  const toolTipList = value?.slice(1)
  return (
    <>
      <Badge pill style={{ marginRight: "4px" }}>
        {badgeValue}
      </Badge>
      {toolTipValue > 0 && (
        <>
          <Badge pill className="cursor-pointer" id={`id${id}`}>
            + {toolTipValue}
          </Badge>
          <UncontrolledTooltip
            placement="top"
            target={`id${id}`}
            style={{ backgroundColor: "#6c757d" }}
          >
            {toolTipList.map((val, i) => (
              <Badge
                pill
                color="text-white bg-primary"
                style={{ margin: "2px" }}
                key={`Id${id}${i}`}
              >
                {val}
              </Badge>
            ))}
          </UncontrolledTooltip>
        </>
      )}
    </>
  )
}
const BASE_ROLES = [
  "CRATO_ACCOUNTANT_PLUS",
  "CRATO_APPROVER",
  "CRATO_ACCOUNTADMIN",
  "SUPER_USER"
]
const CompanyTab = ({ userProfileData, companyData, paramValue, getCall }) => {
  const {
    tenantId,
    userName,
    basicProfile = { dateFormat: "MM/DD/YYYY" }
  } = useSelector((state) => state.auth.userData)
  const { dateFormat } = basicProfile
  const [showVacationModal, setShowVacationModal] = useState(false)
  const [showPreferenceModal, setShowPreferenceModal] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [showCompany, setShowCompany] = useState(false)
  const [header, setHeader] = useState("Add")

  const isTenantMatch = checkTenantId()

  const updateCall = async (payload) => {
    try {
      await updateUserProfile(payload)
      toast.success("Company Information Deleted Successfully")
      getCall()
    } catch (error) {
      toast.error("Error in Deleting")
    }
  }

  const [roleManagement = []] = useFetch(
    `${ROLES_API}/getCustomRolesByTenant`,
    {
      apiParams: {
        params: { tenantId }
      },
      callback: (response) =>
        response.userRoles?.map(({ roleName, baseRoles }) => ({
          roleName,
          roleCode: baseRoles?.roleCode
        }))
    }
  )

  const [forwardToData = []] = useFetch(
    `${getUserDetailsApi1}/userlistdetails`,
    {
      apiParams: {
        params: { ispageable: false, initial: false }
      },
      callback: (response) =>
        response.users
          ?.filter((i) => userName !== i.userName)
          .map(({ firstName, lastName, userName: value }) => ({
            label: `${firstName} ${lastName} - ${value}`,
            value
          }))
    }
  )
  const onEdit = (index, from) => {
    if (from === "Delete") {
      const userRoleAccess =
        userProfileData?.userRoleAccess?.filter((value, id) => id !== index) ??
        []
      return updateCall({
        ...userProfileData,
        userRoleAccess
      })
    }

    setSelectedIndex(index)
    setHeader("Edit")
    setShowCompany(true)
  }

  const renderCompanyTable = () => {
    return (
      <Table responsive className="mt-2 " style={{ marginBottom: "6.5rem" }}>
        <thead>
          <tr>
            <th>COMPANY CODE</th>
            <th>LOCATION</th>
            <th>DEPARTMENT</th>
            <th>USER ROLE</th>
            <th>TITLE</th>
            <th>APPROVAL LIMIT</th>
            {/* <th>NEXT LEVEL USER</th> */}
            {paramValue === "manageUsers" && <th></th>}
          </tr>
        </thead>
        <tbody>
          {userProfileData.userRoleAccess.map(
            (
              {
                clientName,
                approvalLimitFrom,
                approvalLimitTo,
                department,
                userRole,
                title,
                clientEntityName
                // nextLevelUser
              },
              i
            ) => (
              <tr key={i} id={`company-info-row-${i}`}>
                <td>
                  <span className="align-middle fw-bold">
                    {clientName ?? "-"}
                  </span>
                </td>
                <td>{getLocation(clientEntityName)}</td>
                <td>{department}</td>
                <td>{userRole}</td>
                <td>{title}</td>
                <td>
                  {approvalLimitFrom && approvalLimitTo
                    ? `${approvalLimitFrom} to ${approvalLimitTo}`
                    : roleManagement
                        .filter(({ roleName }) => userRole === roleName)
                        .some(({ roleCode }) => BASE_ROLES.includes(roleCode))
                    ? "NA"
                    : "-"}
                </td>
                {/* <td>{formatedValue(12, nextLevelUser)}</td> */}

                {paramValue === "manageUsers" && (
                  <td id={`edit-row-${i}`}>
                    <UncontrolledDropdown direction="start">
                      <DropdownToggle
                        className="icon-btn hide-arrow"
                        color="transparent"
                        size="sm"
                        caret
                      >
                        <MoreVertical size={15} />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          className="w-100"
                          onClick={(e) => {
                            e.preventDefault()
                            onEdit(i)
                          }}
                          id="edit-row-btn"
                        >
                          <Edit className="me-50" size={15} />{" "}
                          <span className="align-middle">Edit</span>
                        </DropdownItem>
                        <DropdownItem
                          className="w-100"
                          disabled={
                            userProfileData?.userRoleAccess?.length === 1
                          }
                          onClick={(e) => {
                            e.preventDefault()
                            onEdit(i, "Delete")
                          }}
                          id="delete-row-btn"
                        >
                          <Trash className="me-50" size={15} />{" "}
                          <span className="align-middle">Delete</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                )}
              </tr>
            )
          )}
        </tbody>
      </Table>
    )
  }

  return (
    <div>
      <AddEditCompanyModal
        show={showCompany}
        header={header}
        setShow={setShowCompany}
        userProfileData={userProfileData}
        index={selectedIndex}
        getCall={getCall}
      />
      {userProfileData && (
        <div>
          <VacationSetupModal
            show={showVacationModal}
            setShow={setShowVacationModal}
            userProfileData={userProfileData}
            forwardToData={forwardToData}
          />
          <PreferencesModal
            show={showPreferenceModal}
            setShow={setShowPreferenceModal}
            userProfileData={userProfileData}
          />

          {false && (
            <Card className={isTenantMatch ? "d-none" : ""}>
              <CardBody>
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Vacation Forward Setup</h4>
                    <EditFilled
                      className="text-end"
                      onClick={() => {
                        setShowVacationModal(!showVacationModal)
                      }}
                      style={{
                        fontSize: 24,
                        color: "#34a353",
                        paddingTop: "20px",
                        paddingRight: "20px"
                      }}
                      id="vacation-forward-setup-edit"
                    />
                  </div>
                </div>
                <Row className="mt-2">
                  <Col xl="7" xs="12">
                    <Row tag="dl" className="mb-0">
                      <Col tag="dt" sm="6" className="fw-bolder mb-1">
                        Turn on Vacation Forward:
                      </Col>
                      <Col tag="dd" sm="6" className="mb-1">
                        {userProfileData.vacationForward ? "Yes" : "No"}
                      </Col>

                      <Col tag="dt" sm="6" className="fw-bolder mb-1">
                        ForwardTo:
                      </Col>
                      <Col tag="dd" sm="6" className="mb-1">
                        {userProfileData.forwardedUser}
                      </Col>

                      <Col tag="dt" sm="6" className="fw-bolder mb-1">
                        Notify when Forwarded:
                      </Col>
                      <Col tag="dd" sm="6" className="mb-1">
                        {userProfileData.forwardNotification ? "Yes" : "No"}
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="5" xs="12">
                    <Row tag="dl" className="mb-0">
                      <Col tag="dt" sm="6" className="fw-bolder mb-1">
                        Start Date:
                      </Col>
                      <Col tag="dd" sm="6" className="mb-1">
                        {moment(userProfileData.startDate).format(dateFormat)}
                      </Col>

                      <Col tag="dt" sm="6" className="fw-bolder mb-1">
                        End date:
                      </Col>
                      <Col tag="dd" sm="6" className="mb-1">
                        {moment(userProfileData.endDate).format(dateFormat)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="my-2">
                  <h6 className="fw-bolder">Personalized Message:</h6>
                  <p>{userProfileData.message}</p>
                </div>
              </CardBody>
            </Card>
          )}
          {paramValue === "Profile" && false && (
            <Card>
              <CardBody>
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Preferences</h4>
                    <Button
                      color="primary"
                      className="mx-1"
                      onClick={() => {
                        setShowPreferenceModal(!showPreferenceModal)
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
                <Row className="mt-3">
                  <Col xl="7" xs="12">
                    <Row tag="dl" className="mb-0">
                      <Col tag="dt" sm="6" className="fw-bolder mb-1">
                        Email Notification:
                      </Col>
                      <Col tag="dd" sm="6" className="mb-1">
                        {userProfileData.emailNotification ? "Yes" : "No"}
                      </Col>

                      <Col tag="dt" sm="6" className="fw-bolder mb-1">
                        Email frequency:
                      </Col>
                      <Col tag="dd" sm="6" className="mb-1">
                        {userProfileData.emailFrequency}
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="5" xs="12">
                    <Row tag="dl" className="mb-0">
                      <Col tag="dt" sm="6" className="fw-bolder mb-1">
                        Main Layout:
                      </Col>
                      <Col tag="dd" sm="6" className="mb-1">
                        {userProfileData.mainLayout}
                      </Col>

                      <Col tag="dt" sm="6" className="fw-bolder mb-1">
                        Desktop Notification:
                      </Col>
                      <Col tag="dd" sm="6" className="mb-1">
                        {userProfileData.desktopNotification ? "Yes" : "No"}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}

          <Card>
            <CardBody style={{ padding: "0" }}>
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ padding: "1.5rem", paddingBottom: "0" }}
                >
                  <h4 className="mb-0">Company Information</h4>
                  {paramValue === "manageUsers" &&
                    userProfileData?.userRoleAccess?.length !==
                      companyData.length && (
                      <div>
                        <Button
                          color="primary"
                          className="mx-1"
                          onClick={() => {
                            setSelectedIndex(null)
                            setHeader("Add")
                            setShowCompany(true)
                          }}
                          id="add-btn"
                        >
                          Add
                        </Button>
                      </div>
                    )}
                </div>
              </div>
              {renderCompanyTable()}
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  )
}

export default CompanyTab
