// ** React Iport
import { forwardRef, Fragment } from "react"

// ** Third Party Components
import ReactPaginate from 'react-paginate'
import DataTable from 'react-data-table-component'
import { ChevronDown, Command, Database } from 'react-feather'

// ** Reactstrap Import
import { Card, Col, Input, Row } from "reactstrap"

// ** Component Imports
import { tableColumn } from "./tableColumn"
import TableHeader from "./TableHeader"

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'

const data = [
    {
        posName: 'SHOPIFY',
        storeName: 'cratostores',
        saleDate: '01/01/2022',
        type: 'Sales Summary',
        status: 'Pushed',
        action: '-'
    },
    {
        posName: 'SHOPIFY',
        storeName: 'cratostores',
        saleDate: '01/01/2022',
        type: 'Sales Summary',
        status: 'Pending Config',
        action: 'Configure, Re post'
    }
]

const SalesTable = () => {

    // ** Bootstrap Checkbox Component
    const BootstrapCheckbox = forwardRef((props, ref) => (
        <div className='form-check'>
            <Input type='checkbox' className='cursor-pointer' ref={ref} {...props} />
        </div>
    ))


    // ** Custom Pagination
    const CustomPagination = () => {
        // const count = Number(Math.ceil(vendorCount / vendorFilters.count))
        // const formatedPage = page(vendorList.length, count, vendorCount)
        return (
            <Row className='align-items-center'>
                <Col md={5} sm={12}>
                    <div className='ps-md-1 ps-0 text-center text-md-start'>
                        {/* {formatedPage} of {vendorCount} */} 1 - 2 of 2
                    </div>
                </Col>
                <Col md={7} sm={12} className='text-sm-center text-md-start'>
                    <ReactPaginate
                        previousLabel={''}
                        nextLabel={''}
                        pageCount={2}
                        activeClassName='active'
                        // forcePage={vendorFilters.page !== 0 ? vendorFilters.page - 1 : 0}
                        // onPageChange={page => handlePagination(page)}
                        pageClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        nextClassName={'page-item next'}
                        previousClassName={'page-item prev'}
                        previousLinkClassName={'page-link'}
                        pageLinkClassName={'page-link'}
                        containerClassName={'pagination react-paginate justify-content-md-start justify-content-center my-2 pe-1'}
                    />
                </Col>
            </Row>
        )
    }

    return (
        <Fragment>
            <Card className='overflow-hidden'>
                <div className='react-dataTable'>
                    <DataTable
                        noHeader
                        subHeader
                        sortServer
                        pagination
                        responsive
                        paginationServer
                        columns={tableColumn}
                        // onSort={handleSort}
                        sortIcon={<ChevronDown />}
                        className='react-dataTable'
                        paginationComponent={CustomPagination}
                        data={data}
                        selectableRowsComponent={BootstrapCheckbox}
                        // onSelectedRowsChange={getSelectedRow}
                        // customStyles={rowHoverStyle}
                        onRowClicked={() => {
                            // setShow(true)
                        }}
                        subHeaderComponent={
                            <TableHeader
                            />
                        }
                    />
                </div>
            </Card>
        </Fragment>
    )
}

export default SalesTable