// ** Reactstrap Imports
import { Button, Nav, NavItem, NavLink } from "reactstrap"

// ** Icons Imports
import { User, Lock, Bell, X } from "react-feather"
import { useNavigate } from "react-router-dom"

const Tabs = ({ activeTab, toggleTab, paramValue }) => {
  const nav = useNavigate()

  return (
    <div className="d-flex justify-content-between">
      <Nav pills className="mb-2">
        <NavItem>
          <NavLink active={activeTab === "1"} onClick={() => toggleTab("1")}>
            <User size={18} className="me-50" />
            <span className="fw-bold" id="account ">
              Account
            </span>
          </NavLink>
        </NavItem>
        {paramValue === "Profile" && (
          <NavItem>
            <NavLink active={activeTab === "2"} onClick={() => toggleTab("2")}>
              <Lock size={18} className="me-50" />
              <span className="fw-bold" id="security">
                Security
              </span>
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink active={activeTab === "3"} onClick={() => toggleTab("3")}>
            <Bell size={18} className="me-50" />
            <span className="fw-bold" id="timeline">
              Timeline
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <Nav>
        <NavItem>
          <Button
            color="flat-primary"
            className="p-50"
            onClick={() => nav(-1)}
            id="detail-view-close-btn"
          >
            <X size={22} />
          </Button>
        </NavItem>
      </Nav>
    </div>
  )
}

export default Tabs
