import { useDispatch, useSelector } from "react-redux"
import { INITIAL_VALUES } from "../../redux/filters/constants"
import {
  handleFilter,
  handleClear,
  setActiveFilter,
  handleClearAll
} from "../../redux/filters/index"

const useFilter = (page = "ALL") => {
  const dispatch = useDispatch()

  const [store, isFilterActive] = useSelector(({ filters }) => [
    page === "ALL" ? filters : filters[page],
    filters.ACTIVE_FILTERS.includes(page)
  ])

  const setFilter = (payload) => {
    dispatch(handleFilter({ key: page, value: payload }))
  }
  const clearFilter = () => {
    dispatch(handleClear({ key: page }))
    return INITIAL_VALUES[page]
  }
  const setFilterActive = (value) => {
    dispatch(setActiveFilter({ key: page, value }))
  }
  const clearAllFiter = () => {
    dispatch(handleClearAll())
  }

  return [
    store,
    setFilter,
    clearFilter,
    { setFilterActive, isFilterActive, clearAllFiter }
  ]
}
export default useFilter
