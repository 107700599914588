// ** Icons Import
import {
  Circle,
  FileText,
  Shield,
  ShoppingBag,
  Truck,
  Book
} from "react-feather"

export default [
  {
    id: "Expenses",
    title: "Payables",
    icon: ShoppingBag,
    accessId: "Payables",
    children: [
      {
        id: "Exp_Vendor_Management",
        title: "Vendors",
        icon: Truck,
        accessId: "Vendor_Management",
        accessRestricted: true,
        navLink: "/v2/ap/vendor/list"
      },
      {
        id: "Exp_Bill_Processing",
        title: "Bill Processing",
        icon: FileText,
        accessId: "Bill_Processing",
        children: [
          // {
          //   id: 'in-process',
          //   title: 'In Process',
          //   icon: Circle,
          //   navLink: '/v2/ap/invoice/processing'

          // },
          {
            id: "Exp_BP_IP",
            title: "In Process",
            icon: Circle,
            accessId: "Bill_Processing",
            isHaveGrantParent: true,
            grantParentId: "Payables",
            navLink: "/v2/ap/invoice/processing"
          },
          {
            id: "Exp_BP_AB",
            title: "All Bills",
            icon: Circle,
            accessId: "Bill_Processing",
            isHaveGrantParent: true,
            grantParentId: "Payables",
            navLink: "/v2/ap/invoice/allbills"
          }
        ]
      },
      {
        id: "Exp_Payments",
        title: "Payments",
        icon: Shield,
        accessId: "Pay_Bills",
        children: [
          {
            id: "Exp_Payments_Mk",
            title: "Make Payments",
            icon: Circle,
            accessId: "Pay_Bills",
            isHaveGrantParent: true,
            grantParentId: "Payables",
            navLink: "/v2/ap/cratopay/make-payments"
          },
          {
            id: "Exp_Payments_Tk",
            title: "Track Payments",
            icon: Circle,
            accessId: "Pay_Bills",
            isHaveGrantParent: true,
            grantParentId: "Payables",
            navLink: "/v2/ap/cratopay/track-payment"
          },
          {
            id: "Exp_Payments_AP",
            title: "Apply Payments",
            icon: Circle,
            accessId: "Pay_Bills",
            isHaveGrantParent: true,
            grantParentId: "Payables",
            navLink: "/v2/ap/cratopay/apply-bank-payments"
          }
          // {
          //   id: 'record-payments',
          //   title: 'Record Payments',
          //   icon: Circle,
          //   navLink: '/apps/todo'
          // }
        ]
      },
      {
        id: "Exp_PO",
        title: "Purchase Order",
        icon: Book,
        accessId: "Purchase_Order",
        accessRestricted: true,
        navLink: "/v2/ap/purchase-order"
      }
    ]
  }
]
