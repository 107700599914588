// ** React Imports
import { Fragment, useState, forwardRef, useRef } from "react"

// ** Third Party Components
import PropTypes from "prop-types"
import classnames from "classnames"
import { Eye, EyeOff } from "react-feather"
import useKeyBinding from "@hooks/useKeyBinding"
// ** Reactstrap Imports
import { InputGroup, Input, InputGroupText, Label } from "reactstrap"
const CAPSLOCK_VALUE = 20
const InputPasswordToggle = forwardRef((props, ref) => {
  // ** Props
  const {
    label,
    hideIcon,
    showIcon,
    visible,
    className,
    htmlFor,
    placeholder,
    iconSize,
    inputClassName,
    invalid,
    noEye = false,
    ...rest
  } = props

  // ** State
  const [inputVisibility, setInputVisibility] = useState(visible)
  const inputRef = useRef(null)
  const isCapsLocktrigger =
    useKeyBinding(inputRef, CAPSLOCK_VALUE, "CapsLock") && !rest.disabled
  // ** Renders Icon Based On Visibility
  const renderIcon = () => {
    const size = iconSize ? iconSize : 14

    if (inputVisibility === false) {
      return hideIcon ? hideIcon : <EyeOff size={size} />
    } else {
      return showIcon ? showIcon : <Eye size={size} />
    }
  }

  return (
    <Fragment>
      {label ? (
        <Label className="form-label" for={htmlFor}>
          {label}
        </Label>
      ) : null}
      <InputGroup
        className={classnames({
          [className]: className,
          "is-invalid": invalid
        })}
      >
        <Input
          /* autoFocus */
          ref={ref}
          invalid={invalid}
          innerRef={inputRef}
          type={inputVisibility ? "text" : "password"}
          placeholder={placeholder ? placeholder : "············"}
          className={classnames(
            { "capslock-warning": isCapsLocktrigger },
            { [inputClassName]: inputClassName }
          )}
          /*eslint-disable */
          {...(label && htmlFor
            ? {
                id: htmlFor
              }
            : {})}
          {...rest}
          /*eslint-enable */
        />
        <InputGroupText
          className="cursor-pointer"
          onClick={() => setInputVisibility(!inputVisibility)}
          {...(isCapsLocktrigger ? { style: { borderColor: "#ff9f43" } } : {})}
        >
          {noEye || renderIcon()}
        </InputGroupText>
      </InputGroup>
      {isCapsLocktrigger && (
        <span className="capslock-warning-error">Caps Lock is on</span>
      )}
    </Fragment>
  )
})

export default InputPasswordToggle

// ** PropTypes
InputPasswordToggle.propTypes = {
  invalid: PropTypes.bool,
  hideIcon: PropTypes.node,
  showIcon: PropTypes.node,
  visible: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  iconSize: PropTypes.number,
  inputClassName: PropTypes.string,
  label(props, propName) {
    // ** If label is defined and htmlFor is undefined throw error
    if (props[propName] && props["htmlFor"] === "undefined") {
      throw new Error("htmlFor prop is required when label prop is present")
    }
  },
  htmlFor(props, propName) {
    // ** If htmlFor is defined and label is undefined throw error
    if (props[propName] && props["label"] === "undefined") {
      throw new Error("label prop is required when htmlFor prop is present")
    }
  }
}

// ** Default Props
InputPasswordToggle.defaultProps = {
  visible: false
}
