// ** React Imports
import { useSelector } from "react-redux"

// ** Menu Components Imports
import HorizontalNavMenuLink from "./HorizontalNavMenuLink"
import HorizontalNavMenuGroup from "./HorizontalNavMenuGroup"
import {
  canViewMenuGroup,
  canViewMenuItem,
  resolveHorizontalNavMenuItemComponent as resolveNavItemComponent
} from "@layouts/utils"

const HorizontalNavMenuItems = (props) => {
  // ** Components Object
  const Components = {
    HorizontalNavMenuGroup,
    HorizontalNavMenuLink
  }

  // ** user data from redux store
  const { userData } = useSelector((state) => state.auth)

  // ** Render Nav Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]
    if (item.children) {
      return (
        canViewMenuGroup(item, userData) && (
          <TagName item={item} index={index} key={item.id} {...props} />
        )
      )
    }
    return (
      canViewMenuItem(item, userData) && (
        <TagName item={item} index={index} key={item.id} {...props} />
      )
    )
  })

  return RenderNavItems
}

export default HorizontalNavMenuItems
