// ** Icons Import
import { Book, BookOpen, Circle, CreditCard, Pocket } from "react-feather"

export default [
  {
    id: "crato-ledge",
    title: "CratoLedge",
    icon: Book,
    children: [
      /*  {
        id: 'build-je',
        title: 'Build JE',
        icon: <BookOpen style={{ height: "10px", aspectRatio: "1" }} />,
        navLink: '/datatables/basic',
        children: [
          {
            id: 'accural-je',
            title: 'Accural JE',
            icon: <Circle style={{ height: "10px", aspectRatio: "1" }} />,
            navLink: '/datatables/basic'
          },
          {
            id: 'payroll-je',
            title: 'Payroll JE',
            icon: <Circle style={{ height: "10px", aspectRatio: "1" }} />,
            navLink: '/datatables/advance'
          }
        ]
      },
      {
        id: 'credit-card-posting',
        title: 'Credit Card Posting',
        icon: <CreditCard style={{ height: "10px", aspectRatio: "1" }} />,
        navLink: '/datatables/advance'
      },
      {
        id: 'bank-posting',
        title: 'Bank Postings',
        icon: <Pocket style={{ height: "10px", aspectRatio: "1" }} />,
        navLink: '/apps/todo'
      } */
    ]
  }
]
