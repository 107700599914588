import { useDropzone } from "react-dropzone"
import { DownloadCloud } from "react-feather"

export default function DropZoneWrapper(props) {
  const { dropZoneOptions, style, children } = props
  const { getRootProps, getInputProps } = useDropzone(dropZoneOptions)
  return (
    <>
      <div {...getRootProps({ className: "dropzone" })} style={style}>
        <input {...getInputProps()} id="dropZone" />
        {children ?? <DefaultComponent />}
      </div>
    </>
  )
}

function DefaultComponent() {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <DownloadCloud size={50} />
      <small className="mt-1">Drop Files here or click to upload</small>
    </div>
  )
}
