import { useState } from "react"
import {
  Card,
  CardHeader,
  CardTitle,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  CardBody,
  CardSubtitle
} from "reactstrap"
// ** Third Party Components
import { Bar } from "react-chartjs-2"

// ** Reactstrap Imports
const [warning, gridLineColor, labelColor, info] = [
  "#ff9f43",
  "#f4f4f4",
  "#000",
  "#00cfe8"
]
const unapplied = {
  maxBarThickness: 15,
  label: "Unapplied",
  backgroundColor: warning,
  borderColor: "transparent"
}
const Unidentified = {
  maxBarThickness: 15,
  backgroundColor: info,
  label: "Unidentified",
  borderColor: "transparent"
}
const rangeOptions = [
  {
    range: "Last 5 Days",
    data: {
      labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"],
      datasets: [
        { ...unapplied, data: [710, 350, 580, 460, 120] },
        {
          ...Unidentified,
          data: [430, 590, 510, 240, 360]
        }
      ]
    }
  },
  {
    range: "Last month",
    data: {
      labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
      datasets: [
        { ...unapplied, data: [310, 210, 433, 212] },
        {
          ...Unidentified,
          data: [321, 425, 533, 478]
        }
      ]
    }
  },
  {
    range: "Last Quarter",
    data: {
      labels: ["Month 1", "Month 2", "Month 3"],
      datasets: [
        { ...unapplied, data: [430, 525, 363] },
        {
          ...Unidentified,
          data: [543, 324, 321]
        }
      ]
    }
  },
  {
    range: "Last Year",
    data: {
      labels: ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"],
      datasets: [
        { ...unapplied, data: [623, 453, 432, 122] },
        {
          ...Unidentified,
          data: [546, 321, 432, 231]
        }
      ]
    }
  }
]
// ** Chart Options
const options = {
  indexAxis: "y",
  responsive: true,
  maintainAspectRatio: false,
  animation: { duration: 500 },
  elements: {
    bar: {
      borderRadius: {
        topRight: 15,
        bottomRight: 15
      }
    }
  },
  layout: {
    padding: { top: -4 }
  },
  scales: {
    x: {
      min: 0,
      grid: {
        drawTicks: false,
        color: gridLineColor,
        borderColor: "transparent"
      },
      ticks: { color: labelColor }
    },
    y: {
      grid: {
        display: false,
        borderColor: gridLineColor
      },
      ticks: { color: labelColor }
    }
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        padding: 25,
        boxWidth: 9,
        color: labelColor,
        usePointStyle: true
      }
    }
  }
}

const UnAppliedDeposits = () => {
  const [activeRange, setActiveRange] = useState(rangeOptions[0])

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column pb-0 position-relative">
        <CardTitle tag="h4">Unapplied / Unidentified Deposits</CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
          >
            {activeRange.range}
          </DropdownToggle>
          <DropdownMenu end>
            {rangeOptions.map((item, i) => (
              <DropdownItem
                className="w-100"
                key={i}
                onClick={() => {
                  setActiveRange(item)
                }}
              >
                {item.range}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <CardSubtitle
          style={{
            position: "absolute",
            left: "24px",
            bottom: "-10px",
            fontSize: "12px"
          }}
        >
          By Amount
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <div style={{ height: "340px" }}>
          <Bar data={activeRange.data} options={options} height={340} />
        </div>
      </CardBody>
    </Card>
    // <div>DeductionWidget</div>
  )
}

export default UnAppliedDeposits
