import axios from "axios"
import qs from "qs"
import {
  clientDetailsApi,
  getCratoPayUrl,
  GET_BASE_ROLE,
  invoiceFilterApi1,
  vendorViewApi,
  invoiceApi,
  saveAstraDetailsUrl,
  getAstraDetailsUrl,
  saveAstraAuthCodeUrl,
  allAccountDataUrl,
  vendorFilterApi,
  linkAstraBankAccUrl,
  linkVendorAccUrl,
  markAsPaidUrl,
  getDownloadUrl,
  getInvoiceBillCount
} from "../../../../services/apiUrl"

export const getAllBills = (query) => {
  return axios.get(`${invoiceFilterApi1}?${query}`)
}

export const getVendorById = (vendorId) => {
  return axios.get(`${vendorViewApi}/${vendorId}`)
}

export const getAllBatchList = (clientId) => {
  return axios.get(`${getCratoPayUrl}/getallbatches?clientId=${clientId}`)
}

export const postApplyPayments = (payload) => {
  return axios.post(`${getCratoPayUrl}/createpaymentbatch`, payload)
}

export const getBatchDataById = (id, params) => {
  return axios.get(`${getCratoPayUrl}/getbatchbyid/${id}`, { params })
}

export const getPaymentAccounts = (clientId, entity) => {
  return axios.get(`${getCratoPayUrl}/businesscustclient/${clientId}/${entity}`)
}

export const getBaseRoles = (formData) => {
  return axios.post(`${GET_BASE_ROLE}`, formData)
}

export const getClientDetail = (clientId) => {
  return axios.get(`${clientDetailsApi}/${clientId}`)
}

export const putClientDetail = (formData) => {
  return axios.put(`${clientDetailsApi}`, formData)
}

export const putBatchDataById = (id, formData) => {
  return axios.put(`${getCratoPayUrl}/updatepaymentBatchbyid/${id}`, formData)
}

export const postInvoiceStatusApi = (userName, status, payload) => {
  console.log(userName)
  return axios.post(`${invoiceApi}/bulkupdatestatus?status=${status}`, payload)
}

export const postInvoicePartillyPaid = (urn, payload) => {
  return axios.put(`${invoiceApi}/${urn}`, payload)
}

export const trackPaymentFilters = (query) => {
  return axios.get(`${getCratoPayUrl}/getpaymenbatchdetails?${query}`)
}

export const saveAstraDetails = (payload, state) =>
  axios.post(`${saveAstraDetailsUrl}?state=${state}`, payload)
export const getAstraDetails = (clientId) =>
  axios.get(`${getAstraDetailsUrl}${clientId}`)
export const saveAstraAuthCode = (clientId, code) =>
  axios.post(
    `${saveAstraAuthCodeUrl}?clientId=${clientId}&code=${code}&userType=business`
  )
export const getBankAccounts = (clientId) =>
  axios.get(`${allAccountDataUrl}/${clientId}`)
export const getAchVendors = (params) =>
  axios.get(vendorFilterApi, {
    params
  })
export const linkAstraBankAcc = (clientId, payload) =>
  axios.post(`${linkAstraBankAccUrl}${clientId}`, payload)

export const linkVendorAccount = (clientId, payload) =>
  axios.post(`${linkVendorAccUrl}${clientId}`, payload)

export const postMaskAsPaid = (payload) => axios.post(markAsPaidUrl, payload)

export const getS3DownloadUrl = (fileName, clientId) => {
  return axios.get(
    `${getDownloadUrl}?fileName=${fileName}&clientId=${clientId}`
  )
}

export const getPayment = (params) =>
  axios.get(`${getInvoiceBillCount}/paymentinvoice`, {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" })
  })

export const getMakePaymentListApi = (query) => {
  return axios.get(`${getInvoiceBillCount}/paymentinvoice?${query}`)
}
export const generateNatchaFile = (batchId) =>
  axios.get(`${getCratoPayUrl}/nacha/${batchId}`)
