// ** React Imports
import { lazy } from "react"

const VendorList = lazy(() =>
  import("../../views/expenses/vendor/vendor-list/index")
)
// const InvoiceProcessing = lazy(() => import('../../views/Home'))
const VendorView = lazy(() =>
  import("../../views/expenses/vendor/vendor-view/index")
)
const AllBills = lazy(() =>
  import("../../views/expenses/enter-bills/invoice-list/index")
)
const BillProcessing = lazy(() =>
  import("../../views/expenses/enter-bills/bills-processing/index")
)

const InvoiceEdit = lazy(() =>
  import("../../views/expenses/invoice-edit/index")
)
const MakePayments = lazy(() =>
  import("../../views/expenses/cratopay/make-payments/index")
)
const ApplyPayments = lazy(() =>
  import(
    "../../views/expenses/cratopay/make-payment-actions/apply-payment-landing/index"
  )
)
const PayNow = lazy(() =>
  import("../../views/expenses/cratopay/make-payment-actions/pay-now/index")
)
const CreateBatch = lazy(() =>
  import("../../views/expenses/cratopay/create-batch/index")
)
const TrackPayment = lazy(() =>
  import("../../views/expenses/cratopay/track-payment/index")
)
const ViewBatch = lazy(() =>
  import("../../views/expenses/cratopay/view-batch/index")
)

const TrackPaymentById = lazy(() =>
  import("../../views/expenses/cratopay/track-payment/viewbyid")
)

const ApplyBankPayments = lazy(() =>
  import(
    "../../views/expenses/cratopay/apply-payments/apply-payments-list/index"
  )
)
const ApplyBankPaymentsView = lazy(() =>
  import(
    "../../views/expenses/cratopay/apply-payments/apply-payments-view/index"
  )
)
const CreateInvoice = lazy(() =>
  import("../../views/expenses/create-invoice/index")
)
const NewMakePayments = lazy(() =>
  import("../../views/expenses/cratopay/expense-make-payments")
)
const NewMakePaymentsActions = lazy(() =>
  import("../../views/expenses/cratopay/expense-make-payments/actions")
)

import CratopayConfiguration from "../../views/expenses/cratopay/configuration"
import RemittanceNoticeSample from "../../views/expenses/cratopay/configuration/components/RemittanceNoticeSample"

const PurchaseOrderList = lazy(() => import("../../views/expenses/PO/list"))
const PurchaseOrderView = lazy(() => import("../../views/expenses/PO/view"))
// ** Role Permission List
import { permissions } from "../../views/role-management/store/permissionList"
import UploadLinetems from "../../views/expenses/invoice-edit/components/upload-line-items"

const permissionList = Object.keys(permissions)
const expensesList = Object.keys(permissions.Expenses)

const ExpenseRoutes = [
  {
    accessMap: "Exp_BP_AB",
    path: "/ap/invoice/allbills",
    element: <AllBills />,
    accessId: expensesList[1],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_BP_IP",
    path: "/ap/invoice/processing",
    element: <BillProcessing />,
    accessId: expensesList[1],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Vendor_Management",
    path: "/ap/vendor/list",
    element: <VendorList />,
    accessId: expensesList[0],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Vendor_Management",
    path: "/ap/vendor/view/:id",
    element: <VendorView />,
    accessId: expensesList[0],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_PO",
    path: "/ap/purchase-order",
    element: <PurchaseOrderList />,
    accessId: expensesList[0],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_PO",
    path: "/ap/purchase-order/:mode",
    element: <PurchaseOrderView />,
    accessId: expensesList[0],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: ["Exp_BP_AB", "Exp_BP_IP"],
    path: "/ap/invoice/edit/:urn",
    element: <InvoiceEdit />,
    accessId: expensesList[1],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      layout: "blank",
      accessRestricted: true
      // className: 'chat-application'
    }
  },
  {
    accessMap: "Exp_Payments_Mk",
    path: "/ap/cratopay/make-payments",
    element: <MakePayments />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: ["Exp_Payments_Mk", "Exp_Payments_Tk"],
    path: "/ap/cratopay/apply-payments/:vendorId",
    element: <ApplyPayments />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Payments_Mk",
    path: "/ap/cratopay/pay-now/:vendorId",
    element: <PayNow />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Payments_Mk",
    path: "/ap/cratopay/create-batch",
    element: <CreateBatch />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Payments_Mk",
    path: "/beta/make-payments",
    element: <NewMakePayments />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Payments_Mk",
    path: "/beta/apply-payment/:id",
    element: <NewMakePaymentsActions />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Payments_Mk",
    path: "/beta/pay-now/:id",
    element: <NewMakePaymentsActions />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Payments_Mk",
    path: "/beta/create-batch",
    element: <NewMakePaymentsActions />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Payments_Tk",
    path: "/ap/cratopay/track-payment",
    element: <TrackPayment />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_Payments_Tk",
    path: "/ap/cratopay/track-payment/:id",
    element: <TrackPaymentById />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },

  {
    accessMap: ["Exp_Payments_Mk", "Exp_Payments_Tk"],
    path: "/ap/cratopay/view-batch",
    element: <ViewBatch />,
    meta: {
      accessRestricted: true,
      restricted: true
    }
  },
  {
    path: "/ap/cratopay/apply-bank-payments",
    element: <ApplyBankPayments />,
    meta: {
      restricted: true
    }
  },
  {
    path: "/ap/cratopay/apply-bank-payments/:page",
    element: <ApplyBankPaymentsView />,
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Exp_BP_AB",
    path: "/ap/expense/create-invoice",
    element: <CreateInvoice />,
    accessId: expensesList[1],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_BP_AB",
    path: "/ap/expense/create-credit-memo",
    element: <CreateInvoice />,
    accessId: expensesList[1],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Exp_BP_AB",
    path: "/ap/invoice/edit/upload-line-items",
    element: <UploadLinetems />,
    accessId: expensesList[1],
    menuGroup: permissionList[1],
    meta: {
      restricted: true,
      // layout: "blank",
      accessRestricted: true
    }
  }
]

export default ExpenseRoutes
