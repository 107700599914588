import React, { useMemo } from "react"
import classnames from "classnames"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Select from "react-select"
import { selectThemeColors } from "@utils"
import { Controller, useForm } from "react-hook-form"
import { CarryOutOutlined } from "@ant-design/icons"
import {
  Button,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap"

const AddNewUserCompanyModal = ({
  isCompanyModal,
  setIsCompanyModal,
  treeData,
  setTreeData,
  userRoleLoading,
  roleData,
  companyData
}) => {
  const addUserSchema = yup.object().shape({
    // firstName: yup.string().required(),
    // lastName: yup.string().required(),
    // email: yup.string().email().required(),
    // contact: yup.string(),
    company: yup.object().required(),
    role: yup.object().required()
  })
  const defaultValues = {
    // firstName: "",
    // lastName: "",
    // email: "",
    // contact: "",
    company: "",
    role: ""
  }
  const formProps = useForm({
    defaultValues,
    resolver: yupResolver(addUserSchema)
  })
  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    formState: { errors }
  } = formProps

  const cancel = () => {
    setIsCompanyModal(!isCompanyModal)
    reset()
  }
  const LoadingMessage = () => <span>Loading...</span>

  const renderRoleList = useMemo(() => {
    if (userRoleLoading) return []
    return roleData.map((data) => ({
      value: data.roleId,
      label: data.roleName,
      baseRole: data.baseRoles
    }))
  }, [userRoleLoading])

  const onSubmit = (e) => {
    console.log(e, "!--eeevent")
    const res = [
      {
        title: e?.company?.label,
        key: `${treeData.length}-${treeData[0]?.children?.length || 0}`,
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Business Unit",
            key: "0-0-0",
            icon: <CarryOutOutlined />,
            children: [
              {
                title: "Class",
                key: "0-0-0-0",
                icon: <CarryOutOutlined />
              },
              {
                title: "Customer Job",
                key: "0-0-0-1",
                icon: <CarryOutOutlined />
              },
              {
                title: "Profit Code",
                key: "0-0-0-3",
                icon: <CarryOutOutlined />
              },
              {
                title: "Test",
                key: "0-0-0-4",
                icon: <CarryOutOutlined />
              }
            ]
          }
        ]
      }
    ]
    setTreeData((prevTreeData) => [...prevTreeData, res])
    cancel()
  }
  return (
    <div>
      <Modal
        isOpen={isCompanyModal}
        className="modal-dialog-centered modal-sm"
        zIndex={1299}
      >
        <ModalHeader className="bg-transparent" toggle={cancel}></ModalHeader>
        <ModalBody>
          <h3 className="text-center mb-2">Choose Your Company and Role</h3>
          <div>
            <Label>
              Company <span className="text-danger">*</span>
            </Label>
            <Controller
              control={control}
              id="company"
              name="company"
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    // value={selectedCompany}
                    theme={selectThemeColors}
                    classNamePrefix="select"
                    // defaultValue={company}
                    options={companyData}
                    isClearable={false}
                    className={classnames("react-select", {
                      "is-invalid": errors.company && true
                    })}
                    // onChange={(e) => {
                    //   console.log(e, "event--")
                    //   field.onChange(e)
                    //   setValue("company", e)
                    //   setSelectedCompany(e)
                    // }}
                    // filterOption={filterOptions}
                    id="company-name"
                    // hideSelectedOptions={selectedCompany}
                  />
                </>
              )}
            />

            {errors.company && (
              <FormFeedback>{"Company is Requried"}</FormFeedback>
            )}
          </div>
          <div className="mt-2">
            <Label className="form-label" for="userRole">
              User Role <span className="text-danger">*</span>
            </Label>
            <Controller
              control={control}
              id="role"
              name="role"
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    theme={selectThemeColors}
                    options={renderRoleList}
                    isClearable={false}
                    // isDisabled={}
                    loadingMessage={userRoleLoading && LoadingMessage}
                    isLoading={userRoleLoading}
                    classNamePrefix="select"
                    className={classnames("react-select", {
                      "is-invalid": errors.role && true
                    })}
                    id="user-role"
                  />
                </>
              )}
            />
            {errors.role && (
              <FormFeedback>{"UserRole is Requried"}</FormFeedback>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center gap-1 mt-3">
            <div>
              <Button
                outline
                onClick={() => setIsCompanyModal(!isCompanyModal)}
              >
                Discard
              </Button>
            </div>
            <div>
              <Button color="primary" onClick={handleSubmit(onSubmit)}>
                Submit
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AddNewUserCompanyModal
