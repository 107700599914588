import { useState } from "react"
import Chart from "react-apexcharts"
import {
  Card,
  CardHeader,
  CardTitle,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Row,
  Col,
  CardText,
  CardBody,
  CardSubtitle
} from "reactstrap"
const rangeOptions = [
  {
    range: "Last 7 Days",
    data: { total: 200, unapplied: 50, unidentified: 80, applied: 70 }
  },
  {
    range: "Last month",
    data: { total: 400, unapplied: 150, unidentified: 90, applied: 160 }
  },
  {
    range: "Last Quater",
    data: { total: 600, unapplied: 200, unidentified: 190, applied: 210 }
  },
  {
    range: "Last Year",
    data: { total: 1000, unapplied: 350, unidentified: 350, applied: 300 }
  }
]
const DepositWidget = () => {
  const [activeRange, setActiveRange] = useState(rangeOptions[0])

  const options = {
    width: "150px",
    plotOptions: {
      radialBar: {
        // size: 150,
        // offsetY: 20,
        // startAngle: -150,
        // endAngle: 150,
        hollow: {
          size: "65%"
        },
        track: {
          background: "#fff",
          strokeWidth: "100%"
        },
        dataLabels: {
          name: {
            offsetY: -5,
            color: "#33cc33",
            fontSize: "1rem"
          },
          value: {
            offsetY: 15,
            fontSize: "1.714rem"
          }
        }
      }
    },
    colors: ["#9563c7"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#e5555e"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      dashArray: 8
    },
    labels: ["Applied"]
  }
  return (
    <Card>
      <CardHeader className="d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column pb-0">
        <CardTitle tag="h4">Deposits</CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
          >
            {activeRange.range}
          </DropdownToggle>
          <DropdownMenu end>
            {rangeOptions.map((item, i) => (
              <DropdownItem
                className="w-100"
                key={i}
                onClick={() => {
                  setActiveRange(item)
                }}
              >
                {item.range}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      <CardBody>
        <CardSubtitle
          style={{
            position: "absolute",
            left: "24px",
            top: "56px",
            fontSize: "12px"
          }}
        >
          By Count
        </CardSubtitle>
        <Row className="mt-2">
          <Col
            sm="4"
            className="d-flex flex-column flex-wrap justify-content-center align-items-center text-center gap-1"
          >
            <div>
              <span className="font-large-1 fw-bold">
                {activeRange.data.total}
              </span>
              <CardText className="mb-50">Total Deposits</CardText>
            </div>

            {/* <span style={{ fonstSize: "10px" }}>Total in process Bills</span> */}
            <div>
              <span className="font-large-1 fw-bold">
                {activeRange.data.applied}{" "}
              </span>
              {/* <span style={{ fonstSize: "10px" }}>Follow up</span> */}
              <CardText className="mb-50">Applied Deposits</CardText>
            </div>
          </Col>
          <Col
            sm="8"
            className="d-flex justify-content-center align-items-center"
          >
            <Chart
              // width="100px"
              // height="100px"
              height={220}
              width={220}
              options={options}
              series={[
                (activeRange.data.applied / activeRange.data.total) * 100
              ]}
              // series={[80]}
              type="radialBar"
              id="support-tracker-card"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <div className="text-center">
              <span className="font-large-1 fw-bold">
                {activeRange.data.unapplied}{" "}
              </span>
              <CardText className="mb-50 text-break">
                Unapplied Deposits
              </CardText>
            </div>
          </Col>
          {/* <Col md={4}>
            <div className="text-center">
              <span className="font-large-1 fw-bold text-break">30 </span>
              <CardText className="mb-50">Pending Approval</CardText>
            </div>
          </Col> */}
          <Col md={8}>
            <div className="text-center">
              <span className="font-large-1 fw-bold text-break">
                {activeRange.data.unidentified}
              </span>
              <CardText className="mb-50">UnIdentified Deposits</CardText>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
    // <div>DeductionWidget</div>
  )
}

export default DepositWidget
