import { useState, useMemo, createContext, useEffect } from "react"
import WizardModal from "./components/WizardModal"
import { useSelector } from "react-redux"
// import BusinessDetailsCard from "./components/BusinessDetailsCard"
import { Button, Spinner } from "reactstrap"
import ConfirmationModal from "./components/ConfirmationModal"
import {
  getAstraDetails,
  saveAstraDetails
} from "../../../service/cratoPayService"
import toast from "@custom-components/toast.jsx"
import axios from "axios"
import BusinessRegistrationModal from "./components/BusinessRegistrationModal"
import BusinessRegistrationView from "./components/BusinessRegistrationView"

const businessRegObj = {
  // ** Business Registration
  phone: "",
  address1: "",
  address2: "",
  businessName: "",
  businessType: "",
  city: "",
  ein: "",
  postalCode: "",
  state: "",
  country: "",
  // ** Administration
  firstName: "",
  lastName: "",
  adminEmail: "",
  dateOfBirth: "",
  ssn: "",
  // ** Controller
  controller: {
    firstName: "",
    lastName: "",
    title: "",
    dateOfBirth: "",
    ssn: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    country: ""
  },
  clientId: "",
  clientCode: "",
  ipAddress: "192.0.2.0"
}

export const BusinessRegContext = createContext()

const BusinessRegistraction = () => {
  const {
    clientId,
    client: { clientCode = "" },
    basicProfile: { dateFormat = "MM/DD/YYYY" }
  } = useSelector((state) => state.auth.userData)

  const [showContainer, setShowContainer] = useState(false)
  const [businessRegDetails, setBusinessRegDetails] = useState(businessRegObj)
  const [isVerified, setIsVerified] = useState(false)
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaved, setIsSaved] = useState(false)

  const onLoad = async () => {
    try {
      const { data = [] } = await getAstraDetails(clientId)
      const idx = data.length - 1
      if (data?.[idx]?.businessName) {
        setIsSaved(true)
        setIsVerified(true)
        setBusinessRegDetails({
          ...data[idx],
          status: data[idx].status.replace("_", " ")
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  const datePickerDate = useMemo(() => {
    return dateFormat === "MM/DD/YYYY" ? "m-d-Y" : "d-m-Y"
  }, [dateFormat])

  const handleValidateSsn = (value) => {
    let inputValue = value.replace(/\D/g, "")
    const hyphenIndex1 = 3
    const hyphenIndex2 = 6

    if (inputValue.length > hyphenIndex1 && inputValue[hyphenIndex1] !== "-") {
      inputValue = `${inputValue.slice(0, hyphenIndex1)}-${inputValue.slice(
        hyphenIndex1
      )}`
    }
    if (inputValue.length > hyphenIndex2 && inputValue[hyphenIndex2] !== "-") {
      inputValue = `${inputValue.slice(0, hyphenIndex2)}-${inputValue.slice(
        hyphenIndex2
      )}`
    }
    value = inputValue.slice(0, 11)
    return value
  }

  const saveBusinessRegDetails = async (regDetails, from, cb) => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json")
      console.log(regDetails, "regDetails")
      const payload = {
        ...regDetails,
        clientId,
        clientCode,
        controller: from === "admin" ? {} : regDetails.controller,
        ipAddress: res.data.ip
      }
      console.log(payload, res.data.ip)

      await saveAstraDetails(payload, window.location.origin)
      // window.location.replace(data?.authorizationUrl ?? "")
      setIsVerified(true)
      toast.success("Bank Registration successful")
    } catch (err) {
      console.log(err)
      setIsVerified(false)
      toast.error(err?.data?.message ?? "Try Again")
    } finally {
      cb()
      setShowContainer(false)
    }
  }
  // const onSubmitCallback = async (formData) => {
  //   await saveBusinessRegDetails(formData)
  // }
  if (isLoading) {
    return (
      <div
        className="text-primary d-flex align-items-center justify-content-center"
        style={{ height: "200px" }}
      >
        <Spinner />
        <h3 className="ms-50 mb-0 me-0">Loading ...</h3>
      </div>
    )
  }

  return (
    <BusinessRegContext.Provider
      value={{
        businessRegDetails,
        setBusinessRegDetails,
        datePickerDate,
        handleValidateSsn,
        saveBusinessRegDetails
      }}
    >
      {!isVerified ? (
        <div>
          <h3 className="mb-1">Business Registration</h3>
          <div className="mb-2">
            Cratopay payment solution allows businesses to process real time ACH
            payments to its vendors and suppliers. To proceed with payment
            processing KYB and KYC registrations are to be completed.
          </div>
          <div className="mb-2">
            <strong>KYB</strong> (Know your Business) is a due diligence process
            that businesses and financial institutions use to verify the
            identity and assess the risk of their business customers.
          </div>
          <div className="mb-2">
            <strong>KYC</strong> (Know Your Customer) is a due diligence process
            that businesses and financial institutions use to verify the
            identity of the user or controllers with access to process payment
            on behalf of the company.
          </div>
          <div>
            <Button
              color="primary"
              onClick={() => setShow(true)}
              id="business-reg-btn"
            >
              Register
            </Button>
          </div>
          <ConfirmationModal
            show={show}
            setShow={setShow}
            onContinue={() => {
              setShowContainer(true)
              setShow(false)
              setIsVerified(true)
            }}
          />
        </div>
      ) : !isSaved ? (
        // <WizardModal
        //   show={showContainer}
        //   setShow={setShowContainer}
        //   datePickerDate={datePickerDate}
        //   setIsVerified={setIsVerified}
        //   businessRegDetails={businessRegDetails}
        // />
        <BusinessRegistrationModal
          show={showContainer}
          setShow={setShowContainer}
          setIsVerified={setIsVerified}
          // onSubmitCallback={saveBusinessRegDetails}
        />
      ) : (
        // <BusinessDetailsCard businessRegDetails={businessRegDetails} />
        <BusinessRegistrationView businessRegDetails={businessRegDetails} />
      )}
    </BusinessRegContext.Provider>
  )
}

export default BusinessRegistraction
