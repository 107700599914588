import React, { useMemo, useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import toast from "react-hot-toast"
import { updateUserProfile } from "../../service/manageUserService"
import Select from "react-select"
import { useSelector } from "react-redux"
const VacationSetupModal = ({
  show,
  setShow,
  userProfileData,
  forwardToData
}) => {
  const [vacationForward, setVacationForward] = useState(
    userProfileData.vacationForward
  )
  const [notifyForward, setNotifyForward] = useState(
    userProfileData.forwardNotification
  )
  const [forwardedUser, setforwardedUser] = useState(
    userProfileData.forwardedUser
  )
  const [startDate, setStartDate] = useState(userProfileData.startDate)
  const [endDate, setEndDate] = useState(userProfileData.endDate)
  const [message, setMessage] = useState(userProfileData.message)
  const { email, basicProfile = { dateFormat: "MM/DD/YYYY" } } = useSelector(
    (state) => state.auth.userData
  )
  // console.log(moment(startDate).format("DD/MM/YYYY"))
  // console.log(moment(endDate).format("DD/MM/YYYY"))

  const handleSubmit = (e) => {
    e.preventDefault()
    userProfileData.vacationForward = vacationForward
    userProfileData.forwardNotification = notifyForward
    userProfileData.startDate = moment(startDate).format("YYYY-MM-DD")
    userProfileData.endDate = moment(endDate).format("YYYY-MM-DD")
    userProfileData.message = message
    userProfileData.forwardedUser = forwardedUser
    userProfileData.status = userProfileData.isActive ? "ACTIVE" : "INACTIVE"
    if (userProfileData.updatedInfo?.updatedBy) {
      userProfileData.updatedInfo.updatedBy = email
    } else {
      userProfileData.updatedInfo = {
        ...userProfileData.updatedInfo,
        updatedBy: email,
        updatedOn: new Date()
      }
      if (!userProfileData.updatedInfo?.updatedOn) {
        userProfileData.updatedInfo = {
          ...userProfileData.updatedInfo,
          updatedOn: new Date()
        }
      }
    }

    updateUserProfile(userProfileData)
      .then((res) => {
        console.log(res.data)
        setShow(!show)
        toast.success("Record updated Successfully", {
          position: "top-center"
        })
      })
      .catch((err) => {
        console.log(err)
        //setShow(!show)
        toast.error("Something went wrong", {
          position: "top-center"
        })
      })
  }

  const datePickerDate = useMemo(() => {
    return basicProfile.dateFormat === "MM/DD/YYYY" ? "m-d-Y" : "d-m-Y"
  }, [basicProfile.dateFormat])

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        setShow(!show)
      }}
      className="modal-dialog-centered modal-lg"
      id="vacation-setup-modal"
    >
      <ModalHeader
        toggle={() => {
          setShow(!show)
        }}
        className="bg-transparent"
      ></ModalHeader>
      <ModalBody>
        <h2 className="text-center">Vacation Forward Setup</h2>
        <Row>
          <Col md={6} className="my-1">
            <h5 className="fw-bold">Turn on Vacation Forward</h5>
            <div className="form-check form-switch mt-2">
              <Input
                type="switch"
                name="active"
                checked={vacationForward}
                onChange={(e) => {
                  setVacationForward(e.target.checked)
                }}
                id="vacation-forward-on"
              />
              <Label className="form-check-label switch-icon-left switch-icon-right ps-2">
                {vacationForward ? "Yes" : "No"}
              </Label>
            </div>
          </Col>
          <Col md={6} className="my-1">
            <Label>Forward To</Label>
            <Select
              className="react-select"
              classNamePrefix="select"
              value={forwardToData.filter((opt) => opt.value === forwardedUser)}
              options={forwardToData}
              isClearable={false}
              onChange={(val) => setforwardedUser(val.value)}
              isDisabled={!vacationForward}
              id="select-forward-to"
            />
          </Col>
          <Col md={6} className="my-1">
            <h5 className="fw-bold">Notify when forwarded</h5>
            <div className="form-check form-switch mt-2">
              <Input
                type="switch"
                name="active"
                checked={notifyForward}
                onChange={(e) => {
                  setNotifyForward(e.target.checked)
                }}
                disabled={!vacationForward}
                id="notify-on"
              />
              <Label className="form-check-label switch-icon-left switch-icon-right ps-2">
                {notifyForward ? "Yes" : "No"}
              </Label>
            </div>
          </Col>
          <Col md={6} className="my-1">
            <Row>
              <Col>
                <Label>Start Date</Label>
                <Flatpickr
                  className="form-control"
                  value={startDate}
                  onChange={(date) => setStartDate(date[0])}
                  id="default-picker"
                  options={{
                    dateFormat: datePickerDate
                  }}
                  disabled={!vacationForward}
                  name="start-date"
                />
              </Col>
              <Col>
                <Label>End Date</Label>
                <Flatpickr
                  className="form-control"
                  value={endDate}
                  onChange={(date) => setEndDate(date[0])}
                  id="default-picker"
                  options={{
                    dateFormat: datePickerDate
                  }}
                  disabled={!vacationForward}
                  name="end-date"
                />
              </Col>
            </Row>
          </Col>

          <Col md={12} className="my-1">
            <h5 className="fw-bold">Personalized Message</h5>
            <Input
              type="textarea"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={!vacationForward}
              id="personalized-message"
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-center align-items-center my-2">
          <Button
            outline
            className="mx-1"
            onClick={() => {
              setShow(!show)
            }}
            id="vacation-forward-discard-btn"
          >
            Discard
          </Button>
          <Button
            color="primary"
            className="mx-1"
            onClick={handleSubmit}
            disabled={!vacationForward}
            id="submit-btn"
          >
            Submit
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default VacationSetupModal
