import { X } from "react-feather"
import Breadcrumbs from "../../../../../custom-components/breadcrumb/Breadcrumbs"
import { salesDashboardBreadCrumb } from "../../../../../custom-components/breadcrumb/breadcrumb-data/breadcrumbData"
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import { useNavigate } from "react-router-dom"

const PageHeader = ({ setIsEditable, isEditable, onSave }) => {
  const navigate = useNavigate()
  return (
    <div className="d-flex justify-content-between align-items-center">
      <Breadcrumbs
        title="Customize Template "
        data={salesDashboardBreadCrumb}
      />
      <div className="d-flex justify-content-between align-items-center gap-1">
        <UncontrolledDropdown>
          <DropdownToggle color="secondary" caret outline>
            <span className="align-middle">Actions</span>
          </DropdownToggle>
          <DropdownMenu>
            {isEditable ? (
              <>
                <DropdownItem className="w-100" onClick={() => onSave("save")}>
                  <span className="align-middle">Save as draft</span>
                </DropdownItem>
                <DropdownItem className="w-100" onClick={() => onSave("post")}>
                  <span className="align-middle">Post</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <span className="align-middle">Send for approval</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <span className="align-middle">Send for resolution</span>
                </DropdownItem>
              </>
            ) : (
              <>
                <DropdownItem
                  className="w-100"
                  onClick={() => setIsEditable(true)}
                >
                  <span className="align-middle">Edit</span>
                </DropdownItem>
                <DropdownItem className="w-100">
                  <span className="align-middle">Reverse</span>
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
        <div>
          <Button color="flat-primary" onClick={() => navigate(-1)}>
            <X />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PageHeader
