import { useDispatch, useSelector } from "react-redux"
import { handleDocumentCount } from "../../redux/notification"
const mapper = {
  documentCount: handleDocumentCount
}
const useNotification = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.notification)
  const updateNotification = (action, payload) => {
    dispatch(handleDocumentCount(payload))
    return
    dispatch(mapper[action](payload))
  }

  return [state, updateNotification]
}

export default useNotification
