// ** Icons Import
import { FileText } from "react-feather"

export default [
  {
    id: "Reporting",
    title: "Reporting",
    icon: FileText,
    navLink: "/v2/reportings"
    // badge: 'light-warning',
    // badgeText: '2'
  }
]
