// ** Axios Import
import axios from "axios"

// ** Api URL Import
import { GET_CLIENT_MIN_INFO, getEntitiesByClientIds } from '@service/apiUrl'
import { getDownloadUrl, adminsetupApi1 } from "../../../../services/apiUrl"


// ** Get All Clients
export const getAllClients = () => {
    return axios.get(`${GET_CLIENT_MIN_INFO}`)
}

export const getAllEntitiesByClientIds = (query) => {
    return axios.get(`${getEntitiesByClientIds}?${query}`)
}

export const getS3DownloadUrl = (fileName, clientId) => {
    return axios.get(`${getDownloadUrl}?fileName=${fileName}&clientId=${clientId}`)
}

export const businessProfileApi = (clientId) => {
    return axios.get(`${adminsetupApi1}/basicprofile?clientId=${clientId}`)
}

