import * as CryptoJS from "crypto-js"

export class EncryptionDecryptionService {
    set(data) {
        return CryptoJS.enc.Base64.stringify(
            CryptoJS.enc.Utf8.parse(CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_CRATO_ENC_DEC_KEY).toString())
        )
    }

    get(data) {
        return JSON.parse(CryptoJS.AES.decrypt(CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8), process.env.REACT_APP_CRATO_ENC_DEC_KEY).toString(CryptoJS.enc.Utf8))
    }
}
export default EncryptionDecryptionService