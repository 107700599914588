// ** React Imports
import { lazy } from "react"
const UploadRemittancePdf = lazy(() =>
  import(
    "../../views/revenue/cash-application-copy/remittance/upload-remittance-pdf"
  )
)
const CustomerList = lazy(() =>
  import("../../views/revenue/customer/customer-list/index")
)
const CustomerView = lazy(() =>
  import("../../views/revenue/customer/customer-list/customer-view/index")
)
const Invoicing = lazy(() => import("../../views/revenue/invoicing/Invoicing"))
const InvoiceView = lazy(() =>
  import("../../views/revenue/invoicing/components/view-invoice/index")
)
const CreateInvoice = lazy(() =>
  import("../../views/revenue/invoicing/components/create-invoice/index")
)
const ApplyDeposits = lazy(() =>
  import(
    "../../views/revenue/cash-application-copy/apply-deposits/apply-deposit-list/index"
  )
)
const RemittanceList = lazy(() =>
  import(
    "../../views/revenue/cash-application-copy/remittance/remittance-list/index"
  )
)
const UploadRemittance = lazy(() =>
  import(
    "../../views/revenue/cash-application-copy/remittance/upload-remittance/index"
  )
)
const DeductionView = lazy(() =>
  import(
    "../../views/revenue/cash-application-copy/deduction/deduction-view/index"
  )
)
const Deduction = lazy(() =>
  import(
    "../../views/revenue/cash-application-copy/deduction/deduction-list/index"
  )
)
const RemittanceAdviceTable = lazy(() =>
  import("../../views/revenue/cash-application-copy/remittance-advice/index")
)

const SalesOrderList = lazy(() =>
  import("../../views/revenue/sales-order/sales-order-list/index")
)
const SalesOrderView = lazy(() =>
  import("../../views/revenue/sales-order/sales-order-view/index")
)
const UploadSalesOrder = lazy(() =>
  import(
    "../../views/revenue/sales-order/sales-order-list/upload-sales-order/index"
  )
)
const CashAppView = lazy(() =>
  import(
    "../../views/revenue/cash-application-copy/apply-deposits/cash-app-view/index"
  )
)
const UploadFiles = lazy(() =>
  import(
    "../../views/revenue/cash-application-copy/remittance/beta-remittance-upload/index"
  )
)
const SalesUpload = lazy(() =>
  import("../../views/revenue/sales-input/sales-input-table/SalesUpload")
)

import SalesEntriesList from "../../views/revenue/sales-dashboard/sales-entries/sales-list/index"
import SalesEntrieView from "../../views/revenue/sales-dashboard/sales-entries/sales-view/index"
import SalesInputList from "../../views/revenue/sales-dashboard/sales-input/sales-list/index"
import Sales from "../../views/revenue/sales-input/index"
const InvoiceUpload = lazy(() =>
  import("../../views/revenue/invoicing/components/invoice-upload/index")
)

// ** Role Permission List
import { permissions } from "../../views/role-management/store/permissionList"
import AddNewUser from "../../views/beta/add-new-user"

const permissionList = Object.keys(permissions)
const revenueList = Object.keys(permissions.Revenue)

const RevenueRoutes = [
  {
    accessMap: "Rev_Customers",
    path: "/ar/customer/list",
    element: <CustomerList />,
    menuGroup: permissionList[2],
    accessId: revenueList[1],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_Customers",
    path: "/ar/customer/view/:id",
    element: <CustomerView />,
    menuGroup: permissionList[2],
    accessId: revenueList[1],
    meta: {
      restricted: true,
      accessRestricted: true
      //msenuHidden: true
      //layout: 'blank'
    }
  },
  {
    accessMap: "Rev_Invoicing",
    path: "/ar/invoicing",
    element: <Invoicing />,
    menuGroup: permissionList[2],
    accessId: revenueList[2],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_Invoicing",
    path: "/ar/cash-app/apply-deposit-view",
    element: <CashAppView />,
    menuGroup: permissionList[2],
    accessId: revenueList[2],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_Invoicing",
    path: "/app/ar/cash-app/apply-deposit-view",
    element: <CashAppView />,
    menuGroup: permissionList[2],
    accessId: revenueList[2],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_Invoicing",
    path: "/ar/apply-deposit/invoice-view/:id",
    element: <InvoiceView />,
    menuGroup: permissionList[2],
    accessId: revenueList[2],
    meta: {
      restricted: true,
      accessRestricted: true,
      layout: "blank"
    }
  },
  {
    accessMap: "Rev_Invoicing",
    path: "/ar/invoicing/view/:id",
    element: <InvoiceView />,
    menuGroup: permissionList[2],
    accessId: revenueList[2],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_Invoicing",
    path: "/ar/invoicing/upload",
    element: <InvoiceUpload />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Sales_Upload",
    path: "/ar/sales-input/sales-upload",
    element: <SalesUpload />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Sales_Upload",
    path: "/manage-user/add-new-user",
    element: <AddNewUser />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_Invoicing",
    path: "/ar/invoicing/create-invoice",
    element: <CreateInvoice />,
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Rev_Invoicing",
    path: "/ar/invoicing/edit/:urn",
    element: <CreateInvoice />,
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Rev_CA_AD",
    path: "/ar/revenue/applydeposits",
    element: <ApplyDeposits />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_CA_AD",
    path: "/app/ar/revenue/applydeposits",
    element: <ApplyDeposits />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_CA_Remittance",
    path: "/ar/revenue/remittance-list",
    element: <RemittanceList />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_CA_Remittance",
    path: "/ar/cash-app/remittance/uplaod-remittance-excel",
    element: <UploadRemittance />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_CA_Remittance",
    path: "/ar/cash-app/remittance/uplaod-remittance-pdf",
    element: <UploadFiles />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_CA_Remittance",
    path: "/ar/cash-app/remittance/uplaod-remittance-txt",
    element: <UploadFiles />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_CA_Remittance",
    path: "/ar/revenue/remittance/remittance-advice/:id",
    element: <RemittanceAdviceTable />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_CA_Remittance",
    path: "/ar/remittance-view/:id",
    element: <RemittanceAdviceTable />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true,
      layout: "blank"
    }
  },
  {
    accessMap: "Rev_CA_Deductions",
    path: "/ar/revenue/deduction",
    element: <Deduction />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_CA_Deductions",
    path: "/ar/revenue/deduction/view/:id",
    element: <DeductionView />,
    menuGroup: permissionList[2],
    accessId: revenueList[3],
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Rev_Sales_Sales_Entries",
    path: "/ar/salesdashboard/list",
    element: <SalesEntriesList />,
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Rev_Sales_Sales_Entries",
    path: "/ar/salesdashboard/view/:id",
    element: <SalesEntrieView />,
    menuGroup: permissionList[2],
    accessId: revenueList[0],
    meta: {
      restricted: true
    }
  },
  {
    accessMap: "Rev_Sales_Sales_Input",
    path: "/ar/salesdashboard/sales-input/list",
    element: <SalesInputList />,
    menuGroup: permissionList[2],
    accessId: revenueList[0],
    meta: {
      restricted: true
    }
  },
  {
    path: "/ar/salesdashboard/sales-order/list",
    element: <SalesOrderList />,
    menuGroup: permissionList[2],
    accessId: revenueList[0],
    meta: {
      restricted: true
    }
  },
  {
    path: "/ar/salesdashboard/sales-order/view/:id",
    element: <SalesOrderView />,
    menuGroup: permissionList[2],
    accessId: revenueList[0],
    meta: {
      restricted: true
    }
  },
  {
    path: "/ar/sales-input",
    element: <Sales />,
    menuGroup: permissionList[2],
    accessId: revenueList[0],
    meta: {
      restricted: true
    }
  },
  {
    path: "/ar/salesdashboard/sales-order/upload-sales-order",
    element: <UploadSalesOrder />,
    menuGroup: permissionList[2],
    accessId: revenueList[0],
    meta: {
      restricted: true
    }
  }
  // {
  //     path: '/ar/customer/view/:id',
  //     element: <VendorView />,
  //     meta: {
  //         restricted: true
  //     }
  // }
]

export default RevenueRoutes
