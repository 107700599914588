// ** Imports
import React from "react"
import { Row, Col, Spinner } from "reactstrap"
import ReactPaginate from "react-paginate"
import "./style.scss"

const CustomPagination = (props) => {
  const {
    showCount,
    pageCount,
    forcePage,
    onPageChange,
    isLoading = false
  } = props

  return (
    <Row className="align-items-center m-0" id="pagination-row">
      <Col md={5} sm={12} id="pagination-count">
        <div className="ps-md-1 ps-0 text-center text-md-start">
          {showCount}
        </div>
      </Col>
      <Col
        md={7}
        sm={12}
        className={`text-sm-center text-md-start ${
          isLoading ? "disable-paginate" : ""
        }`}
        id="pagination"
      >
        <span className="pagnation-loader" />
        <ReactPaginate
          previousLabel=""
          nextLabel=""
          pageCount={pageCount}
          activeClassName="active"
          forcePage={forcePage}
          onPageChange={onPageChange}
          pageClassName="page-item"
          nextLinkClassName="page-link"
          nextClassName="page-item next"
          previousClassName="page-item prev"
          previousLinkClassName="page-link"
          pageLinkClassName="page-link"
          containerClassName="pagination react-paginate justify-content-md-start justify-content-center my-2 pe-1"
        />
      </Col>
    </Row>
  )
}

export default CustomPagination
