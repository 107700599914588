import React from "react"
import { Row, Col } from "reactstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

export const ModalSkeleton = () => {
  return (
    <SkeletonTheme>
      {new Array(3).fill(
        <Row className="my-1">
          {new Array(2).fill(
            <Col md="6" className="my-50">
              <Skeleton width={370} height={40} />
            </Col>
          )}
        </Row>
      )}
    </SkeletonTheme>
  )
}

export const BankReconRulesSekeleton = () => {
  return (
    <SkeletonTheme>
      <div className="d-flex justify-content-between m-1">
        <Skeleton width={200} height={35} />
        <Skeleton width={200} height={35} />
      </div>
      <div className="mt-50">
        {new Array(4).fill(
          <Row className="m-1">
            {new Array(5).fill(
              <Col>
                <Skeleton width={200} height={40} />
              </Col>
            )}
          </Row>
        )}
      </div>
    </SkeletonTheme>
  )
}
