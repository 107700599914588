// ** React Import
import React from "react"

import useFilter from "../../../../../utility/hooks/useFilter"
import PopUpFilter from "@components/popup-filter"
import PillDatePicker from "@components/date-picker"
import moment from "moment"

const Filters = ({ entity }) => {
  const [filters, setFilters] = useFilter("SALES_ENTRIES")

  return (
    <div className="mb-1 demo-inline-spacing">
      <span className="fs-5 mx-1">Filter by :</span>
      <PopUpFilter
        name="Business Unit"
        options={entity}
        value={filters["clientEntityId"]}
        onChange={(e) => setFilters({ clientEntityId: e.options })}
        id="filter-1"
      />
      <PillDatePicker
        placeholder="Date Range"
        name="Invoice Date Range"
        defaultDate={[filters.startDate, filters.endDate]}
        value={
          filters.startDate && filters.endDate
            ? [filters.startDate, filters.endDate]
            : ""
        }
        onChange={([from = "", to = ""]) => {
          if (from && to) {
            setFilters({
              startDate: moment(from).format("YYYY-MM-DD"),
              endDate: moment(to).format("YYYY-MM-DD")
            })
          } else {
            setFilters({ startDate: "", endDate: "" })
          }
        }}
        id="filter-2"
      />
    </div>
  )
}

export default Filters
