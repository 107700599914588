import React from "react"
import VerticalLayout from "./VerticalLayout"
import HorizontalLayout from "./HorizontalLayout"
import navigation from "@src/navigation"
import { Outlet } from "react-router-dom"
import BlankLayout from "./BlankLayout"

const layout = {
  vertical: VerticalLayout,
  horizontal: HorizontalLayout,
  blank: BlankLayout
}
const Layout = (props) => {
  const WrapperLayout = layout[props.layout]
  return (
    <WrapperLayout menuData={navigation} {...props}>
      <Outlet />
    </WrapperLayout>
  )
}

export default Layout
