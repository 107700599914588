// React Import
import React from "react"
import { useSelector } from "react-redux"
import { Card } from "reactstrap"
import DataTable from "react-data-table-component"

// ** BreadCrumb Import
import { salesDashboardBreadCrumb } from "../../../../../custom-components/breadcrumb/breadcrumb-data/breadcrumbData"
import Breadcrumbs from "../../../../../custom-components/breadcrumb/Breadcrumbs"

// ** Child Import
import SalesDashboardHeader from "./SalesDashboardHeader"
import salesJournalTableColunm from "./salesJournalTableColunm"
import Filters from "./Filters"

// ** Icons Import
import { ChevronDown } from "react-feather"

// ** Utils Import
import { rowHoverStyle } from "@utils"
import { useNavigate } from "react-router-dom"
import { allEntityApi, getArUrl } from "../../../../../services/apiUrl"
import useFetch from "../../../../../utility/hooks/useFetch"
import useFilter from "../../../../../utility/hooks/useFilter"
import { removeEmpty } from "../../../../../utility/Utils"
import { Skeletone } from "../../../../../custom-components/skeleton/skeleton"

/**/

const SalesDashboard = () => {
  const navigate = useNavigate()

  const { userData } = useSelector((state) => state.auth)
  const [filters] = useFilter("SALES_ENTRIES")

  const [{ tableData = [], totalCount = 0 } = {}, { isFirstLoad }] = useFetch(
    `${getArUrl}/sales/datefilter`,
    {
      apiParams: {
        params: removeEmpty({
          ...filters,
          clientId: userData.clientId,
          clientEntityId: "ALL"
        })
      },
      callback: (response) => ({
        tableData: response,
        totalCount: 100
      })
    }
  )

  console.log(totalCount)

  const link = (id) => {
    navigate(`/v2/ar/salesdashboard/view/${id}`)
  }

  const [entity = []] = useFetch(`${allEntityApi}/${userData.clientId}`, {
    callback: (response) =>
      response.map((value) => ({
        label: `${value.clientEntityCode} - ${value.clientEntityName}`,
        value: value.clientEntityId
      }))
  })

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <Breadcrumbs title="Sales Entries" data={salesDashboardBreadCrumb} />
      </div>

      <Card>
        <Filters entity={entity} />
      </Card>
      {isFirstLoad ? (
        <Card>
          <Skeletone isloading={isFirstLoad} />
        </Card>
      ) : (
        <Card>
          <div className="react-dataTable">
            <DataTable
              responsive
              customStyles={rowHoverStyle}
              columns={salesJournalTableColunm(userData, link, entity)}
              data={tableData}
              onRowClicked={(row) => {
                link(row.salesId)
              }}
              sortIcon={<ChevronDown />}
              subHeader
              subHeaderComponent={<SalesDashboardHeader />}
            />
          </div>
        </Card>
      )}
    </div>
  )
}

export default SalesDashboard
