import { Fragment } from "react"
import { Button, ButtonGroup, Card, CardBody, Col, Row } from "reactstrap"
import PopUpFilter from "@components/popup-filter"
import useFetch from "../../../utility/hooks/useFetch"
import { allEntityApi } from "../../../services/apiUrl"
import { useSelector } from "react-redux"
import PillDatePicker from "@components/date-picker"

const Filter = ({ viewType, setViewType }) => {
  const { clientId } = useSelector((state) => state.auth.userData)
  const [entityList = []] = useFetch(`${allEntityApi}/${clientId}`, {
    callback: (data) =>
      data.map((item) => {
        return {
          label: item.clientEntityName,
          value: item.clientEntityId
        }
      })
  })
  return (
    <Fragment>
      <Row>
        <Col md="8">
          <Card>
            <CardBody className="pt-0">
              <div className="d-flex align-items-center justify-content-between">
                <div className="demo-inline-spacing">
                  <div>Filter by : </div>
                  <PopUpFilter
                    name="Status"
                    options={statusOptions}
                    onChange={() => {}}
                    value={""}
                  />
                  <PopUpFilter
                    name="Location"
                    options={entityList}
                    onChange={() => {}}
                    value={""}
                  />

                  <PillDatePicker
                    name="Date"
                    value={""}
                    placeholder="Date"
                    onChange={() => {}}
                  />

                  <PopUpFilter
                    name="Pos Name"
                    options={posNameOptions}
                    onChange={() => {}}
                    value={""}
                  />
                </div>

                <div style={{ marginTop: "1.5rem" }}>
                  {/* <ClearFilter currentPage="" /> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody style={{ padding: "1.1rem 0rem" }}>
              <div className=" text-end  align-items-center justify-content-center d-flex">
                <ButtonGroup className="me-0">
                  <Button
                    style={{ width: "180px" }}
                    color="primary"
                    // size="sm"
                    outline={viewType !== "SUMMARY"}
                    onClick={() => setViewType("SUMMARY")}
                    id="summary"
                  >
                    Summary
                  </Button>
                  <Button
                    style={{ width: "180px", whiteSpace: "nowrap" }}
                    color="primary"
                    // size="sm"
                    outline={viewType !== "DETAIL"}
                    onClick={() => setViewType("DETAIL")}
                    id="detailed"
                  >
                    Detail
                  </Button>
                </ButtonGroup>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Filter

const statusOptions = ["Yet to post", "Needs Review"].map((i) => ({
  label: i,
  value: i
}))

const posNameOptions = ["Toast", "PMS"].map((i) => ({
  label: i,
  value: i
}))
