import React, { useState, useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Drawer, Space, Tree } from "antd"
import { Controller, useForm } from "react-hook-form"
import Cleave from "cleave.js/react"
import "cleave.js/dist/addons/cleave-phone.us"

import {
  Button,
  Card,
  Col,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row
} from "reactstrap"
import * as yup from "yup"
import AddNewUserTable from "./AddNewUserTable"
import { Edit, PlusCircle } from "react-feather"
import { getRoleByEntity } from "../../cratomatch/service/service"
import { useSelector } from "react-redux"
import { clientApi } from "../../../services/apiUrl"
import useFetch from "../../../utility/hooks/useFetch"
import AddNewUserCompanyModal from "./AddNewUserCompanyModal"

const AddNewUserDrawer = (props) => {
  const { show, setShow } = props
  const selector = useSelector((state) => state.auth.userData)
  const [treeData, setTreeData] = useState([])
  const [isCompanyModal, setIsCompanyModal] = useState(false)
  const [userRoleLoading, setUserRoleLoading] = useState(false)
  const [roleData, setRoleData] = useState([])

  const options = { phone: true, phoneRegionCode: "US" }

  const addUserSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    contact: yup.string()
    // company: yup.object().required(),
    // role: yup.object().required()
  })
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    contact: ""
    // company: "",
    // role: ""
  }
  const formProps = useForm({
    defaultValues,
    resolver: yupResolver(addUserSchema)
  })
  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    formState: { errors }
  } = formProps

  const onClose = () => {
    setShow(!show)
    reset()
  }

  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info)
  }

  const getRoles = async () => {
    console.log(selector, "!--valueval")
    setUserRoleLoading(true)
    try {
      const { data } = await getRoleByEntity(
        selector.clientId,
        selector.tenantId
      )
      setRoleData(data[0]?.userRoles ?? [])
    } catch (error) {
      console.log(error)
    } finally {
      setUserRoleLoading(false)
    }
  }

  const [companyOptions = []] = useFetch(clientApi, {
    callback: (response) =>
      response.map(({ clientName, clientCode }) => ({
        label: clientName,
        value: clientCode
      }))
  })

  useEffect(() => {
    getRoles()
  }, [])

  const style = {
    padding: "1rem",
    width: "20rem",
    borderRight: "solid 1px #cecece",
    minHeight: "100%",
    scrollBehavior: "smooth",
    overflowX: "auto"
  }

  const headerStyle = {
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }

  const treeFilterData = treeData.flat()

  const companyData = companyOptions.filter((option) => {
    return !treeFilterData.some(
      (submittedOption) => submittedOption.title === option.label
    )
  })
  const onSubmit = () => {}

  return (
    <div>
      <Drawer
        className="drawer-window"
        style={{ height: "100vh" }}
        placement="top"
        title={<h4 className="ps-2">Add New User</h4>}
        onClose={onClose}
        open={show}
        height="100vh"
        zIndex={1200}
        extra={
          <Space>
            <Button onClick={onClose} outline>
              Discard
            </Button>
            <Button
              type="primary"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Submit & Invite
            </Button>
          </Space>
        }
      >
        <Card className="px-2">
          <Row>
            <Col md={3} className="my-1">
              <Label>
                First Name <span className="text-danger">*</span>
              </Label>
              <Controller
                id="Name"
                name="firstName"
                control={control}
                render={({ field }) => (
                  <Input
                    invalid={errors.firstName && true}
                    {...field}
                    id="first-name"
                  />
                )}
              />
              {errors.firstName && (
                <FormFeedback>{"First Name is Requried"}</FormFeedback>
              )}
            </Col>
            <Col md={3} className="my-1">
              <Label>
                Last Name <span className="text-danger">*</span>
              </Label>
              <Controller
                id="Name"
                name="lastName"
                control={control}
                render={({ field }) => (
                  <Input
                    invalid={errors.lastName && true}
                    {...field}
                    id="last-name"
                  />
                )}
              />
              {errors.lastName && (
                <FormFeedback>{"Last Name is Requried"}</FormFeedback>
              )}
            </Col>
            <Col md={3} className="my-1">
              <Label>
                Primary Email <span className="text-danger">*</span>
              </Label>
              <Controller
                id="Name"
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    invalid={errors.email && true}
                    {...field}
                    id="primary-email"
                  />
                )}
              />
              {errors.email && (
                <FormFeedback>{"Email is Requried"}</FormFeedback>
              )}
            </Col>
            <Col md={3} className="my-1">
              <Label>Contact</Label>
              <InputGroup className="input-group-merge">
                <InputGroupText>US (+1)</InputGroupText>
                <Controller
                  control={control}
                  id="contact"
                  name="contact"
                  render={({ field }) => (
                    <Cleave
                      className="form-control"
                      placeholder="1 234 567 8900"
                      options={options}
                      {...field}
                    />
                  )}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card>
        <div>
          <Card>
            <div style={{ display: "flex", minHeight: "32rem" }}>
              <div style={style}>
                <h3 className="mb-1 d-flex justify-content-between align-items-center">
                  Add company
                  <div onClick={() => setIsCompanyModal(!isCompanyModal)}>
                    <PlusCircle
                      size={20}
                      className="ms-1 mt-50 cursor-pointer"
                      style={{ color: "#34A353" }}
                    />
                  </div>
                </h3>
                {treeData.map((data, index) => (
                  <Tree
                    className="mt-2"
                    key={`tree-${index}`}
                    showLine={true}
                    showIcon={false}
                    defaultExpandedKeys={["0"]}
                    onSelect={onSelect}
                    treeData={data}
                  />
                ))}
              </div>
              <div style={{ width: "100%" }}>
                <div style={headerStyle}>
                  <h3>
                    Company2.1
                    <span onClick={() => {}}>
                      <Edit
                        size={20}
                        className="ms-1 cursor-pointer"
                        style={{ color: "#34A353" }}
                      />
                    </span>
                  </h3>
                </div>
                <hr style={{ padding: 0, margin: 0 }} />
                <AddNewUserTable tableData={tableData} />
                <AddNewUserCompanyModal
                  isCompanyModal={isCompanyModal}
                  setIsCompanyModal={setIsCompanyModal}
                  treeData={treeData}
                  setTreeData={setTreeData}
                  userRoleLoading={userRoleLoading}
                  companyData={companyData}
                  roleData={roleData}
                />
              </div>
            </div>
          </Card>
        </div>
      </Drawer>
    </div>
  )
}

export default AddNewUserDrawer

const tableData = [
  {
    id: "16789",
    name: "corp",
    address: "",
    taxID: "",
    isActive: true,
    action: ""
  },
  {
    id: "16790",
    name: "Test",
    address: "",
    taxID: "",
    isActive: true,
    action: ""
  },
  {
    id: "16791",
    name: "Test1.0",
    address: "",
    taxID: "",
    isActive: true,
    action: ""
  }
]
