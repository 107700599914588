import React, { useContext, useEffect, useState } from "react"
import useNotification from "../hooks/useNotification"
import { useSelector } from "react-redux"
const SocketContext = React.createContext()
export const useSocket = () => useContext(SocketContext)

const WSProvider = ({ children }) => {
  const [trigger, setTrigger] = useState(false)
  //   const socket = useRef(null)
  const [data, setData] = useState(null)
  const [isConnected, setIsConnected] = useState(false)
  const updateStoreNotification = useNotification()[1]
  const { clientId } = useSelector((state) => state.auth.userData ?? {})

  const establishConnection = () => {
    // if (process.env.REACT_APP_TYPE == "Prod") return
    try {
      const data = new WebSocket(
        `${
          process.env.REACT_APP_WEB_SOCKET_BASE_URL
        }/notificationapi/ws/notification/socket@cratoflow.com?token=${localStorage.getItem(
          "accessToken"
        )}&transport=websocket`
      )
      setData(data)
    } catch (error) {
      console.log(error)
    }
  }

  const sendMessage = (event) => {
    try {
      data?.send(JSON.stringify({ clientId, ...event }))
    } catch (error) {
      console.log(error)
    }
  }

  const closeSocket = () => {
    data?.send(JSON.stringify({ clientId, eventType: "LOGOUT" }))
    data?.close(1000, "LOGOUT")
  }

  useEffect(() => {
    if (!trigger) return
    establishConnection()
  }, [trigger])

  const handleSocketUtils = () => {
    try {
      data.onopen = () => {
        console.log("connected")
        setIsConnected(true)
      }
      data.onclose = (event) => {
        setIsConnected(false)
        console.log("Connection closed:", event?.code, event?.reason)
        setTrigger(false)
      }
      data.onmessage = (e) => {
        const sockRes = JSON.parse(e.data.replace("Echo:", ""))
        switch (sockRes.notification_type) {
          case "MYBOX":
            console.log(sockRes.data)
            updateStoreNotification(
              "documentCount",
              sockRes.data.notification_payload
            )
            break

          default:
            break
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (!data) return
    handleSocketUtils()
    return () => {
      data?.close()
    }
  }, [data])

  return (
    <SocketContext.Provider
      value={{
        connect: () => setTrigger(true),
        socket: data,
        isConnected,
        sendWSMessage: sendMessage,
        closeSocket
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export default WSProvider
