import React from "react"
import useNotification from "../../../../utility/hooks/useNotification"
import { Bell, Mail } from "react-feather"
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import { kFormatter } from "../../../../utility/Utils"
import "./styles.scss"
import Avatar from "@components/avatar"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const Notification = () => {
  const [state] = useNotification()
  const {
    auth: { userData }
  } = useSelector((state) => state)

  const {
    vendorApprovalCount,
    awaitingResolutionCount,
    awaitingApprovalCount,
    awaitingAssessmentCount,
    paymentApprovalCount,
    purchaseOrderAwaitingApprovalCount
  } = state.documentCount

  const notifiCount = [
    +vendorApprovalCount +
      +awaitingResolutionCount +
      +awaitingApprovalCount +
      +paymentApprovalCount +
      +awaitingAssessmentCount +
      +(purchaseOrderAwaitingApprovalCount ?? 0)
  ]
  const colors = ["light-primary", "light-info", "light-danger"]

  const navLink =
    userData?.preferredRole == "CRATO_APPROVER"
      ? "/v2/approver/dashboard"
      : "/v2/my-box/approver"

  const notificationValue = [
    {
      label: "BP",
      heading: "bill(s) is awaiting your approval.",
      count: +awaitingApprovalCount,
      subtext: "Bills processing",
      avatar: "",
      link: `${navLink}?q=bills`
    },
    {
      label: "BP",
      heading: "bill(s) is awaiting your resolution.",
      count: +awaitingResolutionCount,
      subtext: "Bills processing",
      avatar: "",
      link: `${navLink}?q=bills`
    },
    {
      label: "BP",
      heading: "bill(s) is awaiting your assessment.",
      count: +awaitingAssessmentCount,
      subtext: "Bills Assessment",
      avatar: "",
      link: `${navLink}?q=assessment`
    },
    {
      label: "P",
      heading: "batch(es) is awaiting your approval.",
      count: +paymentApprovalCount,
      subtext: "Payments",
      avatar: "",
      link: `${navLink}?q=payment`
    },
    {
      label: "V",
      heading: "new vendor(s) is awaiting your approval.",
      count: +vendorApprovalCount,
      subtext: "Vendor Management",
      avatar: "",
      link: `${navLink}?q=vendor`
    },
    {
      label: "PO",
      heading: "Purchase order awaiting your approval.",
      count: +(purchaseOrderAwaitingApprovalCount ?? 0),
      subtext: "Purchase order",
      avatar: "",
      link: `${navLink}?q=po`
    }
  ]

  const isNotificationCountsZero = notificationValue.every(
    ({ count }) => !count
  )
  const formatNotificationCount = (value) => {
    if (value > 1000) return kFormatter(value)
    if (value >= 100) return "99+"
    return value
  }

  return (
    <div>
      <DropdownToggle
        color="flat-success"
        className="notification-btn"
        id="notificatio-btn"
      >
        <Bell size={20} />
      </DropdownToggle>
      <div className={!isNotificationCountsZero ? "notifi-count" : ""}>
        {!isNotificationCountsZero ? (
          <div>{formatNotificationCount(notifiCount)}</div>
        ) : null}
      </div>
      <DropdownMenu
        end
        // style={{ minHeight: "18rem" }}
        className="notifi-wrapper"
        id="notifi-wrapper"
      >
        <div className="d-flex justify-content-between align-items-center notification-section">
          <div>
            <h4 className="mb-0">Notifications</h4>
          </div>
          <div>
            <Mail size={20} />
          </div>
        </div>
        <hr className="border-bottom border-1" />
        <div className="notifi-info">
          <div>
            {isNotificationCountsZero ? (
              <div>
                <p className="no-notification">No Notifications</p>
              </div>
            ) : (
              notificationValue.map((val, i) =>
                val.count ? (
                  <NotificationItem
                    key={val.label}
                    notificationInfo={val}
                    color={colors[i % colors.length]}
                  />
                ) : null
              )
            )}
          </div>
        </div>
      </DropdownMenu>
    </div>
  )
}

const NotificationItem = ({ notificationInfo, color }) => {
  const { label, count, avatar, heading, subtext, link } = notificationInfo
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(link)
  }

  const renderingNoticationItem = () => {
    if (count == 1) {
      return (
        <p className="heading-text mb-0 fw-medium ps-50" id={`${heading}`}>
          {heading}
        </p>
      )
    } else if (count > 1) {
      return (
        <p className="heading-text mb-0 fw-medium ps-50" id={`${heading}`}>
          {heading.replace(/is/i, "are")}
        </p>
      )
    }
  }

  return (
    <DropdownItem
      style={{
        backgroundColor: "transparent",
        margin: "0",
        padding: "0",
        color: "#82868b"
      }}
    >
      <div
        className="d-flex  align-items-center notification-wrapper cursor-pointer"
        onClick={handleClick}
      >
        <div>
          {avatar ? (
            <div className="avatar-img">{avatar}</div>
          ) : (
            <Avatar className="avatar-bg" content={label} color={color} />
          )}
        </div>
        <div className="ps-1">
          <div className="d-flex ">
            <p className="mb-0 count-value">{count}</p>
            {/* <p className="heading-text mb-0 fw-medium ps-50">{heading}</p> */}
            {renderingNoticationItem()}
          </div>
          <p className="mb-0 text-secondary font-small-3">{subtext}</p>
        </div>
      </div>
      <hr className="border-bottom border-1"></hr>
    </DropdownItem>
  )
}

export default Notification
