// ** React Imports
import { useSelector } from "react-redux"

// ** Vertical Menu Components
import VerticalNavMenuLink from "./VerticalNavMenuLink"
import VerticalNavMenuGroup from "./VerticalNavMenuGroup"
import VerticalNavMenuSectionHeader from "./VerticalNavMenuSectionHeader"

// ** Utils
import {
  canViewMenuGroup,
  canViewMenuItem,
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent
} from "@layouts/utils"

const VerticalMenuNavItems = (props) => {
  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader
  }

  // ** user data from redux store
  const { userData } = useSelector((state) => state.auth)

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]
    if (item.children) {
      return (
        canViewMenuGroup(item, userData) && (
          <TagName item={item} index={index} key={item.id} {...props} />
        )
      )
    }
    return (
      canViewMenuItem(item, userData) && (
        <TagName key={item.id || item.header} item={item} {...props} />
      )
    )
  })

  return RenderNavItems
}

export default VerticalMenuNavItems
