import React from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const UploadChartSkeleton = () => {
  return (
    <SkeletonTheme>
      <Skeleton className="mb-25" height={155} />
    </SkeletonTheme>
  )
}

export default UploadChartSkeleton
