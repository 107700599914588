import { lazy } from "react"
import DetailUserViewTable from "../../views/manage-users/detail-user-view-table"

const ManageUsers = lazy(() => import("../../views/manage-users"))
//const DetailUserView = lazy(() => import('../../views/manage-users/detail-user-view'))

// ** Role Permission List
import { permissions } from "../../views/role-management/store/permissionList"

const permissionList = Object.keys(permissions)
const configurationList = Object.keys(permissions.Configuration)

const ManageUsersRoute = [
  {
    path: "/manage-users",
    element: <ManageUsers />,
    menuGroup: permissionList[0],
    accessId: configurationList[1],
    meta: {
      restricted: true,
      menuHidden: true
      // accessRestricted: true
    }
  },
  /*  {
        path: '/manage-users/detailview',
        element: <DetailUserView />,
        meta: {
            restricted: true,
            menuHidden: true,
            accessRestricted: false

        }
    }, */
  {
    path: "/manage-users/detailview/:email",
    element: <DetailUserViewTable />,
    menuGroup: permissionList[0],
    accessId: configurationList[1],
    meta: {
      restricted: true,
      menuHidden: true,
      accessRestricted: false
    }
  },
  {
    path: "/view-profile",
    element: <DetailUserViewTable />,
    menuGroup: permissionList[0],
    accessId: configurationList[1],
    meta: {
      restricted: true,
      menuHidden: true,
      accessRestricted: false
    }
  }
]

export default ManageUsersRoute
