// ** Icons Import
import {
  Circle,
  DollarSign,
  FilePlus,
  Package,
  Server,
  ShoppingCart,
  Smile,
  Truck
} from "react-feather"

export default [
  {
    id: "Revenue",
    title: "Revenue",
    accessId: "Revenue",
    icon: DollarSign,
    children: [
      {
        id: "Rev_Customers",
        title: "Customers",
        accessId: "Customers",
        accessRestricted: true,
        icon: Smile,
        navLink: "/v2/ar/customer/list"
      },
      {
        id: "Rev_Invoicing",
        title: "Invoicing",
        accessId: "Invoicing",
        accessRestricted: true,
        icon: FilePlus,
        navLink: "/v2/ar/invoicing"
      },
      {
        id: "Rev_Sales",
        title: "Sales",
        accessId: "Sales Dashboard",
        accessRestricted: true,
        icon: DollarSign,
        children: [
          {
            id: "Rev_Sales_Sales_Input",
            title: "Sales Input",
            accessId: "Sales",
            isHaveGrantParent: true,
            grantParentId: "Revenue",
            icon: Circle,
            navLink: "/v2/ar/sales-input"
          },
          {
            id: "Rev_Sales_Sales_Entries",
            title: "Sales Entries",
            accessId: "Sales",
            isHaveGrantParent: true,
            grantParentId: "Revenue",
            icon: Circle,
            navLink: "/v2/ar/salesdashboard/list"
          }
        ]
      },
      /* {
        id: 'collection',
        title: 'Collection',
        icon: Package,
        navLink: '/datatables/collection'
      }, */
      {
        id: "Rev_CA",
        title: "Cash Application",
        accessId: "Cash_Application",
        icon: Server,
        children: [
          {
            id: "Rev_CA_AD",
            title: "Apply Deposits",
            accessId: "Cash_Application",
            isHaveGrantParent: true,
            grantParentId: "Revenue",
            icon: Circle,
            // navLink: "/v2/ar/revenue/applydeposits"
            navLink: "/v2/app/ar/revenue/applydeposits"
          },
          {
            id: "Rev_CA_Remittance",
            title: "Remittance",
            icon: Circle,
            accessId: "Cash_Application",
            isHaveGrantParent: true,
            grantParentId: "Revenue",
            navLink: "/v2/ar/revenue/remittance-list"
          },
          {
            id: "Rev_CA_Deductions",
            title: "Deduction",
            icon: Circle,
            accessId: "Cash_Application",
            isHaveGrantParent: true,
            grantParentId: "Revenue",
            navLink: "/v2/ar/revenue/deduction"
          }
        ]
      }
      // {
      //   accessId: "Rev_Sales_Order",
      //   accessRestricted: true,
      //   id: "Rev_Sales_Order",
      //   title: "Sales Order",
      //   accessId: "Sales",
      //   icon: Circle,
      //   navLink: "/v2/ar/salesdashboard/sales-order/list"
      // }
      /* ,
      {
        id: 'sales',
        title: 'Sales',
        icon: ShoppingCart,
        navLink: '/datatables/sales'
      } */
    ]
  }
]
