// ** React Imports
import { lazy } from "react"

const UnBookedTransaction = lazy(() =>
  import("../../views/cratomatch/unbooked-transaction/index")
)
const BookedTransactionList = lazy(() =>
  import(
    "../../views/cratomatch/booked-transaction/booked-transaction-list/index"
  )
)
const BookedTransactionView = lazy(() =>
  import(
    "../../views/cratomatch/booked-transaction/booked-transaction-view/index"
  )
)

const CUUnBookedTransaction = lazy(() =>
  import("../../views/cratomatch/MOCK-credit-union/unbooked-transaction/index")
)
const CUBookedTransactionList = lazy(() =>
  import(
    "../../views/cratomatch/MOCK-credit-union/booked-transaction/booked-transaction-list/index"
  )
)
const BankReconciliation = lazy(() =>
  import("../../views/cratomatch/MOCK-bank-reconciliation/index")
)
const CUBookedTransactionView = lazy(() =>
  import(
    "../../views/cratomatch/MOCK-credit-union/booked-transaction/booked-transaction-view/index"
  )
)

const UploadBankTnx = lazy(() =>
  import("../../views/cratomatch/uploadBankTnxs/index")
)

const BankReconList = lazy(() =>
  import("../../views/cratomatch/bank-reconciliation/bank-reconciliation-list")
)

const BankReconView = lazy(() =>
  import("../../views/cratomatch/bank-reconciliation/bank-reconciliation-view")
)

const CapitalExpenseList = lazy(() => import("../../views/cap-ex/list"))
const CapitalExpenseCreation = lazy(() =>
  import("../../views/cap-ex/list/cap-ex-creation")
)
const CapitalExpenseRulesCreation = lazy(() =>
  import("../../views/cap-ex/rules-creation-view")
)
// ** Role Permission List
import { permissions } from "../../views/role-management/store/permissionList"

// const permissionList = Object.keys(permissions)
// const cratomatchList = Object.keys(permissions.Cratomatch)
const permissionList = Object.keys(permissions)[3]
const bankingList = Object.keys(permissions.Banking)
const CratomatchRoutes = [
  {
    accessMap: "Bank_Unbooked_Transactions",
    path: "/cratomatch/uncategorized-transactions",
    element: <UnBookedTransaction />,
    accessId: bankingList[0],
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true,
      className: "email-application"
    }
  },
  {
    accessMap: "Bank_Unbooked_Transactions",
    path: "/cratomatch/uncategorized-transactions/business-user/:batchId",
    element: <UnBookedTransaction />,
    accessId: bankingList[0],
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true,
      className: "email-application"
    }
  },
  {
    accessMap: "Bank_Booked_Transactions",
    path: "/cratomatch/categorized-transactions/list",
    element: <BookedTransactionList />,
    accessId: bankingList[1],
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Bank_Booked_Transactions",
    path: "/cratomatch/categorized-transactions/view/:id",
    element: <BookedTransactionView />,
    accessId: bankingList[1],
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Bank_Unbooked_Transactions",
    path: "/cratomatch/uncategorized-transactions-cu",
    element: <CUUnBookedTransaction />,
    accessId: bankingList[0],
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true,
      className: "email-application"
    }
  },
  {
    accessMap: "Bank_Booked_Transactions",
    path: "/cratomatch/categorized-transactions-cu/list",
    element: <CUBookedTransactionList />,
    accessId: bankingList[1],
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Bank_Booked_Transactions",
    path: "/cratomatch/categorized-transactions-cu/view/:id",
    element: <CUBookedTransactionView />,
    accessId: bankingList[1],
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Bank_Bank_Reconciliation",
    path: "/cratomatch/bank-reconciliation",
    element: <BankReconciliation />,
    accessId: bankingList[1],
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Bank_Bank_Reconciliation",
    path: "/cratomatch/bank-reconciliation/list",
    element: <BankReconList />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Bank_Bank_Reconciliation",
    path: "/cratomatch/bank-reconciliation/view/:reconId",
    element: <BankReconView />,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "Bank_Upload_Bank_Transaction",
    path: "/cratomatch/uploadBank-tnxs",
    element: <UploadBankTnx />,
    accessId: bankingList[1],
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "",
    path: "/beta/cap-ex-list",
    element: <CapitalExpenseList />,
    accessId: "",
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "",
    path: "/beta/create-cap-ex",
    element: <CapitalExpenseCreation />,
    accessId: "",
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "",
    path: "/beta/cap-ex-business-user",
    element: <CapitalExpenseList />,
    accessId: "",
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "",
    path: "/beta/cap-ex-rules",
    element: <CapitalExpenseList />,
    accessId: "",
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "",
    path: "/beta/create-cap-ex-rules",
    element: <CapitalExpenseRulesCreation />,
    accessId: "",
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "",
    path: "/beta/view-cap-ex-rules",
    element: <CapitalExpenseRulesCreation />,
    accessId: "",
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "",
    path: "/beta/view-cap-ex",
    element: <CapitalExpenseCreation />,
    accessId: "",
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  },
  {
    accessMap: "",
    path: "/beta/business-user-view-cap-ex",
    element: <CapitalExpenseCreation />,
    accessId: "",
    menuGroup: permissionList,
    meta: {
      restricted: true,
      accessRestricted: true
    }
  }
]

export default CratomatchRoutes
