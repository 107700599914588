import React from "react"
import { Card, CardBody } from "reactstrap"
import FileUploaderSingle from "./FileUploaderSingle"
import "@styles/react/libs/file-uploader/file-uploader.scss"
const DocumentUpload = ({
  files,
  setFiles,
  setupload,
  seterFiles,
  postDetails,
  setpostDetails
}) => {
  return (
    <Card>
      <CardBody>
        <h4>Upload Documents</h4>
        <FileUploaderSingle
          files={files}
          setFiles={setFiles}
          setupload={setupload}
          seterFiles={seterFiles}
          postDetails={postDetails}
          setpostDetails={setpostDetails}
        />
      </CardBody>
    </Card>
  )
}

export default DocumentUpload
