import React from "react"
import { Row, Col, Card } from "reactstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const WizardSkeleton = () => {
  return (
    <Card>
      <SkeletonTheme>
        <div className="m-2 ">
          <Row>
            <Col lg="2" className=" border-2 border-end">
              <Skeleton height={30} className="mt-2" />
              <Skeleton height={30} className="mt-3" />
              <Skeleton height={30} className="mt-3" />
              <Skeleton height={30} className="mt-3" />
            </Col>

            <Col lg="8">
              <Skeleton height={30} className="mt-2" />
              <Skeleton height={25} className="mt-2" />
              <Skeleton height={25} className="mt-2" />
              <Skeleton height={25} className="mt-2" />
              <Skeleton height={25} className="mt-2" />
            </Col>
          </Row>
        </div>
      </SkeletonTheme>
    </Card>
  )
}
export default WizardSkeleton
