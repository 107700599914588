import { createSlice } from "@reduxjs/toolkit"
import { INITIAL_VALUES } from "./constants"
import { handleLogout } from "../authentication"

const filterSlice = createSlice({
  name: "filters",
  initialState: INITIAL_VALUES,
  reducers: {
    handleFilter: (state, action) => {
      const { key, value } = action.payload
      state[key] = { ...state[key], ...value }
    },
    handleClear: (state, action) => {
      const { key } = action.payload
      state[key] = { ...INITIAL_VALUES[key] }
    },
    setActiveFilter: (state, action) => {
      const { key, value } = action.payload
      const { ACTIVE_FILTERS } = state
      state.ACTIVE_FILTERS = value
        ? [...ACTIVE_FILTERS, key]
        : ACTIVE_FILTERS.filter((val) => val != key)
    },
    handleClearAll: () => {
      return INITIAL_VALUES
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogout.type, () => INITIAL_VALUES)
  }
})

export const { handleFilter, handleClear, setActiveFilter, handleClearAll } =
  filterSlice.actions
export default filterSlice.reducer
