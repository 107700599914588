import { lazy } from "react"

const DashBoard = lazy(() => import("../../views/customer-portal/index"))
const SaaSDashboard = lazy(() => import("../../views/beta/saas-dashboard"))
const CustomerReports = lazy(() =>
  import("../../views/customer-portal/customer-reports/index")
)
const DemoSaasDashboard = lazy(() =>
  import("../../views/beta/demo-saas-dashboard")
)

const CustomerPortalRoutes = [
  {
    path: "/customer-portal",
    element: <DashBoard />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/beta/demo-saas-dashboard",
    element: <DemoSaasDashboard />,
    meta: {
      // layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/saas-dashboard",
    element: <SaaSDashboard />,
    meta: {
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/customer-reports",
    element: <CustomerReports />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  }
]

export default CustomerPortalRoutes
