const SETTINGS_LINK = "/v2/settings"
// ** Payables EnterBill
export const enterBills = [
  { title: "Payables", link: "" },
  { title: "Bill Processing ", link: "" },
  { title: "All Bills", link: "" }
]

// ** Vendor List
export const vendorListBreadCrumb = [
  { title: "Payables", link: "" },
  { title: "Vendors", link: "" }
]

// ** Vendor View
export const vendorViewBreadCrumb = [
  { title: "Payables", link: "" },
  { title: "Vendors", link: "" }
]

// ** Invoice View
export const invoiceViewBreadCrumb = [
  { title: "Pages", link: "/" },
  { title: "User", link: "/" },
  { title: "User Settings", link: "" }
]

// ** Invoice Edit
export const invoiceEditBreadCrumb = [
  { title: "Pages", link: "/" },
  { title: "User", link: "/" },
  { title: "User Settings", link: "" }
]

// ** Cratopay Make Payments
export const makePaymentsBreadcrumb = [
  { title: "Payables", link: "" },
  { title: "Payments", link: "" },
  { title: "Make Payments", link: "" }
]

// ** Cratopay Apply Payments
export const applyPaymentsBreadcrumb = [
  { title: "Pages", link: "/" },
  { title: "User", link: "/" },
  { title: "User Settings", link: "" }
]

// ** Cratopay Pay Now
export const PayNowBreadcrumb = [
  { title: "Pages", link: "/" },
  { title: "User", link: "/" },
  { title: "User Settings", link: "" }
]

// ** Cratopay Add Payment And Batch  Payments
export const PaymentBatchBreadcrumb = [
  { title: "Pages", link: "/" },
  { title: "User", link: "/" },
  { title: "User Settings", link: "" }
]

// ** Cratopay Add Payment And Batch  Payments
export const trackPaymentsBreadcrumb = [
  { title: "Payables", link: "" },
  { title: "Cratopay", link: "" },
  { title: "Track Payments", link: "" }
]

export const billProcessingBreadCrumb = [
  { title: "Payables", link: "" },
  { title: "Bill Processing", link: "" },
  { title: "In Process", link: "" }
]

export const settingsBillProcessingBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Payables", link: "" },
  { title: "Bill Processing", link: "" }
]

export const vendorSetupBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Payables", link: "" },
  { title: "Vendor Setup", link: "" }
]

export const revenueInvoiceBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Revenue", link: "" },
  { title: "Invoicing", link: "" }
]

export const revenueCustomerSetupBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Revenue", link: "" },
  { title: "Customer Creation", link: "" }
]

export const manageUserBreadCrumb = [{ title: "Manage User", link: "" }]

// ** Customer List
export const customerListBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Customers", link: "" }
]

export const invoicingBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Invoices", link: "" }
]

// ** Sales
export const salesDashboardBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Sales", link: "" },
  { title: "Sales Entries", link: "" }
]

export const salesInputCrumb = [
  { title: "Revenue", link: "" },
  { title: "Sales", link: "" },
  { title: "Sales Input", link: "" }
]

// ** Cash Application - ApplyDeposits List
export const applyDepositsBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Cash Application", link: "" },
  { title: "Apply Deposits", link: "" }
]

// ** Apply Deposits View
export const depositViewBreadcrumbData = [
  { title: "Revenue", link: "" },
  { title: "Cash Application", link: "" },
  { title: "Deposits", link: "/v2/ar/revenue/applydeposits" },
  { title: "View Deposits", link: "" }
]
export const remittanceViewBreadcrumbData = [
  { title: "Revenue", link: "" },
  { title: "Cash Application", link: "" },
  { title: "Remittance", link: "/v2/ar/revenue/remittance-list" },
  { title: "Remittance view", link: "" }
]

//** Customer view
export const customerViewBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Customers", link: "" }
]

// ** Remittance
export const remittanceBreadCrumbData = [
  { title: "Revenue", link: "" },
  { title: "Cash Application", link: "" },
  { title: "Remittance", link: "" }
]
// ** Remittance
export const uploadRemittancePdfBreadCrumbData = [
  { title: "Revenue", link: "" },
  { title: "Cash Application", link: "" },
  { title: "Remittance", link: "/v2/ar/revenue/remittance-list" },
  { title: "Upload Remittance", link: "" }
]
export const uploadRemittanceBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Remittance", link: "/v2/ar/revenue/remittance-list" },
  { title: "Upload Remittance", link: "" }
]

export const invoiceviewing = [
  { title: "Revenue", link: "" },
  { title: "Invoice", link: "/v2/ar/invoicing" },
  { title: "View Invoice", link: "" }
]

export const uploadARInvoiceBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Invoicing", link: "/v2/ar/invoicing" },
  { title: "Upload Invoice", link: "" }
]

//Crop structure chart of accouunts
export const chartOfaccounts = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Master Data", link: "" },
  { title: "Chart of Accounts", link: "" }
]
export const uploadDocument = [{ title: "Upload Documents", link: "" }]

// ** Deduction View
export const deductionView = [
  { title: "Revenue", link: "" },
  { title: "Cash Application", link: "" },
  { title: "Deduction", link: "/v2/ar/revenue/deduction" },
  { title: "View Deduction", link: "" }
  // { title: '', link: '' }
]
// ** Cash Application - Deduction
export const DeductionBreadcrumb = [
  { title: "Revenue", link: "" },
  { title: "Cash Application", link: "" },
  { title: "Deduction", link: "" }
]

export const businessProfileBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Master Data", link: "" },
  { title: "Business Profile", link: "" }
]

export const integrationBradCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Integration", link: "" },
  { title: "Accounting System Integration", link: "" }
]

export const ppIntegrationBradCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Integration", link: "" },
  { title: "Subscription Platform", link: "" }
]

export const cratobotViewBreadCrumb = [
  { title: "Settings", link: "" },
  // { title: "Payables", link: "" },
  { title: "Cratobot", link: "" },
  { title: "View Rule", link: "" }
]

export const corporateStructureBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Master Data", link: "" },
  { title: "Corporate Structure", link: "" }
]

export const customizeTemplateBreadCrumb = [
  { title: "Settings", link: "" },
  { title: "Revenue", link: "" },
  { title: "Customize Template", link: "" }
]

// ** Booked Transaction
export const bookedTransactionBreadCrumb = [
  { title: "Banking", link: "" },
  { title: "Bank Posting", link: "" },
  { title: "Booked Transaction", link: "" }
]
// ** unbooked Transaction
export const unbookedTransactionBreadCrumb = [
  { title: "Banking", link: "" },
  { title: "Bank Posting", link: "" },
  { title: "Unbooked Transaction", link: "" }
]
// ** Banking Settings
export const bankingSettigsBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Integration", link: "" },
  { title: "Banking Integration", link: "" }
]

export const cratopayConfigBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Payables", link: "" },
  { title: "Cratopay", link: "" }
]

export const posSystemBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Integration", link: "" },
  { title: "POS System Integration", link: "" }
]

export const codingRules = [
  { title: "Settings", link: SETTINGS_LINK },
  // { title: "Payables", link: "" },
  { title: "AP Coding Rules", link: "" }
]

export const cratoBotBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  // { title: "Payables", link: "" },
  { title: "AP Processing Rules", link: "" }
]

// ** Sales Setting
export const salesConfigBreadCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Revenue", link: "" },
  { title: "Sales", link: "" }
]

// ** Banking Rules
export const bankingRulesListBreadcrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Cratobot", link: "" },
  { title: "Bank Posting Rules", link: "" }
]

export const bankReconRulesListBreadctumb = [
  ...bankingRulesListBreadcrumb,
  { title: "View Posting Rules", link: "" }
]

/* OverPayment Bread Crumb */
export const OverPaymentViewBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Invoices", link: "/v2/ar/invoicing" },
  { title: "View Payment", link: "" }
]

// ** Bank Reconciliation
export const bankReconciliation = [
  { title: "Banking", link: "" },
  { title: "Bank Reconciliation", link: "" }
]

// ** Upload Bank tnxs
export const uploadBankTnxs = [
  { title: "Banking", link: "" },
  { title: "Upload Bank Txn(s)", link: "" }
]

// ** Sales Order
export const salesOrderListBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Sales Order", link: "" }
]

export const salesOrderViewBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Sales Order", link: "" },
  { title: "Order #", link: "" }
]

export const BankPayoutsBreadCrumb = [
  { title: "Payables", link: "" },
  { title: "Payments", link: "" },
  { title: "Apply Payments - List", link: "" }
]

export const applyBankPayoutsBreadCrumb = [
  { title: "Payables", link: "" },
  { title: "Payments", link: "" },
  { title: "Apply Payments", link: "" }
]

export const uploadSalesOrderBreadCrumb = [
  { title: "Revenue", link: "" },
  { title: "Sales Order", link: "" },
  { title: "Upload Sales Order", link: "" }
]

// ** Resportings
export const reportings = [{ title: "Reportings", link: "" }]

export const againgreportBreadCrumb = [
  { title: "Reportings", link: "" },
  { title: "Payables", link: "" },
  { title: "Ap Aging Report", link: "" }
]
export const unpaidAgingReportBreadCrumb = [
  { title: "Reportings", link: "" },
  { title: "Payables", link: "" },
  { title: "Unpaid Bills Aging Report", link: "" }
]

export const accrualReportBreadCrumb = [
  { title: "Reportings", link: "" },
  { title: "Payables", link: "" },
  { title: "Ap Aging Report", link: "" }
]

export const salesTaxInfoBreadCrumb = [
  { title: "Reporting", link: "/v2/reportings" },
  { title: "Revenue", link: "/v2/reportings" },
  { title: "Sales Tax Report", link: "" }
]

export const reportingGeneralLedger = [
  { title: "CratoLedge", link: "" },
  { title: "General Ledger", link: "" }
]

export const reportingUploadTxn = [
  { title: "CratoLedge", link: "" },
  { title: "General Ledger", link: "" },
  { title: "Upload Txn(s)", link: "" }
]

// ** Reporting Configuration General Ledger
export const reportingGeneralLedgerConfig = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "CratoLedge", link: "" },
  { title: "General Ledger", link: "" }
]

export const reminderBradedCrumb = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Cratobot", link: "" },
  { title: "Reminder", link: "" }
]
export const businessUserAgingReport = [
  { title: "Reporting", link: "" },
  { title: "Business User Aging Report", link: "" }
]

const reporting = { title: "Reporting ", link: "/v2/reportings" }
export const reportingBankingBankIntegration = [
  reporting,
  { title: "Banking ", link: "" },
  { title: "Bank Transactions ", link: "" }
]

export const cashReciptBreadcrumb = [
  reporting,
  { title: "Revenue", link: "" },
  { title: "Cash Recipt Report", link: "" }
]

export const cashBalancingBreadcrumb = [
  { title: "Reporting", link: "/v2/reportings" },
  { title: "Revenue", link: "" },
  { title: "Cash Balancing Report", link: "" }
]

export const casFlowTrendBreadCrumb = [
  reporting,
  { title: "Banking", link: "" },
  { title: "Cash Flow Trend", link: "" }
]

export const accountingCalender = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Master Data", link: "" },
  { title: "Accounting Calendar", link: "" }
]
export const deductionSettings = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Cratobot", link: "" },
  { title: "Deductions", link: "" }
]
export const salesInputBreadcrumb = [
  { title: "Revenue", link: "" },
  { title: "Sales Input", link: "" }
]
export const deductionSettingsView = [
  { title: "Settings", link: SETTINGS_LINK },
  { title: "Cratobot", link: "" },
  { title: "Deductions", link: `${SETTINGS_LINK}/cratobot/deductions` }
]

export const throughputReport = [
  { title: "Reporting", link: "/v2/reportings" },
  { title: "Payables", link: "/v2/reportings" },
  { title: "Throughput Report", link: "" }
]
