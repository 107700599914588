import React, { Fragment } from "react"
import { X } from "react-feather"
import { Card, CardImg, Label, Input, Row, Col, Form, Button } from "reactstrap"
import Select from "react-select"
import _ from "lodash"
import Cleave from "cleave.js/react"
// ** Utlis
import { selectThemeColors } from "@utils"
import PdfViewer from "@custom-components/pdf-reader/react-pdf"

const InformationCard = ({
  files,
  entity,
  setFiles,
  postDetails,
  setpostDetails,
  invoiceValues
}) => {
  /*  const companyOption = company.map(val => {
         return { label: `${val.clientCode} -${val.clientName}`, value: val.clientId }
     }) */

  //  const postValues = []
  /*  const prefilValue = () => {
         const postDetailsCopy = _.cloneDeep(postDetails)
         let companyPrefilValue = ''
         postDetailsCopy.map((value, index) => {
             if (value.invoiceValues.clientName === '' && value.invoiceValues.clientId === '') {
                 company.map(val => {
                     if (val.clientCode === selector.client.clientCode) {
                         postDetailsCopy[index].invoiceValues.clientName = val.clientName
                         postDetailsCopy[index].invoiceValues.clientId = val.clientId
                         setpostDetails(postDetailsCopy)
                         postValues = postDetailsCopy
                         companyPrefilValue = { label: `${val.clientCode}-${val.clientName}`, value: val.clientId }
                     }
                 })
             }
         })
         return companyPrefilValue
     } */
  const loactionPrefil = () => {
    let locationPrefilValue = ""
    const postDetailsCopy = _.cloneDeep(postDetails)
    postDetailsCopy.map((value, index) => {
      if (value.invoiceValues.clientEntityId === "") {
        entity.map((val) => {
          postDetailsCopy[index].invoiceValues.clientEntityId =
            val.clientEntityId
          postDetailsCopy[index].invoiceValues.clientEntityName =
            val.clientEntityName
          postDetailsCopy[index].invoiceValues.clientEntityCode =
            val.clientEntityCode
          setpostDetails(postDetailsCopy)
          locationPrefilValue = {
            label: `${val.clientEntityId} -${val.clientEntityName}`,
            value: val.clientEntityId
          }
        })
      }
    })
    return locationPrefilValue
  }
  const location = entity.map((value) => {
    return {
      label: `${value.clientEntityCode} -${value.clientEntityName}`,
      value: value.clientEntityId
    }
  })

  const handleRemoveFile = (file, e, i) => {
    e.preventDefault()
    const postDetailsCopy = _.cloneDeep(postDetails)
    const uploadedFiles = files
    const filtered = uploadedFiles.filter((i) => i.name !== file.name)
    setFiles([...filtered])
    postDetailsCopy.splice(i, 1)
    setpostDetails(postDetailsCopy)
  }

  /* const onchange = (e, i) => {
        const postDetailsCopy = _.cloneDeep(postDetails)
        const postDetailsCopies = _.cloneDeep(postDetailsCopy)
        const s = e.label.split(' -')
        postDetailsCopies.map((value, index) => {
            if (index === i) {
                value.invoiceValues.clientName = s[1]
                value.invoiceValues.clientId = e.value
            }
        })
        postDetailsCopy[i] = postDetailsCopies[i]
        setpostDetails(postDetailsCopy)
    } */

  const locationonChange = (e, i) => {
    const postDetailsCopy = _.cloneDeep(postDetails)
    const postDetailsCopies = _.cloneDeep(postDetailsCopy)
    const tmp = e.label.split(" -")
    postDetailsCopies[i].invoiceValues.clientEntityId = e.value
    entity.map((v) => {
      if (e.value === v.clientEntityId) {
        postDetailsCopies[i].invoiceValues.clientEntityCode = tmp[0]
        postDetailsCopies[i].invoiceValues.clientEntityName = tmp[1]
      }
    })
    postDetailsCopy[i] = postDetailsCopies[i]
    setpostDetails(postDetailsCopy)
  }

  const amountChange = (e, v) => {
    const postDetailsCopy = _.cloneDeep(postDetails)
    const postDetailsCopies = _.cloneDeep(postDetailsCopy)
    postDetailsCopies[v].invoiceValues.totalAmount = e.target.value
    if (postDetailsCopies[v].invoiceValues.totalAmount) {
      postDetailsCopy[v] = postDetailsCopies[v]
      setpostDetails(postDetailsCopy)
    }
  }

  return (
    <Fragment>
      <Row>
        <Col lg="12">
          {files.map((file, v) => {
            if (v + 1 > postDetails.length) {
              postDetails.push({ invoiceValues })
            }
            return (
              <Card key={v}>
                <Row>
                  <Col lg="5">
                    <PdfViewer
                      style={{ height: "200px", overflow: "hidden" }}
                      className="card-img-top"
                      url={file.preview}
                    />
                  </Col>
                  <Col lg="7">
                    <Form className=" p-1 ps-lg-0 pb-lg-0  ">
                      <div className="d-flex justify-content-between">
                        <div className="mt-1">
                          <h6 className="d-inline ">Document Information </h6>
                        </div>
                        <div className="mt-1">
                          <span
                            className="btn bg-transprent fw-bold"
                            onClick={(e) => handleRemoveFile(file, e, v)}
                            id="document-info-close"
                          >
                            <X size={20} />
                          </span>
                        </div>
                      </div>
                      {/*  <Row >
                                            <Col>
                                                <Label className="fw-bold mb-1" >Module <span>*</span></Label>
                                                <Select
                                                    name="module"
                                                    className="react-select"
                                                    classNamePrefix="select"
                                                    theme={selectThemeColors}
                                                    options={[{ label: 'All', value: 'All' }]}
                                                />
                                            </Col>
                                            <Col>
                                                <Label className="fw-bold mb-1 " >Process<span>*</span></Label>
                                                <Select
                                                    name="process"
                                                    className="react-select "
                                                    classNamePrefix="select"
                                                    theme={selectThemeColors}
                                                    options={[{ label: 'All', value: 'All' }]}
                                                />
                                            </Col>
                                        </Row> */}
                      <Row className="my-1 ">
                        {/*  <Col sm='12' md='4'>
                                                    <Label className="fw-bold mb-1">Company</Label>
                                                    <Select
                                                        classNamePrefix="select"
                                                        id={`company${v}`}
                                                        name={`company${v}`}
                                                        className="react-select"
                                                        theme={selectThemeColors}
                                                        options={companyOption}
                                                        defaultValue={prefilValue}
                                                        value={companyOption.find(item => item.value === postDetails[v].invoiceValues.clientId)}
                                                        menuPlacement='top'
                                                        styles={{
                                                            menuList: styles => {
                                                                return {
                                                                    ...styles,
                                                                    maxHeight: 100
                                                                }
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            onchange(e, v)
                                                        }}
                                                    />
                                                </Col> */}
                        <Col sm="12" md="6">
                          <Label className="fw-bold mb-1">Business Unit</Label>
                          <Select
                            id={`location${v}`}
                            name={`location${v}`}
                            className="react-select"
                            classNamePrefix="select"
                            theme={selectThemeColors}
                            options={location}
                            defaultValue={entity.length === 1 && loactionPrefil}
                            value={location.find(
                              (item) =>
                                item.value ===
                                postDetails[v].invoiceValues.clientEntityId
                            )}
                            menuPlacement="top"
                            styles={{
                              menuList: (styles) => {
                                return {
                                  ...styles,
                                  maxHeight: 100
                                }
                              }
                            }}
                            onChange={(e) => {
                              locationonChange(e, v)
                            }}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <Label className="fw-bold mb-1">Amount</Label>
                          <Cleave
                            className="form-control text-end"
                            placeholder="0.00"
                            defaultValue={0}
                            options={{ numericOnly: true }}
                            value={postDetails[v].invoiceValues.totalAmount}
                            onChange={(e) => {
                              amountChange(e, v)
                            }}
                            id="amount"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>
            )
          })}
        </Col>
      </Row>
    </Fragment>
  )
}

export default InformationCard
