// ** React Imports
import { Fragment } from "react"

// ** Reactstrap Import
import {
    Row,
    Col,
    Input
} from 'reactstrap'

const TableHeader = () => {
    return (
        <Fragment>
            <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
                <Row>
                    <Col xl='6' className='d-flex align-items-center p-0'>
                        <div className='d-flex align-items-center w-100'>
                            <label htmlFor='rows-per-page'>Show</label>
                            <Input
                                className='mx-50'
                                type='select'
                                id='rows-per-page'
                                style={{ width: '5rem' }}
                            >
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                            </Input>
                            <label htmlFor='rows-per-page'>Entries</label>
                        </div>
                    </Col>
                    <Col
                        xl='6'
                        className='d-flex align-items-sm-center justify-content-lg-end justify-content-start flex-lg-nowrap flex-wrap flex-sm-row flex-column pe-lg-1 p-0 mt-lg-0 mt-1'
                    >
                        <div className='d-flex align-items-center mb-sm-0 mb-1 me-1'>
                            <label className='mb-0' htmlFor='search-invoice'>
                                Search:
                            </label>
                            <Input
                                type='text'
                                id='search-invoice'
                                className='ms-50 w-100'
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}

export default TableHeader