// ** Imports
import { lazy } from "react"

const Settings = lazy(() => import("../../views/settings-card/index"))

const NewSettings = [
  {
    path: "/settings",
    element: <Settings />,
    meta: {
      // layout: "blank",
      publicRoute: true,
      restricted: true
    }
  }
]

export default NewSettings
