import React, { useState } from "react"
import { Card, CardBody, Row, Col, Button, Badge } from "reactstrap"
import EditPersonalInfoModal from "./EditPersonalInfoModal"
import Avatar from "@components/avatar"
import { hasValue, toCapitalize as C } from "@utils"

const UserCard = ({ userProfileData, paramValue, getCall }) => {
  const [showUserModal, setShowUserModal] = useState(false)
  const layout = C(userProfileData?.mainLayout ?? "vertical")
  const menuCollapsed =
    userProfileData?.menuCollapsed ?? true ? "Collapsed" : "Expanded"
  const isVertical = layout == "Vertical"
  return (
    <div>
      <EditPersonalInfoModal
        show={showUserModal}
        setShow={setShowUserModal}
        userProfileData={userProfileData}
        paramValue={paramValue}
        getCall={getCall}
      />
      {userProfileData && (
        <Card>
          <CardBody>
            <div className="user-avatar-section">
              <div className="d-flex align-items-center flex-column">
                <Avatar
                  initials
                  color={"light-primary"}
                  className="rounded mt-3 mb-2"
                  content={`${userProfileData.firstName
                    ?.charAt(0)
                    .toUpperCase()} ${userProfileData.lastName
                    ?.charAt(0)
                    .toUpperCase()}`}
                  contentStyles={{
                    borderRadius: 0,
                    fontSize: "calc(48px)",
                    width: "100%",
                    height: "100%"
                  }}
                  style={{
                    height: "110px",
                    width: "110px"
                  }}
                />
                <div className="text-center mb-2">
                  <h4 className="fw-bolder">
                    {userProfileData.firstName ?? ""}{" "}
                    {userProfileData.lastName ?? ""}
                  </h4>
                  <Badge
                    color={`${
                      userProfileData?.isActive
                        ? "light-success"
                        : "light-danger"
                    } w-100`}
                  >
                    {userProfileData?.isActive ? "ACTIVE" : "IN-ACTIVE"}
                  </Badge>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <h4>Personal Information</h4>
            </div>
            <hr />
            <Row tag="dl" className="mb-0">
              <Col tag="dt" sm="6" md="4" className="fw-bolder my-1">
                First Name:
              </Col>
              <Col tag="dd" sm="6" md="8" className="my-1">
                {hasValue(userProfileData?.firstName, "-")}
              </Col>
              <Col tag="dt" sm="6" md="4" className="fw-bolder my-1">
                Last Name:
              </Col>
              <Col tag="dd" sm="6" md="8" className="my-1">
                {hasValue(userProfileData?.lastName, "-")}
              </Col>
              <Col tag="dt" sm="6" md="4" className="fw-bolder my-1">
                Email:
              </Col>
              <Col tag="dd" sm="6" md="8" className="my-1">
                {hasValue(userProfileData?.contact?.emailId, "-")}
              </Col>
              <Col tag="dt" sm="6" md="4" className="fw-bolder my-1">
                Contact:
              </Col>
              <Col tag="dd" sm="6" md="8" className="my-1">
                {hasValue(userProfileData?.registerdMobileNo, "-")}
              </Col>
              <Col tag="dt" sm="6" md="4" className="fw-bolder my-1">
                Layout:
              </Col>
              <Col tag="dd" sm="6" md="8" className="my-1">
                {layout}
              </Col>
              {isVertical && (
                <>
                  <Col tag="dt" sm="6" md="4" className="fw-bolder my-1">
                    Menu:
                  </Col>
                  <Col tag="dd" sm="6" md="8" className="my-1">
                    {menuCollapsed}
                  </Col>
                </>
              )}
            </Row>
            <div className="text-center mt-3">
              <Button
                color="primary"
                className="px-5"
                onClick={() => {
                  setShowUserModal(!showUserModal)
                }}
                id="personal-info-edit"
              >
                Edit
              </Button>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  )
}

export default UserCard
