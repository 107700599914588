export const inactiveRoute = [
  // ** Master Data
  // "/ap/settings/master-data/accounting-calender",
  // ** Expenses
  // "/ap/cratopay/make-payments",
  // "/ap/cratopay/apply-payments/:vendorId",
  // "/ap/cratopay/pay-now/:vendorId",
  // "/ap/cratopay/create-batch",
  // "/ap/cratopay/track-payment",
  // "/ap/cratopay/track-payment/:id",
  // "/ap/cratopay/apply-payments",
  // "/ap/cratopay/apply-bank-payments/view",
  // "/ap/cratopay/apply-bank-payments",
  // "/ap/cratopay/configuration",
  // "/ap/cratopay/configuration/remittanceNoticeSample",
  // "/ap/cratopay/view-batch",
  // "/ap/coding-rules",
  "/ap/expense/create-invoice",
  "/ap/expense/create-credit-memo",
  // ** revenue
  "/ar/salesdashboard/list",
  "v2/ar/salesdashboard/sales-input/list",
  "/v2/ar/sales-input",
  "/ar/sales-input",
  "/v2/ar/salesdashboard/list",
  "/ar/salesdashboard/sales-order/list",
  // "/reportings",

  "/ap/settings/revenue/customize-template",
  // "/ar/apply-deposit/invoice-view/:id",
  // "/ar/revenue/applydeposits",
  // "/ar/revenue/applydeposits/view/:id",
  // "/ar/revenue/remittance-list",
  // "/ar/revenue/uplaod-remittance",
  // "/ar/revenue/remittance/remittance-advice/:id",
  // "/ar/revenue/deduction",
  // "/ar/revenue/deduction/view/:id",
  // '/ar/customer/view/:id',

  //banking
  "/cratomatch/uncategorized-transactions-cu",
  "/cratomatch/categorized-transactions-cu/list",
  "/cratomatch/bank-reconciliation",
  // "/cratomatch/uncategorized-transactions",
  // "/cratomatch/categorized-transactions/list",
  // "/cratomatch/uploadBank-tnxs",

  //cratoledge
  "/ledger/general-ledger",

  // ** Settings Cratobot
  /* '/ap/settings/cratobot/allrules',
  '/ap/settings/cratobot/rule/:id',
  '/ap/settings/cratobot/rule/:id/view',
  '/ap/settings/cratobot/rule/:id/edit', */

  // ** Settings Integration
  // '/ap/settings/integration/accountingsystem',
  "/cratomatch/settings/pos-system",
  "/pos-redirect",

  // ** Settings Cratomatch
  "/settings/accountingList",
  "/cratomatch/settings/pos-system",
  "/settings/banking/bank-recon/list",
  "/settings/ledger/general-ledger",
  //setting expense
  // "/ap/cratopay/configuration",
  // "/ap/settings/expenses/reminder",
  // ** Settings Revenue
  // "/settings/revenue/cash-app/apply-deposit",
  // "/ap/settings/revenue/customize-template",
  "/ap/settings/revenue/customize-template2",
  "/beta"
  // ** Settings Cash App
  // "/settings/revenue/cash-app/apply-deposit"
]
const ENV = process.env.REACT_APP_TYPE === "Prod"
export const getNavs = (item) => {
  return item
    .filter(({ navLink }) =>
      ENV
        ? !inactiveRoute.includes(navLink ? navLink.replace("/v2", "") : "")
        : true
    )
    .map((item) => ({
      ...item,
      ...(item?.children ? { children: getNavs(item.children) } : {})
    }))
}

export const routeFilterCheck = (route) =>
  ENV ? !inactiveRoute.includes(route.path) : route
