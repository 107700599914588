import moment from "moment"
import React, { useState, useEffect } from "react"
import { Check, X } from "react-feather"
import Flatpickr from "react-flatpickr"
import { useSelector } from "react-redux"

import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap"

import "./styles.scss"

const DF = "YYYY-MM-DD"

const presetRange = {
  "Previous day": moment().subtract(1, "day").format(DF),
  Today: moment().format(DF),
  "This week": moment().startOf("week").format(DF),
  "Previous week": moment().subtract(1, "week").startOf("week").format(DF),
  "Previous month": moment().subtract(1, "month").startOf("month").format(DF),
  "This month": moment().startOf("month").format(DF),
  "Previous year": moment().subtract(1, "year").startOf("year").format(DF),
  "This year": moment().startOf("year").format(DF)
}
const PillDatePicker = ({
  value,
  onChange,
  displayValue = null,
  dateRequired = null,
  defaultDate = null,
  options: defaultOptions = ["Today", "This week", "This month", "This year"],
  name
}) => {
  const isArrayOfString = defaultOptions.every((v) => typeof v === "string")
  const options = isArrayOfString
    ? defaultOptions
    : defaultOptions.map(({ label }) => label)

  const [isOpen, setIsOpen] = useState(false)
  const [display, setDisplay] = useState(dateRequired ? "Today" : "")
  const selector = useSelector((state) => state)
  const dateFormat = selector?.auth?.userData?.basicProfile?.dateFormat ?? DF
  // const { dateFormat = DF } = useSelector(
  //   ({ auth: { userData } }) => userData.basicProfile
  // )
  useEffect(() => {
    if (!value?.[0]) return setDisplay()
    const [start, end] = value
    setDisplay(
      `${moment(start).format(dateFormat)} to ${moment(end).format(dateFormat)}`
    )
  }, [value])

  const datePickerChange = (date) => {
    if (date.length != 2) return
    setIsOpen(false)
    const [start, end] = date
    setDisplay(
      `${moment(start).format(dateFormat)} to ${moment(end).format(dateFormat)}`
    )
    onChange([moment(start).format(DF), moment(end).format(DF)], "custom-date")
  }

  const onDateChange = (e) => {
    let [from, to] = ["", ""]
    if (e == display && !dateRequired) {
      setDisplay()
      onChange([from, to], e, "RESET")
      return
    }
    if (!isArrayOfString) {
      const optionObj = defaultOptions.find(({ label }) => label === e) ?? {}
      onChange(optionObj.value ?? "", optionObj.label ?? "")
      setDisplay(e)
      return
    }
    from = presetRange[e]
    to = moment().format(DF)
    onChange([from, to], e)
    setDisplay(e)
  }

  useEffect(() => {
    if (displayValue && options.includes(displayValue))
      return setDisplay(displayValue)
    if (defaultDate && options.includes(defaultDate))
      return onDateChange(defaultDate)
    if (defaultDate) return datePickerChange(defaultDate)
  }, [])
  const isActive =
    display && options.filter((val) => val == display).length == 0
  //
  return (
    <ButtonDropdown
      isOpen={isOpen}
      toggle={(e) => {
        console.log(e)
        if (isOpen && stopPropagation(e.srcElement)) return
        setIsOpen((s) => !s)
      }}
      direction="down"
      className="filterButton"
      size="sm"
    >
      <DropdownToggle outline={!display} color="primary" caret id="filter-date">
        {display ? display : name ? name : "Date Range"}
      </DropdownToggle>
      <DropdownMenu
        className="filterMenu "
        style={{ width: "210px" }}
        id="select-date"
      >
        {options.map((val, id) => (
          <DropdownItem
            key={id}
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              display: "flex",
              justifyContent: "space-between"
            }}
            onClick={() => onDateChange(val)}
          >
            {val}
            {display == val && <Check style={{ color: "#34a353" }} />}
          </DropdownItem>
        ))}
        <DropdownItem
          onClick={(e) => e.preventDefault()}
          style={style}
          className="show-on-wrappper"
        >
          <div className="datePickerWrapper">
            <p>Custom Date</p>

            <Flatpickr
              value={value}
              className="form-control customDatePicker"
              onChange={datePickerChange}
              style={{ opacity: "0 !important" }}
              onClick={(e) => e.stopPropagation()}
              // dateFormat="Y-m-d\\Z"
              options={{
                mode: "range",
                dateFormat: "Y-m-d"
              }}
            />
          </div>

          {isActive && (
            <>
              <Button.Ripple
                className="btn-icon hover-show clear-btn "
                color="flat-danger"
                onClick={() => {
                  onChange(["", ""], "", "RESET")
                  setDisplay()
                }}
              >
                <X size={16} />
              </Button.Ripple>
              <div className="hover-hide">
                <Check style={{ color: "#34a353" }} />
              </div>
            </>
          )}
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default PillDatePicker

const stopPropagation = (e) => {
  try {
    const { parentNode, className } = e
    return parentNode.nodeName != "BODY"
      ? stopPropagation(parentNode)
      : className.includes("flatpickr-calendar")
  } catch (error) {
    return false
  }
}
const style = {
  width: "100%",
  height: "100%",
  position: "relative",
  padding: "0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}
