export const tableColumn = [
    {
        name: 'POS Name',
        sortable: true,
        // minWidth: '200px',
        // maxWidth: '200px',
        // sortField: 'vendorId',
        // selector: row => row.vendorId,
        cell: (row) => <span>{row.posName}</span>
    },
    {
        name: 'Store Name',
        sortable: true,
        minWidth: '200px',
        // maxWidth: '200px',
        sortField: 'vendorId',
        // selector: row => row.vendorId,
        cell: (row) => <span>{row.storeName}</span>
    },
    {
        name: 'Sale Date',
        sortable: true,
        minWidth: '200px',
        // maxWidth: '200px',
        sortField: 'vendorId',
        // selector: row => row.vendorId,
        cell: (row) => <span>{row.saleDate}</span>
    },
    {
        name: 'Type',
        sortable: true,
        minWidth: '200px',
        // maxWidth: '200px',
        sortField: 'vendorId',
        // selector: row => row.vendorId,
        cell: (row) => <span>{row.type}</span>
    },
    {
        name: 'Action',
        sortable: true,
        minWidth: '200px',
        // maxWidth: '200px',
        sortField: 'vendorId',
        // selector: row => row.vendorId,
        cell: (row) => <span>{row.action}</span>
    }
]