import { decimalNumberFormatter } from "../Utils"
// import localesList from "./locales.json" //  BCP 47 format

// import { timeZoneObject } from "./timeZoneConverter"
// import { store } from "@src/redux/store.js"

// const currencyFormater = (number) => {
//   const currency = decimalNumberFormatter(number)
//   const timeZone =
//     store.getState().auth?.userData?.basicProfile?.timeZone ??
//     "(GMT-08:00) Pacific Time"
//   const zone = timeZoneObject[timeZone] ?? "America/Los_Angeles"

//   const locales = getLocaleForTimeZone(zone)
//   const formatedCurrency = currency.toLocaleString(locales)
//   console.log({ formatedCurrency })
//   return formatedCurrency
// }

// export default currencyFormater

// const getLocaleForTimeZone = (timeZone) => {
//   const formatter = new Intl.DateTimeFormat(undefined, { timeZone })
//   const locale = formatter.resolvedOptions().locale
//   console.log({ locale })
//   return locale
// }

const currencyFormater = (number) => {
  const currency = decimalNumberFormatter(number)
  const locales = "en-US"
  // const formatedCurrency = currency.toLocaleString(locales)
  return new Intl.NumberFormat(locales, {
    minimumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(currency)
  // return formatedCurrency
}

export default currencyFormater
