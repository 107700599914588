// ** React Imports
import { lazy } from "react"
import { permissions } from "../../views/role-management/store/permissionList"

const RoleManagement = lazy(() => import("../../views/role-management"))
const permissionList = Object.keys(permissions)
const configurationList = Object.keys(permissions.Configuration)
const AdminRoutes = [
  {
    path: "/role-management",
    element: <RoleManagement />,
    menuGroup: permissionList[0],
    accessId: configurationList[1],
    meta: {
      restricted: true,
      menuHidden: true
      // accessRestricted: true
    }
  }
]

export default AdminRoutes
