// ** React Imports
import React, { useEffect, useState } from "react"

// ** Third Party Imports
import GooglePlacesAutocomplete, {
  geocodeByAddress
} from "react-google-places-autocomplete"
// import { components, InputProps } from "react-select"

// ** .evn var
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY

// ** Style imports
import "./addressAutocomplete.scss"

/* 
    Custom component for google address autocomplete api.
    Before using this component, you should load google map script in component where did you use this.
    loadGoogleMapScript method is in Utlis.js file
*/
// const val = {
//   label: "Las Vegass, NV, USA",
//   value: {}
//   // value: { place_id: "ChIJ0X31pIK3voARo3mz1ebVzDo" }
//   // value: {
//   //   description: "Lascc Vegas, NV, USA",
//   //   matched_substrings: [
//   //     {
//   //       length: 4,
//   //       offset: 4
//   //     }
//   //   ]
//   //   // place_id: "ChIJ0X31pIK3voARo3mz1ebVzDo",
//   //   // reference: "ChIJ0X31pIK3voARo3mz1ebVzDo"
//   //   // structured_formatting: {
//   //   //   main_text: "Las Vegas",
//   //   //   main_text_matched_substrings: [
//   //   //     {
//   //   //       length: 4,
//   //   //       offset: 4
//   //   //     }
//   //   //   ],
//   //   //   secondary_text: "NV, USA"
//   //   // },
//   //   // terms: [
//   //   //   {
//   //   //     offset: 0,
//   //   //     value: "Las Vegas"
//   //   //   },
//   //   //   {
//   //   //     offset: 11,
//   //   //     value: "NV"
//   //   //   },
//   //   //   {
//   //   //     offset: 15,
//   //   //     value: "USA"
//   //   //   }
//   //   // ],
//   //   // types: ["locality", "political", "geocode"]
//   // }
// }
const AddressAutocomplete = ({
  onSelect,
  placeHolder,
  addressLine1,
  isInvalid
  // addressChange
}) => {
  const [address, setAddress] = useState(addressLine1)
  const [borderColor, setBorderColor] = useState("")
  const [focusColor, setFocusColor] = useState("")

  useEffect(() => {
    if (isInvalid) {
      setBorderColor("1px solid #ea5455")
      setFocusColor("1px solid #ea5455")
    } else {
      setBorderColor("1px solid #d8d6de")
      setFocusColor("1px solid #40dc65")
    }
  }, [isInvalid])

  const reFormatGoogleAddressComponents = (addressList) => {
    /*addressList Format Sample =>
        [
            {
            long_name: "Waters Place",
            short_name: "Waters Pl",
            types: ["route"],
            },
            {
            long_name: "Pelham Bay",
            short_name: "Pelham Bay",
            types: ["neighborhood", "political"],
            },
        ]*/
    const addressMap = {}
    addressList.map((item) => {
      if (item.types.length === 1) {
        addressMap[item.types[0]] = {
          long: item.long_name,
          short: item.short_name
        }
      } else if (item.types.length > 1) {
        const keyName =
          item.types[0] === "political" ? item.types[1] : item.types[0]
        addressMap[keyName] = {
          long: item.long_name,
          short: item.short_name
        }
      }
      return item
    })
    return addressMap
  }

  const formAddressWithoutFilter = ({ addressComponents, lat, long }) => {
    const addressMap = reFormatGoogleAddressComponents(addressComponents)
    return {
      addressLine1: `${addressMap["street_number"]?.long || ""} ${
        addressMap["route"]?.long || ""
      }`,
      addressLine2: "",
      city: addressMap["locality"]?.long || "",
      state: addressMap["administrative_area_level_1"]?.short || "",
      country: addressMap["country"]?.short || "",
      zipCode: addressMap["postal_code"]?.long || "",
      latitude: lat || "",
      longitude: long || ""
    }
  }

  const formatValue = (e) => {
    console.log(e)
    geocodeByAddress(e.value.description)
      .then((results) => {
        const formattedResponse = {
          addressComponents: results[0]["address_components"],
          lat: results[0]["geometry"]["location"]["lat"](),
          long: results[0]["geometry"]["location"]["lng"]()
        }
        const addressObj = formAddressWithoutFilter(formattedResponse)
        setAddress(addressObj.addressLine1)
        onSelect(addressObj)
      })
      .catch((error) => console.error(error))
  }

  const CustomInput = (innerProps) => (
    <div className="address-custom-wrapper">
      <input
        className=" css-yk16xz-control form-control custom-input"
        name="addressLine1"
        id="custom-address-input"
        onChange={(e) => {
          setAddress(e.target.value)
          console.log(address)
          innerProps.onChange(e)
        }}
        value={address}
      />
    </div>
  )

  return (
    <>
      <GooglePlacesAutocomplete
        apiKey={apiKey}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["us", "ca", "gu", "mp", "pr", "vi"]
          }
        }}
        selectProps={{
          defaultValue: { label: addressLine1, value: {} },
          onChange: (e) => formatValue(e),
          placeholder: placeHolder,
          styles: {
            control: (base, state) => ({
              ...base,
              border: state.isFocused ? focusColor : borderColor,
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
                border: state.isFocused ? focusColor : borderColor
              }
            })
          }
          // components : {Input: CustomInput}
          // defaultInputValue : addressLine1
        }}
      />
    </>
  )
}

export default AddressAutocomplete
