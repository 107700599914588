// ** React Imports
import { Fragment, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

// ** Custom Components
import NavbarUser from "./NavbarUser"

// ** Third Party Components
import { Sun, Moon, Menu, AlertCircle } from "react-feather"
import NavbarBookmarks from "./NavbarBookmarks"
import { searchArr } from "./searchConfig"
import CompanySwitch from "../../../../views/navbar/CompanySwitch"

// ** Reactstrap Imports
import { NavItem, NavLink } from "reactstrap"

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props

  const { pathname } = useLocation()

  const params = useParams()

  // ** Redux State
  const selector = useSelector((state) => state.auth.userData)
  const { companyType } =
    useSelector((state) => state.organizationStructure?.activeCompany) ?? {}
  const isCompanyPage = [
    "/v2/company",
    "/v2/view-profile",
    "/v2/manage-users",
    "/v2/role-management"
  ].includes(location.pathname)

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return <Sun className="ficon" onClick={() => setSkin("light")} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin("dark")} />
    }
  }

  const viewLink = `/v2/manage-users/detailview/${params.email}`
  const accessToken = localStorage.getItem("accessToken")

  const initFreshChat = () => {
    const token = process.env.REACT_APP_FRESHCHAT_TOKEN
    const host = process.env.REACT_APP_FRESHCHAT_HOST
    try {
      window.fcWidget.init({
        token,
        host,
        firstName: selector.firstName,
        lastName: selector.lastName,
        siteId: selector.tenantId,
        email: selector.userName
      })

      window.fcWidget.user.setProperties({
        firstName: selector.firstName,
        lastName: selector.lastName,
        siteId: selector.tenantId,
        email: selector.userName
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const envs = ["Sandbox"]

    const tenantId = [
      "uat_testing",
      "test",
      "uat_naturesbakery",
      "demo",
      "cratosys"
    ]
    if (
      envs.includes(process.env.REACT_APP_TYPE) &&
      tenantId.includes(selector?.tenantId || "")
    ) {
      initFreshChat()
    }
  })

  return (
    <Fragment>
      <div
        className="bookmark-wrapper d-flex align-items-center "
        style={{ fontSize: ".9rem" }}
      >
        {!isCompanyPage && (
          <ul className="navbar-nav d-xl-none ">
            <NavItem className="mobile-menu me-auto">
              <NavLink
                className="nav-menu-main menu-toggle hidden-xs is-active"
                onClick={() => setMenuVisibility(true)}
              >
                <Menu className="ficon" />
              </NavLink>
            </NavItem>
          </ul>
        )}
        {accessToken &&
          (searchArr.filter((o) => o.link === pathname).length > 0 ||
          pathname === viewLink ? (
            <NavbarBookmarks
            //  setMenuVisibility={setMenuVisibility}
            />
          ) : (
            <>
              <CompanySwitch /> <SandBoxEnv />
              <TestCompany companyType={companyType} />
            </>
          ))}
      </div>
      {/* <div style={{ positon: "relative" }}> */}

      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar

const TestCompany = ({ companyType }) => {
  if (!(companyType === "TEST_COMPANY")) return <></>
  return (
    <div className="uat-wrapper">
      <div className="uat-subtitle">
        Sample data is shown here.
        <p>
          See actual data for your business on the <strong>LIVE</strong>{" "}
          company.
        </p>
      </div>
      <h5 className="uat-title" style={{ padding: "5px 3rem 5px 1.6rem" }}>
        <AlertCircle color="white" /> TEST Company
      </h5>
    </div>
  )
}
const SandBoxEnv = () => {
  if (!(process.env.REACT_APP_TYPE === "Sandbox")) return <></>
  return (
    <div className="uat-wrapper">
      <div className="uat-subtitle">
        Sample data is shown here.
        <p>
          See actual data for your business on the <strong>LIVE</strong> site.
        </p>
      </div>
      <h5 className="uat-title">
        <AlertCircle color="white" /> TEST SITE
      </h5>
    </div>
  )
}
