import { useState } from "react"
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  CardSubtitle
} from "reactstrap"
import Chart from "react-apexcharts"

const rangeOptions = [
  {
    range: "Last 7 Days",
    data: [80, 35, 50]
  },
  {
    range: "Last month",
    data: [60, 90, 35]
  },
  {
    range: "Last Quater",
    data: [40, 80, 55]
  },
  {
    range: "Last Year",
    data: [20, 65, 85]
  }
]
const RemitanceWidget = () => {
  const [activeRange, setActiveRange] = useState(rangeOptions[0])
  const donutColors = {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#FFA1A1"
  }

  // ** Chart Options
  const options = {
    colors: [donutColors.series1, donutColors.series2, donutColors.series4],
    plotOptions: {
      radialBar: {
        size: 185,
        hollow: {
          size: "25%"
        },
        track: {
          margin: 15
        },
        dataLabels: {
          name: {
            fontSize: "2rem",
            fontFamily: "Montserrat"
          },
          value: {
            fontSize: "1rem",
            fontFamily: "Montserrat"
          },
          total: {
            show: true,
            fontSize: "1rem",
            label: "Remmitance",
            formatter() {
              return "80%"
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -35,
        bottom: -30
      }
    },
    legend: {
      show: true,
      position: "bottom"
    },
    stroke: {
      lineCap: "round"
    },
    labels: ["Unapplied", "Unidentified", "Missing Template"]
  }
  return (
    <Card>
      <CardHeader className="d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
        <CardTitle tag="h4">Remittance</CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
          >
            {activeRange.range}
          </DropdownToggle>
          <DropdownMenu end>
            {rangeOptions.map((item, i) => (
              <DropdownItem
                className="w-100"
                key={i}
                onClick={() => {
                  setActiveRange(item)
                }}
              >
                {item.range}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </CardHeader>
      <CardBody>
        <CardSubtitle
          style={{
            position: "absolute",
            left: "24px",
            top: "-13px",
            fontSize: "12px"
          }}
        >
          By Count
        </CardSubtitle>
        <Chart
          options={options}
          series={activeRange.data}
          type="radialBar"
          height={330}
        />
      </CardBody>
    </Card>
    // <div>DeductionWidget</div>
  )
}

export default RemitanceWidget
