import { Result } from "antd"
import React from "react"

const UnderMaintenance = () => {
  return (
    <Result
      status={500}
      title="Under Maintanence!"
      subTitle={
        <>
          Our apologies for the inconvenience. We're currently undergoing
          maintenance to improve your experience.
          <br /> We'll be back up and running shortly. Thank you for your
          patience
        </>
      }
    />
  )
}

export default UnderMaintenance
