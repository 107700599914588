import React from 'react'
import { Card, CardBody, CardText, Row, Col, Table } from 'reactstrap'
import logoCratoFlow from '../../../../../assets/images/logo/logo-full.png'
const RemittanceNoticeSample = () => {
    return (
        <div>
            <Card className='invoice-preview-card'>
                <CardBody className='invoice-padding pb-0'>
                    <div className='d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0'>
                        <div>
                            <div className='logo-wrapper'>
                                <img src={logoCratoFlow} width="140px" alt='logo' />
                            </div>
                            <div className='my-2'>
                                <CardText className='mb-25'>667 1/2 Amalia Ave</CardText>
                                <CardText className='mb-25'>Woodbridge Vaughan</CardText>
                                <CardText className='mb-25'>Los Angeles, CA, 90022, USA</CardText>
                            </div>

                        </div>
                        <div className='mt-md-0 mt-2'>
                            <h4 className='invoice-title'>
                                REMITTANCE ADVICE
                            </h4>
                            <div className='invoice-date-wrapper my-2'>
                                <p className='invoice-date-title'>Payment Date: <span className='fw-bolder ps-1'>23/08/2022</span></p>
                            </div>
                        </div>
                    </div>
                </CardBody>
                <hr className='invoice-spacing' />
                <CardBody className='invoice-padding py-2'>
                    <div className='invoice-spacing d-flex justify-content-between flex-md-row flex-column'>
                        <div className='p-0' md={9}>
                            <h6 className='mb-2 fw-bolder'>Remit Payment To:</h6>
                            <h6 className='mb-25'>Thomas shelby</h6>
                            <h6 className='mb-25'>Shelby Company Limited</h6>
                            <h6 className='mb-25'> Small Heath, B10 0HF, UK</h6>
                            <h6 className='mb-25 mt-2'>peakyFBlinders@gmail.com</h6>
                        </div>
                        <div className='p-0' md={3}>
                            <h6 className='mb-2 fw-bolder'>Payment Details:</h6>
                            <h6 className='mb-2'>Total amount paid : <span className='fw-bolder ps-1'>1690.00 USD</span></h6>
                            <h6 className='mb-2'>Mode of Payment  : <span className='fw-bolder ps-1'>ACH</span></h6>
                            <h6 className='mb-2'>Reference Number: <span className='fw-bolder ps-1'>ACH1234345455</span></h6>
                        </div>
                    </div>
                </CardBody>
                <hr className='invoice-spacing' />
                <Table responsive className='py-2'>
                    <thead>
                        <tr>
                            <th className='py-1'>INVOICE DATE</th>
                            <th className='py-1 text-center'>INVOICE NUMBER</th>
                            <th className='py-1 text-center'>INVOICE TOTAL</th>
                            <th className='py-1 text-center'>AMOUNT PAID</th>
                            <th className='py-1 text-center'>REMAINING</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='py-1'>
                                <p className='card-text text-nowrap'>
                                    08/12/2022
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    1523
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <span className='fw-bold'>1000.00</span>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    1000.00
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    0
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td className='py-1'>
                                <p className='card-text text-nowrap'>
                                    08/14/2022
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    1528
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <span className='fw-bold'>3000.00</span>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    300.00
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    0
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td className='py-1'>
                                <p className='card-text text-nowrap'>
                                    08/18/2022
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    1535
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <span className='fw-bold'>380.00</span>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    380.00
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    0
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td className='py-1 text-center'>
                                <span className='fw-bolder'>Total Paid</span>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    1680.00
                                </p>
                            </td>
                            <td className='py-1 text-center'>
                                <p className='card-text text-nowrap'>
                                    0
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <hr className='invoice-spacing' />
                <CardBody className='invoice-padding pt-2'>
                    <Row>
                        <Col sm='12'>
                            <span className='fw-bolder'>Note: {" "}</span>
                            <span>
                                It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!
                            </span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default RemittanceNoticeSample