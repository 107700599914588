import { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  InputGroup,
  InputGroupText
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import toast from "react-hot-toast"
// import { cloneDeep } from "lodash"
import Cleave from "cleave.js/react"
import { Switch } from "antd"
import "cleave.js/dist/addons/cleave-phone.us"
import ProgressButton from "@custom-components/buttons/ProgressButton"
import Mandatory from "@custom-components/mandatoy-star/Mandatory"
import { handleUserData } from "../../../../redux/authentication"
import { useLayout } from "@hooks/useLayout"
// import { handleUserData } from "@store/authentication"

import { updateUserProfile } from "../../service/manageUserService"
import usePost from "../../../../utility/hooks/usePost"
import { getStoreData } from "../../../../services/apiUrl"

const EditPersonalInfoModal = ({
  show,
  setShow,
  userProfileData,
  paramValue,
  getCall
}) => {
  const options = { phone: true, phoneRegionCode: "US" }

  const selector = useSelector((state) => state.auth.userData)
  const [loading, setLoding] = useState(false)
  const { setLayout } = useLayout()
  const dispatch = useDispatch()
  const activeCompany = localStorage.getItem("activeCompany")
  const activeEntity = localStorage.getItem("activeEntity")
  let params = {}
  params = activeCompany ? { ...params, clientId: activeCompany } : params
  params = activeEntity ? { ...params, entityId: activeEntity } : params
  const { makeRequest: getStoreApi } = usePost(getStoreData, "get")

  const [infoData, setInfoData] = useState({
    firstName: userProfileData?.firstName ?? "",
    lastName: userProfileData?.lastName ?? "",
    email: userProfileData?.contact?.emailId ?? "",
    contact: userProfileData?.registerdMobileNo ?? "",
    isActive: userProfileData?.isActive ?? true,
    mainLayout: userProfileData?.mainLayout
      ? userProfileData?.mainLayout
      : "vertical",
    menuCollapsed: userProfileData?.menuCollapsed ?? true
  })

  const isVertical = infoData.mainLayout == "vertical"

  useEffect(() => {
    setInfoData({
      firstName: userProfileData?.firstName ?? "",
      lastName: userProfileData?.lastName ?? "",
      email: userProfileData?.contact?.emailId ?? "",
      contact: userProfileData?.registerdMobileNo ?? "",
      isActive: userProfileData?.isActive ?? true,
      mainLayout: userProfileData?.mainLayout
        ? userProfileData?.mainLayout
        : "vertical",
      menuCollapsed: userProfileData?.menuCollapsed ?? true
    })
  }, [show])

  const onChange = (e, from) => {
    const name = from ?? e.target.name
    const value = e?.target?.value ?? ""
    setInfoData((s) => ({
      ...s,
      [name]: from ? e : value
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const {
      firstName,
      lastName,
      contact,
      isActive,
      email,
      mainLayout,
      menuCollapsed
    } = infoData
    if (!firstName || !lastName) return toast.error("Fill Mandatory Fields")

    const updatedInfo = {
      ...userProfileData.updatedInfo,
      updatedBy: selector.email,
      updatedOn: new Date()
    }
    const payload = {
      ...userProfileData,
      firstName,
      lastName,
      isActive,
      mainLayout,
      registerdMobileNo: contact,
      status: isActive ? "ACTIVE" : "INACTIVE",
      updatedInfo,
      menuCollapsed,
      ...(email ? { contact: { emailId: email } } : {})
    }

    try {
      setLoding(true)
      await updateUserProfile(payload)
      await getCall()
      const { userData } = await getStoreApi({ params })
      const { mainLayout, ...rest } = userData ?? {}
      dispatch(handleUserData({ ...rest, isAllEntity: userData.allEntity }))
      setLayout(mainLayout)
      toast.success("Record updated Successfully")
      setShow(false)
    } catch (error) {
      console.log(error)
      return toast.error("Something went wrong")
    } finally {
      setLoding(false)
    }
  }

  return (
    <Modal
      isOpen={show}
      className="modal-dialog-centered modal-lg"
      id="edit-personal-information"
    >
      <ModalHeader toggle={() => setShow(!show)} className="bg-transparent" />
      <ModalBody>
        <div className="d-flex justify-content-between">
          <span className="fs-4 fw-bold">Edit Personal Information</span>
          {paramValue === "manageUsers" && (
            <div className="d-flex align-items-center">
              <span className="text-center me-50">
                {infoData.isActive ? "Active" : "In-Active"}
              </span>
              <Switch
                className="ant-switch"
                defaultChecked={infoData.isActive}
                onChange={(e) => onChange(e, "isActive")}
                id="active-isActive"
              />
            </div>
          )}
        </div>
        <h2 className="text-center"></h2>
        <Row>
          <Col md={6} className="my-1">
            <Label>
              First Name <Mandatory />
            </Label>
            <Input
              type="text"
              name="firstName"
              value={infoData.firstName}
              onChange={onChange}
              id="edit-first-name"
            />
          </Col>
          <Col md={6} className="my-1">
            <Label>
              Last Name <Mandatory />
            </Label>
            <Input
              type="text"
              name="lastName"
              value={infoData.lastName}
              onChange={onChange}
              id="edit-last-name"
            />
          </Col>
          <Col md={6} className="my-1">
            <Label>Email</Label>
            <Input
              disabled
              type="text"
              name="email"
              value={infoData.email}
              id="edit-email"
            />
          </Col>
          <Col md={6} className="my-1">
            <Label>Contact</Label>
            <InputGroup className="input-group-merge">
              <InputGroupText>US (+1)</InputGroupText>
              <Cleave
                className="form-control"
                placeholder="1 234 567 8900"
                options={options}
                name="contact"
                id="phone-number"
                value={infoData.contact}
                onChange={onChange}
              />
            </InputGroup>
          </Col>
        </Row>
        {paramValue === "Profile" && (
          <div className="my-50">
            <span className="fs-5 me-1">Main Layout : </span>
            <Input
              type="radio"
              name="mainLayout"
              checked={infoData.mainLayout === "vertical"}
              value="vertical"
              onChange={onChange}
            />
            <span className="fs-5 mx-50">Vertical</span>
            <Input
              type="radio"
              name="mainLayout"
              checked={infoData.mainLayout === "horizontal"}
              value="horizontal"
              onChange={onChange}
            />
            <span className="fs-5 mx-50">Horizontal</span>
            {isVertical ? (
              <div className="my-50">
                <span className="fs-5 me-1">Menu : </span>
                <Input
                  type="radio"
                  name="menuCollapsed"
                  checked={infoData.menuCollapsed}
                  onChange={() => onChange(true, "menuCollapsed")}
                />
                <span className="fs-5 mx-50">Collapsed</span>
                <Input
                  type="radio"
                  name="menuCollapsed"
                  checked={!infoData.menuCollapsed}
                  onChange={() => onChange(false, "menuCollapsed")}
                />
                <span className="fs-5 mx-50">Expanded</span>
              </div>
            ) : null}
          </div>
        )}
        <div className="d-flex justify-content-center align-items-center my-2">
          <Button
            outline
            className="mx-1"
            onClick={() => {
              setShow(!show)
            }}
            id="personal-info-discard-btn"
          >
            Discard
          </Button>
          <ProgressButton
            className="mx-1"
            onClick={handleSubmit}
            loader={loading}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default EditPersonalInfoModal
