import { useState } from "react"
import {
  Card,
  CardHeader,
  CardTitle,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  CardBody,
  UncontrolledTooltip,
  CardSubtitle
} from "reactstrap"
import { PolarArea } from "react-chartjs-2"
const backgroundColor = [
  "#28c76f",
  "#cebf2c",
  "#7367f0",
  "#299aff",
  "#4f5d70",
  "#e88bff"
]

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: { duration: 500 },
  layout: {
    padding: {
      top: -5,
      bottom: -45
    }
  },
  scales: {
    r: {
      grid: { display: false },
      ticks: { display: false }
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
}
const rangeOptions = [
  {
    range: "Last 7 Days",
    data: {
      labels: [
        "Bank of Africa",
        "Bank of Asia",
        "Bank of Europe",
        "Bank of America",
        "Bank of Dubai",
        "Bank of Australia"
      ],
      value: [19, 17.5, 15, 13.5, 11, 9]
    }
  },
  {
    range: "Last month",
    data: {
      labels: [
        "Bank of America",
        "Bank of Africa",
        "Bank of Europe",
        "Bank of Dubai",
        "Bank of Australia",
        "Bank of Asia"
      ],
      value: [14, 16.5, 15, 11.5, 15, 6]
    }
  },
  {
    range: "Last Quater",
    data: {
      labels: [
        "Bank of Africa",
        "Bank of Asia",
        "Bank of Europe",
        "Bank of America",
        "Bank of Dubai",
        "Bank of Australia"
      ],
      value: [13.5, 15, 12, 11.5, 15, 11]
    }
  },
  {
    range: "Last Year",
    data: {
      labels: [
        "Bank of Africa",
        "Bank of Asia",
        "Bank of Europe",
        "Bank of America",
        "Bank of Dubai",
        "Bank of Australia"
      ],
      value: [11, 9, 15, 14.5, 11, 9, 11]
    }
  }
]
const CashInWidget = () => {
  const [activeRange, setActiveRange] = useState(rangeOptions[0])

  // ** Chart Data
  const data = {
    labels: activeRange.data.labels,
    datasets: [
      {
        borderWidth: 0,
        label: "Cash",
        data: activeRange.data.value,
        backgroundColor
      }
    ]
  }
  return (
    <Card>
      <CardHeader className="d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column position-relative">
        <CardTitle tag="h4">Cash In - Pending for Action</CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <DropdownToggle
            color=""
            style={{ color: "#33cc33" }}
            className="bg-transparent btn-sm border-0 p-50"
          >
            {activeRange.range}
          </DropdownToggle>
          <DropdownMenu end>
            {rangeOptions.map((item, i) => (
              <DropdownItem
                className="w-100"
                key={i}
                onClick={() => {
                  setActiveRange(item)
                }}
              >
                {item.range}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <CardSubtitle
          style={{
            position: "absolute",
            left: "24px",
            bottom: "9px",
            fontSize: "12px"
          }}
        >
          By Count
        </CardSubtitle>{" "}
      </CardHeader>

      <CardBody>
        <div style={{ height: "280px" }}>
          <PolarArea data={data} options={options} height={280} />
        </div>
        <div className="d-flex align-item-center justify-content-around">
          {data?.labels?.map((label, index) => (
            // <p>{item}</p>
            <div
              class="bullet bullet-sm bullet-bordered me-50 cScale"
              style={{
                backgroundColor: backgroundColor[index],
                cursor: "pointer"
              }}
              id={`cashIn${index}`}
            >
              <UncontrolledTooltip placement="top" target={`cashIn${index}`}>
                {label}
                {" - "}
                {data.datasets[0].data[index]}
              </UncontrolledTooltip>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  )
}

export default CashInWidget
