import { useRef, useState, useEffect } from "react"
import Wizard from "@components/wizard"
import { Command, CreditCard, Grid, Mail } from "react-feather"
import { cratopayConfigBreadCrumb } from "@custom-components/breadcrumb/breadcrumb-data/breadcrumbData"
import Breadcrumbs from "@custom-components/breadcrumb/Breadcrumbs"
import WizardSkeleton from "@custom-components/skeleton/WizardSkeleton"
import BankRegistration from "./components/BankRegistration"
import BatchPayments from "./components/BatchPayments"
import ApprovalSetup from "./components/ApprovalSetup"
import Notification from "./components/Notification"
import { useSelector } from "react-redux"
import { getClientDetail } from "../service/cratoPayService"
import BusinessRegistraction from "./components/business-registration"
import RegisteredPayees from "./components/RegisteredPayees"

const CratopayConfiguration = () => {
  const { userData } = useSelector((state) => state.auth)
  const ref = useRef(null)
  const [stepper, setStepper] = useState(null)
  const [clientData, setClientData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getClientDetail(userData.clientId)
      .then((res) => {
        //console.log(res.data)
        setClientData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }, [])
  const steps = [
    {
      id: "business-registration",
      title: "Business Registration",
      icon: <CreditCard size={18} />,
      content: (
        <BusinessRegistraction stepper={stepper} type="wizard-vertical" />
      )
      // hide: true
    },
    {
      id: "bank-registration",
      title: "Bank Registration",
      icon: <CreditCard size={18} />,
      content: <BankRegistration stepper={stepper} type="wizard-vertical" />
      // hide: true
    },
    {
      id: "registered-payees",
      title: "Registered Payees",
      icon: <CreditCard size={18} />,
      content: <RegisteredPayees stepper={stepper} type="wizard-vertical" />
    },
    {
      id: "batch-payments",
      title: "Batch Payments",
      icon: <Grid size={18} />,
      content: (
        <BatchPayments
          stepper={stepper}
          type="wizard-vertical"
          clientData={clientData}
          setClientData={setClientData}
        />
      )
    },
    {
      id: "approval-setup",
      title: "Approval Setup",
      icon: <Command size={18} />,
      content: (
        <ApprovalSetup
          stepper={stepper}
          type="wizard-vertical"
          clientData={clientData}
          setClientData={setClientData}
        />
      )
    },
    {
      id: "notifications",
      title: "Remittance",
      icon: <Mail size={18} />,
      content: (
        <Notification
          stepper={stepper}
          type="wizard-vertical"
          clientData={clientData}
          setClientData={setClientData}
        />
      ),
      hide: true
    }
  ]
  return (
    <div id="cratopay-bcrumbs-page">
      <Breadcrumbs
        withGoBack
        title="Cratopay"
        data={cratopayConfigBreadCrumb}
      />
      {loading ? (
        <WizardSkeleton />
      ) : (
        <>
          {clientData && clientData !== null && (
            <div className="vertical-wizard">
              <Wizard
                type="vertical"
                ref={ref}
                steps={steps.filter(
                  (item) => process.env.REACT_APP_TYPE !== "Prod" || !item.hide
                )}
                options={{
                  linear: false
                }}
                instance={(el) => setStepper(el)}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CratopayConfiguration
