import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from "reactstrap"
import { getS3DownloadUrl } from "../authentication/company-management/service/apiService"

export const CompanySwitch = () => {
  // ** State & Hooks
  const {
    auth: { userData },
    organizationStructure: {
      clientEntityList,
      activeEntity /* , activeCompany */
    }
  } = useSelector((state) => state)
  const accessedEntityIds =
    userData?.customRoles?.find(({ clientId }) => userData.clientId == clientId)
      ?.clientEntityId ?? []
  const [logoUrl, setLogoUrl] = useState("")

  useEffect(() => {
    if (userData && userData.basicProfile && userData.basicProfile.logo) {
      getS3DownloadUrl(userData.basicProfile.logo, userData.clientId).then(
        ({ data: { downloadURL } }) => {
          setLogoUrl(downloadURL)
        }
      )
    }
  }, [userData])

  const switchEntity = (entity) => {
    console.log(entity)
    localStorage.setItem("activeEntity", entity.clientEntityId)
    window.location.reload()
  }

  const setAllEntity = () => {
    localStorage.removeItem("activeEntity")
    window.location.reload()
  }
  const accessedEntityList = clientEntityList.filter(({ clientEntityId }) =>
    accessedEntityIds.includes(clientEntityId)
  )
  return (
    <>
      {logoUrl && (
        <div /* className='company-switch' */>
          <img
            src={logoUrl}
            style={{ maxWidth: "30px", maxHeight: "30px" }}
            className="company-switch-image"
          />
        </div>
      )}
      <div className="mx-1 d-none d-md-inline-block">
        <div className="m-0 font-weight-bolder">
          {userData?.client?.clientName} :
          <span
            className="company-switch-wrapper"
            style={{ fontSize: ".9rem" }}
          >
            <UncontrolledButtonDropdown>
              <DropdownToggle
                color="flat-secondary"
                caret
                className="company-switch-select"
              >
                {userData?.isAllEntity
                  ? "All Business Units"
                  : activeEntity?.clientEntityName}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  tag={Link}
                  to="/"
                  onClick={(e) => {
                    setAllEntity()
                    e.preventDefault()
                  }}
                >
                  All Business Units
                </DropdownItem>
                {accessedEntityList?.map((entity, index) => (
                  <DropdownItem
                    key={`${entity.clientEntityId} ${index}`}
                    tag={Link}
                    to="/"
                    onClick={(e) => {
                      switchEntity(entity)
                      e.preventDefault()
                    }}
                  >
                    {entity.clientEntityName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </span>
        </div>
        <Link to="/v2/company">Change Company</Link>
      </div>
    </>
  )
}

export default CompanySwitch
