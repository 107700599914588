// ** React Imports
import { Link, useLocation } from "react-router-dom"
import { Fragment } from "react"

// ** Third Party Components
import * as Icon from "react-feather"
// import Images from "../../../../assets/images/Images"

// ** Reactstrap Imports
import { NavItem, NavLink, UncontrolledTooltip } from "reactstrap"
import { searchArr as bookMarkList } from "./searchConfig"
import { useSelector } from "react-redux"
import { ReactComponent as CratoflowFullImg } from "@src/assets/images/svg/crato-full-img.svg"

const NavbarBookmarks = () => {
  const { pathname } = useLocation()
  const role =
    useSelector((state) => state?.auth?.userData?.preferredRole) ??
    "CRATO_ACCOUNTADMIN"
  const hasRoleAccess = ["CRATO_ACCOUNTADMIN", "SUPER_USER"].includes(role)
  // ** Loops through Bookmarks Array to return Bookmarks
  const renderBookmarks = () => {
    if (bookMarkList.length) {
      return (
        bookMarkList
          // .filter(({ id }) => canView || id == 1)
          .map((item) => {
            const IconTag = Icon[item.icon]
            if (item.title != "Home" && !hasRoleAccess)
              return <Fragment key={item.target} />
            return (
              <NavItem key={item.target} className="d-none d-sm-flex">
                <NavLink tag={Link} to={item.link} id={item.target}>
                  <IconTag
                    className="ficon"
                    color={pathname === item.link ? "#40dc65" : "#000"}
                  />
                  <UncontrolledTooltip target={item.target}>
                    {item.title}
                  </UncontrolledTooltip>
                </NavLink>
              </NavItem>
            )
          })
          .slice(0, 10)
      )
    } else {
      return null
    }
  }

  // ** If user has more than 10 bookmarks then add the extra Bookmarks to a dropdown
  /*const renderExtraBookmarksDropdown = () => {
    if (store.bookmarks.length && store.bookmarks.length >= 11) {
      return (
        <NavItem className='d-none d-lg-block'>
          <NavLink tag='span'>
            <UncontrolledDropdown>
              <DropdownToggle tag='span'>
                <Icon.ChevronDown className='ficon' />
              </DropdownToggle>
              <DropdownMenu end>
                {store.bookmarks
                  .map(item => {
                    const IconTag = Icon[item.icon]
                    return (
                      <DropdownItem tag={Link} to={item.link} key={item.id}>
                        <IconTag className='me-50' size={14} />
                        <span className='align-middle'>{item.title}</span>
                      </DropdownItem>
                    )
                  })
                  .slice(10)}
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavLink>
        </NavItem>
      )
    } else {
      return null
    }
  }*/
  return (
    <Fragment>
      {/* {false && (
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu me-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={() => setMenuVisibility(true)}
            >
              <Icon.Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
      )} */}
      <ul className="nav navbar-nav bookmark-icons">
        {renderBookmarks()}
        {/* {renderExtraBookmarksDropdown()} */}
        <CratoflowFullImg
          style={{ width: "150px", height: "40px" }}
          className="nav-cratoflow-logo d-none d-sm-flex"
        />
      </ul>
    </Fragment>
  )
}

export default NavbarBookmarks
