// ** Custom Components
import Avatar from '@components/avatar'
import Timeline from '@components/timeline'
import { getTimelineData } from '../../service/manageUserService'
import moment from "moment"
import * as _ from 'lodash'
// ** Images
//import pdf from '@src/assets/images/file-icons/pdf.png'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import { useEffect, useState } from 'react'

const TimeLineTab = ({ userProfileData }) => {
    const [data, setData] = useState([])
    // const colors = [
    //     { value: 1, color: 'danger' },
    //     { value: 2, color: 'secondary' },
    //     { value: 3, color: 'success' },
    //     { value: 4, color: 'primary' },
    //     { value: 5, color: 'warning' },
    //     { value: 6, color: 'dark' },
    //     { value: 6, color: 'light' }
    // ]

    const dateFormate = (date) => {
        return `${moment(date).format('DD')} ${moment(date).format('MMM')} ${moment(date).format('YYYY')} ${moment(date).format('hh:mm A')}`
    }
    useEffect(() => {
        getTimelineData(userProfileData.userId).then(res => {
            const descDate = _.orderBy(res.data, ['date'], ['desc'])
            const response = descDate
            const listForamt = []
            response.map((val) => {
                const content = val.description ? `${val.description} by ${val.user}` : val.user
                console.log(content)
                const timelineObj = {
                    title: val.currentProcess,
                    content,
                    meta: dateFormate(val.date),
                    // color: colors[Math.round(Math.random() * 6)].color
                    color: 'primary'
                }
                listForamt.push(timelineObj)
            })
            console.log(res)
            setData(listForamt)
        }).catch(res => console.log(res))
    }, [])

    return (
        <Card>
            <CardHeader>
                <CardTitle tag='h4'>User Activity Timeline</CardTitle>
            </CardHeader>
            <CardBody className='pt-1'>
                <Timeline data={data} className='ms-50' />
            </CardBody>
        </Card>
    )
}

export default TimeLineTab