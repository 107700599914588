import axios from "axios"
import jwtDefaultConfig from "./jwtDefaultConfig"
import qs from "qs"
const queryString = require("query-string")
import { store } from "@src/redux/store.js"

// ** Encryption service

import UrlService from "../../../services/urlConfig"

const urlService = new UrlService()

// ** Current Environment
const Env = process.env.REACT_APP_TYPE
const domain = process.env.REACT_APP_DOMAIN

const dashboardUrls = [
  "/ar/remittance/document-by-date/",
  "/cratomatchapi/bank-report/unbooked-summary",
  "/cratomatchapi/bank-report/cash-flow-report",
  "/filterapi/report/posted-invoices",
  "/filterapi/billprocesscount",
  "/filterapi/performancematrix",
  "/filterapi/topvendor",
  "/filterapi/topexceptions",
  "/filterapi/topexpense",
  "/cratomatchapi/cash-receipt-summary/generate-summary",
  "/cratomatchapi/cash-receipt-summary/generate-summary-date"
]
// ** Encryption
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  // ** All entities helper methods and variables
  entityStrings = ["clientEntityId", "entityId", "entityid"]
  apiStrings = [
    "filterapi/filter",
    "entityapiById",
    "ispageable",
    "getpaymenbatchdetails"
  ]

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const reduxStore = store.getState()
        // ** Get tenantId from localStorage

        // ** Url Query
        const query = new URL(config.url).search
        const reqUrl = new URL(config.url)

        // ** return if it is a validate user call
        if (config.url === this.jwtConfig.validateUser) {
          return config
        }

        // ** adding access token in auth apis and remove access token is url is signup
        if (
          config.url.includes("/cratoauth/") ||
          !config.url.includes("api.cratoflow")
        ) {
          const { Authorization, ...rest } = config.headers
          config.headers = { ...rest }
        } else {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        // ** if All entity is selected sending ALL in API url
        if (reduxStore.auth?.userData?.isAllEntity || false) {
          if (
            !this.apiUrlValidation(config.url) &&
            this.entityQueryValidation(query)
          ) {
            const parsed = queryString.parse(query)
            Object.keys(parsed).forEach((item) => {
              if (this.entityStrings.includes(item)) {
                parsed[item] = "ALL"
              }
            })
            const modquery = queryString.stringify(parsed)
            const modurl = `${reqUrl.origin}${reqUrl.pathname}?${modquery}`
            config.url = modurl
          }
        }

        if (config.url.includes("cratoerp")) {
          const integrationType =
            reduxStore.auth?.userData?.erpIntegrationType ?? "erp-not-synced"
          config.url = config.url.replace(
            "/test/",
            urlService.erpRoutes[integrationType]
          )
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response, config } = error
        console.log(response?.status)
        if (false && response?.status >= 500) {
          if (location.pathname == "/v2/dashboard") {
            console.log("Dashboard service broken")
            return Promise.reject(error)
          }
          if (false && dashboardUrls.some((url) => config.url.includes(url))) {
            console.log("DashBoard service broken")
            return Promise.reject(error)
          }

          this.navigateToServerError()
          return Promise.reject(error)
        }
        // ** if (status === 401) {
        if (response && response.status === 401) {
          this.navigateRoute()
          // if (!this.isAlreadyFetchingAccessToken) {
          //   this.isAlreadyFetchingAccessToken = true
          //   this.refreshToken().then(r => {
          //     this.isAlreadyFetchingAccessToken = false

          //     // ** Update accessToken in localStorage
          //     this.setToken(r.data.accessToken)
          //     this.setRefreshToken(r.data.refreshToken)

          //     this.onAccessTokenFetched(r.data.accessToken)
          //   })
          // }
          // const retryOriginalRequest = new Promise(resolve => {
          //   this.addSubscriber(accessToken => {
          //     // ** Make sure to assign accessToken according to your response.
          //     // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          //     // ** Change Authorization header
          //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          //     resolve(this.axios(originalRequest))
          //   })
          // })
          // return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  validateUser(...args) {
    return axios.post(
      this.jwtConfig.validateUser,
      ...args,
      this.jwtConfig.clientHeaders
    )
  }

  login(...args) {
    return axios.post(
      this.jwtConfig.loginEndpoint,
      qs.stringify(...args),
      this.jwtConfig.authHeaders
    )
  }

  logout() {
    const email = localStorage.getItem("email")
    return axios.delete(
      `${this.jwtConfig.logoutEndpoint}?emailId=${email}`,
      null,
      this.jwtConfig.clientHeaders
    )
  }
  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }

  apiUrlValidation = (url) => {
    return this.apiStrings
      .map((item) => {
        return url.includes(item)
      })
      .includes(true)
  }

  entityQueryValidation = (query) => {
    return this.entityStrings
      .map((item) => {
        return query.includes(item)
      })
      .includes(true)
  }

  navigateRoute() {
    console.log("from interceptor")
    localStorage.clear()
    const route = "/v2/login"
    const prefix =
      Env === "dev" ? "http://localhost:3000" : `https://app.${domain}`
    const url = `${prefix}${route}`
    window.location.href = url
  }
  navigateToServerError() {
    console.log("Service Down")
    const route = "/v2/server-error"
    const prefix =
      Env === "dev" ? "http://localhost:3000" : `https://app.${domain}`
    const url = `${prefix}${route}`
    location.href = url
  }
}
