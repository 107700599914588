import React, { useEffect, useState } from "react"
import { Button, Card, Input } from "reactstrap"
import Select from "react-select"
import toast from "@custom-components/toast.jsx"
import { cloneDeep } from "lodash"
import { useNavigate, useSearchParams } from "react-router-dom"
import VirtualTable from "@components/virtual-table-scroll"

import { selectThemeColors } from "@utils"
import { invoiceApi, putInvoice } from "@service/apiUrl"
import useFetch from "@hooks/useFetch"
import usePost from "@hooks/usePost"
import ProgressButton from "@custom-components/buttons/ProgressButton"
import useMisc from "@hooks/useMisc"

const LineItemTable = (props) => {
  const {
    data,
    headers,
    corpData,
    accountcodingOption,
    selectedItem,
    setSelectedItem,
    setData
  } = props

  const [selectedHeaders, setSelectedHeaders] = useState([])
  const [searchParam] = useSearchParams()
  const [setMiscData] = useMisc("EDIT_BILLS_LINE_ITEMS")
  const navigate = useNavigate()
  const urn = searchParam.get("q")

  const [invData] = useFetch(`${invoiceApi}/${urn}`)

  const { makeRequest: saveInvoiceApi, isLoading } = usePost(
    `${putInvoice}/${urn}`,
    "put"
  )
  useEffect(() => {
    const defaultSelectedHeaders = headers.map(({ value }) => value)
    setSelectedHeaders(defaultSelectedHeaders)
  }, [JSON.stringify(headers)])
  const handleCheckBoxAll = () => {
    const isAllChecked = data?.length == selectedItem?.length
    if (isAllChecked) return setSelectedItem([])
    const _selectedItem = data.map(({ _id }) => _id)
    setSelectedItem(_selectedItem)
  }
  const getMandatoryHeaders = () =>
    headers.reduce((p, n) => (n.isMandatory ? [...p, n.value] : p), [])
  const handleSingleBox = (id, checked) => {
    if (!checked) setSelectedItem((s) => s.filter((_id) => _id !== id))
    else setSelectedItem((s) => [...s, id])
  }

  const handleHeaderSelection = (e, idx) => {
    setSelectedHeaders((s) => {
      s[idx] = e?.value ?? ""
      return s.slice()
    })
  }

  const onCancel = () => {
    setSelectedHeaders([])
    setData([])
    setSelectedItem([])
  }

  const validateHeaders = () => {
    const hasMandatoryFields = getMandatoryHeaders().every((v) =>
      selectedHeaders.includes(v)
    )
    return hasMandatoryFields
  }

  const getExpenceCode = (data) => {
    function findGlValue({ value }) {
      const hasAccountCode = value.accountCode
        ? data?.trim() == value.accountCode
        : false
      const hasDescription = value.description
        ? data?.trim() == value.description
        : false
      return hasAccountCode || hasDescription
    }

    const glValue = accountcodingOption.find(findGlValue)?.value
    const glCodeDescription = glValue?.description?.trim() ?? ""
    const glCode = glValue?.accountCode ?? ""
    return { glCode, glCodeDescription }
  }

  const getAdditionalSegmentValue = (data, code) => {
    const options =
      corpData.find(({ segmentCode }) => segmentCode == code)?.options ?? []

    function findValue({ value }) {
      const hasAccountCode = value.accountCode
        ? data?.trim() == value.accountCode
        : false
      const hasAccountCodeName = value.accountCodeName
        ? data?.trim() == value.accountCodeName
        : false
      return hasAccountCode || hasAccountCodeName
    }
    const value = options.find(findValue)?.value
    return value
  }

  const savePayload = async (payload) => {
    try {
      const data = {
        ...invData,
        invoiceLineItems: [...(invData.invoiceLineItems ?? []), ...payload]
      }
      await saveInvoiceApi({ data })
      toast.success("Successfully imported")
      setMiscData({ pageMode: "LINE_ITEM_EDIT" })
      navigate(-1)
      onCancel()
    } catch (error) {
      toast.error("Error in importing, please try again later.")
    }
  }

  const onImport = () => {
    if (!validateHeaders()) return toast.error("Please fill mandatory fields.")

    const isAllSelected = selectedItem.length == data.length
    const filteredData = isAllSelected
      ? data
      : data.filter(({ _id }) => selectedItem.includes(_id))

    const additionalSegmentHeader = corpData.map(
      ({ segmentCode }) => segmentCode
    )
    let hasError = false
    const payload = filteredData.map((item) => {
      const obj = cloneDeep(defaultPayload)

      selectedHeaders.forEach((head, idx) => {
        const _value = Object.values(item).at(idx) ?? ""
        const value = _value.replace(/\t.*/, "")
        if (!head) return null
        if (getMandatoryHeaders().includes(head) && !value) {
          hasError = true
          toast.error(
            "Mandatory field value is empty. Please fill mandatory fields."
          )
        }
        if (additionalSegmentHeader.includes(head)) {
          const additionalSegmentValue = getAdditionalSegmentValue(value, head)
          // if (!additionalSegmentValue) return null
          obj.additionalSegment[head] = cloneDeep(additionalSegmentValue)
        } else if (head == "glCodeDescription")
          Object.assign(obj, getExpenceCode(value))
        // Use Object.assign to merge properties
        else obj[head] = value
      })

      return cloneDeep(obj)
    })

    if (hasError) return
    savePayload(payload)
  }

  const tableHead = (
    <thead style={{ background: "#f3f2f7" }}>
      <th>
        <div className="text-center">
          <Input
            style={{ width: "23px", height: "23px" }}
            className="mt-50"
            type="checkbox"
            checked={selectedItem?.length == data?.length}
            onChange={handleCheckBoxAll}
            id="select-lineItem-all"
          />
        </div>
      </th>
      <th className="p-1">
        <div className="pt-50 pb-0 px-0">S.No</div>
      </th>

      {headers?.map((_, id) => (
        <th
          key={id}
          className="text-nowrap"
          style={{ minWidth: "200px" }}
          id={`invoice-upload-select-${id}`}
        >
          <Select
            className="p-50 react-select"
            classNamePrefix="select"
            theme={selectThemeColors}
            options={headers.filter(
              ({ value }) => !selectedHeaders.includes(value)
            )}
            styles={MAX_HEIGHT}
            value={headers.find((data) => data.value === selectedHeaders[id])}
            onChange={(e) => handleHeaderSelection(e, id)}
            isClearable
            id={`select-invoice-${id}`}
          />
        </th>
      ))}
    </thead>
  )

  const tableRow = ({ index: rowIndex }) => {
    const rowData = cloneDeep(data[rowIndex])
    return (
      <tr
        key={rowIndex}
        className="text-nowrap"
        style={{ borderBottom: "1px solid #f3f2f7" }}
      >
        <td style={{ padding: ".8rem" }}>
          <div className="text-center">
            <Input
              style={{ width: "20px", height: "20px" }}
              className="mt-50"
              type="checkbox"
              checked={selectedItem?.includes(rowData._id)}
              id={`invoice-lineItem-${rowIndex}`}
              onChange={({ target }) =>
                handleSingleBox(rowData?._id, target.checked)
              }
            />
          </div>
        </td>
        <td style={{ padding: ".8rem" }}>{rowIndex + 1}.</td>
        {Object.entries(rowData).map(([key, colData], colIndex) => {
          if (key == "_id") return <></>
          return (
            <td key={colIndex} style={{ padding: ".8rem" }}>
              <div>{colData}</div>
            </td>
          )
        })}
      </tr>
    )
  }
  console.log({ data })
  return (
    <Card>
      <div className="my-2 ">
        <div className="text-end me-1">
          <Button color="danger" outline className="me-1" onClick={onCancel}>
            Cancel
          </Button>
          <ProgressButton
            disabled={!selectedItem.length}
            loader={isLoading}
            onClick={onImport}
          >
            {isLoading ? "Importing..." : "Import"}
          </ProgressButton>
        </div>
      </div>
      <VirtualTable
        itemCount={data?.length ?? 0}
        header={tableHead}
        row={tableRow}
      />
    </Card>
  )
}

export default LineItemTable

export const defaultPayload = {
  amount: 0,
  unit_price: 0,
  quantity: 0,
  unit: "", //uom
  description: "",
  product_code: "", //item code
  glCode: "",
  glCodeDescription: "", //expence code
  additionalSegment: {}
}

const MAX_HEIGHT = {
  menuList: (s) => ({ ...s, maxHeight: "180px" }),
  menu: (s) => ({ ...s, maxHeight: "180px" })
}
