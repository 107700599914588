import { Fragment, useState, useEffect } from "react"
import DataTable from "react-data-table-component"
import {
  Badge,
  Button,
  Card,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from "reactstrap"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import { cratoDeepClone, formatedValue as F, toCapitalize as TC } from "@utils"
import { useSelector } from "react-redux"
import { getAchVendors, linkVendorAccount } from "../../service/cratoPayService"
import { CustomSpinner } from "@custom-components/loadSpinner/CustomSpinner"
import { Skeletone } from "../../../../../custom-components/skeleton/skeleton"
import CustomPagination from "@custom-components/custom-pagination/CustomPagination"
import toast from "@custom-components/toast.jsx"
import ProgressButton from "@custom-components/buttons/ProgressButton"
import axios from "axios"
import { inviteAstraVendorUrl } from "../../../../../services/apiUrl"
import { useNavigate } from "react-router-dom"
// import { cratoDeepClone } from "@utils"

const redirectUrl = `https://redirect.cratoflow.${location.host.split(".")[2]}`
const RegisteredPayees = () => {
  const {
    clientId,
    client: { clientName, email }
  } = useSelector((state) => state.auth.userData)
  const [show, setShow] = useState(false)
  const [isLinking, setIsLinking] = useState(false)
  const [selectedVendor, setSelectedVendor] = useState({})
  const [vendors, setVendors] = useState([])
  const [vendorCount, setVendorCount] = useState(0)
  const [isUpdating, setIsUpdating] = useState(false)
  const [filters, setFilters] = useState({
    clientId: "",
    page: 1,
    count: 100,
    // paymentMode: "ACH",
    isActive: true,
    ispageable: true
  })
  const [isloading, setIsLoading] = useState(true)

  const handleGetAchVendors = async (obj = {}, initial) => {
    const filterStore = {
      ...filters,
      clientId,
      ...obj
    }
    setFilters(filterStore)
    try {
      if (!initial) setIsUpdating(true)
      const { data } = await getAchVendors(filterStore)
      setVendors(data.vendors)
      setVendorCount(data.totalCount)
      if (!initial) setIsUpdating(false)
    } catch (err) {
      console.log(err)
    } finally {
      if (initial) setIsLoading(false)
    }
  }

  useEffect(async () => {
    handleGetAchVendors(null, true)
  }, [])

  const handleLinkAcc = async () => {
    const {
      vendorId,
      vendorName,
      vendorRoutingNumber,
      vendorBankAccount,
      vendorBankAccountType
    } = selectedVendor
    if (
      !(
        vendorName &&
        vendorRoutingNumber &&
        vendorBankAccount &&
        vendorBankAccountType
      )
    ) {
      toast.error(
        `${!vendorName ? "Vendor Name, " : ""} ${
          !vendorRoutingNumber ? "Routing Number, " : ""
        }${!vendorBankAccount ? "Account Number, " : ""}${
          !vendorBankAccountType ? "Account Type " : ""
        } are requried, please fill this details.`
      )
      return
    }
    const payload = {
      vendorId,
      vendorName,
      vendorRoutingNumber,
      vendorBankAccount,
      vendorAccountType: vendorBankAccountType,
      isLinkedforPayment: true
    }
    console.log(payload)
    setIsLinking(true)
    try {
      const vendorsStore = cratoDeepClone(vendors)
      const { data } = await linkVendorAccount(clientId, payload)
      vendorsStore.splice(selectedVendor.index, 1, {
        ...selectedVendor,
        ...(payload ?? data)
      })
      setVendors(vendorsStore)
      toast.success("Linked Successfully")
    } catch (err) {
      console.log(err)
      toast.error("Try Again")
    } finally {
      setIsLinking(false)
      onClose()
    }
  }

  const paginationComponent = () => {
    const { count, page } = filters
    const formatedPage = `${1 + count * (page - 1)} - ${
      count * page < vendorCount ? count * page : vendorCount
    }`
    return (
      <CustomPagination
        showCount={`${formatedPage} of ${vendorCount}`}
        pageCount={Math.ceil(vendorCount / count) || 0}
        onPageChange={({ selected }) =>
          handleGetAchVendors({ page: selected + 1 })
        }
        forcePage={page !== 0 ? page - 1 : 0}
      />
    )
  }

  const getVendordata = (data) => {
    setShow(true)
    setSelectedVendor(data)
    console.log(selectedVendor)
    return
    handleLinkAcc()
  }
  const onClose = () => {
    setSelectedVendor({})
    setShow(false)
  }

  return (
    <Fragment>
      <div className="mb-2">List of registered payees / vendors</div>

      <div className="react-dataTable">
        {isloading ? (
          <Skeletone isloading={isloading} />
        ) : (
          <DataTable
            noHeader
            fixedHeader
            fixedHeaderScrollHeight="400px"
            subHeader
            pagination
            responsive
            paginationServer
            columns={tableColumn(getVendordata, clientName, email, setVendors)}
            className="react-dataTable"
            paginationComponent={paginationComponent}
            data={vendors}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex align-items-center p-1">
                  <label htmlFor="rows-per-page">Show</label>
                  <Input
                    className="mx-50"
                    type="select"
                    id="rows-per-page"
                    value={filters.count}
                    onChange={(e) =>
                      handleGetAchVendors({ count: e.target.value, page: 1 })
                    }
                    style={{ width: "5rem" }}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </Input>
                </div>
                {isUpdating && <CustomSpinner />}
              </div>
            }
          />
        )}
      </div>
      {show && (
        <Modal
          isOpen={show}
          toggle={onClose}
          className="modal-dialog-centered modal-md"
          id="exp-cratopay-modal"
        >
          <ModalHeader
            className="bg-transparent"
            toggle={onClose}
          ></ModalHeader>
          <ModalBody>
            <h1 className="text-center mb-1">Payee Details</h1>
            <div className="pe-3 ps-5 py-2">
              <div className="d-flex gap-1 mb-1">
                <div className="fw-bolder" style={{ width: "123px" }}>
                  Vendor Name
                </div>
                <div>: {selectedVendor.vendorName || "-"}</div>
              </div>
              <div className="d-flex gap-1 mb-1">
                <div className=" fw-bolder" style={{ width: "123px" }}>
                  Routing Number
                </div>
                <div>: {selectedVendor.vendorRoutingNumber || "-"}</div>
              </div>
              <div className="d-flex gap-1 mb-1">
                <div className=" fw-bolder" style={{ width: "123px" }}>
                  Bank Account
                </div>
                <div>: {selectedVendor.vendorBankAccount || "-"}</div>
              </div>
              <div className="d-flex gap-1 mb-1">
                <div className=" fw-bolder" style={{ width: "123px" }}>
                  Account Type
                </div>
                <div>: {selectedVendor.vendorBankAccountType || "-"}</div>
              </div>
            </div>
            <div className="d-flex justify-content-center pb-1">
              <Button
                color="danger"
                outline
                onClick={onClose}
                id="payee-discard-btn"
              >
                Discard
              </Button>
              <ProgressButton
                className="ms-1"
                disabled={isLinking}
                name="Link Now"
                loaderName="Linking"
                loader={isLinking}
                onClick={handleLinkAcc}
              />
            </div>
          </ModalBody>
        </Modal>
      )}
    </Fragment>
  )
}

export default RegisteredPayees

const tableColumn = (getVendordata, clientName, email, setVendors) => [
  // {
  //   name: "Cratopay Network vendor",
  //   // minWidth: "160px",
  //   // maxWidth: "200px",
  //   cell: () => <div>{ }</div>
  // },
  {
    name: "Vendor Id",
    minWidth: "130px",
    maxWidth: "130px",
    cell: (row) => <div>{row.vendorCode ?? ""}</div>
  },
  {
    name: "Vendor Name",
    // minWidth: "160px",
    // maxWidth: "200px",
    cell: (row) => <div>{F(25, row.vendorName)}</div>
  },
  {
    name: "Email",
    // minWidth: "160px",
    // maxWidth: "200px",
    cell: (row) => <div>{F(25, row.email)}</div>
  },
  {
    name: "Payment Mode",
    minWidth: "160px",
    maxWidth: "160px",
    cell: (row) => <div>{row.preferredPaymentMethod ?? ""}</div>
  },
  {
    name: "Status",
    minWidth: "130px",
    maxWidth: "140px",
    center: true,
    cell: (row, index) => {
      const isAchVendor = row.preferredPaymentMethod?.toLowerCase() === "ach"
      const [loading, setLoading] = useState(false)
      const navigate = useNavigate()

      const inviteVendor = async (vendor) => {
        const payload = {
          vendorId: vendor.vendorId,
          vendorName: vendor.vendorName,
          vendorRoutingNumber: vendor.vendorRoutingNumber,
          vendorBankAccount: vendor.vendorBankAccount,
          vendorAccountType: vendor.vendorBankAccountType,
          isLinkedforPayment: vendor.isLinkedforPayment,
          clientId: vendor.clientId,
          clientName,
          emailId: vendor.email,
          clientEmail: email
        }
        console.log(payload)
        setLoading(true)
        try {
          const res = await axios.post(
            `${inviteAstraVendorUrl}?host=${redirectUrl}`,
            payload
          )
          console.log(res)
          setVendors((s) =>
            s.map((v) =>
              vendor.vendorId == v.vendorId
                ? { ...v, paymentProcessStatus: "INVITE_SENT" }
                : v
            )
          )
          toast.success("Vendor invited Successfuly")
        } catch ({ response }) {
          console.log(response)
          toast.error(
            response?.data?.message
              ? response.data.message
              : response.data.error
          )
        } finally {
          setLoading(false)
        }
      }
      const handleViewVendor = (id) => {
        navigate(`/v2/ap/vendor/view/${id}?fromPage=vendor`)
      }
      if (loading) return <Spinner color="primary" />
      const checkStatus = ["pending", "verified", "failed", "UPDATED"].includes(
        row.paymentProcessStatus
      )
      const isFailed = row.paymentProcessStatus == "failed"
      if (checkStatus)
        return (
          <Badge color={isFailed ? "light-danger" : "light-success"}>
            {TC(row.paymentProcessStatus, "_")}
          </Badge>
        )

      if (row.paymentProcessStatus == "INVITE_SENT")
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Badge>Invited</Badge>
            <div
              onClick={() => {
                inviteVendor(row)
              }}
              className={
                row.email
                  ? `cursor-pointer text-decoration-underline text-primary`
                  : "text-secondary"
              }
            >
              Resend
            </div>
          </div>
        )
      return (
        <div
          onClick={() => {
            if (!isAchVendor) return handleViewVendor(row.vendorId)

            inviteVendor(row)
            return
            if (!row.email) return
            getVendordata({ ...row, index })
          }}
          className={
            row.email
              ? `cursor-pointer text-decoration-underline text-primary`
              : "text-secondary"
          }
        >
          {isAchVendor ? "Invite Now" : "View Vendor"}
        </div>
      )
    }
  }
]
